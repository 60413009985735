import {
    SET_VENDOR_NAME,
    SET_VENDOR_SERVICE,
    SET_LICENSE_NUMBER,
    SET_PAYEMENT_TYPE,
    SET_FIXED_AMOUNT,
    SET_ACCOUNT_NUMBER,
    SET_BANK_NAME,
    SET_BANK_IFSC,
    SET_BANK_BRANCH,
    RESET_VENDOR,
    SET_VENDOR_CONTACT,
    SET_ALL_VENDORS,
    SET_SINGLE_VENDOR,
    SET_IS_FIXED,
    SET_STARTING_AFTER_VENDORS,
    SET_ONLY_ALL_VENDORS,
    SET_CONTACT_NUM,
    SET_CONTACT_NAME,
    SET_OTHER_CONTACT,
    SET_VENDORS_DETAILS,
    SET_BUILDING_VENDORS,
    SET_VENDOR_TYPE,
    SET_VENDOR_IMAGE,
    SET_CONTACT_IMAGE,
    RESET,

    SET_ID_PROOF_NUMBER,
    SET_ID_PROOF_IMAGE,
    SET_ID_PROOF_ID
} from "../../constants/vendors/vendors_const";
import { LIMIT } from "../../constants/ActionTypes";
const initial_state = {
    payment_types: ["credit", "debit", "both"],
    branch: "",
    bank_ifsc: "",
    Bank_name: "",
    account_no: "",
    amount: "",
    payment_type: "",
    license_no: "",
    vendor_service: "",
    vendor_name: "",
    vendor_image: null,
    contact: "",
    all_vendors: [],
    single_vendor_details: [],
    is_fixed_payment: false,
    vendor_id: "",
    limit: LIMIT,
    starting_after: 0,
    end_var: 0,
    start_var: 0,
    total: 0,
    end_var2: 0,
    start_var2: 0,
    total2: 0,
    contact_name: "",
    contact_num: "",
    other_contact: [],
    vendor_details: [],
    building_vendor: [],
    rendering_array: false,
    contact_image: null,
    vendor_type: "",
    vendorIndividualData: [],
    vendorIdNumber: "",
    vendorIdType: "",
    vendorIdImage: "",
    vendorIdImageToUpload: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case RESET:
            return state = { ...state, starting_after: 0, all_vendors: [] };
        case SET_ID_PROOF_NUMBER:
            return state = { ...state, vendorIdType: action.payload };
        case SET_ID_PROOF_IMAGE:
            return state = { ...state, vendorIdImageToUpload: action.payload };
        case SET_ID_PROOF_ID:
            return state = { ...state, vendorIdNumber: action.payload };
        case SET_IS_FIXED:
            return state = { ...state, is_fixed_payment: action.payload };
        case SET_STARTING_AFTER_VENDORS:
            return state = { ...state, starting_after: action.payload };
        case SET_VENDORS_DETAILS:
            return state = { ...state, vendor_details: action.payload };
        case SET_VENDOR_CONTACT:
            return state = { ...state, contact: action.payload };
        case SET_VENDOR_NAME:
            return state = { ...state, vendor_name: action.payload };
        case SET_VENDOR_SERVICE:
            return state = { ...state, vendor_service: action.payload };
        case SET_LICENSE_NUMBER:
            return state = { ...state, license_no: action.payload };
        case SET_PAYEMENT_TYPE:
            return state = { ...state, payment_type: action.payload, }
        case SET_FIXED_AMOUNT:
            return state = { ...state, amount: action.payload, }
        case SET_ACCOUNT_NUMBER:
            return state = { ...state, account_no: action.payload, }
        case SET_BANK_NAME:
            return state = { ...state, Bank_name: action.payload, }
        case SET_BANK_IFSC:
            return state = { ...state, bank_ifsc: action.payload, }
        case SET_BANK_BRANCH:
            return state = { ...state, branch: action.payload, }
        case SET_CONTACT_NUM:
            return state = { ...state, contact_num: action.payload, }
        case SET_CONTACT_NAME:
            return state = { ...state, contact_name: action.payload }
        case SET_OTHER_CONTACT:
            return state = { ...state, other_contact: action.payload }
        case SET_VENDOR_TYPE:
            return state = { ...state, vendor_type: action.payload }
        case SET_VENDOR_IMAGE:
            return state = { ...state, vendor_image: action.payload }
        case SET_CONTACT_IMAGE:
            return state = { ...state, contact_image: action.payload }
        case SET_SINGLE_VENDOR:
            return state = {
                ...state,
                vendorIdNumber: action.payload.vendor_id_number,
                vendorIdType: action.payload.vendor_id_type,
                vendorIdImage: action.payload.vendor_id_image,
                is_fixed_payment: action.payload.is_fixed_payment,
                account_no: action.payload.vendor_account_no,
                vendor_type: action.payload.vendor_type,
                amount: action.payload.amount,
                branch: action.payload.vendor_bank_branch,
                bank_ifsc: action.payload.vendor_bank_ifsc,
                Bank_name: action.payload.vendor_bank_name,
                license_no: action.payload.vendor_govt_license_no,
                vendor_name: action.payload.vendor_name,
                payment_type: action.payload.vendor_payment_type,
                vendor_service: action.payload.vendor_service,
                contact: action.payload.vendor_contact_info,
                vendor_id: action.payload._id,
                vendor_image: action.payload.vendor_image,
                other_contact: action.payload.vendor_other_contacts
            };
        case SET_BUILDING_VENDORS:
            return state = { ...state, building_vendor: action.payload.response, start_var2: action.payload.f, end_var2: action.payload.s, total2: action.payload.total };
        case SET_ALL_VENDORS:
            return state = { ...state, starting_after: action.payload.starting_after, all_vendors: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, rendering_array: false };
        case RESET_VENDOR:
            return state = {
                ...state,
                branch: "",
                bank_ifsc: "",
                Bank_name: "",
                account_no: "",
                amount: "",
                payment_type: "",
                license_no: "",
                vendor_service: "",
                vendor_name: "",
                vendor_image: null,
                contact: "",
                all_vendors: [],
                single_vendor_details: [],
                is_fixed_payment: false,
                vendor_id: "",
                starting_after: 0,
                end_var: 0,
                start_var: 0,
                total: 0,
                end_var2: 0,
                start_var2: 0,
                total2: 0,
                contact_name: "",
                contact_num: "",
                other_contact: [],
                vendor_details: [],
                building_vendor: [],
                rendering_array: [],
                contact_image: null,
                vendor_type: "",
            }
        case SET_ONLY_ALL_VENDORS:
            return state = { ...state, all_vendors: action.payload.array, rendering_array: action.payload.arr }
        default:
            return state;
    }
} 