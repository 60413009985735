import React from "react";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class ViewVendorCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            editredirect: false,
            delete_redirect: false,
            category_id: "",
            category_name: ""
        }
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                redirect: true
            },
                function () {
                    this.props.get_all_vendor_category(this.props.login.token);
                })
        }
        else {
            this.props.get_all_vendor_category(this.props.login.token);
        }
    }
    checkRedirect = () => {
        this.setState({
            redirect: true,
        })
    }
    ed = (s) => {
        this.setState({
            vendor_category_id: s.vendor_category_id,
            vendor_category_name: s.vendor_category_name
        })
    }
    render() {
        const {
            login,
            vendor_category,
            validate_add_vendor_category,
            delete_vendor_category,
            validate_update_vendor_category,
            set_category_name,
            snackbar,
            close_snack_bar,
        } = this.props;
        return (
            <Grid container spacing={8} justify="center">
                <Grid item sm={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                <h3>
                                    VIEW VENDOR CATEGORY
                                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent >
                            <Grid item xs={12} >
                                <IconButton
                                    onClick={this.checkRedirect.bind(this)}
                                >
                                    <Icon >add</Icon>
                                </IconButton>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                                            {login.type === "SA" ? <TableCell align="right">Action</TableCell> : ""}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vendor_category.all_vendor_category.map(row => (
                                            <TableRow key={row.vendor_category_id} >
                                                <TableCell align="left" className="table_cells">
                                                    &nbsp;&nbsp;{row.vendor_category_name}
                                                </TableCell>
                                                {login.type === "SA" ?
                                                    <TableCell align="right" className="table_cells">
                                                        <IconButton
                                                            onClick={() => {
                                                                this.ed(row)
                                                                this.setState({
                                                                    editredirect: true,
                                                                    category_id: row._id
                                                                });
                                                            }}>
                                                            <Icon>edit</Icon>
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({
                                                                    delete_redirect: true,
                                                                    vendor_category_id: row.vendor_category_id
                                                                });
                                                            }}>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </TableCell> : ""}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Dialog
                        open={this.state.redirect}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Enter Category Name</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogContent>
                            <Grid container>
                                <Grid container>
                                    <TextField
                                        id="outlined-email-input"
                                        label="Category Name"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendor_category.category_name}
                                        onChange={(event) => { set_category_name(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                    <br /><br /><br />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <br />
                            <Button
                                onClick={() => {
                                    this.setState({
                                        redirect: false,
                                    });
                                }}
                                color="primary">
                                Close
                            </Button>
                            {vendor_category.category_name === "" ?
                                <Button
                                    disabled={true}
                                    color="primary"
                                >
                                    ADD
                                </Button>
                                :
                                <Button
                                    onClick={() => {
                                        validate_add_vendor_category(login.token, vendor_category)
                                        this.setState({
                                            redirect: false
                                        })
                                    }}
                                    color="primary"
                                >
                                    ADD
                            </Button>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.editredirect}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogContent>
                            <Grid container>
                                <Grid container>
                                    <TextField
                                        id="outlined-email-input"
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.vendor_category_name}
                                        onChange={(event) => { this.setState({ vendor_category_name: (event.target.value) }) }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                    <br /><br /><br />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <br /><br /><br />
                            <Button
                                onClick={() => {
                                    this.setState({
                                        editredirect: false,
                                    });
                                }}
                                color="primary">
                                Close
              </Button>
                            <Button
                                onClick={() => {
                                    validate_update_vendor_category(login.token, this.state.vendor_category_id, this.state.vendor_category_name)
                                    this.setState({
                                        redirect: false,
                                        editredirect: false
                                    })
                                }}
                                color="primary" autoFocus>
                                UPDATE
              </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.delete_redirect}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete The Vendor Category</DialogTitle>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        delete_redirect: false,
                                        category_id: ""
                                    });
                                }}
                                color="primary">
                                NO
                </Button>
                            <Button
                                onClick={() => {
                                    delete_vendor_category(login.token, this.state.vendor_category_id)
                                    this.setState({
                                        delete_redirect: false,
                                        category_id: ""
                                    })
                                }}
                                color="primary" autoFocus>
                                Yes
              </Button>
                        </DialogActions>
                    </Dialog>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(ViewVendorCategory);