import React, { Component } from "react";
import { connect } from "react-redux";
import CreateVisitorEntry from "../../components/visitors/create_visitor_entry";
import {
    validate_visitor_func,
    reset_all,
    set_visitor_date_of_visit,
    set_visitor_unit_name,
    set_visitor_vehicle_num,
    set_visitor_vehicle_type,
    set_visitor_mob_num,
    set_visitor_name,
    set_purpose_of_visit,
    set_purpose_of_visit_other
} from "../../actions/visitors/visitors_action";
import {
    get_all_vehicles
} from "../../actions/vehicles/vehicles_action"
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    get_same_units,
} from "../../actions/units/units_action";
export class Create_visitor_cont extends Component {
    render() {
        return (
            <CreateVisitorEntry {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        visitors: store.visitors,
        vehicles: store.vehicles,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        units: store.units,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_same_units: (token, building_id) => {
            dispatch(get_same_units(token, building_id))
        },
        get_all_vehicles: (token) => {
            dispatch(get_all_vehicles(token))
        },
        set_visitor_date_of_visit: (payload) => {
            dispatch(set_visitor_date_of_visit(payload))
        },
        validate_visitor_func: (token, building_id, visitors) => {
            dispatch(validate_visitor_func(token, building_id, visitors))
        },
        set_visitor_unit_name: (payload) => {
            dispatch(set_visitor_unit_name(payload));
        },
        set_visitor_vehicle_num: (payload) => {
            dispatch(set_visitor_vehicle_num(payload));
        },
        set_visitor_vehicle_type: (payload) => {
            dispatch(set_visitor_vehicle_type(payload));
        },
        set_visitor_mob_num: (payload) => {
            dispatch(set_visitor_mob_num(payload));
        },
        set_visitor_name: (payload) => {
            dispatch(set_visitor_name(payload));
        },
        set_purpose_of_visit: (payload) => {
            dispatch(set_purpose_of_visit(payload));
        },
        set_purpose_of_visit_other: (payload) => {
            dispatch(set_purpose_of_visit_other(payload));
        },
        reset_all: () => {
            dispatch(reset_all());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Create_visitor_cont);