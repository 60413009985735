import React, { Component } from "react/index.js";
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";
import UNIVERSAL from "../../config/config";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import "../../styles/style.scss";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_redirect: false,
      delete_redirect: false,
      name: "",
      desc: "",
      id: "",
      building_id: "",
      type_id: "",
      square_feet: "",
      final_level: [],
      anchorEl: null,
      parent_id: "",
      del_check: false,
      finalUnit: "false",
      parent_type: ""
    }
  }
  componentDidMount() {
    this.props.set_reset();
    this.props.get_all_units(this.props.login.token, this.props.login.building_id);
    this.props.get_all_units_type(this.props.login.token, this.props.login.building_id);
    var a = this.props.location.search.split("=");
    this.setState({
      parent_id: a[1],
      finalUnit: a[2],
      parent_type: a[3]
    }, function () {
      this.props.get_final_unit_details(this.props.login.token, this.state.parent_id, this.props.login.building_id, "")
    })
  }
  componentWillReceiveProps(next) {
    if (next.location.search !== this.props.location.search) {
      this.props.set_reset();
      var a = next.location.search.split("=");
      this.setState({
        finalUnit: a[2],
        parent_id: a[1],
        parent_type: a[3]
      })
      this.props.get_final_unit_details(this.props.login.token, a[1], this.props.login.building_id, "");
    }
  }
  set_del_check() {
    for (var i = 0; i < this.props.units.final_units.length; i++) {
      if (this.props.units.final_units[i].checked === true) {
        this.setState({
          del_check: true
        });
        break;
      }
      else {
        this.setState({
          del_check: false
        })
      }
    }
  }
  edit_single_unit = (s) => {
    this.setState({
      editredirect: true,
      name: s.unit_name,
      desc: s.unit_desc,
      id: s._id,
      building_id: s.building_id,
      type_id: s.unit_type_id,
      square_feet: s.square_feet,
      parent_id: s.unit_parent_id,
    })
    this.props.get_all_parent(s.unit_type_id, this.props.login.token, this.props.login.building_id, this.props.units.units_types);
  }
  render() {
    const {
      units,
      snackbar,
      login,
      close_snack_bar,
      prev_btn,
      next_btn,
      toggleCheckbox1,
      update_single_unit,
      delete_single_unit,
      handleMenu,
      import_file_func,
      search_unit
    } = this.props;
    var btn;
    if (units.total > units.limit) {
      if (units.end_var === units.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, units.limit, units.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {units.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{units.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{units.total}
        </span>
      }
      else if (units.start_var > units.limit && units.end_var < units.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(this.props.login.token, login.building_id, units.limit, units.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(this.props.login.token, login.building_id, units.limit, units.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {units.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{units.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{units.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(login.token, login.building_id, units.limit, units.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {units.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{units.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{units.total}
          </span>
      }
    }
    else {
      btn = <span>
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {units.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{units.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{units.total}
      </span>
    }


    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW UNITS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item lg={12} xs={12}>
                <Link to="add_units" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                {this.state.del_check === true &&
                  <IconButton
                    onClick={() => {
                      this.setState({
                        delete_redirect: true,
                      })
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                }

                {this.state.finalUnit === "true" &&
                  <div style={{ float: "right" }}>

                    <IconButton
                      onClick={() => {
                        this.props.set_reset();
                        this.props.get_all_units(this.props.login.token, this.props.login.building_id);
                        this.props.get_all_units_type(this.props.login.token, this.props.login.building_id);
                        var a = this.props.location.search.split("=");
                        this.setState({
                          parent_id: a[1],
                          finalUnit: a[2],
                          parent_type: a[3]
                        }, function () {
                          this.props.get_final_unit_details(this.props.login.token, this.state.parent_id, this.props.login.building_id, "")
                        })
                      }}
                    >
                      <Icon>
                        refresh
                      </Icon>
                    </IconButton>
                    &nbsp;
                    <a
                      style={{ textDecoration: "none" }}
                      href={UNIVERSAL.BASEURL + "v1/unit_excelsheet_export"}
                    >
                      <Button
                        variant="outlined"
                        className="submitBtn"
                      >
                        Export
                      </Button>
                    </a>
                    <input
                      id="outlined-name"
                      type="file"
                      label="Import"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => { import_file_func(event.target.files, login.token, login.building_id, this.state.parent_id, this.state.parent_type); }}
                      ref={input => this.inputElement = input}
                      style={{ opacity: 0, width: 0 }}
                    />
                    <Button
                      style={{ marginLeft: 10 }}
                      variant="outlined"
                      onClick={() => {
                        this.inputElement.click();
                      }}>
                      Import
                    </Button>
                  </div>}
                <TextField
                  placeholder="Global Unit Search"
                  type="text"
                  variant="outlined"
                  onChange={(event) => {
                    this.props.set_reset();
                    var self = this;
                    var val = event.target.value;
                    if (event.target.value === "") {
                      var a = this.props.location.search.split("=");
                      this.setState({
                        finalUnit: a[2],
                        parent_id: a[1],
                        parent_type: a[3]
                      })
                      this.props.get_final_unit_details(this.props.login.token, a[1], this.props.login.building_id, "");
                    } else {
                      setTimeout(function () {
                        search_unit(self.props.login.token, self.props.login.building_id, val)
                      }, 500)
                    }
                  }}
                  InputProps={{ classes: { input: this.props.classes.textfield } }}
                  InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  {units.final_units.length > 0 &&
                    <Table style={{ width: "90%", marginLeft: 20 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Parent Unit</TableCell>
                          <TableCell align="left">Owner Name</TableCell>
                          <TableCell align="left">Contact info</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {units.final_units.map((s) => (
                          <TableRow key={s.unit_name}>
                            <TableCell align="left" className="table_cells">
                              <Checkbox
                                checked={s.checked}
                                size="small"
                                onChange={() => {
                                  toggleCheckbox1(s, units.final_units);
                                  this.set_del_check();
                                }}
                              />
                              {s.unit_name}
                            </TableCell>
                            <TableCell align="left" className="table_cells">{s.parent_name}</TableCell>
                            <TableCell align="left" className="table_cells">{s.owner_name}</TableCell>
                            <TableCell align="left" className="table_cells">{s.owner_contact_info}</TableCell>
                            <TableCell align="center" className="table_cells">
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => { this.edit_single_unit(s) }}>
                                EDIT
                              </Button>
                              {s.final_unit === true &&
                                <Link
                                  style={{ textDecoration: "none", marginLeft: 10 }}
                                  to={{
                                    pathname: "unit_details",
                                    state: {
                                      unit_id: s._id
                                    }
                                  }}>
                                  <Button variant="outlined" size="small">
                                    view
                                  </Button>
                                </Link>
                              }
                              {this.state.finalUnit === "true" &&
                                <IconButton
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    handleMenu(s, units.final_units)
                                    this.setState({
                                      anchorEl: e.currentTarget
                                    })
                                  }}>
                                  <Icon>
                                    more_vert
                                </Icon>
                                </IconButton>}
                              <Menu
                                id="simple-menu"
                                keepMounted
                                open={s.menu}
                                anchorEl={this.state.anchorEl}
                                onClose={() => {
                                  handleMenu(s, units.final_units)
                                }}
                              >
                                <MenuItem>
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={{
                                      pathname: "add_resident",
                                      state: {
                                        unit_id: s._id
                                      }
                                    }}>
                                    Add Resident
                                  </Link>
                                </MenuItem>
                                <MenuItem>
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={{
                                      pathname: "invite_residents",
                                      state: {
                                        unit_id: s._id
                                      }
                                    }}>
                                    Invite Resident
                                </Link>
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.delete_redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete The Unit</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    delete_redirect: false,
                    units_type_id: ""
                  });
                }}
                color="primary">
                NO
                </Button>
              <Button
                onClick={() => {
                  delete_single_unit(this.props.login.token, units.final_units, login.building_id, this.state.parent_id)
                  this.setState({
                    delete_redirect: false,
                    units_type_id: "",
                    del_check: false
                  })
                }}
                color="primary" autoFocus>
                Yes
                </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  <TextField
                    label="NAME"
                    type="text"
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.name}
                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    label="DESCRIPTION"
                    type="text"
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.desc}
                    onChange={(event) => { this.setState({ desc: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    label="Square Feet"
                    type="text"
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.square_feet}
                    onChange={(event) => { this.setState({ square_feet: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    label="Unit Type"
                    type="dropdown"
                    select
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.type_id}
                    onChange={(event) => { this.setState({ type_id: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.dropDown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {units.units_types.map((single) => (
                      <MenuItem key={single.type_name} value={single._id}>
                        {single.type_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    label="Unit Parent"
                    type="dropdown"
                    select
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.parent_id + ""}
                    onChange={(event) => { this.setState({ parent_id: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {units.units_parents.map((single) => (
                      <MenuItem key={single.unit_name} value={single._id}>
                        {single.unit_name}
                      </MenuItem>
                    ))}
                    <MenuItem key={"null"} value={"null"}>
                      {"NONE"}
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                    name: "",
                    desc: "",
                    id: "s._id",
                    building_id: "",
                    type_id: "",
                    square_feet: ""
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  update_single_unit(this.props.login.token, this.state.id, this.state.building_id, this.state.name, this.state.desc, this.state.type_id, this.state.square_feet, this.state.parent_id);
                  this.setState({
                    editredirect: false
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(Units);