import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../../cards/CardHeader.js";
import CardIcon from "../../cards/CardIcon.js";
import Card from "../../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { styles } from "../../../styles/style";
import LoaderCon from "../../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AddHelperVisitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      helper: "",
      selectedDate: new Date()
    }
  }
  componentDidMount() {
  }
  render() {
    const {
      visitors,
      snackbar,
      close_snack_bar,
      login,
      find_helper,
      helper_entry
    } = this.props;
    return (
      <Grid container item xs={12} lg={12} spacing={8}>
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD HELPER VISITOR
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid container spacing={2}>
                <Grid container item lg={4}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Search by Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => { find_helper(login.token, login.building_id, event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <br />
                  <br />
                  Selected helper: <b>{this.state.helper !== "" && this.state.helper.helper_name}</b>
                </Grid>
                <Grid item xs={5}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Entry time"
                      value={this.state.selectedDate}
                      onChange={(e) => {
                        this.setState({
                          selectedDate: e
                        })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                  {this.state.helper === "" ?
                    <Button
                      variant="outlined"
                      style={{ position: "relative", top: 30 }}
                      size="small"
                      disabled={true}
                    >
                      SUBMIT
                    </Button>
                    :
                    <Button
                      variant="outlined"
                      style={{ position: "relative", top: 30 }}
                      size="small"
                      onClick={() => {
                        helper_entry(login.token, login.building_id, this.state.helper, this.state.selectedDate)
                      }}
                    >
                      SUBMIT
                    </Button>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Image</TableCell>
                      <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                      <TableCell align="left">Service</TableCell>
                      <TableCell align="left">Contact</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visitors.search_helpers.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell align="left" >
                          <input
                            style={{
                              position: "relative",
                              top: -17
                            }}
                            type="radio"
                            name="helper"
                            onClick={() => { this.setState({ helper: row }) }}
                          />
                          <Avatar src={row.helper_img} style={{ display: "inline-block" }} />
                        </TableCell>
                        <TableCell align="left">
                          {row.helper_name}
                        </TableCell>
                        <TableCell align="left">
                          {row.helper_service}
                        </TableCell>
                        <TableCell align="left">
                          {row.helper_contact_info}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddHelperVisitor);