import React, { Component } from "react";
import { connect } from "react-redux";
import MaintenaceCreditEntries from "../../components/maintenance/maintenanceCredit";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  getAllMaintenaceCredit,
  approveMaintenanceCredit,
  reset,
  getAllMaintenaceType,
  addUnitMaintenance,
  getAllPendingAmenityCharges,
  setInvoiceType,
  setInvoiceAmount,
  getPendingPayments,
  setPendingPayments,
  addUnitPaymentMaintenance,
} from "../../actions/maintenance/maintenance_action";

export class MaintenaceCreditEntriesCont extends Component {
  render() {
    return (
      <MaintenaceCreditEntries {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    maintenance: store.maintenance,
    bank: store.bank
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    approveMaintenanceCredit: (token, unit_id, array, maintenance, building_id, creditTo) => {
      dispatch(approveMaintenanceCredit(token, unit_id, array, maintenance, building_id, creditTo));
    },
    getAllMaintenaceCredit: (token, limit, startingAfter, startDate, endDate, building_id) => {
      dispatch(getAllMaintenaceCredit(token, limit, startingAfter, startDate, endDate, building_id));
    },
    reset: () => {
      dispatch(reset());
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenaceCreditEntriesCont);