import React, { Component } from "react";
import RoutesCon from "./containers/router/router_cont";
class App extends Component {
  render() {
    return (
      <RoutesCon />
    );
  }
}
export default App;
