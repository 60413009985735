import React, { Component } from "react";
import { connect } from "react-redux";
import AddEmployeeVisitor from "../../../components/visitors/employee_visitor/add_employee_visitor";
import {
    find_employee,
    employee_entry
} from "../../../actions/visitors/employee/employee_visitors_action";
import {
    close_snack_bar
} from "../../../actions/snackbar/snackbar_action";
export class Add_Employee_Visitors_Cont extends Component {
    render() {
        return (
            <AddEmployeeVisitor {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        visitors: store.employee_visitor_reducer,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        find_employee: (token, building_id, search_keyword) => {
            dispatch(find_employee(token, building_id, search_keyword));
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        employee_entry: (token, building_id, employee, time) => {
            dispatch(employee_entry(token, building_id, employee, time));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Employee_Visitors_Cont);