import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "../../styles/style";
import CardContent from "@material-ui/core/Card";
import Divider from '@material-ui/core/Divider';
import Icon from "@material-ui/core/Icon";
const cards = {
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    marginLeft: 10
};
class SuperAdminDashboard extends Component {
    componentDidMount() {
        this.props.get_super_dashboard_details(this.props.login.token);
    }
    render() {
        const { dashboard, classes } = this.props;
        return (
            <Grid container>
                <Grid container item sm={12}>
                    {/* CARD ONE */}
                    <Grid container item lg={4} xs={12} >
                        <Card style={cards}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning" >
                                    <Icon>
                                        account_balance
                  </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Societies</p>
                                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                                    {dashboard.dashboard_details.buildings_count}
                                </h1>
                            </CardHeader>
                            <CardContent  >
                                <Divider />
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* END OF CARD ONE */}
                    {/* CARD TWO */}
                    <Grid container item lg={4} xs={12} >
                        <Card style={cards}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning" >
                                    <Icon>
                                        next_week
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Vendors</p>
                                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                                    {dashboard.dashboard_details.vendors_count}
                                </h1>
                            </CardHeader>
                            <CardContent  >
                                <Divider />
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* END OF CARD TWO */}
                    {/* CARD THREE */}
                    <Grid container item lg={4} xs={12} >
                        <Card style={cards}>
                            <CardHeader color="warning" stats icon>
                                <CardIcon color="warning" >
                                    <Icon>
                                        outlined_flag
                                    </Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Vendor Classifications</p>
                                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                                    {dashboard.dashboard_details.vendor_category_count}
                                </h1>
                            </CardHeader>
                            <CardContent  >
                                <Divider />
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* END OF CARD THREE */}
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(styles)(SuperAdminDashboard);
