import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Tooltip from "@material-ui/core/Tooltip";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import { IconButton, TextField, MenuItem } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FullWidthTabs from "./tabs";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

class MaintenancePaymentUnitDetails extends React.Component {

  componentDidMount() {
    this.props.reset()
    this.props.getMaintenanceDetails(this.props.login.token, this.props.location.state.unit_id, this.props.maintenance.limit, this.props.maintenance.startingAfter, null, null, this.props.login.building_id);
  }

  constructor(props) {
    super(props);
    this.state = {
      unit_parent_id: "",
      dialog: false,
      arraymapped: [],
      btnCheck: false,
      addDialog: false,
      charge: "",
      amount: "",
      dueDate: null,
      payDialog: false,
      fullyPaid: false,
      startDate: null,
      endDate: null,
      disabledDateBtn: true,
      payBtnAmount: "",
      raisedDate: null,
      paymentType: "",
      transactionId: "",
      bankAccount: "",
      creditedTo: "",
    }
  }
  setDialog = (array, id, approved, receipt_image, row) => {
    var arr = []
    for (var i = 0; i < array.length; i++) {
      arr.push({
        id: id,
        checked: false,
        approved: approved,
        amount: array[i].amount,
        due_date: array[i].due_date,
        invoice_id: array[i].invoice_id,
        is_full_paid: array[i].is_full_paid,
        raised_on: array[i].raised_on,
        type: array[i].type,
        receipt_image: receipt_image,
        bank_id: "",
        paymentType: row.payment_type
      })
    }

    this.setState({
      dialog: true,
      arraymapped: arr
    })


  }
  checkDate = () => {
    var startDate = this.state.startDate
    var endDate = this.state.endDate
    if (endDate.getTime() > startDate.getTime()) {
      this.setState({ disabledDateBtn: false })
    }
  }
  render() {
    const {
      login,
      snackbar,
      close_snack_bar,
      maintenance,
      units,
      getUnitMaintenance,
      approvePayment,
      addUnitMaintenance,
      setInvoiceAmount,
      setInvoiceType,
      getPendingPayments,
      setPendingPayments,
      addUnitPaymentMaintenance,
      getMaintenanceDetails,
      bank,
    } = this.props;
    var btn;
    if (maintenance.total > maintenance.limit) {
      if (maintenance.end_var === maintenance.total) {
        btn = <span >
          <IconButton
            onClick={() => { getMaintenanceDetails(login.token, this.props.location.state.unit_id, maintenance.limit, parseInt(maintenance.startingAfter) - parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
        </span>
      }
      else if (maintenance.start_var > maintenance.limit && maintenance.end_var < maintenance.total) {
        btn = <span >
          <IconButton
            onClick={() => { getMaintenanceDetails(this.props.login.token, this.props.location.state.unit_id, maintenance.limit, parseInt(maintenance.startingAfter) - parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { getMaintenanceDetails(this.props.login.token, this.props.location.state.unit_id, maintenance.limit, parseInt(maintenance.startingAfter) + parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { getMaintenanceDetails(login.token, this.props.location.state.unit_id, maintenance.limit, parseInt(maintenance.startingAfter) + parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
      </span>
    }
    if (this.props.maintenance.invoiceAmount > 0) {
      this.setState({ amount: this.props.maintenance.invoiceAmount }, function () {
        setInvoiceAmount(0)
      })
    }
    return (
      <Grid container spacing={8} >
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW UNIT MAINTENANCE DETAILS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {this.props.location.state.blockName + " " + this.props.location.state.unit_no} -
                    {this.props.location.state.owner_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} style={{ marginTop: 20 }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: "unit_maintenance",
                      state: {
                        id: this.props.location.state.selectedParentId,
                        blockName: this.props.location.state.blockName
                      }
                    }}>
                    <Tooltip title="Go back">
                      <IconButton style={{ backgroundColor: "#E18890" }}>
                        <Icon style={{color:"white"}}>
                          arrow_back
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </Link>
                  <IconButton
                    onClick={() => { this.setState({ addDialog: true }) }}
                  >
                    <Icon>
                      add
                    </Icon>
                  </IconButton>
                </Grid>
                <Grid item xs={12} md={1} style={{ marginTop: 20 }}>
                  <Button
                    disabled={maintenance.pendingPayments.length > 0 ? false : true}
                    style={{ marginTop: 10 }}
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      this.setState({ payDialog: true })
                      // getPendingPayments(this.props.login.token, this.props.location.state.unit_id)
                    }}
                  >
                    pay
                  </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Start Date"
                      value={this.state.startDate}
                      onChange={(e) => {
                        this.setState({ startDate: e })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {this.state.startDate !== null &&
                  <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="End Date"
                        value={this.state.endDate}
                        onChange={(e) => {
                          this.setState({ endDate: e }, function () {
                            this.checkDate()
                          })

                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                }
                <Grid item xs={12} md={3}>
                  <Button
                    disabled={this.state.disabledDateBtn}
                    style={{ marginTop: 23, marginLeft: 10 }}
                    variant="outlined"
                    onClick={() => {
                      this.props.getMaintenanceDetails(this.props.login.token, this.props.location.state.unit_id, this.props.maintenance.limit, 0, this.state.startDate, this.state.endDate, this.props.login.building_id)
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    className="refreshBtn"
                    variant="outlined"
                    onClick={() => {
                      this.props.getMaintenanceDetails(this.props.login.token, this.props.location.state.unit_id, this.props.maintenance.limit, 0, this.state.startDate, this.state.endDate, this.props.login.building_id)
                    }}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
              {(maintenance.unit_details.length > 0 && maintenance.pendingPayments.length > 0) ?
                <FullWidthTabs
                  firstTable={maintenance.unit_details}
                  setDialog={this.setDialog}
                  secondTable={maintenance.pendingPayments}
                  btn={btn}
                /> : <FullWidthTabs
                  firstTable={maintenance.unit_details}
                  setDialog={this.setDialog}
                  secondTable={[]}
                  btn={btn}
                />
              }
            </CardContent>
          </Card>


          <Dialog
            open={this.state.dialog}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Validate Payment</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} md={12}>
                  {this.state.arraymapped.length > 0 &&
                    <div style={{ textAlign: "center" }}>
                      {this.state.arraymapped[0].receipt_image !== null &&
                        <img src={this.state.arraymapped[0].receipt_image} style={{ width: 120 }} alt="">
                        </img>
                      }
                    </div>
                  }
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Charges</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Raised On</TableCell>
                        <TableCell align="left">Due Date</TableCell>
                        <TableCell align="left">Payment Type</TableCell>
                        <TableCell align="left">Full payment</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.arraymapped.map((row, index) => (
                        <TableRow key={row.id} >
                          <TableCell align="left" className="table_cells">
                            {row.approved === false &&
                              <input
                                type="checkbox"
                                checked={row.checked}
                                onChange={() => {
                                  var arr = this.state.arraymapped;
                                  var c = false
                                  arr[index].checked = !arr[index].checked;
                                  for (var i = 0; i < arr.length; i++) {
                                    if (arr[i].checked === true) {
                                      c = true
                                    }
                                  }
                                  this.setState({ arraymapped: arr, btnCheck: c })
                                }}
                              />
                            }
                            {row.type}
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            {row.amount}
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            <Moment format="DD/MM/YYYY">
                              {row.raised_date}
                            </Moment>
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            <Moment format="DD/MM/YYYY">
                              {row.due_date}
                            </Moment>
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            {row.paymentType}
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            {row.approved ?
                              <Checkbox
                                checked={row.is_full_paid}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                              :
                              <Checkbox
                                checked={row.is_full_paid}
                                name="checkedA"
                                color="primary"
                                onChange={() => {
                                  var arr = this.state.arraymapped;
                                  var c = false
                                  arr[index].is_full_paid = !arr[index].is_full_paid;
                                  this.setState({ arraymapped: arr })
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Grid item xs={6} md={6}>
                    {this.state.arraymapped.length > 0 &&
                      < span >
                        {
                          (this.state.arraymapped[0].paymentType !== "Cash" && this.state.arraymapped[0].approved === false) &&
                          <TextField
                            value={this.state.creditedTo}
                            select
                            label="Account"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              this.setState({ creditedTo: e.target.value })
                            }}
                            InputProps={{ classes: { input: this.props.classes.dropdown } }}
                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                          >
                            {bank.all_bank_accounts.map(su => (
                              <MenuItem key={su.id}
                                value={su.id}
                              >
                                {su.bankAccountMix}
                              </MenuItem>
                            ))}
                          </TextField>
                        }
                      </span>
                    }
                  </Grid>

                </Grid>


              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    dialog: false,
                    creditedTo: "",
                  });
                }}
                color="primary">
                Close
              </Button>
              {this.state.arraymapped.length > 0 &&
                < span >
                  {
                    this.state.arraymapped[0].paymentType === "Cash" ?
                      <Button
                        disabled={!this.state.btnCheck}
                        onClick={() => {
                          approvePayment(login.token, this.props.location.state.unit_id, this.state.arraymapped, maintenance, login.building_id)
                          this.setState({
                            dialog: false,
                            btnCheck: false,
                            creditedTo: "",
                          })
                        }}
                        color="primary"
                        autoFocus
                      >
                        Approve
                    </Button>
                      :
                      <Button
                        disabled={!this.state.btnCheck || this.state.creditedTo === ""}
                        onClick={() => {
                          approvePayment(login.token, this.props.location.state.unit_id, this.state.arraymapped, maintenance, login.building_id, this.state.creditedTo)
                          this.setState({
                            dialog: false,
                            btnCheck: false,
                            creditedTo: "",
                          })
                        }}
                        color="primary"
                        autoFocus
                      >
                        Approve
            </Button>
                  }
                </span>
              }

            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.addDialog}
            aria-labelledby="alert-dialog-title"
            // fullWidth={true}
            // maxWidth={"md"}
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Create new Payment</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <TextField
                    value={this.state.charge}
                    select
                    label="Select Charges"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({ charge: e.target.value })
                      if (e.target.value === "Amenity Charges") {
                        this.props.getAllPendingAmenityCharges(this.props.login.token, this.props.location.state.unit_id)
                      } else if (e.target.value === "Complaints/Repairs Attended") {
                        this.props.getAllPendingComplaintCharges(this.props.login.token, this.props.location.state.unit_id)
                      }
                      else if (e.target.value === "Pending Charges") {
                        this.props.getPendingChargesPayment(this.props.login.token, this.props.location.state.unit_id)
                      }
                    }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {maintenance.all_maintenance.map(su => (
                      <MenuItem key={su._id}
                        value={su.type}
                      >
                        {su.type}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    value={this.state.amount}
                    label="Amount"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({ amount: e.target.value })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Due Date"
                      value={this.state.dueDate}
                      onChange={(e) => { this.setState({ dueDate: e }) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    addDialog: false,
                    amount: "",
                    charge: "",
                    dueDate: null
                  });
                }}
                color="primary"
              >
                Close
              </Button>
              <Button
                disabled={this.state.charge === "" || this.state.amount === "" || this.state.dueDate === null ? true : false}
                onClick={() => {
                  addUnitPaymentMaintenance(login.token, this.props.location.state.unit_id, login.building_id, this.state.charge, this.state.amount, this.state.dueDate, maintenance)
                  this.setState({
                    addDialog: false,
                    amount: "",
                    charge: "",
                    dueDate: null
                  })
                }}
                color="primary"
                autoFocus
              >
                CREATE
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.payDialog}
            fullWidth={true}
            maxWidth={"md"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Pay invoice</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Charges</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Raised On</TableCell>
                      <TableCell align="left">Due Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maintenance.pendingPayments.map((row, index) => (
                      <TableRow key={row._id} >
                        <TableCell align="left" className="table_cells">
                          <input
                            type="checkbox"
                            checked={row.checked}
                            onChange={() => {
                              maintenance.pendingPayments[index].checked = !row.checked;
                              setPendingPayments(maintenance.pendingPayments)
                            }}
                          />
                          {row.type}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.amount}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          <Moment format="DD/MM/YYYY">
                            {row.raised_date}
                          </Moment>
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          <Moment format="DD/MM/YYYY">
                            {row.due_date}
                          </Moment>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Grid item xs={12} md={6}>
                  <TextField
                    value={this.state.paymentType}
                    label="Payment type"
                    select
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => {
                      if (e.target.value === "Cash") {
                        this.setState({
                          paymentType: e.target.value,
                          transactionId: "",
                          bankAccount: ""
                        })
                      }

                      this.setState({ paymentType: e.target.value })
                    }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {maintenance.mode.map(s => (
                      <MenuItem key={s}
                        value={s}
                      >
                        {s}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    value={this.state.payBtnAmount}
                    label="Amount"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(e) => { this.setState({ payBtnAmount: e.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                  </TextField>

                </Grid>
                {(this.state.paymentType !== "Cash" && this.state.paymentType !== "") &&
                  <Grid container xs={12} md={12} spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={this.state.transactionId}
                        label="Transaction ID"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => { this.setState({ transactionId: e.target.value }) }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      >
                      </TextField>

                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        value={this.state.bankAccount}
                        select
                        label="Select Bank Account"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => {
                          this.setState({ bankAccount: e.target.value })
                        }}
                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      >
                        {bank.all_bank_accounts.map(su => (
                          <MenuItem key={su.id}
                            value={su.id}
                          >
                            {su.bankAccountMix}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                }
                {/* 
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Due Date"
                      value={this.state.dueDate}
                      onChange={(e) => { this.setState({ dueDate: e }) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Raised Date"
                      value={this.state.raisedDate}
                      onChange={(e) => { this.setState({ raisedDate: e }) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <input
                    type="checkbox"
                    checked={this.state.fullyPaid}
                    onChange={() => {
                      this.setState({
                        fullyPaid: !this.state.fullyPaid
                      })
                    }}
                  />
                  Fully Paid?
                </Grid>

              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    payDialog: false,
                    amount: "",
                    charge: "",
                    dueDate: null,
                    fullyPaid: false,
                    payBtnAmount: "",
                    raisedDate: null,
                    paymentType: "",
                    transactionId: "",
                    bankAccount: "",

                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                disabled={this.state.paymentType === ""
                  || this.state.payBtnAmount === ""
                  || maintenance.pendingPayments.some(function (product) {
                    if (product.checked) {
                      return true
                    } else {
                      return false
                    }
                  })
                  === false ? true : false}
                onClick={() => {
                  addUnitMaintenance(login.token, this.props.location.state.unit_id, login.building_id, this.state.paymentType, this.state.payBtnAmount, this.state.dueDate, maintenance.pendingPayments, this.state.fullyPaid, maintenance, this.state.raisedDate, this.state.transactionId, this.state.bankAccount)

                  this.setState({
                    payDialog: false,
                    amount: "",
                    paymentType: "",
                    dueDate: null,
                    fullyPaid: false,
                    payBtnAmount: "",
                    raisedDate: null,
                    paymentType: "",
                    transactionId: "",
                    bankAccount: "",
                  })
                }}
                color="primary"
                autoFocus
              >
                PAY
              </Button>
            </DialogActions>
          </Dialog>

          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid >
    )
  }
}
export default withStyles(styles)(MaintenancePaymentUnitDetails);