import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Avatar, } from "@material-ui/core";
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Slide } from 'react-slideshow-image';
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true
}
class Amenities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editredirect: false,
      amenity_desc: "",
      amenity_name: "",
      amenity_fullday_price: "",
      amenity_halfday_price: "",
      guest_fullday_price: "",
      guest_halfday_price: "",
      amenitiy_images: [],
      start_time: new Date(),
      end_time: new Date(),
      amenity_id: "",
      isfree: "",
      no_of_slots: "",
      confirm_delete: false,
      id: "",
    }
  }
  componentDidMount() {
    this.props.reset_amenities_pagination();
    this.props.get_all_amenities(this.props.login.token, this.props.login.building_id, this.props.amenities.limit, this.props.amenities.starting_after);
  }
  ed = (s) => {
    this.setState({
      amenity_desc: s.amenity_desc,
      amenity_name: s.amenity_name,
      amenity_fullday_price: s.amenity_price_info.Hourly_price,
      guest_fullday_price: s.guest_price_info.Hourly_price,
      start_time: moment(s.available_timings[0], ["h:mm A"]).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(s.available_timings[1], ["h:mm A"]).format("YYYY-MM-DD HH:mm:ss"),
      amenity_id: s._id,
      isfree: s.is_free,
      no_of_slots: s.no_of_slots,
      imgs: s.amenity_imgs
    })
  }
  del_single_row = (row) => {
    this.setState({
      confirm_delete: true,
      id: row._id,
    })
  }
  render() {
    const {
      amenities,
      prev_btn,
      next_btn,
      login,
      delete_user_info,
      snackbar,
      close_snack_bar,
      update_amenities,
      set_images,
      set_del_images,
    } = this.props;
    var btn;
    if (amenities.total > amenities.limit) {
      if (amenities.end_var === amenities.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, amenities.limit, amenities.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {amenities.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities.total}
        </span>
      }
      else if (amenities.start_var > amenities.limit && amenities.end_var < amenities.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, amenities.limit, amenities.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(login.token, amenities.building_id, amenities.limit, amenities.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {amenities.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(amenities.token, login.building_id, amenities.limit, amenities.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {amenities.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {amenities.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities.total}
      </span>
    }

    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW AMENITIES
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid item lg={12}>
                <Link to="add_amenities" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                <br /><br />
                <div style={{ float: "right" }}>
                  {btn}
                </div>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                    {/* <TableCell align="left">Number Of Slot</TableCell> */}
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {amenities.all_amenities.map(row => (
                    <TableRow key={row._id} >
                      <TableCell align="left" className="table_cells" >
                        <Slide {...properties} style={{ height: 210, width: 180 }}>
                          {row.amenity_imgs.map(img => (
                            <Avatar key={img} alt="Remy Sharp" src={String(img)} style={{ height: 180, width: 180, float: "center" }} />
                          ))}
                        </Slide>
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        &nbsp;&nbsp;{row.amenity_name}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.amenity_desc}
                      </TableCell>
                      {/* <TableCell align="left" className="table_cells">
                        &nbsp;&nbsp;{row.no_of_slots}
                      </TableCell> */}
                      <TableCell align="right" className="table_cells" style={{ padding: 0 }}>
                        <IconButton
                          onClick={() => {
                            this.ed(row)
                            set_images(row.amenity_imgs)
                            this.setState({
                              editredirect: true,
                            });
                          }}
                        >
                          <Icon>edit</Icon>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            this.del_single_row(row)
                          }}
                        >
                          <Icon >delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Amenity Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.amenity_name}
                    onChange={(event) => {
                      this.setState({
                        amenity_name: (event.target.value)
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Grid container>
                    {amenities.amenity_img.map(img => (
                      <Grid item lg={3} xs={3}>
                        <img src={img} style={{ height: 100, width: 100 }} alt='amenities' />
                        <IconButton
                          onClick={(img) => { set_del_images(img, amenities) }}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                        &nbsp;&nbsp;&nbsp;
                      </Grid>
                    ))}
                  </Grid>
                  add :
                  <input
                    type="file"
                    multiple
                    onChange={(event) => {
                      for (var i = 0; i < event.target.files.length; i++) {
                        this.state.amenitiy_images.push(event.target.files[i]);
                        this.setState({
                          amenitiy_images: this.state.amenitiy_images
                        })
                      }
                    }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Description"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.amenity_desc}
                    onChange={(event) => {
                      this.setState({
                        amenity_desc: event.target.value
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                {/* <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Number Of Slots"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.no_of_slots}
                    onChange={(event) => {
                      this.setState({
                        no_of_slots: event.target.value
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid> */}
                {
                  !this.state.isfree &&
                  <Grid item lg={6} xs={6}>
                    <TextField
                      id="outlined-email-input"
                      label="Amenit hourly price"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.amenity_fullday_price}
                      onChange={(event) => {
                        this.setState({
                          amenity_fullday_price: event.target.value
                        })
                      }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                }
                {
                  !this.state.isfree &&

                  <Grid item lg={6} xs={6}>
                    <TextField
                      id="outlined-email-input"
                      label="Guest hourly price"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.guest_fullday_price}
                      onChange={(event) => {
                        this.setState({
                          guest_fullday_price: event.target.value
                        })
                      }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                }
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item lg={6} xs={6}>
                    <KeyboardTimePicker
                      margin="normal"
                      label="Start Time"
                      fullWidth
                      value={this.state.start_time}
                      onChange={(event) => {
                        // moment(s.available_timings[0], ["h:mm A"]).format("YYYY-MM-DD HH:mm:ss")
                        this.setState({
                          start_time: event
                        });
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={6}>
                    <KeyboardTimePicker
                      margin="normal"
                      label="End Time"
                      fullWidth
                      value={this.state.end_time}
                      onChange={(event) => {
                        this.setState({
                          end_time: event
                        });
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item lg={12} xs={12}>
                  <input
                    id="outlined-email-input"
                    type="checkbox"
                    checked={this.state.isfree}
                    value={this.state.isfree}
                    onClick={(event) => {
                      this.setState({
                        isfree: !(this.state.isfree),
                        guest_fullday_price: 0,
                        amenity_fullday_price: 0
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                  Is Free
                  </Grid><br /><br />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                    amenity_desc: "",
                    amenity_imgs: [],
                    amenity_name: "",
                    amenity_fullday_price: "",
                    amenity_halfday_price: "",
                    guest_fullday_price: "",
                    guest_halfday_price: "",
                    start_time: null,
                    end_time: null,
                    amenity_id: "",
                    isfree: "",
                    no_of_slots: "",
                    amenitiy_images: []
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  update_amenities(login.token, login.building_id, this.state.amenity_id, this.state.amenity_name, this.state.amenity_desc, amenities.amenity_img, this.state.isfree, this.state.amenity_fullday_price, this.state.guest_fullday_price, this.state.start_time, this.state.end_time, this.state.no_of_slots, amenities.limit, amenities.starting_after, this.state.amenitiy_images)
                  this.setState({
                    redirect: false,
                    editredirect: false,
                    amenity_desc: "",
                    amenity_imgs: [],
                    amenity_name: "",
                    amenity_fullday_price: "",
                    amenity_halfday_price: "",
                    guest_fullday_price: "",
                    guest_halfday_price: "",
                    start_time: null,
                    end_time: null,
                    amenity_id: "",
                    isfree: "",
                    no_of_slots: "",
                    amenitiy_images: []
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirm_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirm_delete: false,
                    id: "",
                  });
                }}
                color="primary">
                No
              </Button>
              <Button
                onClick={() => {
                  delete_user_info(login.token, this.state.id, amenities.limit, amenities.starting_after, login.building_id)
                  this.setState({
                    confirm_delete: false,
                    id: ""
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(Amenities);