import React, { Component } from "react";
import { connect } from "react-redux";
import ViewPolling from "../../components/polling/viewPolling";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  get_active_polls,
  endPoll,
  getPollResult,
  setPollResult,
} from "../../actions/polling/pollingAction";

export class ViewPollingCont extends Component {
  render() {
    return (
      <ViewPolling {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    polling: store.polling
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    setPollResult: (value) => {
      dispatch(setPollResult(value));
    },
    get_active_polls: (token, buildingId) => {
      dispatch(get_active_polls(token, buildingId));
    },
    endPoll: (token, building_id, pollId) => {
      dispatch(endPoll(token, building_id, pollId));
    },
    getPollResult: (token, pollId) => {
      dispatch(getPollResult(token, pollId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewPollingCont);