import React, { Component } from "react";
import { connect } from "react-redux";
import AmenitiesBooking from "../../components/amenities_booking/amenity_booking";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    get_all_amenities_booking,
    set_selected_amenity,
    show_amenity_booking,
    set_end_date,
    set_start_date,
    set_check,
    next_btn,
    prev_btn,
    prev_btn_single,
    next_btn_single,
    get_unapproved_booking,
    prev_btn_unapproved,
    next_btn_unapproved,
    approve_booking,
    unapprove_booking
} from "../../actions/amenities_booking/amenity_booking_action";
import {
    get_all_amenities
} from "../../actions/amenities/amenities_action"
export class Amenities_booking_cont extends Component {
    render() {
        return (
            <AmenitiesBooking {...this.props} />
        );
    };
};
export const mapStateToProps = store => {
    return {
        amenities_booking: store.amenities_booking,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        amenities: store.amenities,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_amenities_booking: (token, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(get_all_amenities_booking(token, building_id, limit, starting_after, start_date, end_date))
        },
        get_unapproved_booking: (token, building_id, limit, starting_after) => {
            dispatch(get_unapproved_booking(token, building_id, limit, starting_after))
        },
        get_all_amenities: (token, amenity_id, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(get_all_amenities(token, amenity_id, building_id, limit, starting_after, start_date, end_date))
        },
        show_amenity_booking: (token, building_id, amenities_booking) => {
            dispatch(show_amenity_booking(token, building_id, amenities_booking))
        },
        set_selected_amenity: (payload) => {
            dispatch(set_selected_amenity(payload))
        },
        set_start_date: (payload) => {
            dispatch(set_start_date(payload))
        },
        set_end_date: (payload) => {
            dispatch(set_end_date(payload))
        },
        set_check: (payload) => {
            dispatch(set_check(payload))
        },
        prev_btn: (token, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(prev_btn(token, building_id, limit, starting_after, start_date, end_date))
        },
        next_btn: (token, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(next_btn(token, building_id, limit, starting_after, start_date, end_date))
        },
        prev_btn_single: (token, amenity_id, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(prev_btn_single(token, amenity_id, building_id, limit, starting_after, start_date, end_date))
        },
        next_btn_single: (token, amenity_id, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(next_btn_single(token, amenity_id, building_id, limit, starting_after, start_date, end_date))
        },
        prev_btn_unapproved: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn_unapproved(token, building_id, limit, starting_after))
        },
        next_btn_unapproved: (token, building_id, limit, starting_after) => {
            dispatch(next_btn_unapproved(token, building_id, limit, starting_after))
        },
        approve_booking: (token, booking_id, building_id, limit, starting_after, amenities_booking) => {
            dispatch(approve_booking(token, booking_id, building_id, limit, starting_after, amenities_booking))
        },
        unapprove_booking: (token, booking_id, building_id, comment, limit, starting_after, amenities_booking) => {
            dispatch(unapprove_booking(token, booking_id, building_id, comment, limit, starting_after, amenities_booking))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Amenities_booking_cont); 