import {
    SET_ALL_VENDOR_CATEGORY,
    SET_CATEGORY_NAME,
    RESET_ALL,
} from "../../constants/vendor_category/vendor_category_const";
import { set_loader, unset_loader }
    from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function get_all_vendor_category(token) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_vendor_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_all_vendor_category(responseJson.result))
                }
                else {
                    dispatch(set_all_vendor_category([]));
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_all_vendor_category(payload) {
    return {
        type: SET_ALL_VENDOR_CATEGORY,
        payload: payload,
    }
}
export function reset_all() {
    return {
        type: RESET_ALL,
    }
}
export function set_category_name(payload) {
    return {
        type: SET_CATEGORY_NAME,
        payload: payload,
    }
}
export function validate_add_vendor_category(token, vendor_category) {
    return (dispatch) => {
        if (vendor_category.category_name === "") {
            dispatch(set_snack_bar(true, "Please Enter Category Name"));
        }
        else {
            dispatch(add_vendor_category(token, vendor_category));
        }
    };
}
export function add_vendor_category(token, vendor_category) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/add_vendor_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                vendor_category_name: vendor_category.category_name
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Vendor Category Added Succcessfully"));
                    dispatch(reset_all())
                    dispatch(get_all_vendor_category(token))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Resident Could Not Be Added"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function validate_update_vendor_category(token, vendor_category_id, vendor_category_name) {
    return (dispatch) => {
        if (vendor_category_name === "") {
            dispatch(set_snack_bar(true, "Please Enter Vendor Category Name"));
        }
        else {
            dispatch(update_vendor_category(token, vendor_category_id, vendor_category_name));
        }
    };
}
export function update_vendor_category(token, vendor_category_id, vendor_category_name) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/update_vendor_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                vendor_category_id: vendor_category_id,
                vendor_category_name: vendor_category_name,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(get_all_vendor_category(token))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function delete_vendor_category(token, vendor_category_id) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/delete_vendor_category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                vendor_category_id: vendor_category_id,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(get_all_vendor_category(token))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}