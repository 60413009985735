import {
    SET_ALL_LOANS,
    RESET,
} from "../../constants/loans/loans_const";
import { set_loader, unset_loader }
    from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
export function getAllLoans(token, building_id) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_building_loans", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(setAllLoans(responseJson.result))
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function setAllLoans(payload) {
    return {
        type: SET_ALL_LOANS,
        payload: payload
    }
}
export function reset() {
    return {
        type: RESET,
    }
}
export function addBuildingLoan(token, building_id, state) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/add_building_loan", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                loan_title: state.title,
                starts_from: state.startDate,
                ends_on: state.endDate,
                loan_amount: state.loanAmount,
                current_outstanding_amount: state.outstandingAmount,
                bank_id: state.bank
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(getAllLoans(token, building_id));

                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function closeLoan(token, building_id, id) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/close_building_loan", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                loan_id: id,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(getAllLoans(token, building_id));

                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function addEmiEntry(token, building_id, state) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/add_loan_emi_entry", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                loan_id: state.selectedId,
                paid_month: state.paidMonth,
                paid_year: state.paidYear,
                payment_mode: state.paymentMode,
                emi_amount: state.emiAmount,
                transaction_id: state.transactionId,
                bank_id: state.bankId
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(getAllLoans(token, building_id));

                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}