import { combineReducers } from "redux";
import login from "./login-reducer";
import admin from "./admin_reducer/admin_reducer";
import units from "./units/units_redu";
import loader from "./loader/loader_reducer";
import snackbar from "./snackbar/snackbar_reducer";
import residents from "./residents/residents_reducer";
import e_notice from "./e-notice/e-notice_redu";
import buildings from "./buildings/buildings_reducer";
import vendors from "./vendors/vendors_reducer";
import vehicles from "./vehicles/vehicles_reducer";
import id_proofs from "./id_proofs/id_proof_reducer";
import amenities from "./amenities/amenities_reducer";
import employee from "./employee/employee_reducer";
import complaints from "./complaints/complaints_reducer";
import department from "./department/department_reducer";
import helper from "./helper/helper_reducer";
import amenities_booking from "./amenities_booking/amenity_booking_reducer";
import visitors from "./visitors/visitors_reducer";
import sharewall from "./sharewall/sharewall_reducer";
import dashboard from "./dashboard/dashboard_reducer";
import vendor_category from "./vendor_category/vendor_category_redu"
import change_password from "./employee/change_password_reducer";
import classified from "./classified/classified_reducer";
import helper_visitor_reducer from "./visitors/helper/helper_visitor_reducer";
import employee_visitor_reducer from "./visitors/employee/employee_visitor_reducer";
import resident_visitor_reducer from "./visitors/resident/resident_visitor_reducer";
import polling from "./polling/pollingReducer";
import maintenance from "./maintenance/maintenance_reducer";
import bank from "./bankAccounts/bankAccountsReducer";
import assets from "./assets/assetsReducer";
import salary from "./salary/salaryReducer";
import loans from "./loans/loans_reducer";

export default combineReducers({
    polling,
    resident_visitor_reducer,
    employee_visitor_reducer,
    helper_visitor_reducer,
    classified,
    change_password,
    dashboard,
    complaints,
    sharewall,
    vendor_category,
    visitors,
    amenities_booking,
    helper,
    e_notice,
    employee,
    vendors,
    vehicles,
    buildings,
    residents,
    snackbar,
    loader,
    admin,
    login,
    units,
    id_proofs,
    amenities,
    department,
    maintenance,
    bank,
    assets,
    salary,
    loans
});
