import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import AddVehiclesCont from "../../containers/vehicles/add_vehicles_cont";
import AddHelperCont from "../../containers/helper/add_helper_cont"
import Chip from '@material-ui/core/Chip';
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Avatar } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip"
import "../../styles/style.scss";
import LoaderCon from "../../containers/loader/loader_cont";
import CursorZoom from 'react-cursor-zoom';
import { Slide } from 'react-slideshow-image';
import ReactImageZoom from 'react-image-zoom';
import ResidentTable from "./components/residentTable";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true
}

class UnitDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      helper_dialog: false,
      vehicle_dialog: false,
      resident_menu: false,
      add_vehicle_redirect: false,
      add_helper_dialog: false,
      parking_delete_dialog: false,
      parking_to_delete: null,
      editModal: false,
      resident_name: "",
      resident_permanent_address: "",
      resident_email: "",
      resident_contact_info: "",
      resident_sec_contact_info: "",
      resident_id: "",
      resident_img: "",
      is_residing: false,
      is_owner: false,
      is_sub_resident: false,
      addSosDialog: false,
      sosEditModal: false,
      sos_name: "",
      sos_phone_number: "",
      sos_id: "",
      sos_img: null,
      sosDeleteDialog: false,
      sosEditModal: false,
      sos_img2: null,
      idProofsDialog: false,
      idProofDetails: []
    }
  }
  componentDidMount() {
    this.props.reset_unit_details();
    this.props.get_unit_details(this.props.login.token, this.props.location.state.unit_id)
    this.props.get_all_helpers(this.props.login.token, this.props.login.building_id, this.props.helper.limit, this.props.helper.starting_after);
    this.props.get_all_vehicles(this.props.login.token, this.props.vehicles.limit, this.props.vehicles.starting_after);
  }
  render() {
    const {
      units,
      get_all_vehicles,
      get_all_helpers,
      login,
      helper,
      vehicles,
      select_vehicle,
      select_helper,
      add_helper_to_unit,
      remove_helper_from_unit,
      snackbar,
      close_snack_bar,
      select_resident_vehicle,
      set_vehicle_number,
      add_vehicle_to_unit,
      remove_vehicle_from_resident,
      set_add_parking_no,
      add_parking_to_unit,
      delete_arr_element,
      remove_resident,
      add_parking_no_temp,
      delete_arr_element_temp,
      set_temp_parking,
      update_resident,
      deleteSos,
      updateSos,
      addSos
    } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" className="unit_details_title">
                  Residents
                </Typography>
                <ResidentTable
                  remove_resident={remove_resident}
                  resident_details={units.resident_details}
                  login={login}
                  id={this.props.location.state.unit_id}
                  update_resident={update_resident}
                />
              </CardContent>
            </Card>
          </Grid>
          {/* END OF RESIDENT DETAILS */}
          {/* SOS DETAILS  */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" className="unit_details_title">
                  Emergency Contact Details
                </Typography>
                <IconButton
                  onClick={() => {
                    this.setState({
                      addSosDialog: true
                    })
                  }}
                  className="unit_details_add_btn">
                  <Icon>
                    add
                  </Icon>
                </IconButton>
                <Table style={{ width: "95%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">image</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Number</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {units.sos_details.map((s) => (
                      <TableRow key={s.sos_id}>
                        <TableCell align="left" className="table_cells">
                          {/* {s.sos_img !== "" ?


                            < CursorZoom
                              image={{
                                src: s.sos_img,
                                width: 35,
                                height: 35
                              }}
                              zoomImage={{
                                src: s.sos_img,
                                width: "50%",
                                height: 1200
                              }}
                              // size={800}
                              cursorOffset={{ x: 300, y: -80 }}
                            /> : */}
                          <Avatar src={s.sos_img} />
                          {/* } */}
                        </TableCell>
                        <TableCell align="left" className="table_cells">{s.sos_name}</TableCell>
                        <TableCell align="left" className="table_cells">{s.sos_phone_number}</TableCell>
                        <TableCell align="right" className="table_cells">
                          <IconButton
                            size="small"
                            onClick={() => {
                              this.setState({
                                sosEditModal: true,
                                sos_name: s.sos_name,
                                sos_phone_number: s.sos_phone_number,
                                sos_id: s.sos_id,
                                sos_img: s.sos_img
                              })
                            }}
                          >
                            <Icon>
                              edit
                            </Icon>
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => {
                              this.setState({
                                sos_id: s.sos_id,
                                sosDeleteDialog: true
                              })
                            }}
                          >
                            <Icon>
                              delete
                            </Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          {/* END OF SOS DETAILS  */}
          {/* VEHICLE DETAILS */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" className="unit_details_title">
                  Vehicles
                </Typography>
                <IconButton
                  onClick={() => {
                    this.setState({
                      vehicle_dialog: true
                    })
                  }}
                  className="unit_details_add_btn">
                  <Icon>
                    add
                  </Icon>
                </IconButton>
                <Table style={{ width: "90%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Registration</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {units.vehicle_details.map((s) => (
                      <TableRow key={s.resident_id}>
                        <TableCell align="left">{s.vehicle_name}</TableCell>
                        <TableCell align="left">{s.vehicle_regd_num}</TableCell>
                        <TableCell align="left">{s.vehicle_type}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            onClick={() => {
                              remove_vehicle_from_resident(s.resident_id, { vehicle_name: s.vehicle_name, vehicle_type: s.vehicle_type, vehicle_regd_num: s.vehicle_regd_num }, this.props.login.token, this.props.location.state.unit_id)
                            }}
                          >
                            <Icon>
                              close
                            </Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" className="unit_details_title">
                  Helpers
                </Typography>
                <IconButton
                  onClick={() => {
                    this.setState({
                      helper_dialog: true
                    })
                  }}
                  className="unit_details_add_btn">
                  <Icon>
                    add
                  </Icon>
                </IconButton>
                <Table style={{ width: "90%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Contact</TableCell>
                      <TableCell align="left">Service</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {units.helper_details.map((s) => (
                      <TableRow key={s._id}>
                        <TableCell align="left">{s.helper_name}</TableCell>
                        <TableCell align="left">{s.helper_contact_info}</TableCell>
                        <TableCell align="left">{s.helper_service}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            onClick={() => {
                              remove_helper_from_unit(s.helper_id, this.props.location.state.unit_id, this.props.login.token, this.props.login.building_id);
                            }}
                          >
                            <Icon>
                              close
                            </Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" className="unit_details_title">
                  Parking
                </Typography>
                <IconButton
                  onClick={() => {
                    this.setState({
                      parking_dialog: true
                    })
                  }}
                  className="unit_details_add_btn">
                  <Icon>
                    add
                  </Icon>
                </IconButton>
              </CardContent>
              <CardContent>
                {units.parking_space.length !== 0 &&
                  units.parking_space.map(r => (
                    <Chip
                      onDelete={() => {
                        this.setState({
                          parking_delete_dialog: true,
                          parking_to_delete: r
                        })
                      }}
                      label={r}
                      color="secondary"
                    />
                  ))
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* DELETE PARKING DIALOG */}
        <Dialog
          open={this.state.parking_delete_dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Are you sure you want to delete this parking spot?</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  parking_delete_dialog: false
                });
              }}
              color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                delete_arr_element(this.state.parking_to_delete, units.parking_space, this.props.login.token, this.props.login.building_id, this.props.location.state.unit_id)
                this.setState({
                  parking_delete_dialog: false
                })
              }}
              color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* VEHICLE DIALOG */}
        <Dialog
          open={this.state.vehicle_dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Select Vehicles</DialogTitle>
          <DialogContent>
            <TextField

              label="Select Resident"
              fullWidth
              select
              margin="normal"
              variant="outlined"
              value={units.selected_resident}
              onChange={(event) => { select_resident_vehicle(event.target.value); }}
              InputProps={{ classes: { input: this.props.classes.dropDown } }}
              InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
            >
              {units.resident_details.map((s) => (
                <MenuItem value={s.resident_id}>{s.resident_name}</MenuItem>
              ))}
            </TextField>
            <TextField

              label="Vehicle Number"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              value={vehicles.vehicle_number}
              onChange={(event) => { set_vehicle_number(event.target.value); }}
              InputProps={{ classes: { input: this.props.classes.textfield } }}
              InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
            />
            <Button variant="outlined" color="primary"
              onClick={() => {
                this.setState({
                  add_vehicle_redirect: true
                })
              }}
            >
              ADD A New Vehicle
            </Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">&nbsp;&nbsp;Vehicle Name</TableCell>
                  <TableCell align="left">Manufacturer</TableCell>
                  <TableCell align="left">Vehicle Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicles.all_vehicles.map(row => (
                  <TableRow key={row._id} >
                    <TableCell align="left" className="table_cells">
                      <input type="radio"
                        value={JSON.stringify(row)}
                        name="vehicles"
                        onChange={(e) => {
                          var v = JSON.parse(e.target.value);
                          select_vehicle(v._id, v.vehicle_name, v.vehicle_type)
                        }} />
                      {row.vehicle_name}
                    </TableCell>
                    <TableCell align="left" className="table_cells">
                      {row.vehicle_manufacturer}
                    </TableCell>
                    <TableCell align="left" className="table_cells">
                      {row.vehicle_type}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  vehicle_dialog: false
                });
              }}
              color="primary">
              Cancel
            </Button>
            <Button
              disabled={vehicles.vehicle_number === "" || units.selected_resident === "" || vehicles.selected_vehicle_id === ""}
              onClick={() => {
                add_vehicle_to_unit(units.selected_resident, vehicles.selected_vehicle_name, vehicles.selected_vehicle_type, vehicles.vehicle_number, this.props.login.token, this.props.location.state.unit_id);
                this.setState({
                  vehicle_dialog: false
                })
              }}
              color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
        {/* HELPER DIALOG */}
        <Dialog
          open={this.state.helper_dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Select helper</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell> Image</TableCell>
                  <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                  <TableCell align="left">Contact info</TableCell>
                  <TableCell align="left">Permanent Address</TableCell>
                  <TableCell align="left">Service</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {helper.all_helper.map(row => (
                  <TableRow >
                    <TableCell align="center" className="table_cells">
                      <input type="radio" value={row._id} name="helpers" onChange={(e) => { select_helper(e.target.value) }} />
                    </TableCell>
                    <TableCell>
                      <Avatar alt={row.helper_name} src={row.helper_img}
                        style={{ width: 35, height: 35 }} >
                        {/* {row.helper_name.substring(0,1).toUpperCase()} */}
                      </Avatar>
                    </TableCell>
                    <TableCell align="left" className="table_cells">
                      &nbsp;&nbsp;{row.helper_name}
                    </TableCell>
                    <TableCell align="left" className="table_cells">
                      {row.helper_contact_info}
                    </TableCell>
                    <TableCell align="left" className="table_cells">
                      {row.helper_permanent_address}
                    </TableCell>
                    <TableCell align="left" className="table_cells">
                      {row.helper_service}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ position: "absolute", left: 0 }}
              onClick={() => {
                this.setState({
                  add_helper_dialog: true
                })
              }}
              color="primary" autoFocus>
              Add New Helper
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  helper_dialog: false
                });
              }}
              color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                add_helper_to_unit(helper.selected_helper, this.props.location.state.unit_id, this.props.login.token, this.props.login.building_id);
                this.setState({
                  helper_dialog: false
                })
              }}
              color="primary" autoFocus>
              Add from list
            </Button>

          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="lg"
          open={this.state.add_vehicle_redirect}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Select helper</DialogTitle> */}
          <DialogContent>
            <AddVehiclesCont
              onClose={() => {
                this.setState({ add_vehicle_redirect: false })
                // setTimeout(() => {
                //   get_all_vehicles(this.props.login.token, this.props.vehicles.limit, this.props.vehicles.starting_after);
                // }, 2000);

              }}

            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                get_all_vehicles(this.props.login.token, this.props.vehicles.limit, this.props.vehicles.starting_after);
                this.setState({
                  add_vehicle_redirect: false
                });
              }}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="lg"
          open={this.state.add_helper_dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Select helper</DialogTitle> */}
          <DialogContent>
            <AddHelperCont
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                get_all_helpers(this.props.login.token, this.props.login.building_id, this.props.helper.limit, this.props.helper.starting_after);
                this.setState({
                  add_helper_dialog: false
                });
              }}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.parking_dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Add Parking</DialogTitle>
          <DialogContent>
            <TextField

              label="Parking Space"
              fullWidth
              type="text"
              margin="normal"
              variant="outlined"
              value={units.parking}
              onChange={(event) => { set_add_parking_no(event.target.value); }}
              InputProps={{ classes: { input: this.props.classes.textfield } }}
              InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
            />
            {units.parking !== "" ?
              <Button variant="outlined" color="primary"
                onClick={() => {
                  add_parking_no_temp(units.parking_space_temp, units.parking);
                  set_add_parking_no("");
                }}
              >
                ADD
              </Button>
              :
              <Button variant="outlined" disabled={true}>
                ADD
              </Button>
            }
            <br />
            {units.parking_space_temp.length !== 0 &&
              units.parking_space_temp.map(r => (
                <Chip
                  onDelete={() => { delete_arr_element_temp(r, units.parking_space_temp) }}
                  label={r}
                  color="secondary"
                />
              ))
            }
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                set_temp_parking([]);
                this.setState({
                  parking_dialog: false
                });
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={units.parking === "" ? false : true}
              onClick={() => {
                add_parking_to_unit(this.props.login.token, this.props.login.building_id, this.props.location.state.unit_id, units.parking_space_temp.concat(units.parking_space));
                this.setState({
                  parking_dialog: false
                })
                set_temp_parking([]);
              }}
              color="primary">
              Assign
            </Button>
          </DialogActions>
        </Dialog>

        {/* SOS EDIT MODAL  */}
        <Dialog
          open={this.state.sosEditModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Edit the fields and click on Update</DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Avatar
                src={this.state.sos_img}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="SOS Name"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.sos_name}
                onChange={(e) => { this.setState({ sos_name: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                fullWidth
                margin="normal"
                type="number"
                variant="outlined"
                value={this.state.sos_phone_number}
                onChange={(e) => { this.setState({ sos_phone_number: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                Image :
                <input
                  type="file"
                  multiple
                  onChange={(event) => { this.setState({ sos_img2: event.target.files }) }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  sosEditModal: false,
                  sos_id: "",
                  sos_name: "",
                  sos_phone_number: "",
                  sos_img: null
                });
              }}
              color="primary">
              Cancel
            </Button>
            {this.state.sos_name === "" || this.state.sos_phone_number === "" || this.state.sos_img === null ?
              <Button
                disabled={true}
                color="primary">
                UPDATE
              </Button>
              :
              <Button
                onClick={() => {
                  if (this.state.sos_img2 === null) {
                    updateSos(
                      login.token,
                      login.building_id,
                      this.props.location.state.unit_id,
                      this.state.sos_id,
                      this.state.sos_name,
                      this.state.sos_phone_number,
                      this.state.sos_img
                    )
                  } else {
                    updateSos(
                      login.token,
                      login.building_id,
                      this.props.location.state.unit_id,
                      this.state.sos_id,
                      this.state.sos_name,
                      this.state.sos_phone_number,
                      this.state.sos_img2
                    )
                  }

                  this.setState({
                    sosEditModal: false,
                    sos_id: "",
                    sos_name: "",
                    sos_phone_number: "",
                    sos_img: null
                  })
                }}
                color="primary">
                UPDATE
            </Button>
            }
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.sosDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Are you sure you want to delete this SOS contact?</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  sosDeleteDialog: false,
                  sos_id: ""
                });
              }}
              color="primary">
              No
            </Button>

            <Button
              onClick={() => {
                this.setState({
                  sosDeleteDialog: false,
                  sos_id: ""
                });
                deleteSos(login.token, login.building_id, this.props.location.state.unit_id, this.state.sos_id)
              }}
              color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* ADD SOS DIALOG  */}
        <Dialog
          open={this.state.addSosDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Add SOS details</DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <TextField
                label="SOS Name"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.sos_name}
                onChange={(e) => { this.setState({ sos_name: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                fullWidth
                margin="normal"
                type="number"
                variant="outlined"
                value={this.state.sos_phone_number}
                onChange={(e) => { this.setState({ sos_phone_number: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item xs={12}>
              Image :
              <input
                type="file"
                multiple
                onChange={(event) => { this.setState({ sos_img: event.target.files }) }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  addSosDialog: false,
                  sos_name: "",
                  sos_phone_number: "",
                  sos_img: null
                });
              }}
              color="primary">
              Cancel
            </Button>
            {this.state.sos_name === "" || this.state.sos_phone_number === "" || this.state.sos_img === null ?
              <Button
                disabled={true}
                color="primary">
                ADD
              </Button>
              :
              <Button
                onClick={() => {
                  addSos(
                    login.token,
                    this.props.location.state.unit_id,
                    this.state.sos_name,
                    this.state.sos_phone_number,
                    this.state.sos_img
                  )
                  this.setState({
                    addSosDialog: false,
                    sos_name: "",
                    sos_phone_number: "",
                    sos_img: null
                  })
                }}
                color="primary">
                ADD
              </Button>
            }
          </DialogActions>
        </Dialog>

        <LoaderCon />

        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />
      </Grid>
    );
  }
}
export default withStyles(styles)(UnitDetails);