import {
  SET_ALL_UNITS,
  SET_ALL_UNITS_TYPES,
  SET_UNIT_TYPE_NAME,
  SET_UNIT_TYPE_PARENT_NAME,
  SET_SQUARE_FEET,
  SET_UNIT_DESCRIPTION,
  SET_UNIT_TYPE,
  SET_UNIT_NAME,
  RESET_ALL_VALUE,
  SET_RESET_TYPE,
  SET_STARTING_AFTER,
  RESET_DEFAULT_UNITS,
  SET_UNIT_PARENT,
  SET_UNIT_DETAILS,
  SET_FINAL_UNIT_DETAILS,
  SET_UNIT_INFORMATION,
  RESET_UNIT_DETAILS,
  SET_ONLY_UNITS,
  SELECT_RESIDENT_VEHICLE,
  SET_DEL_VALUES,
  SET_UNITS,
  SET_UNIT_PARENTS,
  SET_UNIT_PARKING,
  SET_UNIT_PARKING_SPACE,
  SET_UNIT_PARKING_SPACE_TEMP,
  SET_PARKING_TEMP
} from "../../constants/units/units_Actiontypes";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
import { array } from "prop-types";

export function get_unit_details(token, unit_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_unit_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_unit_information(responseJson.result));
        } else {
          dispatch(set_unit_information({ vehicle_details: [], resident_details: [], helper_details: [] }));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_all_unit_details(token, building_id) {
  return (dispatch) => {
    // dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_full_building_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_unit_details(responseJson))
        }
        // dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function remove_resident(token, resident_id, building_id, unit_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/remove_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        resident_id: resident_id,
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(get_unit_details(token, unit_id))
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_unit_information(payload) {
  return {
    type: SET_UNIT_INFORMATION,
    payload: payload
  }
}
export function reset_unit_details() {
  return {
    type: RESET_UNIT_DETAILS
  }
}
export function set_all_unit_details(response) {
  return {
    type: SET_UNIT_DETAILS,
    payload: response.result
  }
}
export function set_add_parking_no(payload) {
  return {
    type: SET_UNIT_PARKING,
    payload: payload
  }
}
export function set_temp_parking(payload) {
  return {
    type: SET_PARKING_TEMP,
    payload: payload
  }
}
export function add_parking_no(payload) {
  var payloads = payload.parking_space
  payloads.push(payload.parking)
  return {
    type: SET_UNIT_PARKING_SPACE,
    payload: payloads
  }
}
export function add_parking_no_temp(parking_arr, parking_unit) {
  parking_arr.push(parking_unit);
  return {
    type: SET_UNIT_PARKING_SPACE_TEMP,
    payload: parking_arr
  }
}
export function set_all_final_unit_details(response) {
  for (var i = 0; i < response.length; i++) {
    response[i].menu = false;
  }
  for (var j = 0; j < response.length; j++) {
    response.checked = false;
  }
  return {
    type: SET_FINAL_UNIT_DETAILS,
    payload: response
  }
}
export function handleMenu(obj, array) {
  var index = array.indexOf(obj);
  array[index].menu = !array[index].menu;
  return {
    type: SET_FINAL_UNIT_DETAILS,
    payload: array
  }
}

export function deleteSos(token, building_id, unit_id, sos_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/delete_sos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        sos_id: sos_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(get_unit_details(token, unit_id))
        }
        dispatch(set_snack_bar(true, responseJson.message));
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function updateSos(token, building_id, unit_id, sos_id, sos_name, sos_phone_number, sos_img) {
  return (dispatch) => {
    if (typeof sos_img === "string") {
      dispatch(set_loader())
      return fetch(UNIVERSAL.BASEURL + "v1/edit_sos", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "user-token": token
        },
        body: JSON.stringify({
          building_id: building_id,
          sos_id: sos_id,
          sos_name: sos_name,
          sos_phone_number: sos_phone_number,
          sos_img: sos_img

        }),
      }).then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(get_unit_details(token, unit_id))
          }
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unset_loader())
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + sos_name.replace(" ", "-") + ".png").put(sos_img[0]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch(set_loader())
          return fetch(UNIVERSAL.BASEURL + "v1/edit_sos", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "user-token": token
            },
            body: JSON.stringify({
              building_id: building_id,
              sos_id: sos_id,
              sos_name: sos_name,
              sos_phone_number: sos_phone_number,
              sos_img: downloadURL

            }),
          }).then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.status) {
                dispatch(get_unit_details(token, unit_id))
              }
              dispatch(set_snack_bar(true, responseJson.message));
              dispatch(unset_loader())
            })
            .catch((error) => {
              console.error(error);
            });
        })
      })
    }
  };
}

export function addSos(token, unit_id, sos_name, sos_phone_number, sos_img) {
  return (dispatch) => {
    var storageRef = firebase.storage().ref();
    var uploadTask = storageRef.child("images/" + sos_name.replace(" ", "-") + ".png").put(sos_img[0]);
    uploadTask.on("state_changed", function (snapshot) {
    }, function (error) {
    }, function () {
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/add_sos_to_unit", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "user-token": token
          },
          body: JSON.stringify({
            unit_id: unit_id,
            sos_name: sos_name,
            sos_phone_number: sos_phone_number,
            sos_img: downloadURL
          }),
        }).then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.status) {
              dispatch(get_unit_details(token, unit_id))
            }
            dispatch(set_snack_bar(true, responseJson.message));
            dispatch(unset_loader())
          })
          .catch((error) => {
            console.error(error);
          });
      });
    });
  }
}

export function get_final_unit_details(token, unit_id, building_id, keyword) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_final_unit_info", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        unit_id: unit_id,
        keyword: keyword
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_final_unit_details(responseJson.result))
        } else {
          dispatch(set_all_final_unit_details([]))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function search_unit(token, building_id, keyword) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/search_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        keyword: keyword
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_final_unit_details(responseJson.result))
        } else {
          dispatch(set_all_final_unit_details([]))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_all_units(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_units(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_units_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_all_unit(token, building_id) {
  return (dispatch) => {
    // dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_units(responseJson))
        }
        // dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_same_units(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_units(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_units_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          if (responseJson.message === "No Units Found") {
            dispatch(set_all_units({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_units(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_units_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_units(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_units_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function reset_units() {
  return {
    type: RESET_DEFAULT_UNITS
  }
}
export function set_starting_after(payload) {
  return {
    type: SET_STARTING_AFTER,
    payload: payload
  }
}
export function set_all_units(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_ALL_UNITS,
    payload: obj,
  }
}
export function set_all_units_pagination(responseJson, limit, starting_after) {
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  return {
    type: SET_ALL_UNITS,
    payload: obj,
  }
}
export function update_unit_type(token, name, parent_name, all_data, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/update_unit_type", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_type_id: all_data._id,
        building_id: all_data.building_id,
        type_name: name,
        type_parent_name: parent_name,
        type_parent_id: all_data.type_parent_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Unit Type Updated Succcessfully"));
          dispatch(get_all_units_type(token, building_id))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Unit Type Could Not Be Updated"));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function delete_unit_type(token, unit_types, building_id) {
  var units = []
  for (var i = 0; i < unit_types.length; i++) {
    if (unit_types[i].checked === true) {
      units.push(unit_types[i]._id)
    }
  }
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/delete_unit_type", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_type_id: JSON.stringify(units),
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_all_units_type(token, building_id))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function validate_add_unit_type_func(token, units, building_id) {
  return (dispatch) => {
    if (units.units_type_name === "") {
      dispatch(set_snack_bar(true, "Please enter Unit type  Name"));
    } else if (units.units_type_parent_name === "") {
      dispatch(set_snack_bar(true, "Please enter unit type parent name"));
    } else {
      dispatch(add_unit_type(token, units, building_id))
    };
  }
}
export function add_unit_type(token, units, building_id) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/add_unit_type", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        type_name: units.units_type_name,
        type_parent_id: units.units_type_parent_name,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Unit Type added Successfully"));
          dispatch(set_reset_type())
          dispatch(get_all_units_type(token, building_id))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Unit Type could not be added"));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function set_reset_type() {
  return {
    type: SET_RESET_TYPE,
  }
}
export function get_all_units_type(token, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_unit_type", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_units_type(responseJson.result));
        }
        else {
          dispatch(set_all_units_type([]));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function get_sublevel(token, building_id, parent_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/get_sublevel_units", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        type_parent_id: parent_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_units_type(responseJson.result));
        }
        else {
          dispatch(set_all_units_type([]));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function select_resident_vehicle(resident_id) {
  return {
    type: SELECT_RESIDENT_VEHICLE,
    payload: resident_id,
  }
}
export function add_vehicle_to_unit(resident_id, vehicle_name, vehicle_type, vehicle_regd_num, token, unit_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/add_vehicle_to_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        resident_id: resident_id,
        resident_vehicle_details: JSON.stringify({
          vehicle_name: vehicle_name,
          vehicle_type: vehicle_type,
          vehicle_regd_num: vehicle_regd_num
        })
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(get_unit_details(token, unit_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function add_helper_to_unit(helper_id, unit_id, token, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/assign_helper_to_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        unit_id: unit_id,
        helper_id: helper_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(get_unit_details(token, unit_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function remove_vehicle_from_resident(resident_id, vehicle_details, token, unit_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/remove_vehicle_from_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        resident_id: resident_id,
        resident_vehicle_details: JSON.stringify(vehicle_details)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(get_unit_details(token, unit_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function remove_helper_from_unit(helper_id, unit_id, token, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/remove_helper_from_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        unit_id: unit_id,
        helper_id: helper_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(get_unit_details(token, unit_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function set_all_units_type(payload) {
  for (var i = 0; i < payload.length; i++) {
    payload.checked = false;
  }
  return {
    type: SET_ALL_UNITS_TYPES,
    payload: payload,
  }
}
export function set_unit_type_name(payload) {
  return {
    type: SET_UNIT_TYPE_NAME,
    payload: payload
  }
}
export function set_unit_type_parent_name(payload) {
  return {
    type: SET_UNIT_TYPE_PARENT_NAME,
    payload: payload
  }
}
export function set_units_square_feet(payload) {
  return {
    type: SET_SQUARE_FEET,
    payload: payload
  }
}
export function set_units_unit_type(payload) {
  return {
    type: SET_UNIT_TYPE,
    payload: payload
  }
}
export function set_units_parent(payload) {
  return {
    type: SET_UNIT_PARENT,
    payload: payload
  }
}
export function set_unit_name(payload) {
  return {
    type: SET_UNIT_NAME,
    payload: payload
  }
}
export function set_unit_description(payload) {
  return {
    type: SET_UNIT_DESCRIPTION,
    payload: payload
  }
}
export function toggleCheckbox(obj, array) {
  var index = array.indexOf(obj);
  array[index].checked = !array[index].checked;
  return {
    type: SET_ONLY_UNITS,
    payload: array,
  }
}
export function toggleCheckbox1(obj, array) {
  var index = array.indexOf(obj);
  array[index].checked = !array[index].checked;
  return {
    type: SET_UNITS,
    payload: array,
  }
}
export function delete_arr_element_temp(s, arr) {
  var index = arr.indexOf(s);
  arr.splice(index, 1);
  return {
    type: SET_UNIT_PARKING_SPACE_TEMP,
    payload: arr,
  }
}
export function delete_arr_element(s, arr, token, building_id, unit_id) {
  var index = arr.indexOf(s);
  arr.splice(index, 1);
  return (dispatch) => {
    dispatch(add_parking_to_unit(token, building_id, unit_id, arr))
  }
}
export function setCheckbox(array) {
  return {
    type: SET_ONLY_UNITS,
    payload: array,
  }
}
export function reset_del_values() {
  return {
    type: SET_DEL_VALUES,
    payload: [],
  }
}
export function validate_add_unit(token, units, building_id) {
  return (dispatch) => {
    if (units.unit_name === "") {
      dispatch(set_snack_bar(true, "Please enter Unit Name"));
    } else if (units.square_feet === "") {
      dispatch(set_snack_bar(true, "Please enter unit Square Feet"));
    } else if (units.selected_unit === "") {
      dispatch(set_snack_bar(true, "Please select an unit from the Dropdown"));
    } else {
      dispatch(add_unit(token, units, building_id))
    };
  }
}
export function add_unit(token, units, building_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/add_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        unit_name: units.unit_name,
        unit_desc: units.unit_description,
        unit_type_id: units.selected_unit,
        square_feet: units.square_feet,
        unit_parent_id: units.units_parent
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(unset_loader())
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_all_units(token, building_id))
          dispatch(set_reset())
          dispatch(get_all_unit_details(token, building_id))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function set_reset() {
  return {
    type: RESET_ALL_VALUE,
  }
}
export function update_single_unit(token, id, building_id, name, desc, type_id, square_feet, parent_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/update_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: id,
        building_id: building_id,
        unit_name: name,
        unit_desc: desc,
        unit_type_id: type_id,
        square_feet: square_feet,
        unit_parent_id: parent_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_all_units(token, building_id, limit, starting_after));
          dispatch(get_final_unit_details(token, parent_id, building_id, ""));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function delete_single_unit(token, units, building_id, parent_id) {
  var unit_id = []
  for (var i = 0; i < units.length; i++) {
    if (units[i].checked === true) {
      unit_id.push(units[i]._id)
    }
  }
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/delete_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: JSON.stringify(unit_id),
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_final_unit_details(token, parent_id, building_id, ""));
          dispatch(get_all_unit_details(token, building_id));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function import_file_func(files, token, building_id, parent_id, parent_type) {
  return dispatch => {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/unit_excelsheet_import", {
      method: "POST",
      headers: {
        "user-token": token,
        "building-id": building_id,
        "unit-parent-id": parent_id,
        "unit-parent-type-id": parent_type
      },
      body:
        formData
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status) {
          dispatch(unset_loader());
          dispatch(set_reset())
          dispatch(set_snack_bar(true, responseJson.message))
          dispatch(get_final_unit_details(token, parent_id, building_id, ""))
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader())
        }
        dispatch(unset_loader())
      })
      .catch(error => {
      });
  };
}
export function get_all_parent(type_id, token, building_id, unit_types) {
  var type_parent_id;
  for (var i = 0; i < unit_types.length; i++) {
    if (unit_types[i]._id === type_id) {
      type_parent_id = unit_types[i].type_parent_id;
    }
  }
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_parent_units", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        type_parent_id: type_parent_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_unit_parent(responseJson.result))
        }
        else {
          dispatch(set_all_unit_parent([]))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_all_unit_parent(payload) {
  return {
    type: SET_UNIT_PARENTS,
    payload: payload
  }
}
export function update_resident(token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, resident_img, unit_id, is_residing, is_owner, is_sub_resident, resident_image, id_image_array, id_name, id_number, resident_id_array) {
  var resident_new_image = null;
  var array = []
  var id = ""
  return (dispatch) => {
    dispatch(set_loader())
    if (resident_image === null && id_image_array === null) {
      dispatch(update_resident_final(token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, resident_img, unit_id, is_residing, is_owner, is_sub_resident, resident_id_array))

    }
    if (resident_image !== null && id_image_array === null) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + resident_image.name + ".png").put(resident_image);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch(update_resident_final(token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, downloadURL, unit_id, is_residing, is_owner, is_sub_resident, resident_id_array))

        })
      })
    }
    if (id_image_array !== null && resident_image === null) {
      Array.from(id_image_array).forEach(file => {
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child("images/" + file.name + ".png").put(file);
        uploadTask.on("state_changed", function (snapshot) {
        }, function (error) {
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            array.push(downloadURL)
            if (array.length === id_image_array.length) {
              var resident_id_arrays = [{
                idproof_id: id_number,
                idproof_image: array,
                idproof_type: id_name
              }]
              dispatch(update_resident_final(token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, resident_img, unit_id, is_residing, is_owner, is_sub_resident, resident_id_arrays))
            }
          })
        })
      });
    }
    if (id_image_array !== null && resident_image !== null) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + resident_image.name + ".png").put(resident_image);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL1) {
          // resident_new_image = downloadURL
          Array.from(id_image_array).forEach(file => {
            var storageRef = firebase.storage().ref();
            var uploadTask = storageRef.child("images/" + file.name + ".png").put(file);
            uploadTask.on("state_changed", function (snapshot) {
            }, function (error) {
            }, function () {
              uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                array.push(downloadURL)
                if (array.length === id_image_array.length) {
                  var resident_id_arrays = [{
                    idproof_id: id_number,
                    idproof_image: JSON.stringify(array),
                    idproof_type: id_name
                  }]
                  dispatch(update_resident_final(token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, downloadURL1, unit_id, is_residing, is_owner, is_sub_resident, resident_image, resident_id_arrays))
                }
              })
            })
          });
        })
      })
    }

  }



}
export function update_resident_final(token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, resident_img, unit_id, is_residing, is_owner, is_sub_resident, resident_id_array) {
  return (dispatch) => {
    // dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/update_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        resident_id: resident_id,
        resident_name: resident_name,
        resident_contact_info: resident_contact_info,
        resident_sec_contact_info: resident_sec_contact_info,
        resident_permanent_address: resident_permanent_address,
        resident_img: resident_img,
        is_residing: is_residing,
        is_owner: is_owner,
        is_sub_resident: is_sub_resident,
        resident_email: "",
        resident_id_proof: JSON.stringify(resident_id_array)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_unit_details(token, unit_id));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function add_parking_to_unit(token, building_id, unit_id, parking_spaces) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/add_parking_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
        building_id: building_id,
        parking_spaces: JSON.stringify(parking_spaces)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_unit_details(token, unit_id));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}