import {
  SET_ALL_AMENITIES_BOOKING,
  SET_SELECTED_AMENITY,
  SET_START_DATE,
  SET_END_DATE,
  SET_STARTING_AFTER,
  SET_CHECK,
  SET_UNAPPROVED_AMENITIES_BOOKING,
  SET_START_TIME,
  SET_END_TIME,
  SET_BOOKING_DATE,
  RESET_AMENITY_BOOKING,
  SET_CHARGES
} from "../../constants/amenities_booking/amenity_booking_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function get_all_amenities_booking(token, building_id, limit, starting_after, start_date, end_date) {
  starting_after = 0;
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_bookings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        start_date: start_date,
        end_date: end_date,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities_booking(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_amenities_booking_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(set_all_amenities_booking({ result: [], total: 0 }, 0, 0))
        }
        dispatch(get_unapproved_booking(token, building_id, limit, starting_after));
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_amenities_booking(token, amenity_id, building_id, limit, starting_after, start_date, end_date) {
  starting_after = 0;
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/view_bookings_amenities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        amenity_id: amenity_id,
        start_date: start_date,
        end_date: end_date,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities_booking(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_amenities_booking_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(set_all_amenities_booking({ result: [], total: 0 }, 0, 0))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn(token, building_id, limit, starting_after, start_date, end_date) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_bookings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        start_date: start_date,
        end_date: end_date,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_amenities_booking(responseJson, limit, starting_after))
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn(token, building_id, limit, starting_after, start_date, end_date) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_bookings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        start_date: start_date,
        end_date: end_date,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities_booking(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_amenities_booking_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn_single(token, amenity_id, building_id, limit, starting_after, start_date, end_date) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_bookings_amenities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        start_date: start_date,
        amenity_id: amenity_id,
        end_date: end_date,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_amenities_booking(responseJson, limit, starting_after))
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function add_amenity_booking(token, building_id, amenity_id, start_time, end_time, booking_date, resident_id, unit_id, charges) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/book_amenity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        amenity_id: amenity_id,
        booking_date: booking_date,
        starting_time: start_time,
        ending_time: end_time,
        resident_id: resident_id,
        guest_info: JSON.stringify([]),
        unit_id: unit_id,
        charges: charges
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(reset_amenity_booking());
        }
        dispatch(set_snack_bar(true, responseJson.message));
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn_single(token, amenity_id, building_id, limit, starting_after, start_date, end_date) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_bookings_amenities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        amenity_id: amenity_id,
        start_date: start_date,
        end_date: end_date,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities_booking(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_amenities_booking_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_starting_after(payload) {
  return {
    type: SET_STARTING_AFTER,
    payload: payload
  }
}
export function set_all_amenities_booking(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_ALL_AMENITIES_BOOKING,
    payload: obj,
  }
}

export function set_all_amenities_booking_pagination(responseJson, limit, starting_after) {
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  return {
    type: SET_ALL_AMENITIES_BOOKING,
    payload: obj,
  }
}

export function reset_amenity_booking() {
  return {
    type: RESET_AMENITY_BOOKING
  }
}

export function setCharges(charges) {
  return {
    type: SET_CHARGES,
    payload: charges
  }
}
export function set_selected_amenity(payload) {
  return {
    type: SET_SELECTED_AMENITY,
    payload: payload
  }
}

export function set_check(payload) {
  return {
    type: SET_CHECK,
    payload: payload
  }
}
export function set_start_date(payload) {
  return {
    type: SET_START_DATE,
    payload: payload
  }
}
export function set_end_date(payload) {
  return {
    type: SET_END_DATE,
    payload: payload
  }
}
export function set_start_time(payload) {
  return {
    type: SET_START_TIME,
    payload: payload
  }
}
export function set_end_time(payload) {
  return {
    type: SET_END_TIME,
    payload: payload
  }
}
export function set_booking_date(payload) {
  return {
    type: SET_BOOKING_DATE,
    payload: payload
  }
}
export function show_amenity_booking(token, building_id, amenities_booking) {
  return (dispatch) => {
    dispatch(set_loader());
    if (amenities_booking.selected_amenity === "All" || amenities_booking.selected_amenity === "") {
      dispatch(get_all_amenities_booking(token, building_id, amenities_booking.limit, amenities_booking.starting_after, amenities_booking.start_date, amenities_booking.end_date))
      dispatch(set_check(false))
    }
    else {
      dispatch(get_amenities_booking(token, amenities_booking.selected_amenity, building_id, amenities_booking.limit, amenities_booking.starting_after, amenities_booking.start_date, amenities_booking.end_date))
      dispatch(set_check(true))
    }
  };
}
export function get_unapproved_booking(token, building_id, limit, starting_after) {
  starting_after = 0;
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/view_unapproved_bookings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_snack_bar(true, responseJson.message));
            dispatch(set_unapproved_amenities_booking(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_snack_bar(true, responseJson.message));
            dispatch(set_unapproved_amenities_booking_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(set_unapproved_amenities_booking({ result: [], total: 0 }, 0, 0))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn_unapproved(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_unapproved_bookings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_unapproved_amenities_booking(responseJson, limit, starting_after))
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn_unapproved(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_unapproved_bookings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_unapproved_amenities_booking(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_unapproved_amenities_booking_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_unapproved_amenities_booking(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_UNAPPROVED_AMENITIES_BOOKING,
    payload: obj,
  }
}
export function set_unapproved_amenities_booking_pagination(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  }
  return {
    type: SET_UNAPPROVED_AMENITIES_BOOKING,
    payload: obj,
  }
}
export function approve_booking(token, booking_id, building_id, limit, starting_after, amenities_booking) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/approve_booking", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        booking_id: booking_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_unapproved_booking(token, building_id, limit, starting_after));
          dispatch(get_all_amenities_booking(token, building_id, amenities_booking.limit, amenities_booking.starting_after, amenities_booking.start_date, amenities_booking.end_date));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function unapprove_booking(token, booking_id, building_id, comment, limit, starting_after, amenities_booking) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/cancel_booking", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        booking_id: booking_id,
        cancelling_reason: comment
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_unapproved_booking(token, building_id, limit, starting_after));
          dispatch(get_all_amenities_booking(token, building_id, amenities_booking.limit, amenities_booking.starting_after, amenities_booking.start_date, amenities_booking.end_date));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}