import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import { Redirect }
  from "react-router-dom";
import { Avatar } from "@material-ui/core";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class Residents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      editredirect: false,
      resident_id_proof_id: "",
      resident_id_proof_type: "",
      resident_vehicle_details: "",
      resident_rc_no: "",
      resident_id: "",
      building_id: "",
      resident_name: "",
      resident_img: "",
      resident_img_old: "",
      resident_email: "",
      resident_contact_info: "",
      resident_sec_contact_info: "",
      resident_permanent_address: "",
      unit_id: "",
      is_owner: "",
      delete_redirect: false
    }
  }
  componentDidMount() {
    this.props.reset_residents();
    this.props.get_all_vehicles(this.props.login.token);
    this.props.get_all_residents(this.props.login.token, this.props.login.building_id, this.props.residents.limit, this.props.residents.starting_after);
    this.props.get_all_units(this.props.login.token, this.props.login.building_id);
    this.props.get_all_id_proofs(this.props.login.token);
  }
  checkRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  handleClose = () => {
    this.setState({
      editredirect: true,
    })
  }
  ed = (s) => {
    this.setState({
      resident_id_proof_id: s.resident_id_proof[0].idproof_id,
      resident_id_proof_type: s.resident_id_proof[0].idproof_type,
      resident_vehicle_details: JSON.stringify({
        vehicle_id: s.resident_vehicle_details[0].vehicle_id,
        vehicle_name: s.resident_vehicle_details[0].vehicle_name,
        vehicle_type: s.resident_vehicle_details[0].vehicle_type
      }),
      resident_rc_no: s.resident_vehicle_details[0].vehicle_regd_num,
      resident_id: s._id,
      building_id: s.building_id,
      resident_name: s.resident_name,
      resident_img_old: s.resident_img,
      resident_email: s.resident_email,
      resident_contact_info: s.resident_contact_info,
      resident_sec_contact_info: s.resident_sec_contact_info,
      resident_permanent_address: s.resident_permanent_address,
      unit_id: s.unit_id,
      is_owner: s.is_owner
    })
  }
  render() {
    const {
      residents,
      vehicles,
      snackbar,
      close_snack_bar,
      login,
      id_proofs,
      delete_user_info,
      units,
      validate_resident_edit_func,
      prev_btn,
      next_btn
    } = this.props;
    var btn;
    if (this.state.redirect) {
      return <Redirect to="/add_resident" />;
    }
    if (residents.total > residents.limit) {
      if (residents.end_var === residents.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, residents.limit, residents.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {residents.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{residents.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{residents.total}
        </span>
      }
      else if (residents.start_var > residents.limit && residents.end_var < residents.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, residents.limit, residents.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(login.token, login.building_id, residents.limit, residents.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {residents.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{residents.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{residents.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled
            >
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(login.token, login.building_id, residents.limit, residents.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {residents.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{residents.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{residents.total}
          </span>
      }
    }
    else {
      btn = <span >
        {/* {transactions.start_var}&nbsp;-&nbsp;{transactions.end_var}&nbsp;out of&nbsp;{transactions.total} */}
        <IconButton disabled
        >
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled
        >
          <Icon>navigate_next</Icon>
        </IconButton>
        {residents.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{residents.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{residents.total}
      </span>
    }
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                {/* <Icon>content_copy</Icon> */}
                <h3>
                  VIEW RESIDENTS
              </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item xs={12}>
                <IconButton
                  onClick={this.checkRedirect.bind(this)}
                >
                  <Icon >add</Icon>
                </IconButton>
                <div style={{ float: "right" }}>
                  {btn}
                </div>
                <br /><br />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell ></TableCell>
                      <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                      <TableCell align="left">Contact info</TableCell>
                      <TableCell align="left">Permanent Address</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {residents.all_residents.map(row => (
                      <TableRow >
                        <TableCell align="center" className="table_cells" >
                          <Avatar alt={row.resident_name} src={row.resident_img}
                            style={{ width: 50, height: 50 }}></Avatar>
                        </TableCell>
                        <TableCell align="left" className="table_cells" >
                          &nbsp;&nbsp;{row.resident_name}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.resident_contact_info}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.resident_permanent_address}
                        </TableCell>
                        <TableCell align="right" className="table_cells">
                          <IconButton
                            onClick={() => {
                              this.ed(row)
                              this.setState({
                                editredirect: true,
                              });
                            }}
                          >
                            <Icon >edit</Icon>
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              this.setState({
                                delete_redirect: true,
                                resident_id: row._id,
                              })
                            }}
                          >
                            <Icon >delete</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_name}
                    onChange={(event) => { this.setState({ resident_name: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  Image : <input type="file"
                    onChange={(event) => { this.setState({ resident_img: event.target.files[0] }) }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Email"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_email}
                    onChange={(event) => { this.setState({ resident_email: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Contact_Info"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_contact_info}
                    onChange={(event) => { this.setState({ resident_contact_info: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Secondary_contact_info(Optional)"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_sec_contact_info}
                    onChange={(event) => { this.setState({ resident_sec_contact_info: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Permanent Address"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_permanent_address}
                    onChange={(event) => { this.setState({ resident_permanent_address: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    id="outlined-email-input"
                    label="ID Proof Type"
                    type="dropdown"
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_id_proof_type}
                    onChange={(event) => { this.setState({ resident_id_proof_type: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {id_proofs.all_id_proofs.map((single) => (
                      <MenuItem key={single.proof_name} value={single.proof_name}>
                        {single.proof_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    id="outlined-email-input"
                    label="ID Proof Number"
                    type="text"
                    disabled={this.state.resident_id_proof_type === "" ? true : false}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_id_proof_id}
                    onChange={(event) => { this.setState({ resident_id_proof_id: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Vehicle Name"
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_vehicle_details}
                    onChange={(event) => {
                      var data = JSON.parse(event.target.value)
                      this.setState({
                        resident_vehicle_details: JSON.stringify({
                          vehicle_id: data.vehicle_id,
                          vehicle_name: data.vehicle_name,
                          vehicle_type: data.vehicle_type
                        }),
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {vehicles.all_vehicles.map((single) => (
                      <MenuItem key={single.unit_name} value={JSON.stringify({ vehicle_id: single._id, vehicle_name: single.vehicle_name, vehicle_type: single.vehicle_type })}>
                        {single.vehicle_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Vehicle Registration Number"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.resident_rc_no}
                    onChange={(event) => {
                      this.setState({
                        resident_rc_no: (event.target.value)
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Unit Type"
                    type="dropdown"
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={this.state.unit_id}
                    onChange={(event) => { this.setState({ unit_id: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {units.all_units.map((single) => (
                      <MenuItem key={single.unit_name} value={single._id}>
                        {single.unit_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <input
                    type="checkbox"
                    checked={this.state.is_owner}
                    onClick={(event) => { this.setState({ is_owner: !(this.state.is_owner) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                  Is Owner
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                    resident_id_proof_id: "",
                    resident_id_proof_type: "",
                    resident_vehicle_details: "",
                    resident_id: "",
                    building_id: "",
                    resident_name: "",
                    resident_img: "",
                    resident_img_old: "",
                    resident_email: "",
                    resident_contact_info: "",
                    resident_sec_contact_info: "",
                    resident_permanent_address: "",
                    unit_id: "",
                    is_owner: "",
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_resident_edit_func(login.token, this.state.resident_id_proof_id, this.state.resident_id_proof_type, this.state.resident_vehicle_details, this.state.resident_rc_no, this.state.resident_id, this.state.resident_name, this.state.resident_img, this.state.resident_img_old, this.state.resident_email, this.state.resident_contact_info, this.state.resident_sec_contact_info, this.state.resident_permanent_address, this.state.unit_id, this.state.is_owner, login.building_id, residents.limit, residents.starting_after);
                  this.setState({
                    redirect: false,
                    editredirect: false,
                    resident_id_proof_id: "",
                    resident_id_proof_type: "",
                    resident_vehicle_details: [],
                    resident_id: "",
                    building_id: "",
                    resident_name: "",
                    resident_img: "",
                    resident_img_old: "",
                    resident_email: "",
                    resident_contact_info: "",
                    resident_sec_contact_info: "",
                    resident_permanent_address: "",
                    unit_id: "",
                    is_owner: "",
                  })
                }}
                color="primary" autoFocus>
                UPDATE
                            </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.delete_redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete The Unit Type</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    delete_redirect: false,
                    resident_id: ""
                  });
                }}
                color="primary">
                NO
                </Button>
              <Button
                onClick={() => {
                  delete_user_info(this.props.login.token, this.state.resident_id, login.building_id, residents.limit, residents.starting_after)
                  this.setState({
                    delete_redirect: false,
                    resident_id: ""
                  })
                }}
                color="primary" autoFocus>
                Yes
                            </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid >
      </Grid >
    );
  }
}
export default withStyles(styles)(Residents);