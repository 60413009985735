import React, { Component } from "react";
import { connect } from "react-redux";
import AllComplaints from "../../components/complaints/all_complaints";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    get_all_complaints,
    prev_btn,
    next_btn,
    get_unresolved_complaints,
    validate_allot_func,
    validate_complete_func,
    next_btn_unresolved,
    prev_btn_unresolved,
    next_btn_incomplete,
    prev_btn_incomplete,
    get_incomplete_complaints
} from "../../actions/complaints/complaints_action";
import {
    get_same_employee
} from "../../actions/employee/employee_action";
export class All_complaints_cont extends Component {
    render() {
        return (
            <AllComplaints {...this.props} />
        );
    };
};
export const mapStateToProps = store => {
    return {
        complaints: store.complaints,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        employee: store.employee
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_complaints: (token, building_id, limit, starting_after) => {
            dispatch(get_all_complaints(token, building_id, limit, starting_after))
        },
        get_incomplete_complaints: (token, building_id, limit, starting_after) => {
            dispatch(get_incomplete_complaints(token, building_id, limit, starting_after))
        },
        next_btn_incomplete: (token, building_id, limit, starting_after) => {
            dispatch(next_btn_incomplete(token, building_id, limit, starting_after))
        },
        prev_btn_incomplete: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn_incomplete(token, building_id, limit, starting_after))
        },
        prev_btn: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn(token, building_id, limit, starting_after))
        },
        next_btn: (token, building_id, limit, starting_after) => {
            dispatch(next_btn(token, building_id, limit, starting_after))
        },
        validate_complete_func: (token, building_id, complaint_id, employee_id, complete_date, selected_type, complaint_fee, feedback, limit, starting_after) => {
            dispatch(validate_complete_func(token, building_id, complaint_id, employee_id, complete_date, selected_type, complaint_fee, feedback, limit, starting_after))
        },
        validate_allot_func: (token, building_id, complaint_id, employee_id, date, limit, starting_after) => {
            dispatch(validate_allot_func(token, building_id, complaint_id, employee_id, date, limit, starting_after))
        },
        get_same_employee: (token, building_id, ) => {
            dispatch(get_same_employee(token, building_id))
        },
        get_unresolved_complaints: (token, building_id, limit, starting_after) => {
            dispatch(get_unresolved_complaints(token, building_id, limit, starting_after))
        },
        prev_btn_unresolved: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn_unresolved(token, building_id, limit, starting_after))
        },
        next_btn_unresolved: (token, building_id, limit, starting_after) => {
            dispatch(next_btn_unresolved(token, building_id, limit, starting_after))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(All_complaints_cont); 