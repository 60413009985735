import React, { Component } from "react";
import { connect } from "react-redux";
import AddHelperVisitor from "../../../components/visitors/helper_visitor/add_helper_visitor";
import {
    find_helper,
    helper_entry
} from "../../../actions/visitors/helper/helper_visitors_action";
import {
    close_snack_bar
} from "../../../actions/snackbar/snackbar_action";
export class Add_Helper_Visitors_Cont extends Component {
    render() {
        return (
            <AddHelperVisitor {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        visitors: store.helper_visitor_reducer,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        find_helper: (token, building_id, search_keyword) => {
            dispatch(find_helper(token, building_id, search_keyword));
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        helper_entry: (token, building_id, helper, time) => {
            dispatch(helper_entry(token, building_id, helper, time));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Helper_Visitors_Cont);