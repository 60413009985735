import React, { Component } from "react";
import { connect } from "react-redux";
import AddPoll from "../../components/polling/addPolling";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  addPoll
} from "../../actions/polling/pollingAction";

export class AddPollingCont extends Component {
  render() {
    return (
      <AddPoll {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    polling: store.polling
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    addPoll: (token, buildingId, question, options) => {
      dispatch(addPoll(token, buildingId, question, options));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPollingCont);