export const SET_ALL_RESIDENTS = "SET_ALL_RESIDENTS";
export const SET_RESIDENT_EMAIL = "SET_RESIDENT_EMAIL";
export const SET_RESIDENT_CONTACT_INFO = "SET_RESIDENT_CONTACT_INFO";
export const SET_RESIDENT_SECONDARY_CONTACT_INFO = "SET_RESIDENT_SECONDARY_CONTACT_INFO";
export const SET_RESIDENT_PERMANENT_ADDRESS = "SET_RESIDENT_PERMANENT_ADDRESS";
export const SET_RESIDENT_ID_PROOF_TYPE = "SET_RESIDENT_ID_PROOF_TYPE";
export const SET_RESIDENT_ID_PROOF_ID = "SET_RESIDENT_ID_PROOF_ID";
export const SET_RESIDENT_VEHICLE_DETAILS = "SET_RESIDENT_VEHICLE_DETAILS";
export const SET_RESIDENT_RC_NUM = "SET_RESIDENT_RC_NUM";
export const SET_RESIDENT_NAME = "SET_RESIDENT_NAME";
export const SET_RESIDENT_IMAGE = "SET_RESIDENT_IMAGE";
export const SET_IS_OWNER = "SET_IS_OWNER";
export const SET_IS_RESIDING = "SET_IS_RESIDING";
export const SET_UNIT_ID = "SET_UNIT_ID";
export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const RESET_ALL_RESIDENTS = "RESET_ALL_RESIDENTS";
export const RESET_DEFAULT_RESIDENT = "RESET_DEFAULT_RESIDENT";
export const SET_IS_SUB_RESIDENT = "SET_IS_SUB_RESIDENT";
export const SET_ID_PROOF_IMAGE = "SET_ID_PROOF_IMAGE";