import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import Tooltip from '@material-ui/core/Tooltip';
import Moment from "react-moment";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class Building extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editredirect: false,
      loginredirect: false,
      name: "",
      id: "",
      confirm_delete: false,
      building_account_num: "",
      building_ifsc: "",
      building_bank_name: "",
      building_address: "",
      building_poc_name: "",
      building_poc_phonenumber: "",
      building_login_id: "",
      building_id: "",
      latitude: "",
      longitude: "",
      e_name: "",
      e_building_id: "",
      e_email: "",
      e_contact_info: "",
      e_img: "",
    }
  }
  componentDidMount() {
    // this.props.set_all_buildings([])
    this.props.setRedirect(false)
    this.props.get_all_buildings(this.props.login.token)
  }
  edit_buiding = (s) => {
    this.setState({
      editredirect: true,
      building_id: s._id,
      name: s.building_name,
      building_account_num: s.building_accounts[0].account_number,
      building_bank_name: s.building_accounts[0].bank_name,
      building_ifsc: s.building_accounts[0].ifsc_code,
      building_address: s.building_address,
      building_poc_name: s.building_poc_name,
      building_poc_phonenumber: s.building_poc_phonenumber,
      latitude: s.location[0],
      longitude: s.location[1],
    })
  }
  render() {
    const {
      buildings,
      snackbar,
      close_snack_bar,
    } = this.props;
    
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW BUILDINGS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item lg={12} >
                <Link
                  style={{ textDecoration: "none" }}
                  to="add_buildings">
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                <br /><br />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell >&nbsp;&nbsp;Name</TableCell>
                      <TableCell align="center">Point of Contact Name</TableCell>
                      <TableCell align="center">Point of Contact Number </TableCell>
                      <TableCell align="center" >Point of Address </TableCell>
                      <TableCell align="center" >Valid Till </TableCell>
                      <TableCell align="center" >Action </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {buildings.all_buildings.map(row => (
                      <TableRow key={row._id} >
                        <TableCell align="left" className="table_cells">
                          &nbsp;&nbsp;{row.building_name}
                        </TableCell>
                        <TableCell align="center" className="table_cells">
                          {row.building_poc_name}
                        </TableCell>
                        <TableCell align="center" className="table_cells">
                          {row.building_poc_phonenumber}
                        </TableCell>
                        <TableCell align="center" className="table_cells">
                          {row.building_address}
                        </TableCell>
                        <TableCell align="center" className="table_cells">
                          <Moment format="DD/MM/YYYY">{row.valid_till}</Moment>
                        </TableCell>
                        <TableCell align="right" style={{ padding: 0 }}>
                          {(parseInt((new Date(row.valid_till)).getTime()) - buildings.current_date.getTime()) < 1296000000 ?
                            <Tooltip title="Your subcription is going to end soon" aria-label="add">
                              <IconButton
                                style={{ color: "red", padding: 10 }}
                              >
                                <Icon style={{ fontSize: 16 }}>error</Icon>
                              </IconButton>
                            </Tooltip> : ""}
                          <Tooltip title="Details" aria-label="add">
                            <Link style={{ textDecoration: "none" }}
                              to={
                                {
                                  pathname: "/building_details",
                                  state: {
                                    row: row
                                  }
                                }}>
                              <IconButton style={{ padding: 10 }}>
                                <Icon style={{ fontSize: 16 }}>list</Icon>
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(Building);