import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import { IconButton, TextField, MenuItem, Grid, Icon } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));


export default function FullWidthTabs(firstTable) {

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position="static" color="default"> */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab style={{ background: "linearGradient(#ec407a, #d81b60)!important" }} className="tabs" label="Ledger" {...a11yProps(0)} />
        <Tab style={{ background: "linearGradient(#ec407a, #d81b60)!important" }} className="tabs" label="Pending Payments" {...a11yProps(1)} />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel style={{ width: "100%" }} value={value} index={0} dir={theme.direction}>

        <div style={{ float: "right" }}>
          {firstTable.btn}
        </div>

        <Grid item xs={12} md={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Raised Date</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Charges</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Due Date</TableCell>
                <TableCell align="left">Paid On</TableCell>
                <TableCell align="left">Payment Mode</TableCell>
                <TableCell align="left">Txn Id</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firstTable.firstTable.map(row => (
                <TableRow key={row._id} >
                  <TableCell align="left" className="table_cells">
                    <Moment format="DD/MM/YYYY">
                      {row.raised_date}
                    </Moment>
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.input_type === "C" ? "CR" : "DR"}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.type}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.amount}
                  </TableCell>

                  <TableCell align="left" className="table_cells">
                    <Moment format="DD/MM/YYYY">
                      {row.due_date}
                    </Moment>
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.payment_date !== null ?
                      <Moment format="DD/MM/YYYY">
                        {row.payment_date}
                      </Moment>
                      : ""}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {
                      row.bank_details.bank_name ?
                        row.payment_type + "-" +
                        row.bank_details.bank_name + "- ****"
                        + row.bank_details.account_no.substring(row.bank_details.account_no.length, row.bank_details.account_no.length - 4)

                        : row.payment_type
                    }
                    {/* {row.payment_type} */}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.txn_id}
                  </TableCell>

                  <TableCell align="right" className="table_cells">
                    {row.input_type === "C" && row.invoice_id.length > 0 &&
                      <IconButton
                        onClick={() => { firstTable.setDialog(row.invoice_id, row._id, row.approved, row.receipt_image, row) }}
                      >
                        <Icon>
                          list
                        </Icon>
                      </IconButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={1} dir={theme.direction}>
        <Grid item xs={12}>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Charges</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Raised On</TableCell>
                <TableCell align="left">Due Date</TableCell>
                <TableCell align="left">Paid On</TableCell>
                <TableCell align="left">Payment Mode</TableCell>
                <TableCell align="left">Txn Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firstTable.secondTable.map(row => (
                <TableRow key={row._id} >
                  <TableCell align="left" className="table_cells">
                    {row.input_type}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.type}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.amount}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    <Moment format="DD/MM/YYYY">
                      {row.raised_date}
                    </Moment>
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    <Moment format="DD/MM/YYYY">
                      {row.due_date}
                    </Moment>
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.payment_date !== null ?
                      <Moment format="DD/MM/YYYY">
                        {row.payment_date}
                      </Moment>
                      : ""}
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.bank_details &&
                      row.bank_details.bank_name ?
                        row.payment_type + "-" +
                        row.bank_details.bank_name + "- ****"
                        + row.bank_details.account_no.substring(row.bank_details.account_no.length, row.bank_details.account_no.length - 4)

                        : row.payment_type
                    }
                  </TableCell>
                  <TableCell align="left" className="table_cells">
                    {row.txn_id}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

      </TabPanel>
    </div>
  );
}