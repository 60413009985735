import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Tooltip from "@material-ui/core/Tooltip";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import { IconButton, TextField, MenuItem } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import UNIVERSAL from "../../config/config";

import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FullWidthTabs from "./tabs";
class MaintenaceCreditEntries extends React.Component {

  componentDidMount() {
    this.props.reset()
    this.props.getAllMaintenaceCredit(this.props.login.token, this.props.maintenance.limit, this.props.maintenance.startingAfter, null, null, this.props.login.building_id);
  }

  constructor(props) {
    super(props);
    this.state = {
      unit_parent_id: "",
      dialog: false,
      arraymapped: [],
      btnCheck: false,
      addDialog: false,
      charge: "",
      amount: "",
      dueDate: null,
      payDialog: false,
      fullyPaid: false,
      startDate: null,
      endDate: null,
      disabledDateBtn: true,
      payBtnAmount: "",
      raisedDate: null,
      paymentType: "",
      transactionId: "",
      bankAccount: "",
      creditedTo: "",
    }
  }
  setDialog = (array, id, approved, receipt_image, row) => {
    var arr = []
    for (var i = 0; i < array.length; i++) {
      arr.push({
        id: id,
        checked: false,
        approved: approved,
        amount: array[i].amount,
        due_date: array[i].due_date,
        invoice_id: array[i].invoice_id,
        is_full_paid: array[i].is_full_paid,
        raised_on: array[i].raised_on,
        type: array[i].type,
        receipt_image: receipt_image,
        bank_id: "",
        paymentType: row.payment_type,
        unit_id: row.unit_id
      })
    }

    this.setState({
      dialog: true,
      arraymapped: arr,
      unit_id: row.unit_id
    })


  }
  checkDate = () => {
    var startDate = this.state.startDate
    var endDate = this.state.endDate
    if (endDate.getTime() > startDate.getTime()) {
      this.setState({ disabledDateBtn: false })
    }
  }
  render() {
    const {
      login,
      snackbar,
      close_snack_bar,
      maintenance,
      units,
      getUnitMaintenance,
      approveMaintenanceCredit,
      addUnitMaintenance,
      setInvoiceAmount,
      setInvoiceType,
      getPendingPayments,
      setPendingPayments,
      addUnitPaymentMaintenance,
      getAllMaintenaceCredit,
      bank,
    } = this.props;
    var btn;
    if (maintenance.total > maintenance.limit) {
      if (maintenance.end_var === maintenance.total) {
        btn = <span >
          <IconButton
            onClick={() => { getAllMaintenaceCredit(login.token, maintenance.limit, parseInt(maintenance.startingAfter) - parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
        </span>
      }
      else if (maintenance.start_var > maintenance.limit && maintenance.end_var < maintenance.total) {
        btn = <span >
          <IconButton
            onClick={() => { getAllMaintenaceCredit(this.props.login.token, maintenance.limit, parseInt(maintenance.startingAfter) - parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { getAllMaintenaceCredit(this.props.login.token, maintenance.limit, parseInt(maintenance.startingAfter) + parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { getAllMaintenaceCredit(login.token, maintenance.limit, parseInt(maintenance.startingAfter) + parseInt(maintenance.limit), this.state.startDate, this.state.endDate, this.props.login.building_id); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {maintenance.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{maintenance.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{maintenance.total}
      </span>
    }
    return (
      <Grid container spacing={8} >
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW ALL MAINTENANCE CREDIT
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container justify="space-evenly" >
                <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                  {this.state.endDate === null || this.state.startDate === null ?
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={this.state.endDate !== null && this.state.startDate !== null ? false : true}
                    >
                      Export
                                     </Button>
                    :
                    <a
                      style={{ textDecoration: "none" }}
                      href={UNIVERSAL.BASEURL + "v1/export_building_maintenance_credits?building_id=" + login.building_id + "&start_date=" + this.state.startDate + "&end_date=" + this.state.endDate}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                      >
                        Export
                                       </Button>

                    </a>
                  }
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Start Date"
                      value={this.state.startDate}
                      onChange={(e) => {
                        this.setState({ startDate: e })
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="End Date"
                      value={this.state.endDate}
                      onChange={(e) => {
                        this.setState({ endDate: e }, function () {
                          this.checkDate()
                        })

                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Button
                    disabled={this.state.startDate === null || this.state.endDate === null || this.state.disabledDateBtn ? true : false}
                    style={{ marginTop: 23, marginLeft: 10 }}
                    variant="outlined"
                    onClick={() => {
                      this.props.getAllMaintenaceCredit(this.props.login.token, this.props.maintenance.limit, 0, this.state.startDate, this.state.endDate, this.props.login.building_id)
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    className="refreshBtn"
                    variant="outlined"
                    onClick={() => {
                      this.props.getAllMaintenaceCredit(this.props.login.token, this.props.maintenance.limit, 0, this.state.startDate, this.state.endDate, this.props.login.building_id)
                    }}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <div style={{ float: "right" }}>
                  {btn}
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Raised On</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Charges</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Due Date</TableCell>
                      <TableCell align="left">Paid On</TableCell>
                      <TableCell align="left">Payment Mode</TableCell>
                      <TableCell align="left">unit no</TableCell>
                      <TableCell align="left">Txn Id</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maintenance.all_maintenance_credit.map(row => (
                      <TableRow key={row._id} >
                        <TableCell align="left" className="table_cells">
                          <Moment format="DD/MM/YYYY">
                            {row.raised_date}
                          </Moment>
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.input_type === "C" ? "CR" : "DR"}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.type}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.amount}
                        </TableCell>

                        <TableCell align="left" className="table_cells">
                          <Moment format="DD/MM/YYYY">
                            {row.due_date}
                          </Moment>
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.payment_date !== null ?
                            <Moment format="DD/MM/YYYY">
                              {row.payment_date}
                            </Moment>
                            : ""}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {
                            row.bank_details.bank_name ?
                              row.payment_type + "-" +
                              row.bank_details.bank_name + "- ****"
                              + row.bank_details.account_no.substring(row.bank_details.account_no.length , row.bank_details.account_no.length - 4)
                              : row.payment_type
                          }
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.unit_no}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.txn_id}
                        </TableCell>

                        <TableCell align="right" className="table_cells">
                          {row.input_type === "C" && row.invoice_id.length > 0 &&
                            <IconButton
                              onClick={() => { this.setDialog(row.invoice_id, row._id, row.approved, row.receipt_image, row) }}
                            >
                              <Icon>
                                list
                              </Icon>
                            </IconButton>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>


          <Dialog
            open={this.state.dialog}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Validate Payment</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} md={12}>
                  {this.state.arraymapped.length > 0 &&
                    <div style={{ textAlign: "center" }}>
                      {this.state.arraymapped[0].receipt_image !== null &&
                        <img src={this.state.arraymapped[0].receipt_image} style={{ width: 120 }} alt="">
                        </img>
                      }
                    </div>
                  }
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Charges</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Raised On</TableCell>
                        <TableCell align="left">Due Date</TableCell>
                        <TableCell align="left">Payment Type</TableCell>
                        <TableCell align="left">payment</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.arraymapped.map((row, index) => (
                        <TableRow key={row.id} >
                          <TableCell align="left" className="table_cells">
                            {row.approved === false &&
                              <input
                                type="checkbox"
                                checked={row.checked}
                                onChange={() => {
                                  var arr = this.state.arraymapped;
                                  var c = false
                                  arr[index].checked = !arr[index].checked;
                                  for (var i = 0; i < arr.length; i++) {
                                    if (arr[i].checked === true) {
                                      c = true
                                    }
                                  }
                                  this.setState({ arraymapped: arr, btnCheck: c })
                                }}
                              />
                            }
                            {row.type}
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            {row.amount}
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            <Moment format="DD/MM/YYYY">
                              {row.raised_date}
                            </Moment>
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            <Moment format="DD/MM/YYYY">
                              {row.due_date}
                            </Moment>
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            {row.paymentType}
                          </TableCell>
                          <TableCell align="left" className="table_cells">
                            {row.approved ?
                              <Switch
                                checked={row.is_full_paid}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              /> :
                              <Switch
                                checked={row.is_full_paid}
                                name="checkedA"
                                onChange={() => {
                                  var arr = this.state.arraymapped;
                                  var c = false
                                  arr[index].is_full_paid = !arr[index].is_full_paid;
                                  this.setState({ arraymapped: arr })
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Grid item xs={6} md={6}>
                    {this.state.arraymapped.length > 0 &&
                      < span >
                        {
                          (this.state.arraymapped[0].paymentType !== "Cash" && this.state.arraymapped[0].approved === false) &&
                          <TextField
                            value={this.state.creditedTo}
                            select
                            label="Account"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              this.setState({ creditedTo: e.target.value })
                            }}
                            InputProps={{ classes: { input: this.props.classes.dropdown } }}
                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                          >
                            {bank.all_bank_accounts.map(su => (
                              <MenuItem key={su.id}
                                value={su.id}
                              >
                                {su.bank_name}
                              </MenuItem>
                            ))}
                          </TextField>
                        }
                      </span>
                    }
                  </Grid>

                </Grid>


              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    dialog: false,
                    creditedTo: "",
                  });
                }}
                color="primary">
                Close
              </Button>


              <Button
                disabled={!this.state.btnCheck || this.state.creditedTo === ""}
                onClick={() => {
                  approveMaintenanceCredit(login.token, this.state.unit_id, this.state.arraymapped, maintenance, login.building_id, this.state.creditedTo)
                  this.setState({
                    dialog: false,
                    btnCheck: false,
                    creditedTo: "",
                  })
                }}
                color="primary"
                autoFocus
              >
                Approve
            </Button>

            </DialogActions>
          </Dialog>


          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid >
    )
  }
}
export default withStyles(styles)(MaintenaceCreditEntries);