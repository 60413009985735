import { LIMIT } from "../../constants/ActionTypes";
import {
  SET_ALL_UNITS,
  SET_ALL_UNITS_TYPES,
  SET_UNIT_TYPE_NAME,
  SET_UNIT_TYPE_PARENT_NAME,
  SET_SQUARE_FEET,
  SET_UNIT_DESCRIPTION,
  SET_UNIT_TYPE,
  SET_UNIT_NAME,
  RESET_ALL_VALUE,
  SET_RESET_TYPE,
  SET_STARTING_AFTER,
  RESET_DEFAULT_UNITS,
  SET_UNIT_PARENT,
  SET_UNIT_DETAILS,
  SET_FINAL_UNIT_DETAILS,
  SET_UNIT_INFORMATION,
  RESET_UNIT_DETAILS,
  SET_ONLY_UNITS,
  SELECT_RESIDENT_VEHICLE,
  SET_DEL_VALUES,
  SET_UNITS,
  SET_UNIT_PARENTS,
  SET_UNIT_PARKING,
  SET_UNIT_PARKING_SPACE,
  SET_UNIT_PARKING_SPACE_TEMP,
  SET_PARKING_TEMP
} from "../../constants/units/units_Actiontypes";
const initial_state = {
  all_units: [],
  units_types: [],
  del_values: [],
  units_parent: "",
  units_type_name: "",
  units_type_parent_name: "",
  unit_name: "",
  unit_description: "",
  square_feet: "",
  selected_unit: "",
  starting_after: 0,
  start_var: "",
  end_var: "",
  check: false,
  limit: LIMIT,
  total: "",
  unit_details: [],
  final_units: [],
  resident_details: [],
  vehicle_details: [],
  helper_details: [],
  selected_resident: "",
  units_parents: [],
  parking_details: [],
  parking_space: [],
  parking_space_temp: [],
  parking: "",
  sos_details: []
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ALL_UNITS:
      return state = { ...state, all_units: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
    case SET_ALL_UNITS_TYPES:
      return state = { ...state, units_types: action.payload }
    case SET_UNIT_TYPE_NAME:
      return state = { ...state, units_type_name: action.payload }
    case SET_UNIT_PARKING:
      return state = { ...state, parking: action.payload }
    case SET_UNIT_PARKING_SPACE:
      return state = { ...state, parking_space: action.payload }
    case SET_UNIT_PARKING_SPACE_TEMP:
      return state = { ...state, parking_space_temp: action.payload }
    case SET_UNIT_TYPE_PARENT_NAME:
      return state = { ...state, units_type_parent_name: action.payload }
    case SET_UNIT_NAME:
      return state = { ...state, unit_name: action.payload }
    case SET_UNIT_TYPE:
      return state = { ...state, selected_unit: action.payload }
    case SET_UNIT_PARENT:
      return state = { ...state, units_parent: action.payload }
    case SET_UNIT_PARENTS:
      return state = { ...state, units_parents: action.payload }
    case SET_UNIT_DESCRIPTION:
      return state = { ...state, unit_description: action.payload }
    case SET_SQUARE_FEET:
      return state = { ...state, square_feet: action.payload }
    case SET_STARTING_AFTER:
      return state = { ...state, starting_after: action.payload }
    case RESET_ALL_VALUE:
      return state = { ...state, square_feet: "", unit_name: "", selected_unit: "", unit_description: "", units_parent: "", units_type_name: "", units_type_parent_name: "", final_units: [] }
    case SET_RESET_TYPE:
      return state = { ...state, units_type_name: "", units_type_parent_name: "", units_parent: "", selected_unit: "" }
    case RESET_DEFAULT_UNITS:
      return state = { ...state, start_var: 0, end_var: 0, starting_after: 0 }
    case SET_UNIT_DETAILS:
      return state = { ...state, unit_details: action.payload }
    case SET_FINAL_UNIT_DETAILS:
      return state = { ...state, final_units: action.payload }
    case SET_UNIT_INFORMATION:
      return state = {
        ...state,
        vehicle_details: action.payload.vehicle_details,
        resident_details: action.payload.resident_details,
        helper_details: action.payload.helper_details,
        parking_space: action.payload.parking_details.parking_space,
        sos_details: action.payload.sos_details
      }
    case RESET_UNIT_DETAILS:
      return state = { ...state, vehicle_details: [], resident_details: [], helper_details: [], units_parents: [], parking_details: [] }
    case SET_ONLY_UNITS:
      return state = { ...state, units_types: action.payload }
    case SET_UNITS:
      return state = { ...state, final_units: action.payload }
    case SELECT_RESIDENT_VEHICLE:
      return state = { ...state, selected_resident: action.payload }
    case SET_DEL_VALUES:
      return state = { ...state, del_values: action.payload }
    case SET_PARKING_TEMP:
      return state = { ...state, parking_space_temp: action.payload }
    default:
      return state;
  }
} 