import React, { Component } from "react";
import { connect } from "react-redux";
import ViewBankAccounts from "../../components/bankAccounts/viewBankAccounts";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    getAllBankAccounts,
    addBankAccounts,
    deleteSingleAccount,
} from "../../actions/bankAccounts/bankAccountAction";

export class ViewBankAccountsCont extends Component {
    render() {
        return (
            <ViewBankAccounts {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        bank: store.bank
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        getAllBankAccounts: (token, building_id) => {
            dispatch(getAllBankAccounts(token, building_id));
        },
        deleteSingleAccount: (token, building_id, id) => {
            dispatch(deleteSingleAccount(token, building_id, id));
        },
        addBankAccounts: (token, building_id, bankName, accountNumber, ifscCode) => {
            dispatch(addBankAccounts(token, building_id, bankName, accountNumber, ifscCode));
        },
        // reset: () => {
        //     dispatch(reset());
        // },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewBankAccountsCont);