export const SET_ALL_AMENITIES_BOOKING = "SET_ALL_AMENITIES_BOOKING";
export const SET_SELECTED_AMENITY = "SET_SELECTED_AMENITY";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const SET_CHECK = "SET_CHECK";
export const SET_UNAPPROVED_AMENITIES_BOOKING ="SET_UNAPPROVED_AMENITIES_BOOKING";
export const SET_START_TIME ="SET_START_TIME";
export const SET_END_TIME ="SET_END_TIME";
export const SET_BOOKING_DATE ="SET_BOOKING_DATE";
export const RESET_AMENITY_BOOKING ="RESET_AMENITY_BOOKING";
export const SET_CHARGES ="SET_CHARGES";