import { LIMIT } from "../../constants/ActionTypes";
import {
  SET_ALL_E_NOTICE,
  SET_STARTING_AFTER,
  SET_E_NOTICE_TITLE,
  SET_E_NOTICE_DESC,
  SET_E_NOTICE_TARGET,
  SET_E_NOTICE_VALID_TILL,
  RESET_E_NOTICE,
  SET_ONLY_ALL_E_NOTICE,
  SET_ADMIN_CHECK,
  SET_EMPLOYEE_CHECK,
  SET_RESIDENT_CHECK,
} from "../../constants/enotice/e-notice_const";
import moment from "moment";
const initial_state = {
  all_e_notices: [],
  starting_after: 0,
  start_var: "",
  end_var: "",
  check: false,
  limit: LIMIT,
  total: "",
  title: "",
  target: "",
  desc: "",
  valid_till: moment(new Date().setDate(new Date().getDate() + 7)).format("YYYY-MM-DD"),
  rendering_array: [],
  admin: false,
  employee: false,
  resident: false,
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ADMIN_CHECK:
      return state = { ...state, admin: action.payload }
    case SET_EMPLOYEE_CHECK:
      return state = { ...state, employee: action.payload }
    case SET_RESIDENT_CHECK:
      return state = { ...state, resident: action.payload }
    case SET_ALL_E_NOTICE:
      return state = { ...state, all_e_notices: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
    case SET_STARTING_AFTER:
      return state = { ...state, starting_after: action.payload }
    case SET_E_NOTICE_TITLE:
      return state = { ...state, title: action.payload }
    case SET_E_NOTICE_DESC:
      return state = { ...state, desc: action.payload }
    case SET_E_NOTICE_TARGET:
      return state = { ...state, target: action.payload }
    case SET_E_NOTICE_VALID_TILL:
      return state = { ...state, valid_till: action.payload }
    case RESET_E_NOTICE:
      return state = {
        ...state,
        title: "",
        target: "",
        desc: "",
        valid_till: moment(new Date().setDate(new Date().getDate() + 7)).format("YYYY-MM-DD"),
        admin: false,
        employee: false,
        resident: false,
      }
    case SET_ONLY_ALL_E_NOTICE:
      return state = { ...state, all_e_notices: action.payload.array, rendering_array: action.payload.arr }
    default:
      return state;
  }
}