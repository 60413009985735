import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "../../styles/style";
import CardContent from "@material-ui/core/Card";
import Divider from '@material-ui/core/Divider';
import Icon from "@material-ui/core/Icon";
const cards = {
  borderRadius: "8px",
  backgroundColor: "#ffffff",
  marginLeft: 10
};
class AdminDashboard extends Component {
  componentDidMount() {
    this.props.getAdmindashboardFunc(this.props.login.token, this.props.login.building_id);
  }
  render() {
    const { dashboard, classes } = this.props;
    return (
      <Grid container>
        <Grid container item sm={12}>
          {/* CARD ONE */}
          <Grid container item lg={4} xs={12} >
            <Card style={cards}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning" >
                  <Icon>
                    build
                  </Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Total vendors</p>
                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                  {dashboard.dashboard_details.total_vendors}
                </h1>
              </CardHeader>
              <CardContent  >
                <Divider />
              </CardContent>
            </Card>
          </Grid>
          {/* END OF CARD ONE */}
          {/* CARD TWO */}
          <Grid container item lg={4} xs={12} >
            <Card style={cards}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning" >
                  <Icon>
                    pool
                  </Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Amenity bookings</p>
                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                  {dashboard.dashboard_details.current_date_ammenitis_bookings}
                </h1>
              </CardHeader>
              <CardContent  >
                <Divider />
              </CardContent>
            </Card>
          </Grid>
          {/* END OF CARD TWO */}
          {/* CARD THREE */}
          <Grid container item lg={4} xs={12} >
            <Card style={cards}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning" >
                  <Icon>
                    report_problem
                  </Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Complaints</p>
                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                  {dashboard.dashboard_details.total_active_complaints}
                </h1>
              </CardHeader>
              <CardContent  >
                <Divider />
              </CardContent>
            </Card>
          </Grid>
          {/* END OF CARD THREE */}
          {/* CARD FOUR */}
          <Grid container item lg={4} xs={12} >
            <Card style={cards}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning" >
                  <Icon>
                    how_to_reg
                  </Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Residents</p>
                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                  {dashboard.dashboard_details.total_residents}
                </h1>
              </CardHeader>
              <CardContent  >
                <Divider />
              </CardContent>
            </Card>
          </Grid>
          {/* END OF CARD FOUR */}
          {/* CARD FIVE */}
          <Grid container item lg={4} xs={12} >
            <Card style={cards}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning" >
                  <Icon>
                    transfer_within_a_station
                  </Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Visitors</p>
                <h1 className={classes.cardTitle} style={{ fontSize: 30 }}>
                  {dashboard.dashboard_details.total_current_visitors}
                </h1>
              </CardHeader>
              <CardContent  >
                <Divider />
              </CardContent>
            </Card>
          </Grid>
          {/* END OF CARD FIVE */}
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AdminDashboard);
