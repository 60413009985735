export const SET_ALL_E_NOTICE = "SET_ALL_E_NOTICE"; 
export const SET_STARTING_AFTER = "SET_STARTING_AFTER"; 
export const SET_E_NOTICE_TITLE = "SET_E_NOTICE_TITLE"; 
export const SET_E_NOTICE_DESC = "SET_E_NOTICE_DESC"; 
export const SET_E_NOTICE_TARGET = "SET_E_NOTICE_TARGET";
export const SET_E_NOTICE_VALID_TILL = "SET_E_NOTICE_VALID_TILL";
export const RESET_E_NOTICE = "RESET_E_NOTICE";
export const SET_ONLY_ALL_E_NOTICE = "SET_ONLY_ALL_E_NOTICE";
export const SET_ADMIN_CHECK = "SET_ADMIN_CHECK";
export const SET_EMPLOYEE_CHECK = "SET_EMPLOYEE_CHECK";
export const SET_RESIDENT_CHECK = "SET_RESIDENT_CHECK";