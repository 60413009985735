import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class AddEnotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        this.props.reset_e_notice()
    }
    render() {
        var generated_date = new Date();
        const {
            e_notice,
            snackbar,
            close_snack_bar,
            login,
            set_e_notice_title,
            set_e_notice_desc,
            set_e_notice_valid_till,
            validate_add_e_notice,
            set_employee_target,
            set_resident_target,
        } = this.props;
        return (
            <Grid container spacing={8} justify="center">
                <Grid item sm={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                {/* <Icon>content_copy</Icon> */}
                                <h3>
                                    ADD E-NOTICE
                                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent >
                            <Grid container spacing={8} style={{ padding: 40 }}>
                                <Grid container item lg={6} xs={12}>
                                    <Grid container lg={12} xs={12} >
                                        <TextField
                                            id="outlined-email-input"
                                            label="Title"
                                            type="text"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={e_notice.title}
                                            onChange={(event) => { set_e_notice_title(event.target.value); }}
                                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextField
                                            id="outlined-email-input"
                                            label="Notice Description"
                                            type="text"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={e_notice.desc}
                                            onChange={(event) => { set_e_notice_desc(event.target.value); }}
                                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        Target
                                        <br />
                                        {/* <input 
                                            type="checkbox"
                                            checked={e_notice.admin}
                                            onChange={() => { set_admin_target(!e_notice.admin) }}
                                        /> 
                                        Admin
                                        <br /> */}
                                        <input
                                            type="checkbox"
                                            checked={e_notice.employee}
                                            onChange={() => { set_employee_target(!e_notice.employee) }}
                                        />
                                        Employees
                                        <br />
                                        <input
                                            type="checkbox"
                                            checked={e_notice.resident}
                                            onChange={() => { set_resident_target(!e_notice.resident) }}
                                        />
                                        Resident
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextField
                                            id="date"
                                            label="Valid Till"
                                            type="date"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={e_notice.valid_till}
                                            onChange={(event) => { set_e_notice_valid_till(event.target.value); }}
                                            InputProps={{ classes: { input: this.props.classes.date } }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <br />
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => { validate_add_e_notice(login.token, login.building_id, e_notice, generated_date); }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(styles)(AddEnotice);