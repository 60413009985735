export const SET_EMP_NAME = "SET_EMP_NAME";
export const SET_EMP_DESIGNATION = "SET_EMP_DESIGNATION";
export const SET_EMP_IMG = "SET_EMP_IMG";
export const EMPLOYEE_CONTACT_NO = "EMPLOYEE_CONTACT_NO";
export const SET_EMP_ID_PROOF = "SET_EMP_ID_PROOF";
export const SET_ID_PROOF_ID = "SET_ID_PROOF_ID";
export const EMPLOYEE_CODE = "EMPLOYEE_CODE";
export const EMPLOYEE_EMAIL = "EMPLOYEE_EMAIL";
export const ACCOUNT_NO = "ACCOUNT_NO";
export const BANK_NAME = "BANK_NAME";
export const BANK_IFSC = "BANK_IFSC";
export const BRANCH = "BRANCH";
export const SET_IS_PROVIDED_BY_VENDOR = "SET_IS_PROVIDED_BY_VENDOR";
export const SET_SELECTED_VENDOR = "SET_SELECTED_VENDOR";
export const SET_EMP_SALARY = "SET_EMP_SALARY";
export const RESET_EMPLOYEE = "RESET_EMPLOYEE";
export const SET_ONLY_EMPLOYEE = "SET_ONLY_EMPLOYEE";
export const SET_ALL_EMPLOYEE = "SET_ALL_EMPLOYEE";
export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const SET_EMP_ID_PROOF_IMG = "SET_EMP_ID_PROOF_IMG";
export const SET_DIALOG = "SET_DIALOG";
export const SET_BUILDING_VENDORS_FOR_EMPLOYESS = "SET_BUILDING_VENDORS_FOR_EMPLOYESS";