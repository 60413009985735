import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class ViewClassified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editDialog: false,
      ad_id: "",
      ad_name: "",
      ad_desc: "",
      ad_contact_person: "",
      ad_contact_number: "",
      ad_images: [],
      del_check: false
    }
  }
  componentDidMount() {
    this.props.get_all_classifieds(this.props.login.token, this.props.login.building_id, this.props.classified.starting_after, this.props.classified.limit);
  }
  edit_classified = (s) => {
    this.setState({
      editDialog: true,
      ad_id: s._id,
      ad_name: s.ad_name,
      ad_desc: s.ad_desc,
      ad_contact_person: s.ad_contact_person,
      ad_contact_number: s.ad_contact_number,
      ad_images: s.ad_images
    })
  }
  del_single_row = (row) => {
    this.setState({
      confirm_delete: true,
      id: row._id,
    })
  }
  set_del_check() {
    for (var i = 0; i < this.props.classified.all_classifieds.length; i++) {
      if (this.props.classified.all_classifieds[i].checked === true) {
        this.setState({
          del_check: true
        });
        break;
      }
      else {
        this.setState({
          del_check: false
        })
      }
    }
  }
  render() {
    const {
      classified,
      login,
      snackbar,
      close_snack_bar,
      toggleCheckbox,
      delete_classified,
      validate_edit_classified
    } = this.props;
    var btn;
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW CLASSIFIED
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item lg={12}>
                <Link to="add_classified" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                {this.state.del_check === true ?
                  <IconButton
                    onClick={() => {
                      this.setState({
                        confirm_delete: true
                      })
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton> : ""}
                <br /><br />
                <div style={{ float: "right" }}>
                  {btn}
                </div>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Contact details</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classified.all_classifieds.map(row => (
                    <TableRow >
                      <TableCell align="left" className="table_cells">
                        <Checkbox
                          checked={row.checked}
                          size="small"
                          onChange={() => {
                            toggleCheckbox(row, classified.all_classifieds);
                            this.set_del_check();
                          }}
                        />
                        &nbsp;&nbsp;{row.ad_name}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.ad_desc}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.ad_contact_person}-{row.ad_contact_number}
                      </TableCell>
                      <TableCell align="right" className="table_cells">
                        <IconButton
                          onClick={() => {
                            this.edit_classified(row);
                          }}
                        >
                          <Icon >edit</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12} >
                  <TextField
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.ad_name}
                    onChange={(event) => {
                      this.setState({
                        ad_name: (event.target.value)
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    label="Description"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.ad_desc}
                    onChange={(event) => {
                      this.setState({
                        ad_desc: (event.target.value)
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    label="Contact Person"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.ad_contact_person}
                    onChange={(event) => {
                      this.setState({
                        ad_contact_person: (event.target.value)
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    label="Contact Number"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.ad_contact_number}
                    onChange={(event) => {
                      this.setState({
                        ad_contact_number: (event.target.value)
                      })
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Grid container>
                    {this.state.ad_images.map(img => (
                      <Grid item lg={3} xs={3}>
                        <img src={img} style={{ height: 100, width: 100 }} alt='classified' />
                        <IconButton
                          onClick={() => {
                            var index = this.state.ad_images.indexOf(img);
                            this.state.ad_images.splice(index, 1);
                            this.setState({
                              ad_images: this.state.ad_images
                            })
                          }}
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                        &nbsp;&nbsp;&nbsp;
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editDialog: false,
                    ad_id: "",
                    ad_name: "",
                    ad_desc: "",
                    ad_contact_person: "",
                    ad_contact_number: "",
                    ad_images: []
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_edit_classified(
                    login.token,
                    login.building_id,
                    {
                      ad_id: this.state.ad_id,
                      ad_name: this.state.ad_name,
                      ad_desc: this.state.ad_desc,
                      ad_contact_person: this.state.ad_contact_person,
                      ad_contact_number: this.state.ad_contact_number,
                      ad_images: this.state.ad_images
                    },
                    classified.limit,
                    classified.starting_after
                  )
                  this.setState({
                    editDialog: false,
                    ad_id: "",
                    ad_name: "",
                    ad_desc: "",
                    ad_contact_person: "",
                    ad_contact_number: "",
                    ad_images: []
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirm_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirm_delete: false,
                    id: "",
                  });
                }}
                color="primary">
                No
              </Button>
              <Button
                onClick={() => {
                  delete_classified(login.token, classified.all_classifieds, classified.starting_after, classified.limit, login.building_id)
                  this.setState({
                    confirm_delete: false,
                    id: "",
                    del_check: false
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(ViewClassified);