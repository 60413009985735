import React, { Component } from "react";
import { connect } from "react-redux";
import ViewUnits from "../../components/units/view_units";
import {
  get_all_units,
  update_single_unit,
  delete_single_unit,
  get_all_units_type,
  get_same_units,
  toggleCheckbox1,
  prev_btn,
  next_btn,
  set_reset,
  get_all_unit_details,
  get_final_unit_details,
  handleMenu,
  search_unit,
  import_file_func,
  set_units_unit_type,
  set_units_parent,
  get_all_parent
} from "../../actions/units/units_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class View_Units_Container extends Component {
  render() {
    return (
      <ViewUnits {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    units: store.units,
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    get_all_units: (token, building_id, limit, starting_after) => {
      dispatch(get_all_units(token, building_id, limit, starting_after))
    },
    get_all_parent: (type_id, token, building_id, unit_types) => {
      dispatch(get_all_parent(type_id, token, building_id, unit_types))
    },
    import_file_func: (files, token, building_id, parent_id, parent_type) => {
      dispatch(import_file_func(files, token, building_id, parent_id, parent_type))
    },
    set_reset: () => {
      dispatch(set_reset())
    },
    set_units_unit_type: (payload) => {
      dispatch(set_units_unit_type(payload))
    },
    set_units_parent: (payload) => {
      dispatch(set_units_parent(payload))
    },
    get_same_units: (token, building_id, limit, starting_after) => {
      dispatch(get_same_units(token, building_id, limit, starting_after))
    },
    get_all_units_type: (token, building_id) => {
      dispatch(get_all_units_type(token, building_id))
    },
    toggleCheckbox1: (obj, array) => {
      dispatch(toggleCheckbox1(obj, array))
    },
    update_single_unit: (token, id, building_id, name, desc, type_id, square_feet, parent_id, limit, starting_after) => {
      dispatch(update_single_unit(token, id, building_id, name, desc, type_id, square_feet, parent_id, limit, starting_after))
    },
    delete_single_unit: (token, unit_id, building_id, parent_id) => {
      dispatch(delete_single_unit(token, unit_id, building_id, parent_id))
    },
    prev_btn: (token, building_id, limit, starting_after) => {
      dispatch(prev_btn(token, building_id, limit, starting_after))
    },
    next_btn: (token, building_id, limit, starting_after) => {
      dispatch(next_btn(token, building_id, limit, starting_after))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    get_all_unit_details: (token, building_id) => {
      dispatch(get_all_unit_details(token, building_id));
    },
    get_final_unit_details: (token, unit_id, building_id, keyword) => {
      dispatch(get_final_unit_details(token, unit_id, building_id, keyword));
    },
    handleMenu: (obj, array) => {
      dispatch(handleMenu(obj, array));
    },
    search_unit: (token, building_id, keyword) => {
      dispatch(search_unit(token, building_id, keyword));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_Units_Container);