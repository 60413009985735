import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Moment from 'react-moment';
import "../../styles/style.scss";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class Enotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editredirect: false,
      delete_redirect: false,
      title: "",
      desc: "",
      target: "",
      generated_date: "",
      valid_till: "",
      e_notice_id: "",
    }
  }
  componentDidMount() {
    this.props.get_all_e_notice(this.props.login.token, this.props.login.building_id, this.props.e_notice.limit, this.props.e_notice.starting_after)
  }
  edit_single_e_notice = (s) => {
    this.setState({
      title: s.notice_title,
      desc: s.notice_desc,
      target: s.notice_target,
      generated_date: s.notice_generated_date,
      valid_till: s.notice_valid_till,
      e_notice_id: s._id,
    })
  }
  removeTarget = (item) => {
    var target = this.state.target;
    for (var i = 0; i < target.length; i++) {
      if (target[i] === item) {
        target.splice(i)
      }
    }
    this.setState({
      target: target
    })
  }
  addTarget = (item) => {
    this.state.target.push(item);
    this.setState({
      target: this.state.target
    })
  }
  render() {
    const { e_notice,
      snackbar,
      close_snack_bar,
      login, next_btn,
      prev_btn,
      delete_single_e_notice,
      validate_single_e_notice,
      toggleCheckbox,
    } = this.props;
    var btn;
    if (e_notice.total > e_notice.limit) {
      if (e_notice.end_var === e_notice.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, e_notice.limit, e_notice.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {e_notice.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{e_notice.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{e_notice.total}
        </span>
      }
      else if (e_notice.start_var > e_notice.limit && e_notice.end_var < e_notice.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(this.props.login.token, login.building_id, e_notice.limit, e_notice.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(this.props.login.token, login.building_id, e_notice.limit, e_notice.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {e_notice.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{e_notice.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{e_notice.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(login.token, login.building_id, e_notice.limit, e_notice.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {e_notice.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{e_notice.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{e_notice.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {e_notice.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{e_notice.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{e_notice.total}
      </span>
    }
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12} >
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW E-NOTICE
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item lg={12} xs={12}>
                <Link to="add_e_notice" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon >add</Icon>
                  </IconButton>
                </Link>
                {e_notice.rendering_array.length > 0 ?
                  <IconButton
                    onClick={() => {
                      this.setState({
                        delete_redirect: true
                      })
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton> : ""}
                <div style={{ float: "right" }}>
                  {btn}
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="left">&nbsp;&nbsp;Title</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Target</TableCell>
                      <TableCell align="left">Generated Date</TableCell>
                      <TableCell align="left">Valid Till</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {e_notice.all_e_notices.map(row => (
                      <TableRow key={row._id}>
                        <TableCell align="center" className="table_cells">
                          <Checkbox
                            checked={row.checked}
                            size="small"
                            onChange={() => {
                              toggleCheckbox(row, e_notice.all_e_notices)
                            }}
                          />
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.notice_title}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.notice_desc}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {
                            row.notice_target.includes("A") && row.notice_target.includes("E") && row.notice_target.includes("R") ?
                              "Admin, Employee, Resident"
                              : row.notice_target.includes("A") && row.notice_target.includes("E") ?
                                "Admin , Employee" :
                                row.notice_target.includes("E") && row.notice_target.includes("R") ?
                                  "Employee , Resident" :
                                  row.notice_target.includes("R") && row.notice_target.includes("A") ?
                                    "Admin , Resident "
                                    : row.notice_target.includes("A") ?
                                      "Admin" :
                                      row.notice_target.includes("E") ?
                                        "Employee" :
                                        row.notice_target.includes("R") ?
                                          "Resident" :
                                          row.notice_target
                          }
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          <Moment format="DD/MM/YYYY">
                            {row.notice_generated_date}
                          </Moment>
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          <Moment format="DD/MM/YYYY">
                            {row.notice_valid_till}
                          </Moment>
                        </TableCell>
                        <TableCell align="right" className="table_cells">
                          <IconButton
                            onClick={() => {
                              this.edit_single_e_notice(row)
                              this.setState({
                                editredirect: true
                              })
                            }}
                          >
                            <Icon>edit</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Title"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.title}
                    onChange={(event) => { this.setState({ title: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Description"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.desc}
                    onChange={(event) => { this.setState({ desc: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <br />
                  {this.state.target.includes("E") ?
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={() => { this.removeTarget("E") }}
                    />
                    :
                    <input
                      type="checkbox"
                      checked={false}
                      onChange={() => { this.addTarget("E") }}
                    />
                  }
                    Employee
                    <br />
                  {this.state.target.includes("R") ?
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={() => { this.removeTarget("R") }}
                    />
                    :
                    <input
                      type="checkbox"
                      checked={false}
                      onChange={() => { this.addTarget("R") }}
                    />
                  }
                    Resident
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                    title: "",
                    desc: "",
                    target: "",
                    generated_date: "",
                    valid_till: "",
                    e_notice_id: "",
                  });
                }}
                color="primary"
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_single_e_notice(this.props.login.token, login.building_id, this.state.e_notice_id, this.state.desc, this.state.title, this.state.target, this.state.generated_date, this.state.valid_till, e_notice.limit, e_notice.starting_after);
                  this.setState({
                    editredirect: false,
                    title: "",
                    desc: "",
                    target: "",
                    generated_date: "",
                    valid_till: "",
                    e_notice_id: "",
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.delete_redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete these E Notice</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    delete_redirect: false,
                    e_notice_id: ""
                  });
                }}
                color="primary">
                NO
              </Button>
              <Button
                onClick={() => {
                  delete_single_e_notice(login.token, e_notice.rendering_array, login.building_id, e_notice.limit, e_notice.starting_after)
                  this.setState({
                    delete_redirect: false,
                    e_notice_id: ""
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(Enotice);