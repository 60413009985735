import {
    SET_ALL_VISITORSS,
    SET_VISITOR_DATE_OF_VISIT,
    SET_STARTING_AFTER,
    SET_START_DATE,
    SET_END_DATE,
    SET_VISITOR_NAME,
    SET_VISITOR_MOB_NUM,
    SET_VISITOR_VEHICLE_TYPE,
    SET_VISITOR_VEHICLE_NUM,
    SET_VISITOR_UNIT_NAME,
    RESET_DEFAULT_VISITOR,
    SET_PURPOSE_OF_VISIT,
    SET_PURPOSE_OF_VISIT_OTHER
} from "../../constants/visitors/visitors_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function get_all_visitors(token, building_id, limit, starting_after, start_date, end_date) {
    starting_after = 0;
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_visitors", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
                start_date: start_date,
                end_date: end_date
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_visitors(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_visitors_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(set_all_visitors({ result: [], total: 0 }, 0, 0));
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_same_visitors(token, building_id, limit, starting_after, start_date, end_date) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_visitors", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
                start_date: start_date,
                end_date: end_date
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_visitors(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_visitors_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(set_all_visitors({ result: [], total: 0 }, 0, 0));
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function prev_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_visitors", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_visitors(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_visitors_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_visitors", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_visitors(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_visitors_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_starting_after(payload) {
    return {
        type: SET_STARTING_AFTER,
        payload: payload
    }
}
export function set_start_date(payload) {
    return {
        type: SET_START_DATE,
        payload: payload
    }
}
export function set_purpose_of_visit(payload) {
    return {
        type: SET_PURPOSE_OF_VISIT,
        payload: payload
    }
}
export function set_purpose_of_visit_other(payload) {
    return {
        type: SET_PURPOSE_OF_VISIT_OTHER,
        payload: payload
    }
}
export function set_end_date(payload) {
    return {
        type: SET_END_DATE,
        payload: payload
    }
}
export function set_visitor_name(payload) {
    return {
        type: SET_VISITOR_NAME,
        payload: payload
    }
}
export function set_visitor_mob_num(payload) {
    return {
        type: SET_VISITOR_MOB_NUM,
        payload: payload
    }
}
export function set_visitor_vehicle_type(payload) {
    return {
        type: SET_VISITOR_VEHICLE_TYPE,
        payload: payload
    }
}
export function set_visitor_vehicle_num(payload) {
    var payloads = payload.toUpperCase();
    return {
        type: SET_VISITOR_VEHICLE_NUM,
        payload: payloads
    }
}
export function set_visitor_unit_name(payload) {
    return {
        type: SET_VISITOR_UNIT_NAME,
        payload: payload
    }
}
export function set_visitor_date_of_visit(payload) {
    return {
        type: SET_VISITOR_DATE_OF_VISIT,
        payload: payload
    }
}
export function reset_all() {
    return {
        type: RESET_DEFAULT_VISITOR,
    }
}
export function set_all_visitors(responseJson, limit, starting_after) {
    var obj;
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.totaldata };
    }
    return {
        type: SET_ALL_VISITORSS,
        payload: obj,
    }
}
export function set_all_visitors_pagination(responseJson, limit, starting_after) {
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.totaldata), total: responseJson.totaldata };
    return {
        type: SET_ALL_VISITORSS,
        payload: obj,
    }
}
export function validate_visitor_func(token, building_id, visitors) {
    return (dispatch) => {
        dispatch(set_loader());
        if (visitors.visitor_name === "") {
            dispatch(set_snack_bar(true, "Please Enter Visitor Name"));
            dispatch(unset_loader())
        } else if (visitors.visitor_mob_num === "") {
            dispatch(set_snack_bar(true, "Please Enter Visitor Mobile Number"));
            dispatch(unset_loader())
        }
        else if (visitors.visitor_unit_name === "") {
            dispatch(set_snack_bar(true, "Please Select Unit Name"));
            dispatch(unset_loader())
        }
        else if (visitors.visitor_date_of_visit === "") {
            dispatch(set_snack_bar(true, "Please Enter Date Of Visit"));
            dispatch(unset_loader())
        }
        else if (visitors.purpose_of_visit === "") {
            dispatch(set_snack_bar(true, "Please Select Purpose of Visit"));
            dispatch(unset_loader())
        }
        else {
            dispatch(create_visitor_entry(token, building_id, visitors));
        };
    }
}
export function create_visitor_entry(token, building_id, visitors) {
    return (dispatch) => {
        var visitor_details = JSON.stringify([{ "visitor_name": visitors.visitor_name, "visitor_mobilenumber": visitors.visitor_mob_num }]);
        var visitor_vehicle_details = JSON.stringify({ "vehicle_type_id": visitors.visitor_vehicle_type, vehicle_number: visitors.visitor_vehicle_num });
        return fetch(UNIVERSAL.BASEURL + "v1/create_visitor_entry", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                visitor_details: visitor_details,
                visitor_vehicle_details: visitor_vehicle_details,
                unit_id: visitors.visitor_unit_name,
                date_of_visit: visitors.visitor_date_of_visit,
                purpose_of_visit: visitors.purpose_of_visit
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(reset_all())
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function allow_visitor_entry(token, building_id, visitor_id, limit, starting_after, start_date, end_date) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/allow_visitor_entry", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                visit_id: visitor_id,
                building_id: building_id
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(get_same_visitors(token, building_id, limit, starting_after, start_date, end_date))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
