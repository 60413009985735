import {
    SET_ALL_BANK_ACCOUNTS,
    RESET,
  } from "../../constants/bankAccounts/bankAccountsConst";
  
  const initial_state = {
    all_bank_accounts: [],
  };
  
  export default function reducer(state = initial_state, action) {
    switch (action.type) {
      case SET_ALL_BANK_ACCOUNTS:
        return state = { ...state, all_bank_accounts: action.payload };
      case RESET:
        return state = {
          ...state,
          unit_details: [],
          all_maintenance: [],
          unit_data: [],
        };
      default:
        return state;
    }
  }
  