import {
    SET_ALL_VENDOR_CATEGORY,
    SET_CATEGORY_NAME,
    RESET_ALL,
} from "../../constants/vendor_category/vendor_category_const";
const initial_state = {
    all_vendor_category: [],
    category_name: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_VENDOR_CATEGORY:
            return state = { ...state, all_vendor_category: action.payload };
        case SET_CATEGORY_NAME:
            return state = { ...state, category_name: action.payload }
        case RESET_ALL:
            return state = { ...state, category_name: "" }
        default:
            return state;
    }
}