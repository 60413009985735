export const SET_VENDOR_NAME = "SET_VENDOR_NAME";
export const SET_VENDOR_SERVICE = "SET_VENDOR_SERVICE";
export const SET_LICENSE_NUMBER = "SET_LICENSE_NUMBER";
export const SET_PAYEMENT_TYPE = "SET_PAYEMENT_TYPE";
export const SET_FIXED_AMOUNT = "SET_FIXED_AMOUNT";
export const SET_ACCOUNT_NUMBER = "SET_ACCOUNT_NUMBER";
export const SET_BANK_NAME = "SET_BANK_NAME";
export const SET_BANK_IFSC = "SET_BANK_IFSC";
export const SET_BANK_BRANCH = "SET_BANK_BRANCH";
export const RESET_VENDOR = "RESET_VENDOR";
export const SET_VENDOR_CONTACT = "SET_VENDOR_CONTACT";
export const SET_ALL_VENDORS = "SET_ALL_VENDORS";
export const SET_SINGLE_VENDOR = "SET_SINGLE_VENDOR";
export const SET_BANK_BRANCH1 = "SET_BANK_BRANCH1";
export const SET_IS_FIXED = "SET_IS_FIXED";
export const SET_STARTING_AFTER_VENDORS = "SET_STARTING_AFTER_VENDORS";
export const SET_ONLY_ALL_VENDORS = "SET_ONLY_ALL_VENDORS";
export const SET_CONTACT_NUM = "SET_CONTACT_NUM";
export const SET_CONTACT_NAME = "SET_CONTACT_NAME";
export const SET_OTHER_CONTACT = "SET_OTHER_CONTACT";
export const SET_VENDORS_DETAILS = "SET_VENDORS_DETAILS";
export const SET_BUILDING_VENDORS = "SET_BUILDING_VENDORS";
export const SET_VENDOR_TYPE = "SET_VENDOR_TYPE";
export const SET_VENDOR_IMAGE = "SET_VENDOR_IMAGE";
export const SET_CONTACT_IMAGE = "SET_CONTACT_IMAGE";
export const SET_ID_PROOF_NUMBER = "SET_ID_PROOF_NUMBER";
export const SET_ID_PROOF_IMAGE = "SET_ID_PROOF_IMAGE";
export const SET_ID_PROOF_ID = "SET_ID_PROOF_ID";
export const RESET = "RESET";