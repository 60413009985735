import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class AddVehicles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
        }
    }
    render() {
        const {
            vehicles,
            snackbar,
            close_snack_bar,
            login,
            set_vehicle_type,
            set_vehicle_manufacturer,
            set_vehicle_name,
            validate_add_vehicle,
        } = this.props;
        return (
            <Grid container spacing={8} justify="center">
                <Card style={card}>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="rose">
                            <h3>
                                ADD VEHICLES
                                </h3>
                        </CardIcon>
                    </CardHeader>
                    <CardContent >
                        <Grid container style={{ padding: 40 }}>
                            <Grid container item lg={6} xs={12}>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        id="outlined-email-input"
                                        label="Vehicle Type"
                                        type="dropdown"
                                        fullWidth
                                        select
                                        margin="normal"
                                        variant="outlined"
                                        value={vehicles.vehicle_type}
                                        onChange={(event) => { set_vehicle_type(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.Dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {vehicles.vehicle_types.map((single) => (
                                            <MenuItem key={single} value={single}>
                                                {single}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        id="outlined-email-input"
                                        label="Vehicle Manufacturer"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vehicles.vehicle_manufacturer}
                                        onChange={(event) => { set_vehicle_manufacturer(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        id="outlined-email-input"
                                        label="Vehicle Name"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vehicles.vehicle_name}
                                        onChange={(event) => { set_vehicle_name(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            validate_add_vehicle(login.token, vehicles)
                                            if (this.props.onClose) {
                                                this.props.onClose()
                                            }
                                        }}
                                    >
                                        Submit
                                       </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
            </Grid>
        );
    }
}
export default withStyles(styles)(AddVehicles);