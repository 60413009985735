import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import { Avatar } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import ImageViewer from "../imageViewer/imageViewer";
import Menu from "@material-ui/core/Menu";

class ViewEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      editredirect: false,
      employee_image: "",
      employee_image_old: "",
      employee_name: "",
      employee_designation: "",
      employee_contact_info: "",
      employee_salary_amount: "",
      employee_id_proof_type: "",
      employee_id_proof_number: "",
      employee_code: "",
      employee_email: "",
      account_no: "",
      bank_name: "",
      bank_ifsc: "",
      branch: "",
      is_provide_by_vendor: "",
      selected_vendor: "",
      employee_id: "",
      id: "",
      confirm_delete: false,
      del_check: false,
      departments: [],
      view_dialog: false,
      checkImage: false,
      menuAnchor: null,
      row: null,
      resetPasswordDialog: false,
      confirmPassword: "",
      password: "",
      employee_id_proof_images: [],
      changeImageIdProof: false
    }
  }
  componentDidMount() {
    this.props.get_all_employee(this.props.login.token, this.props.login.building_id, this.props.employee.limit, this.props.employee.starting_after)
  }
  set_del_check() {
    for (var i = 0; i < this.props.employee.all_employee.length; i++) {
      if (this.props.employee.all_employee[i].checked === true) {
        this.setState({
          del_check: true
        });
        break;
      }
      else {
        this.setState({
          del_check: false
        })
      }
    }
  }
  checkRedirect = () => {
    this.setState({
      redirect: true,
    })
  }
  edit_single_employee = (row) => {
    var departments = this.props.department.all_department;
    for (var i = 0; i < departments.length; i++) {
      if (row.departments.includes(departments[i].department_name)) {
        departments[i].checked = true;
      } else {
        departments[i].checked = false;
      }
    }
    this.setState({
      editredirect: true,
      employee_name: row.employee_name,
      employee_designation: row.employee_designation,
      employee_contact_info: row.employee_contact_info,
      employee_salary_amount: row.employee_salary_amount,
      employee_id_proof_images: (row.employee_id_proof && row.employee_id_proof.id_proof_image) ? row.employee_id_proof.id_proof_image : [],
      employee_id_proof_type: row.employee_id_proof.id_proof_type,
      employee_id_proof_number: row.employee_id_proof.id_proof_number,
      employee_code: row.employee_code,
      employee_email: row.employee_email,
      account_no: row.employee_bank_account_no,
      bank_name: row.employee_bank_name,
      bank_ifsc: row.employee_bank_ifsc_code,
      branch: row.employee_bank_branch,
      is_provide_by_vendor: row.is_provided_by_vendor,
      selected_vendor: row.vendor_id,
      employee_id: row._id,
      employee_image_old: row.employee_img,
      departments: departments
    })
  }
  single_employee_details = (row) => {
    this.setState({
      view_dialog: true,
      employee_name: row.employee_name,
      employee_designation: row.employee_designation,
      employee_contact_info: row.employee_contact_info,
      employee_salary_amount: row.employee_salary_amount,
      employee_id_proof: row.employee_id_proof,
      employee_email: row.employee_email,
      employee_image_old: row.employee_img,
    })
  }
  toggleCheckbox = (s, departments) => {
    var index = departments.indexOf(s);
    departments[index].checked = !departments[index].checked;
    this.setState({
      departments: departments
    })
  }
  render() {
    var btn;
    const {
      login,
      snackbar,
      employee,
      id_proofs,
      vendors,
      close_snack_bar,
      prev_btn,
      next_btn,
      set_employee_id_proof_img,
      validate_update_employee,
      delete_single_employee,
      toggleCheckbox,
      updatePassword
    } = this.props;
    if (this.state.redirect) {
      return <Redirect to="/add_employee" />;
    }
    if (employee.total > employee.limit) {
      if (employee.end_var === employee.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(this.props.login.token, this.props.login.building_id, employee.limit, employee.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {employee.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{employee.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{employee.total}
        </span>
      }
      else if (employee.start_var > employee.limit && employee.end_var < employee.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(this.props.login.token, this.props.login.building_id, employee.limit, employee.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(this.props.login.token, this.props.login.building_id, employee.limit, employee.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {employee.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{employee.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{employee.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(this.props.login.token, this.props.login.building_id, employee.limit, employee.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {employee.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{employee.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{employee.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {employee.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{employee.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{employee.total}
      </span>
    }

    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                {/* <Icon>content_copy</Icon> */}
                <h3>
                  VIEW EMPLOYEE
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item xs={12} >
                <IconButton
                  onClick={this.checkRedirect.bind(this)}
                >
                  <Icon >add</Icon>
                </IconButton>
                {this.state.del_check === true ? <IconButton
                  onClick={() => {
                    this.setState({
                      confirm_delete: true,
                    })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton> : ""}
                <div style={{ float: "right" }}> {btn}</div><br /><br />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">&nbsp;&nbsp;Image</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Contact Info</TableCell>
                      <TableCell align="left">Designation</TableCell>
                      {/* <TableCell align="left">Salary</TableCell> */}
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employee.all_employee.map(row => (
                      <TableRow key={row._id} >
                        <TableCell align="left" className="table_cells">
                          {row.employee_designation !== "Owner" &&
                            <Checkbox
                              checked={row.checked}
                              size="small"
                              onChange={() => {
                                toggleCheckbox(row, employee.all_employee);
                                this.set_del_check();
                              }}
                            />
                          }
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "center" }}>
                          <Avatar
                            alt={row.employee_name}
                            src={row.employee_img}
                            style={{ width: 35, height: 35, margin: "auto" }}
                            onClick={() => {
                              this.setState({
                                image: row.employee_img
                              })
                            }}
                          />
                          <br />

                          <StarRatingComponent
                            name={"feedback"}
                            value={parseFloat(row.employee_rating)}
                            starColor={"#ffcc00"}
                            renderStarIcon={() => { return <Icon style={{ fontSize: 14 }}>star</Icon> }}
                            renderStarIconHalf={() => { return <Icon style={{ fontSize: 14, color: "#ffcc00" }}>star_half</Icon> }}
                            emptyStarColor={"#cccccc"}
                            editing={false}
                          />

                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.employee_name}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.employee_email}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.employee_contact_info}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.employee_designation}
                        </TableCell>
                        {/* <TableCell align="left" className="table_cells">
                          {row.employee_salary_amount}
                        </TableCell> */}
                        <TableCell align="right" className="table_cells">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={
                              {
                                pathname: "/view_details",
                                state: {
                                  row: row,
                                  name: "EMPLOYEE",
                                  vendorName: employee.building_vendors.find(vendor => {
                                    if (vendor.vendor_id === row.vendor_id) {
                                      return vendor.vendor_name
                                    }
                                  })
                                }
                              }}>
                            <IconButton
                            >
                              <Icon >list</Icon>
                            </IconButton>
                          </Link>
                          <IconButton
                            onClick={(e) => {
                              // this.edit_single_employee(row)
                              // this.props.dialogAction(true)
                              this.setState({
                                menuAnchor: e.currentTarget,
                                row: row,

                              })
                            }}
                          >
                            <Icon style={{ fontSize: 20 }}>edit</Icon>
                          </IconButton>

                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.menuAnchor}
                  keepMounted
                  open={Boolean(this.state.menuAnchor)}
                  onClose={() => {
                    this.setState({
                      menuAnchor: null
                    })
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      this.edit_single_employee(this.state.row)
                      this.props.dialogAction(true)
                    }}
                  >
                    Edit details
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.edit_single_employee(this.state.row)
                      this.setState({
                        resetPasswordDialog: true,
                        menuAnchor: null
                      })
                    }}
                  >
                    Edit password
                  </MenuItem>
                </Menu>
              </Grid>
            </CardContent>
          </Card>

          <Dialog
            open={this.state.resetPasswordDialog}
            onClose={() => {
              this.setState({
                resetPasswordDialog: false
              })
            }}
          >
            <DialogTitle>
              Change password for {this.state.employee_name}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    label="password"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    type="password"
                    value={this.state.password}
                    onChange={(e) => {
                      this.setState({
                        password: e.target.value
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="confirm password"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={(e) => {
                      this.setState({
                        confirmPassword: e.target.value
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    resetPasswordDialog: false
                  })
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={(this.state.password === "" || this.state.password !== this.state.confirmPassword) ? true : false}
                onClick={() => {
                  updatePassword(login.token, this.state.employee_id, this.state.password);
                  this.setState({
                    resetPasswordDialog: false
                  })
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={employee.dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12} >
                  <TextField
                    label="Employee Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_name}
                    onChange={(event) => { this.setState({ employee_name: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={12} >
                  <TextField
                    label="Employee Designation"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_designation}
                    onChange={(event) => { this.setState({ employee_designation: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  {this.state.employee_image_old === "" ?
                    <span> "Image" : <input type="file"
                      onChange={(event) => { this.setState({ employee_image: event.target.files[0] }); }}
                    /></span> :
                    <span>
                      <Avatar src={this.state.employee_image_old} alt="no-img" />
                      Do you want to Change Image ?
                      <input type="checkbox" checked={this.state.checkImage}
                        onChange={() => { this.setState({ checkImage: !this.state.checkImage }) }}
                      />
                      <br />
                      {this.state.checkImage &&
                        <input type="file"
                          onChange={(event) => { this.setState({ employee_image: event.target.files[0] }); }}
                        />}
                    </span>

                  }
                </Grid>
                <Grid item lg={6} xs={12} >
                  <TextField
                    label="Contact Info"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_contact_info}
                    onChange={(event) => { this.setState({ employee_contact_info: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={12} >
                  <TextField
                    label="Employee Salary Amount"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_salary_amount}
                    onChange={(event) => { this.setState({ employee_salary_amount: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="ID Proof Type"
                    type="dropdown"
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_id_proof_type}
                    onChange={(event) => { this.setState({ employee_id_proof: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {id_proofs.all_id_proofs.map((single) => (
                      <MenuItem key={single.proof_name} value={single.proof_name}>
                        {single.proof_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="ID proof Number"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_id_proof_number}
                    onChange={(event) => { this.setState({ employee_id_proof_number: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />

                </Grid>
                <Grid item xs={12} md={12}>
                  {
                    this.state.employee_id_proof_images.map(s => (

                      <Avatar style={{ width: 60, height: 60 }}>
                        <img
                          src={s}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Avatar>

                    ))}

                      Do you want to Change Image ?

                  <input type="checkbox" checked={this.state.changeImageIdProof}
                    onChange={() => { this.setState({ changeImageIdProof: !this.state.changeImageIdProof }) }}
                  />
                  <br />

                  {this.state.changeImageIdProof &&
                    <span>
                      Image :
                     <input type="file" multiple
                        onChange={(event) => { set_employee_id_proof_img(event); }}
                      />
                    </span>
                  }

                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="Employee Code(Optional)"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_code}
                    onChange={(event) => { this.setState({ employee_code: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="Employee Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.employee_email}
                    onChange={(event) => { this.setState({ employee_email: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {vendors.payment_types.map((single) => (
                      <MenuItem key={single} value={single}>
                        {single}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="Employee Bank Account No"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.account_no}
                    onChange={(event) => { this.setState({ account_no: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="Bank Name(Optional)"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.bank_name}
                    onChange={(event) => { this.setState({ bank_name: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="Bank IFSC(Optional)"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.bank_ifsc}
                    onChange={(event) => { this.setState({ bank_ifsc: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    label="Branch"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.branch}
                    onChange={(event) => { this.setState({ branch: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <input
                    type="checkbox"
                    checked={this.state.is_provide_by_vendor}
                    value={this.state.is_provide_by_vendor}
                    onClick={(event) => { this.setState({ is_provide_by_vendor: !(this.state.is_provide_by_vendor) }); }}
                  />Is Provided by vendor
                </Grid>
                <Grid item lg={12} xs={12}>
                  {this.state.is_provide_by_vendor === true ?
                    <TextField
                      label="Vendors"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={this.state.selected_vendor}
                      onChange={(event) => { this.setState({ selected_vendor: event.target.value }); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {employee.building_vendors.map((single) => (
                        <MenuItem key={single.vendor_id} value={single.vendor_id}>
                          {single.vendor_name}
                        </MenuItem>
                      ))}
                      {/* {vendors.all_vendors.map((single) => (
                        <MenuItem key={single.vendor_id} value={single.vendor_id}>
                          {single.vendor_name}
                        </MenuItem>
                      ))} */}
                    </TextField> : ""}
                  <Grid item xs={12}>
                    {this.state.departments.map((s) => (
                      <div>
                        <Checkbox
                          checked={s.checked}
                          size="small"
                          onChange={() => {
                            this.toggleCheckbox(s, this.state.departments)
                          }}
                        />
                        {s.department_name}
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.props.dialogAction(false)
                  this.setState({
                    editredirect: false,
                    employee_name: "",
                    employee_designation: "",
                    employee_contact_info: "",
                    employee_salary_amount: "",
                    employee_id_proof_type: "",
                    employee_id_proof_number: "",
                    employee_code: "",
                    employee_email: "",
                    account_no: "",
                    bank_name: "",
                    bank_ifsc: "",
                    branch: "",
                    is_provide_by_vendor: "",
                    selected_vendor: "",
                    employee_id: "",
                    employee_image: "",
                    employee_image_old: ""
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_update_employee(login.token, login.building_id, this.state.employee_id, this.state.employee_name, this.state.employee_designation, this.state.employee_contact_info, this.state.employee_salary_amount, { id_proof_type: this.state.employee_id_proof_type, id_proof_number: this.state.employee_id_proof_number }, this.state.employee_code, this.state.employee_email, this.state.account_no, this.state.bank_name, this.state.bank_ifsc, this.state.branch, this.state.is_provide_by_vendor, this.state.selected_vendor, this.state.employee_image, this.state.employee_image_old, this.state.departments, employee.limit, employee.starting_after, employee);

                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirm_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirm_delete: false,
                    id: "",
                  });
                }}
                color="primary">
                No
              </Button>
              <Button
                onClick={() => {
                  delete_single_employee(login.token, login.building_id, employee.all_employee, employee.limit, employee.starting_after)
                  this.setState({
                    confirm_delete: false,
                    id: "",
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
        <ImageViewer
          image={this.state.image}
          close={() => {
            this.setState({
              image: null
            })
          }}
        />
      </Grid>
    );
  }
}
export default withStyles(styles)(ViewEmployee);