import {
    SET_ALL_COMPLAINTS,
    RESET_DEFAULT_COMPLAINTS,
    SET_STARTING_AFTER,
    SET_UNRESOLVED_COMPLAINTS,
    SET_INCOMPLETE_COMPLAINTS,
    SET_STARTING_AFTER_INCOMPLETE,
    SET_STARTING_AFTER_UNRESOLVED,
    SET_IS_URGENT,
    SET_RAISED_DATE,
    SET_COMPLAINT_DESC,
    SET_COMPLAINT_TITLE,
    PHONE_NUMBER,
    SET_RESIDENT_INFO,
    RESET_ADD_COMPLAINTS,
} from "../../constants/complaints/complaints_const"
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function validate_add_complaints(login, complaints) {
    return (dispatch) => {
        dispatch(set_loader());
        if (complaints.title === "") {
            dispatch(set_snack_bar(true, "Please Enter a Title"));
            dispatch(unset_loader())
        } else if (complaints.desc === "") {
            dispatch(set_snack_bar(true, "Please Enter the complaint Description"));
            dispatch(unset_loader())
        }
        else if (complaints.raised_date === "") {
            dispatch(set_snack_bar(true, "Please Enter the Raised date of the Complaint"));
            dispatch(unset_loader())
        } else if (complaints.resident_name === "") {
            dispatch(set_snack_bar(true, "Please Search the resident whose complaint you want to register"));
            dispatch(unset_loader())
        }
        else {
            dispatch(add_complaints(login, complaints));
        }
    };
}
export function add_complaints(login, complaints) {
    var array = JSON.stringify([])
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/book_complaint", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": login.token
            },
            body: JSON.stringify({
                complaint_title: complaints.title,
                complaint_desc: complaints.desc,
                complaint_raised_date: complaints.raised_date,
                is_urgent: complaints.is_urgent,
                unit_id: complaints.unit_id,
                resident_id: complaints.resident_id,
                building_id: login.building_id,
                complaint_raised_imgs: array,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(reset_add_complaints())
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function reset_add_complaints() {
    return {
        type: RESET_ADD_COMPLAINTS,
    }
}
export function set_phone_number(payload) {
    return {
        type: PHONE_NUMBER,
        payload: payload
    }
}
export function find_resident(value, token, building_id) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/search_residents", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                keyword: value,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_resident_info(responseJson.result))
                    dispatch(set_snack_bar(true, responseJson.message))
                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message))
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    }
}
export function set_resident_info(payload) {
    var obj = { unit_no: payload[0].unit_no, building_name: payload[0].building_name, unit_id: payload[0].unit_id, resident_id: payload[0]._id, resident_name: payload[0].resident_name }
    return {
        type: SET_RESIDENT_INFO,
        payload: obj,
    }
}
export function set_is_urgent(payload) {
    return {
        type: SET_IS_URGENT,
        payload: payload
    }
}
export function set_raised_date(payload) {
    return {
        type: SET_RAISED_DATE,
        payload: payload
    }
}
export function set_complaints_desc(payload) {
    return {
        type: SET_COMPLAINT_DESC,
        payload: payload
    }
}
export function set_complaints_title(payload) {
    return {
        type: SET_COMPLAINT_TITLE,
        payload: payload
    }
}
export function get_all_complaints(token, building_id, limit, starting_after) {
    starting_after = 0;
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_complaints(responseJson, limit, starting_after));
                        dispatch(get_unresolved_complaints(token, building_id, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_complaints_pagination(responseJson, limit, starting_after))
                        dispatch(get_unresolved_complaints(token, building_id, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_same_complaints(token, building_id, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_complaints_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function reset_complaints() {
    return {
        type: RESET_DEFAULT_COMPLAINTS
    }
}
export function prev_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_complaints_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_complaints_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_starting_after(payload) {
    return {
        type: SET_STARTING_AFTER,
        payload: payload
    }
}
export function set_starting_after_unresolved(payload) {
    return {
        type: SET_STARTING_AFTER_UNRESOLVED,
        payload: payload
    }
}
export function set_all_complaints(responseJson, limit, starting_after) {
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
    return {
        type: SET_ALL_COMPLAINTS,
        payload: obj,
    }
}
export function set_all_complaints_pagination(responseJson, limit, starting_after) {
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
    return {
        type: SET_ALL_COMPLAINTS,
        payload: obj,
    }
}
export function get_unresolved_complaints(token, building_id, limit, starting_after) {
    starting_after = 0;
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_unallotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_unresolved_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_unresolved_pagination(responseJson, limit, starting_after))
                    }
                } else {
                    dispatch(set_unresolved_complaints({ result: [], total: 0 }, 0, 0))
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_same_unresolved_complaints(token, building_id, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_unallotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_unresolved_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_unresolved_pagination(responseJson, limit, starting_after))
                    }
                } else {
                    dispatch(set_unresolved_complaints({ result: [], total: 0 }, 0, 0))
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function prev_btn_unresolved(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after_unresolved(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_unallotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_unresolved_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_unresolved_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn_unresolved(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after_unresolved(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_unallotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_unresolved_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_unresolved_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_unresolved_complaints(responseJson, limit, starting_after) {
    var obj
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
    }
    return {
        type: SET_UNRESOLVED_COMPLAINTS,
        payload: obj,
    }
}
export function set_all_unresolved_pagination(responseJson, limit, starting_after) {
    var obj
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
    }
    return {
        type: SET_UNRESOLVED_COMPLAINTS,
        payload: obj,
    }
}
export function validate_allot_func(token, building_id, complaint_id, employee_id, date, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        if (employee_id === "") {
            dispatch(set_snack_bar(true, "Please Select A Employee"));
            dispatch(unset_loader())
        } else if (date === "") {
            dispatch(set_snack_bar(true, "Please select date and time"));
            dispatch(unset_loader())
        }
        else {
            dispatch(allot_funct(token, building_id, complaint_id, employee_id, date, limit, starting_after));
        }
    };
}
export function allot_funct(token, building_id, complaint_id, employee_id, date, limit, starting_after) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/allot_complaint", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                complaint_id: complaint_id,
                employee_id: employee_id,
                complaint_allotted_date: date
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Employee Alloted Succcessfully"));
                    dispatch(get_same_unresolved_complaints(token, building_id, limit, starting_after));
                    dispatch(get_incomplete_complaints(token, building_id, limit, starting_after));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Employee Could Not Be Alloted"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function validate_complete_func(token, building_id, complaint_id, employee_id, complete_date, selected_type, complaint_fee, feedback, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        if (complete_date === "") {
            dispatch(set_snack_bar(true, "Please Enter A Date"));
            dispatch(unset_loader())
        } else if (selected_type === "") {
            dispatch(set_snack_bar(true, "Please select Type"));
            dispatch(unset_loader())
        }
        else if (feedback === "") {
            dispatch(set_snack_bar(true, "Please Enter Service Feedback"));
            dispatch(unset_loader())
        }
        else {
            dispatch(complete_funct(token, building_id, complaint_id, employee_id, complete_date, selected_type, complaint_fee, feedback, limit, starting_after));
        }
    };
}
export function complete_funct(token, building_id, complaint_id, employee_id, complete_date, selected_type, complaint_fee, feedback, limit, starting_after) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/complaint_completed", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                complaint_id: complaint_id,
                employee_id: employee_id,
                complaint_service_type: selected_type,
                complaint_fee: complaint_fee,
                service_feedback: feedback,
                complaint_completed_date: complete_date
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Complaint Completed"));
                    dispatch(get_incomplete_complaints(token, building_id, limit, starting_after));
                    dispatch(get_all_complaints(token, building_id, limit, starting_after));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Failed To Make Complaint Complete"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_incomplete_complaints(token, building_id, limit, starting_after) {
    starting_after = 0;
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_allotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_incomplete_complaints(responseJson, limit, starting_after));
                    }
                    else {
                        dispatch(set_incomplete_complaints_pagination(responseJson, limit, starting_after))
                    }
                } else {
                    dispatch(set_incomplete_complaints({ result: [], total: 0 }, 0, 0));
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_same_incomplete_complaints(token, building_id, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_allotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_incomplete_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_incomplete_complaints_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function prev_btn_incomplete(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after_incomplete(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_allotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_incomplete_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_incomplete_complaints_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn_incomplete(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after_incomplete(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_allotted_complaints", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_incomplete_complaints(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_incomplete_complaints_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_starting_after_incomplete(payload) {
    return {
        type: SET_STARTING_AFTER_INCOMPLETE,
        payload: payload
    }
}
export function set_incomplete_complaints(responseJson, limit, starting_after) {
    var obj
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    } else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
    }
    return {
        type: SET_INCOMPLETE_COMPLAINTS,
        payload: obj,
    }
}
export function set_incomplete_complaints_pagination(responseJson, limit, starting_after) {
    var obj
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
    }
    return {
        type: SET_INCOMPLETE_COMPLAINTS,
        payload: obj,
    }
}