import React, { Component } from "react";
import { connect } from "react-redux";
import ViewLoans from "../../components/loans/view_loans";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    getAllBankAccounts
} from "../../actions/bankAccounts/bankAccountAction";
import {
    getAllLoans,
    reset,
    addBuildingLoan,
    closeLoan,
    addEmiEntry
} from "../../actions/loans/loans_action";

export class ViewLoansCont extends Component {
    render() {
        return (
            <ViewLoans {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        snackbar: store.snackbar,
        bank: store.bank,
        loans: store.loans,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        reset: () => {
            dispatch(reset());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        closeLoan: (token, building_id, id) => {
            dispatch(closeLoan(token, building_id, id));
        },
        getAllLoans: (token, building_id) => {
            dispatch(getAllLoans(token, building_id));
        },
        getAllBankAccounts: (token, building_id) => {
            dispatch(getAllBankAccounts(token, building_id));
        },
        addBuildingLoan: (token, building_id, state) => {
            dispatch(addBuildingLoan(token, building_id, state));
        },
        addEmiEntry: (token, building_id, state) => {
            dispatch(addEmiEntry(token, building_id, state));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewLoansCont);