import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AddUnitsType extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    this.props.get_all_units(this.props.login.token, this.props.login.building_id)
    this.props.get_all_units_type(this.props.login.token, this.props.login.building_id)
  }
  render() {
    const {
      snackbar,
      close_snack_bar,
      login,
      units,
      validate_add_unit_type_func,
      set_unit_type_name,
      set_unit_type_parent_name,
    } = this.props;
    return (
      <Grid container spacing={8} justify="center"  >
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD UNITS TYPE
                  </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container>
                <Grid container item lg={4} xs={12} >
                  <Grid item lg={12} xs={12} >
                    <TextField
                      label="Type Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={units.units_type_name}
                      onChange={(event) => { set_unit_type_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Unit Parent Type"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={units.units_type_parent_name + ""}
                      onChange={(event) => { set_unit_type_parent_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {/* <MenuItem key={null} value={"null"}>
                        NONE
                      </MenuItem> */}
                      {units.units_types.map((single) => (
                        <MenuItem key={single.type_name} value={single._id}>
                          {single.type_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <br />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => { validate_add_unit_type_func(login.token, units, login.building_id); }}
                    >
                      Submit
                      </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddUnitsType);