import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from "@material-ui/core/MenuItem";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Moment from "react-moment";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class BuildingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editredirect: false,
      loginredirect: false,
      name: "",
      id: "",
      confirm_delete: false,
      building_account_num: "",
      building_ifsc: "",
      building_bank_name: "",
      building_address: "",
      building_poc_name: "",
      building_poc_phonenumber: "",
      building_login_id: "",
      building_id: "",
      latitude: "",
      longitude: "",
      e_name: "",
      e_building_id: "",
      e_email: "",
      e_contact_info: "",
      e_img: "",
      Subcrition_dialog: false,
      balance_dialog: false,
      payment_history_dialog: false,
      subscription_amount: "",
      duration: "",
      add_dialog: false,
    }
  }
  componentDidMount() {
    this.props.get_all_departments_id(this.props.login.token, this.props.location.state.row._id)
    this.props.get_building_admins(this.props.login.token, this.props.location.state.row._id)
  }
  edit_buiding = (s) => {
    this.setState({
      editredirect: true,
      building_id: s._id,
      name: s.building_name,
      building_account_num: s.building_accounts[0].account_number,
      building_bank_name: s.building_accounts[0].bank_name,
      building_ifsc: s.building_accounts[0].ifsc_code,
      building_address: s.building_address,
      building_poc_name: s.building_poc_name,
      building_poc_phonenumber: s.building_poc_phonenumber,
      latitude: s.location[0],
      longitude: s.location[1],
    })
  }
  render() {
    const {
      login,
      buildings,
      snackbar,
      close_snack_bar,
      update_buiding_single,
      delete_single_building,
      validate_create_credential,
      get_building_admins,
      remove_building_admin,
      resend_invitiation,
      subcription_extend
    } = this.props;

    // if (buildings.redirect_Building) {
    //   return <Redirect to="/all_buildings" />
    // }
    console.log(this.props)
    return (
      <Grid container justify="center">
        <Grid container item xs={12} lg={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW ADMIN DETAILS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item lg={12} xs={12} >
                <Tooltip title="Add admin" aria-label="add">
                  <IconButton
                    onClick={() => {
                      get_building_admins(login.token, this.props.location.state.row._id)
                      this.setState({
                        loginredirect: true,
                        e_building_id: this.props.location.state.row._id
                      })
                    }}
                  >
                    <Icon>add</Icon>
                  </IconButton>
                </Tooltip>
                <IconButton
                  onClick={() => {
                    this.edit_buiding(this.props.location.state.row);
                  }}
                >
                  <Icon>edit</Icon>
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.setState({
                      confirm_delete: true,
                      id: this.props.location.state.row._id,
                    })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
              <Grid container>
                <div style={{ backgroundColor: "#f2f2f2", borderRadius: 20, padding: 20 }}>
                  <Grid item xs={12} lg={12}>
                    {this.props.location.state.row.payment_history.length !== 0 &&
                      <ListItemText primary="Building details:" style={{ color: "black", textDecoration: "underline" }} />
                    }
                    <List>
                      <ListItem>
                        <ListItemText primary="Building Name" />
                        {this.props.location.state.row.building_name}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Building Owner Name" />
                        {this.props.location.state.row.building_poc_name}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Phone Number" />
                        {this.props.location.state.row.building_poc_phonenumber}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Building Address" />
                        {this.props.location.state.row.building_address}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Building Location (Co-Ordinates)" />
                        {this.props.location.state.row.location.map((s) => (
                          <div key={s}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {s}
                          </div>
                        ))}
                        <Divider />
                      </ListItem>
                    </List>
                  </Grid>
                </div>
              </Grid>
              <br />

              <Grid item lg={12} xs={12}>
                <div style={{ backgroundColor: "#f2f2f2", borderRadius: 20, padding: 20 }}>

                  {this.props.location.state.row.vendor_details.length === 0 ? "" :
                    <ListItemText primary="Building Vendor Details:" style={{ color: "black", textDecoration: "underline" }} />}
                  {this.props.location.state.row.vendor_details.length === 0 ?
                    <ListItemText primary="No Vendors in this building . Please ask the admin of the building to add vendors of his choice"
                      style={{ color: "black" }} /> :
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="left">Phone Number</TableCell>
                          <TableCell align="left" >Service </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.location.state.row.vendor_details.map((s) => (
                          <TableRow key={s._id}>
                            <TableCell align="left" className="table_cells">
                              {s.vendor_name}
                            </TableCell>
                            <TableCell align="left" className="table_cells">
                              {s.vendor_contact_info}
                            </TableCell>
                            <TableCell align="left" className="table_cells">
                              {s.vendor_service}
                            </TableCell>
                          </TableRow>
                        ))
                        }
                      </TableBody>
                    </Table>
                  }
                </div>
                <br />
                <div style={{ backgroundColor: "#f2f2f2", borderRadius: 20, padding: 20 }}>

                  {this.props.location.state.row.admin_details.length === 0 ?
                    <ListItemText primary="No Admin in this building . Please add admin"
                      style={{ color: "black" }} />
                    : <ListItemText primary="Building Admin Details:"
                      style={{ color: "black", textDecoration: "underline" }} />
                  }
                  {buildings.building_admins.length === 0 ?
                    "" :
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="center">Designation</TableCell>
                          <TableCell align="center" >Phone Number </TableCell>
                          <TableCell align="left" >Email </TableCell>
                          <TableCell align="right" >Action </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {buildings.building_admins.map((s) => (
                          <TableRow key={s._id}>
                            <TableCell align="center" className="table_cells">{s.employee_name}</TableCell>
                            <TableCell align="center" className="table_cells">{s.employee_designation}</TableCell>
                            <TableCell align="center" className="table_cells">{s.employee_contact_info}</TableCell>
                            <TableCell align="left" className="table_cells">{s.employee_email}</TableCell>
                            <TableCell align="right" className="table_cells">
                              <IconButton
                                onClick={() => {
                                  remove_building_admin(login.token, s.user_id, this.props.location.state.row._id)
                                }}
                              >
                                <Icon>
                                  delete
                                </Icon>
                              </IconButton>
                              <Tooltip title="Resend Admin credentials" aria-label="add">
                                <IconButton
                                  onClick={() => {
                                    resend_invitiation(this.props.login.token, s.employee_email)
                                  }}
                                >
                                  <Icon>send</Icon>
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                        }
                      </TableBody>
                    </Table>
                  }
                </div>
                <br />
                <div style={{ backgroundColor: "#f2f2f2", borderRadius: 20, padding: 20 }}>

                  {this.props.location.state.row.payment_history.length !== 0 &&
                    <ListItemText primary="Payment history:" style={{ color: "black", textDecoration: "underline" }} />
                  }
                  <Button
                    onClick={() => { this.setState({ Subcrition_dialog: true, amount: "", duration: "" }) }}
                    variant="outlined"
                    color="secondary"
                    className="btn_float"
                  >
                    Add Subcription
                </Button>
                  {this.props.location.state.row.payment_history.length === 0 ?
                    <ListItemText primary="No payment details found for this Building"
                      style={{ color: "black" }} /> :
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left"> Payment Date</TableCell>
                          <TableCell align="center">Duration </TableCell>
                          <TableCell align="center" >Amount </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.location.state.row.payment_history.map(row => (
                          <TableRow key={row._id} >
                            <TableCell align="left" className="table_cells">
                              <Moment format="DD/MM/YYYY">{row.payment_date}</Moment>
                            </TableCell>
                            <TableCell align="center" className="table_cells">
                              {row.duration}
                            </TableCell>
                            <TableCell align="center" className="table_cells">
                              {row.amount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </div>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            onClose={() => {
              this.setState({
                editredirect: false
              })
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.name}
                    onChange={(event) => { this.setState({ name: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Address"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.building_address}
                    onChange={(event) => { this.setState({ building_address: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="POC_Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.building_poc_name}
                    onChange={(event) => { this.setState({ building_poc_name: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid><Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="POC_PhoneNumber"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.building_poc_phonenumber}
                    onChange={(event) => { this.setState({ building_poc_phonenumber: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Account Number"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.building_account_num}
                    onChange={(event) => { this.setState({ building_account_num: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                  </TextField>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="IFSC Code"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.building_ifsc}
                    onChange={(event) => { this.setState({ building_ifsc: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                  </TextField>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Bank Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.building_bank_name}
                    onChange={(event) => { this.setState({ building_bank_name: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                  </TextField>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Latitude"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.latitude}
                    onChange={(event) => { this.setState({ latitude: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                  </TextField>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Longitude"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.longitude}
                    onChange={(event) => { this.setState({ longitude: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                  });
                }}
                color="primary">
                Close
              </Button>
              <Link style={{ textDecoration: "none" }} to="/all_buildings">
                <Button
                  onClick={() => {
                    update_buiding_single(login.token, this.state.name, this.state.building_account_num, this.state.building_ifsc, this.state.building_bank_name, this.state.building_address, this.state.building_poc_name, this.state.building_poc_phonenumber, this.state.building_id, this.state.latitude, this.state.longitude);
                    this.setState({
                      editredirect: false,
                    })
                  }}
                  color="primary" autoFocus>
                  UPDATE
              </Button>
              </Link>
            </DialogActions>
          </Dialog>
          {/* Email Credential */}
          <Dialog
            open={this.state.loginredirect}
            onClose={() => {
              this.setState({
                loginredirect: false
              })
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Add Building admins</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Admin Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.e_name}
                    onChange={(event) => { this.setState({ e_name: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  Image : <input type="file"
                    onChange={(event) => { this.setState({ e_img: event.target.files[0] }) }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input234"
                    label="Admin Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.e_email}
                    onChange={(event) => { this.setState({ e_email: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input34"
                    label="Contact Number"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.e_contact_info}
                    onChange={(event) => { this.setState({ e_contact_info: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    loginredirect: false,
                    e_name: "",
                    e_building_id: "",
                    e_email: "",
                    e_contact_info: "",
                    e_img: "",
                  });
                }}
                color="primary">
                Close
              </Button>
              {
                this.state.e_name === ""
                  || this.state.e_img === ""
                  || this.state.e_email === ""
                  || this.state.e_contact_info === "" ?
                  <Button
                    disabled={true}
                    color="primary"
                  >
                    Create
                  </Button> 
                  :
                  <Button
                    onClick={() => {
                      validate_create_credential(this.props.login.token, this.state.e_name, this.state.e_building_id, this.state.e_img, this.state.e_email, this.state.e_contact_info, buildings.ids_array);
                      this.setState({
                        loginredirect: false,
                        e_name: "",
                        e_building_id: "",
                        e_email: "",
                        e_contact_info: "",
                        e_img: "",
                      })
                    }}
                    color="primary"
                  >
                    Create
                </Button>
              }
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirm_delete}
            onClose={() => {
              this.setState({
                confirm_delete: false
              })
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirm_delete: false,
                    id: "",
                  });
                }}
                color="primary">
                No
              </Button>
              <Link style={{ textDecoration: "none" }} to="all_buildings">
                <Button
                  onClick={() => {
                    delete_single_building(this.props.login.token, this.state.id)
                    this.setState({
                      confirm_delete: false,
                      id: ""
                    })
                  }}
                  color="primary" autoFocus>
                  Yes
              </Button>
              </Link>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.Subcrition_dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">SUBCRIPTION</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <TextField
                    id="amount"
                    label="amount"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.subscription_amount || ""}
                    onChange={(event) => { this.setState({ subscription_amount: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <TextField
                    id="Duration"
                    label="Duration"
                    type="text"
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={this.state.duration || ""}
                    onChange={(event) => { this.setState({ duration: event.target.value }); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {buildings.duration.map((s) => (
                      <MenuItem key={s.value} value={s.value}>
                        {s.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    Subcrition_dialog: false,
                    id: "",
                  });
                }}
                color="primary">
                Close
              </Button>
              {this.state.subscription_amount && this.state.duration !== "" ?
                <Link
                  style={{ textDecoration: "none" }} to="all_buildings">
                  <Button
                    onClick={() => {
                      subcription_extend(this.props.login.token, this.state.subscription_amount, this.state.duration, buildings, this.props.location.state.row._id)
                      this.setState({
                        Subcrition_dialog: false,
                        id: ""
                      })
                    }}
                    color="primary" autoFocus>
                    Extend
                                </Button>
                </Link> :
                <Button
                  disabled={true}
                  color="primary" autoFocus>
                  Extend
                        </Button>}
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.payment_history_dialog}
            onClose={() => {
              this.setState({
                payment_history_dialog: false
              })
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell >&nbsp;&nbsp;Name</TableCell>
                    <TableCell align="center">Point of Contact Name</TableCell>
                    <TableCell align="center">Point of Contact Number </TableCell>
                    <TableCell align="center" >Point of Address </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.location.state.row.payment_history.map(row => (
                    <TableRow key={row._id} >
                      <TableCell align="left" className="table_cells">
                        &nbsp;&nbsp;{row}
                      </TableCell>
                      <TableCell align="center" className="table_cells">
                        {row}
                      </TableCell>
                      <TableCell align="center" className="table_cells">
                        {row}
                      </TableCell>
                      <TableCell align="center" className="table_cells">
                        {row}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    payment_history_dialog: false,
                    id: "",
                  });
                }}
                color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.add_dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">ADD ADMIN FOR THE BUILDING {this.props.location.state.row.building_name}</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container >
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    add_dialog: false,
                    id: "",
                  });
                }}
                color="primary">
                Close
              </Button>
              <Link style={{ textDecoration: "none" }} to="all_buildings">
                <Button
                  onClick={() => {
                    this.setState({
                      add_dialog: false,
                      id: ""
                    })
                  }}
                  color="primary" autoFocus>
                  Add
              </Button>
              </Link>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(BuildingDetails);