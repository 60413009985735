import {
  SET_ALL_POLLS,
  SET_HISTORY_POLLS,
  SET_POLL_RESULT
} from "../../constants/polling/pollingActionType";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";

export function setActiveAllPolls(all_polls) {
  return {
    type: SET_ALL_POLLS,
    payload: all_polls
  };
}

export function setHistoryPolls(all_polls) {
  return {
    type: SET_HISTORY_POLLS,
    payload: all_polls
  };
}

export function setPollResult(pollResult) {
  return {
    type: SET_POLL_RESULT,
    payload: pollResult
  };
}

export function get_active_polls(token, building_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_active_polls", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(setActiveAllPolls(responseJson.result))
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(setActiveAllPolls([]))

        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function get_history_polls(token, building_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_history_polls", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setHistoryPolls(responseJson.result))
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function endPoll(token, building_id, pollId) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/delete_poll", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        poll_id: pollId
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(get_active_polls(token, building_id));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function addPoll(token, building_id, question, options) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/create_poll", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        question: question,
        options: JSON.stringify(options)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(true, responseJson.message));
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getPollResult(token, pollId) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/get_poll_result", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        poll_id: pollId
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setPollResult(responseJson.result))
        }
        else {
          dispatch(set_snack_bar(true, responseJson.message))

        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}