import React, { Component } from "react";
import { connect } from "react-redux";
import AddEnotice from "../../components/e-notice/add_e_notice"
import {
    set_e_notice_title,
    set_e_notice_desc,
    set_e_notice_target,
    set_e_notice_valid_till,
    validate_add_e_notice,
    set_admin_target,
    set_employee_target,
    set_resident_target,
    reset_e_notice
} from "../../actions/e-notice/e_notice_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_e_notice_cont extends Component {
    render() {
        return (
            <AddEnotice {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        e_notice: store.e_notice,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        set_e_notice_title: (payload) => {
            dispatch(set_e_notice_title(payload))
        },
        set_e_notice_desc: (payload) => {
            dispatch(set_e_notice_desc(payload))
        },
        set_e_notice_target: (payload) => {
            dispatch(set_e_notice_target(payload))
        },
        set_e_notice_valid_till: (payload) => {
            dispatch(set_e_notice_valid_till(payload))
        },
        set_admin_target: (payload) => {
            dispatch(set_admin_target(payload))
        },
        set_employee_target: (payload) => {
            dispatch(set_employee_target(payload))
        },
        set_resident_target: (payload) => {
            dispatch(set_resident_target(payload))
        },
        validate_add_e_notice: (token, building_id, e_notice, generated_date) => {
            dispatch(validate_add_e_notice(token, building_id, e_notice, generated_date))
        },
        reset_e_notice: () => {
            dispatch(reset_e_notice());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_e_notice_cont);