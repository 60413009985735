import { LIMIT } from "../../../constants/ActionTypes";
import {
    SET_ALL_VISITORS,
    SET_STARTING_AFTER,
    SET_START_DATE,
    SET_END_DATE,
    SET_VISITOR_DATE_OF_VISIT,
    SET_VISITOR_NAME,
    SET_VISITOR_MOB_NUM,
    SET_VISITOR_VEHICLE_TYPE,
    SET_VISITOR_VEHICLE_NUM,
    SET_VISITOR_UNIT_NAME,
    RESET_DEFAULT_VISITOR,
    SET_SEARCH_EMPLOYEES
} from "../../../constants/visitors/employee/employee_visitors_const";
var date = new Date();
const initial_state = {
    all_visitors: [],
    visitor_name: "",
    visitor_mob_num: "",
    visitor_vehicle_type: "",
    visitor_vehicle_num: "",
    visitor_unit_name: "",
    visitor_date_of_visit: new Date(),
    starting_after: 0,
    start_var: "",
    end_var: "",
    check: false,
    limit: LIMIT,
    total: "",
    start_date: null,
    end_date: null,
    search_employees: []
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_VISITORS:
            return state = { ...state, all_visitors: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total };
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload }
        case SET_START_DATE:
            return state = { ...state, start_date: action.payload }
        case SET_END_DATE:
            return state = { ...state, end_date: action.payload }
        case SET_VISITOR_DATE_OF_VISIT:
            return state = { ...state, visitor_date_of_visit: action.payload }
        case SET_VISITOR_NAME:
            return state = { ...state, visitor_name: action.payload }
        case SET_VISITOR_MOB_NUM:
            return state = { ...state, visitor_mob_num: action.payload }
        case SET_VISITOR_VEHICLE_TYPE:
            return state = { ...state, visitor_vehicle_type: action.payload }
        case SET_VISITOR_VEHICLE_NUM:
            return state = { ...state, visitor_vehicle_num: action.payload }
        case SET_VISITOR_UNIT_NAME:
            return state = { ...state, visitor_unit_name: action.payload }
        case SET_SEARCH_EMPLOYEES:
            return state = { ...state, search_employees: action.payload }
        case RESET_DEFAULT_VISITOR:
            return state = {
                ...state,
                visitor_name: "",
                visitor_mob_num: "",
                visitor_vehicle_type: "",
                visitor_vehicle_num: "",
                visitor_unit_name: "",
                visitor_date_of_visit: new Date(),
            }
        default:
            return state;
    }
}