import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import "../../styles/style.scss";
import { Link } from "react-router-dom";
import LinearProgress from '@material-ui/core/LinearProgress';

class ViewPolling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm_delete: false,
      id: "",
      resultDialog: false
    }
  }
  componentDidMount() {
    this.props.get_active_polls(this.props.login.token, this.props.login.building_id);
  }

  del_single_row = (row) => {
    this.setState({
      confirm_delete: true,
      id: row._id,
    })
  }

  render() {
    const {
      snackbar,
      close_snack_bar,
      login,
      polling,
      endPoll,
      getPollResult
    } = this.props;

    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12} spacing={8}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW POLLS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid item lg={12} xs={12} justify="center">
                <Link to="add_polling" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                <br /><br />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">&nbsp;&nbsp;Question</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {polling.all_polls.map(row => (
                      <TableRow key={row._id}>
                        <TableCell align="left" className="table_cells">
                          {row.question}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              this.setState({
                                confirm_delete: true,
                                id: row.poll_id
                              })
                            }}
                          >
                            End Poll
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              this.setState({
                                resultDialog: true
                              })
                              getPollResult(login.token, row.poll_id);
                            }}
                          >
                            Results
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.confirm_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirm_delete: false,
                    id: ""
                  });
                }}
                color="primary">
                No
            </Button>
              <Button
                onClick={() => {
                  endPoll(login.token, login.building_id, this.state.id);
                  this.setState({
                    confirm_delete: false,
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={polling.pollResult.length > 0 ? true : false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            fullWidth={true}
          >
            {/* <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle> */}
            <DialogContent>
              <Grid container>
                <Grid item xs={12} container>
                  <Grid xs={2}>
                    <b>Question</b>
                  </Grid>
                  <Grid xs={1}>
                    <b>Votes</b>
                  </Grid>
                  <Grid xs={9}>
                    <b>Progress</b>
                  </Grid>
                </Grid>
                {polling.pollResult.map((single) => (
                  <Grid item xs={12} container>
                    <Grid xs={2}>
                      {single.option}
                    </Grid>
                    <Grid xs={1}>
                      {single.no_answered}/{single.total_answered}
                    </Grid>
                    <Grid xs={9}>
                      <LinearProgress
                        // className={classes.margin}
                        style={{ margin: 10 }}
                        variant="determinate"
                        color="secondary"
                        value={single.answer_percentage}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.props.setPollResult([])
                }}
                color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid >
    );
  }
}
export default withStyles(styles)(ViewPolling);