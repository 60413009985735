import React, { Component } from "react";
import { connect } from "react-redux";
import AddHelper from "../../components/helper/add_helper";
import {
    validate_helper_func,
    set_helper_email,
    set_helper_contact_info,
    set_helper_secondary_contact_info,
    set_helper_permanent_address,
    set_helper_id_proof_id,
    set_helper_id_proof_type,
    set_helper_name,
    set_helper_image,
    set_helper_service,
    set_is_private,
    reset_all,
    set_id_proof_image,
    setUnitIdForHelper,
} from "../../actions/helper/helpers_action";
import {
    get_all_id_proofs,
} from "../../actions/id_proofs/id_proofs_action";
import {
    get_same_units,
} from "../../actions/units/units_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class AddHelperCont extends Component {
    render() {
        return (
            <AddHelper
                {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        id_proofs: store.id_proofs,
        helper: store.helper,
        units: store.units
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        setUnitIdForHelper: (id) => {
            dispatch(setUnitIdForHelper(id))
        },
        get_same_units: (token, building_id) => {
            dispatch(get_same_units(token, building_id))
        },
        get_all_id_proofs: (token) => {
            dispatch(get_all_id_proofs(token))
        },
        validate_helper_func: (token, helper, units) => {
            dispatch(validate_helper_func(token, helper, units))
        },
        set_helper_email: (payload) => {
            dispatch(set_helper_email(payload));
        },
        set_id_proof_image: (payload) => {
            dispatch(set_id_proof_image(payload));
        },
        set_helper_contact_info: (payload) => {
            dispatch(set_helper_contact_info(payload));
        },
        set_helper_secondary_contact_info: (payload) => {
            dispatch(set_helper_secondary_contact_info(payload));
        },
        set_helper_permanent_address: (payload) => {
            dispatch(set_helper_permanent_address(payload));
        },
        set_helper_id_proof_id: (payload) => {
            dispatch(set_helper_id_proof_id(payload));
        },
        set_is_private: (payload) => {
            dispatch(set_is_private(payload));
        },
        set_helper_id_proof_type: (payload) => {
            dispatch(set_helper_id_proof_type(payload));
        },
        set_helper_name: (payload) => {
            dispatch(set_helper_name(payload));
        },
        set_helper_image: (payload) => {
            dispatch(set_helper_image(payload));
        },
        set_helper_service: (payload) => {
            dispatch(set_helper_service(payload))
        },
        reset_all: () => {
            dispatch(reset_all());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddHelperCont);