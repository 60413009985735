import React, { Component } from "react";
import { connect } from "react-redux";
import AddBuidings from "../../components/buildings/add_buildings";
import {
    set_building_name,
    set_building_poc_name,
    set_building_poc_number,
    set_building_account_no,
    validate_add_building,
    set_building_address,
    set_building_bank_name,
    set_building_ifsc_code,
    set_building_latitude,
    set_building_longitude
} from "../../actions/buildings/buildings_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_buildings_cont extends Component {
    render() {
        return (
            <AddBuidings {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        residents: store.residents,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        units: store.units,
        buildings: store.buildings,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        validate_add_building: (token, buildings) => {
            dispatch(validate_add_building(token, buildings))
        },
        set_building_address: (token) => {
            dispatch(set_building_address(token))
        },
        set_building_latitude: (token) => {
            dispatch(set_building_latitude(token))
        },
        set_building_longitude: (token) => {
            dispatch(set_building_longitude(token))
        },
        set_building_name: (token) => {
            dispatch(set_building_name(token))
        },
        set_building_poc_name: (payload) => {
            dispatch(set_building_poc_name(payload))
        },
        set_building_poc_number: (payload) => {
            dispatch(set_building_poc_number(payload))
        },
        set_building_account_no: (payload) => {
            dispatch(set_building_account_no(payload))
        },
        set_building_ifsc_code: (payload) => {
            dispatch(set_building_ifsc_code(payload))
        },
        set_building_bank_name: (payload) => {
            dispatch(set_building_bank_name(payload))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_buildings_cont);