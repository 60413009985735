import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import { Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
const buttonnn = {
  borderRadius: "5px",
  height: "36px",
  color: "white",
  paddingLeft: "30px",
  paddingRight: "30px",
  backgroundColor: "green",
  alignSelf: "left",
  marginTop: 20
};
class InviteResidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    this.props.reset_all()
  }
  render() {
    const {
      residents,
      snackbar,
      close_snack_bar,
      login,
      set_resident_email,
      set_resident_contact_info,
      set_is_owner,
      invite_resident,
      set_is_subresidents,
      set_is_residing
    } = this.props;
    return (
      <Grid container item xs={12} lg={12} spacing={8}  >
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  INVITE RESIDENT
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container>
                <Grid container item lg={4}>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Email"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_email}
                      onChange={(event) => { set_resident_email(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Mobile Number"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_contact_info}
                      onChange={(event) => { set_resident_contact_info(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Checkbox
                      checked={residents.is_residing}
                      value={residents.is_residing}
                      onChange={(event) => { set_is_residing(event.target.value) }}
                      size="small"
                    />
                    Tick if person resides in the unit
                  </Grid>
                  <Grid xs={12} style={{ backgroundColor: "#f2f2f2", borderRadius: 10 }}>
                    {residents.is_residing === true &&
                      <Grid item lg={12} xs={12}>
                        <Checkbox
                          checked={residents.is_owner}
                          value={residents.is_owner}
                          onChange={(event) => { set_is_owner(event.target.value) }}
                          size="small"
                        />
                      Tick if person is <b>ALSO</b> the owner
                    </Grid>
                    }
                    {residents.is_residing === true &&
                      <Grid item lg={12} xs={12}>
                        <Checkbox
                          checked={residents.is_sub_resident}
                          value={residents.is_sub_resident}
                          onChange={(event) => { set_is_subresidents(event.target.value) }}
                          size="small"
                        />
                        Tick if person is a family member
                      </Grid>
                    }
                    <Grid item lg={12} xs={12} style={{padding: 20}}>
                      NOTE: do not tick anything if the person is an owner who <b>IS NOT</b> residing at the unit.
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Button
                      style={buttonnn}
                      variant="outlined"
                      size="small"
                      onClick={() => { invite_resident(login.token, residents, login.building_id, this.props.location.state.unit_id, residents.is_residing); }}
                    >
                      Submit
                    </Button>
                  </Grid>

                </Grid>
              </Grid>

            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(InviteResidents);