import React from "react";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
class ViewBankAccounts extends React.Component {
    componentDidMount() {
        this.props.getAllBankAccounts(this.props.login.token, this.props.login.building_id)
    }
    constructor(props) {
        super(props);
        this.state = {
            addDialog: false,
            bankName: "",
            accountNumber: "",
            ifscCode: "",
            deleteDialog:false,
            delteId:"",
        }
    }
    render() {
        const {
            getAllBankAccounts,
            addBankAccounts,
            deleteSingleAccount,
            login,
            snackbar,
            close_snack_bar,
            bank,
        } = this.props;
        return (
            <Grid container spacing={8} justify="center">
                <Grid item sm={12}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                <h3>
                                    VIEW BANK ACCOUNTS
                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent >
                            <IconButton
                                onClick={() => { this.setState({ addDialog: true }) }}
                            >
                                <Icon>
                                    add
                                </Icon>
                            </IconButton>
                            <Grid item xs={12} >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Bank Name</TableCell>
                                            <TableCell align="left">Account Number</TableCell>
                                            <TableCell align="left">IFSC Code</TableCell>
                                            <TableCell align="left">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bank.all_bank_accounts.map(row => (
                                            <TableRow key={row.id}>
                                                <TableCell align="left" className="table_cells">
                                                    {row.bank_name}
                                                </TableCell>
                                                <TableCell align="left" className="table_cells">
                                                    {row.account_no}
                                                </TableCell>
                                                <TableCell align="left" className="table_cells">
                                                    {row.ifsc_code}
                                                </TableCell>
                                                <TableCell align="left" className="table_cells">
                                                    <IconButton
                                                        onClick={() => { this.setState({ deleteDialog: true, deleteId: row.id }) }}
                                                    >
                                                        <Icon>
                                                            delete
                                                        </Icon>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Dialog
                        open={this.state.addDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">ADD BANK ACCOUNT</DialogTitle>
                        <DialogContent>
                            <Grid container justify="center">
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        id="outlined-email-input56"
                                        label="Bank Name"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.bankName}
                                        onChange={(event) => { this.setState({ bankName: event.target.value }); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        id="outlined-email-input34"
                                        label="Account Number"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.accountNumber}
                                        onChange={(event) => { this.setState({ accountNumber: event.target.value }); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        id="outlined-email-input67"
                                        label="IFSC code"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.ifscCode}
                                        onChange={(event) => { this.setState({ ifscCode: event.target.value }); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        addDialog: false,
                                        accountNumber: "",
                                        bankName: "",
                                        ifscCode: ""
                                    });
                                }}
                                color="primary">
                                Close
                             </Button>

                            <Button
                                disabled={this.state.accountNumber === "" || this.state.bankName === "" || this.state.ifscCode === "" ?
                                    true : false}
                                onClick={() => {
                                    this.setState({
                                        addDialog: false,
                                        accountNumber: "",
                                        bankName: "",
                                        ifscCode: ""
                                    })
                                    addBankAccounts(login.token, login.building_id, this.state.bankName, this.state.accountNumber, this.state.ifscCode)
                                }}
                                color="primary"
                                autoFocus
                            >
                                ADD NEW ACCOUNT
                             </Button>
                        </DialogActions>
                    </Dialog>
                    {/* <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.proof_name}
                    onChange={(event) => { this.setState({ proof_name: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                  <br /><br /><br />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <br /><br /><br />
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_update_id_proof(login.token, this.state.proof_id, this.state.proof_name)
                  this.setState({
                    redirect: false,
                    editredirect: false
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
            </Dialog>*/}
                    <Dialog
                        open={this.state.deleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete this Account ?</DialogTitle>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        deleteDialog: false,
                                    });
                                }}
                                color="primary">
                                NO
                </Button>
                            <Button
                                onClick={() => {
                                    deleteSingleAccount(login.token, login.building_id, this.state.deleteId)
                                    this.setState({
                                        deleteDialog: false,
                                    })
                                }}
                                color="primary" autoFocus>
                                Yes
              </Button>
                        </DialogActions>
                    </Dialog>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(ViewBankAccounts);