import React, { Component } from "react";
import { connect } from "react-redux";
import ViewIdProofs from "../../components/id_proofs/id_proofs";
import {
}
    from "../../components/id_proofs/id_proofs";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    get_all_id_proofs,
    update_id_proof,
    delete_id_proofs,
    validate_update_id_proof,
    validate_proof_func,
    add_id_proof,
    set_proof_name,
    toggleCheckbox
} from "../../actions/id_proofs/id_proofs_action";
export class Id_proofs_cont extends Component {
    render() {
        return (
            <ViewIdProofs {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        id_proofs: store.id_proofs,
        snackbar: store.snackbar,
        loader: store.loader,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_id_proofs: (token) => {
            dispatch(get_all_id_proofs(token))
        },
        update_id_proof: (token, proof_id, proof_name) => {
            dispatch(update_id_proof(token, proof_id, proof_name))
        },
        delete_id_proofs: (token, proof_id) => {
            dispatch(delete_id_proofs(token, proof_id))
        },
        validate_update_id_proof: (token, proof_id, proof_name) => {
            dispatch(validate_update_id_proof(token, proof_id, proof_name))
        },
        validate_proof_func: (token, id_proofs) => {
            dispatch(validate_proof_func(token, id_proofs))
        },
        set_proof_name: (payload) => {
            dispatch(set_proof_name(payload))
        },
        add_id_proof: (token, id_proofs) => {
            dispatch(add_id_proof(token, id_proofs))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        toggleCheckbox: (obj, array) => {
            dispatch(toggleCheckbox(obj, array));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Id_proofs_cont);