import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LoaderCon from "../../containers/loader/loader_cont";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import { Link } from "react-router-dom";
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class ViewVisitors extends Component {
    componentDidMount() {
        this.props.get_all_visitors(this.props.login.token, this.props.login.building_id, this.props.visitors.limit, this.props.visitors.starting_after, this.props.visitors.start_date, this.props.visitors.end_date);
    }
    render() {
        const {
            visitors,
            snackbar,
            close_snack_bar,
            login,
            allow_visitor_entry,
            prev_btn,
            next_btn,
            set_start_date,
            set_end_date,
            get_all_visitors
        } = this.props;
        var btn;
        if (visitors.total > visitors.limit) {
            if (visitors.end_var === visitors.total) {
                btn = <span >
                    <IconButton
                        onClick={() => { prev_btn(login.token, login.building_id, visitors.limit, visitors.starting_after); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton disabled>
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {visitors.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{visitors.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{visitors.total}
                </span>
            }
            else if (visitors.start_var > visitors.limit && visitors.end_var < visitors.total) {
                btn = <span >
                    <IconButton
                        onClick={() => { prev_btn(login.token, login.building_id, visitors.limit, visitors.starting_after); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton
                        onClick={() => { next_btn(login.token, login.building_id, visitors.limit, visitors.starting_after); }}
                    >
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {visitors.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{visitors.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{visitors.total}
                </span>
            }
            else {
                btn =
                    <span >
                        <IconButton disabled>
                            <Icon>navigate_before</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => { next_btn(login.token, login.building_id, visitors.limit, visitors.starting_after); }}
                        >
                            <Icon>navigate_next</Icon>
                        </IconButton>
                        {visitors.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{visitors.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{visitors.total}
                    </span>
            }
        }
        else {
            btn = <span >
                <IconButton disabled>
                    <Icon>navigate_before</Icon>
                </IconButton>
                <IconButton disabled>
                    <Icon>navigate_next</Icon>
                </IconButton>
                {visitors.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{visitors.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{visitors.total}
            </span>
        }
        return (
            <Grid container spacing={8} justify="center">
                <Grid item sm={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                <h3>
                                    VIEW VISITORS
                                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent>
                            <Grid item xs={12}>
                                <Link to="create_visitor_entry" style={{ textDecoration: "none" }}>
                                    <IconButton>
                                        <Icon>add</Icon>
                                    </IconButton>
                                </Link>
                                <Button
                                    style={{ float: "right" }}
                                    variant="outlined"
                                    onClick={() => {
                                        this.props.get_all_visitors(this.props.login.token, this.props.login.building_id, this.props.visitors.limit, 0, this.props.visitors.start_date, this.props.visitors.end_date);
                                    }}
                                >
                                    Refresh
                               </Button>
                            </Grid>
                            <Grid container justify="center">
                                <Grid container item lg={6} xs={6} spacing={2}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid item lg={5} xs={5}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Start Date"
                                                format="dd/MM/yyyy"
                                                value={visitors.start_date}
                                                onChange={(event) => { set_start_date(event); }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={5} xs={5}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialo1g"
                                                label="End Date"
                                                format="dd/MM/yyyy"
                                                value={visitors.end_date}
                                                onChange={(event) => { set_end_date(event); }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <br />
                                    <Grid item lg={2} xs={2}>
                                        <Button
                                            style={{ position: "relative", top: 30 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => { get_all_visitors(login.token, login.building_id, visitors.limit, visitors.starting_after, visitors.start_date, visitors.end_date); }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div style={{ float: "right" }}>
                                {btn}
                            </div>
                            <br /><br />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                                        <TableCell align="left">Contact info</TableCell>
                                        <TableCell align="center">Unit Name/No</TableCell>
                                        <TableCell align="left">Vehicle Number</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visitors.all_visitors.map(row => (
                                        <TableRow key={row._id}>
                                            {row.visitor_details.map(single => (
                                                <TableCell align="left" >
                                                    &nbsp;&nbsp;{single.visitor_name}
                                                </TableCell>
                                            ))}
                                            {row.visitor_details.map(single => (
                                                <TableCell align="left" >
                                                    {single.visitor_mobilenumber}
                                                </TableCell>
                                            ))}
                                            <TableCell align="center" className="table_cells">
                                                {row.resident_unit_no}
                                            </TableCell>
                                            <TableCell align="left" >
                                                {row.visitor_vehicle_details.vehicle_number}
                                            </TableCell>
                                            <TableCell align="right" className="table_cells">
                                                {row.is_user_approved === false ?
                                                    <IconButton
                                                        onClick={() => {
                                                            allow_visitor_entry(login.token, login.building_id, row._id, visitors.limit, visitors.starting_after, visitors.start_date, visitors.end_date)
                                                        }}
                                                    >
                                                        <Icon>done_all</Icon>
                                                    </IconButton>
                                                    :
                                                    "Visitor Allowed"
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(styles)(ViewVisitors);