import { LIMIT } from "../../constants/ActionTypes";
import {
    SET_STARTING_AFTER,
    SET_ALL_AMENITIES,
    SET_GUEST_HALFDAY_PRICE,
    SET_AMINITY_NAME,
    SET_AMINITY_DESC,
    SET_AMINITY_IMAGES,
    SET_AMINITY_FULLDAY_PRICE,
    SET_AMINITY_HALFDAY_PRICE,
    SET_GUEST_FULLDAY_PRICE,
    SET_NO_OF_SLOTS,
    SET_START_TIME,
    SET_END_TIME,
    SET_ISFREE,
    RESET_DEFAULT_AMENITIES,
    RESET_AMENITIES_PAGINATION,
    SET_IMAGE_URL,
} from "../../constants/amenities/amenities_const";
const initial_state = {
    all_amenities: [],
    starting_after: 0,
    start_var: 0,
    end_var: 0,
    check: false,
    limit: LIMIT,
    total: 0,
    amenity_name: "",
    amenity_desc: "",
    image_url: [],
    amenity_img: [],
    amenity_fullday_price: "",
    amenity_halfday_price: "",
    guest_fullday_price: "",
    guest_halfday_price: "",
    isfree: false,
    no_of_slots: "",
    start_time: new Date(),
    end_time: new Date(),
    time_to_start: "",
    time_to_end: "",
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_AMENITIES:
            return state = { ...state, all_amenities: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload, }
        case SET_AMINITY_NAME:
            return state = { ...state, amenity_name: action.payload, }
        case SET_AMINITY_DESC:
            return state = { ...state, amenity_desc: action.payload, }
        case SET_AMINITY_IMAGES:
            return state = { ...state, amenity_img: action.payload, }
        case SET_AMINITY_FULLDAY_PRICE:
            return state = { ...state, amenity_fullday_price: action.payload, }
        case SET_AMINITY_HALFDAY_PRICE:
            return state = { ...state, amenity_halfday_price: action.payload, }
        case SET_GUEST_FULLDAY_PRICE:
            return state = { ...state, guest_fullday_price: action.payload, }
        case SET_GUEST_HALFDAY_PRICE:
            return state = { ...state, guest_halfday_price: action.payload, }
        case SET_NO_OF_SLOTS:
            return state = { ...state, no_of_slots: action.payload, }
        case SET_START_TIME:
            return state = { ...state, start_time: action.payload }
        case SET_END_TIME:
            return state = { ...state, end_time: action.payload }
        case SET_ISFREE:
            return state = { ...state, isfree: action.payload, }
        case SET_IMAGE_URL:
            return state = { ...state, image_url: action.payload, }
        case RESET_AMENITIES_PAGINATION:
            return state = {
                ...state, starting_after: 0,
                start_var: "",
                end_var: "",
                check: false,
                total: 0
            }
        case RESET_DEFAULT_AMENITIES:
            return state = {
                ...state,
                amenity_name: "",
                amenity_desc: "",
                image_url: [],
                amenity_fullday_price: "",
                amenity_halfday_price: "",
                guest_fullday_price: "",
                guest_halfday_price: "",
                isfree: false,
                no_of_slots: "",
                start_time: new Date(),
                end_time: new Date(),
            }
        default:
            return state;
    }
}
