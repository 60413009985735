import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import { styles } from "../../styles/style";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import moment from "moment";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Rating from '@material-ui/lab/Rating';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Image from "react-graceful-image";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AllComplaints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      completeredirect: false,
      complaint_id: "",
      employee_id: "",
      date: new Date(),
      service_type: ["Free", "Paid"],
      complaint_fee: "",
      complete_date: new Date(),
      selected_type: "",
      feedback: "",
      view_dialog: false,
      complaints_details: [],
      complaint_images: []
    }
  }
  componentDidMount() {
    this.props.get_all_complaints(this.props.login.token, this.props.login.building_id, this.props.complaints.limit, this.props.complaints.starting_after)
    this.props.get_unresolved_complaints(this.props.login.token, this.props.login.building_id, this.props.complaints.limit_unresolved, this.props.complaints.starting_after_unresolved)
    this.props.get_incomplete_complaints(this.props.login.token, this.props.login.building_id, this.props.complaints.limit_incomplete, this.props.complaints.starting_after_incomplete)
    this.props.get_same_employee(this.props.login.token, this.props.login.building_id)
  }
  allotfunct = (s) => {
    this.setState({
      complaint_id: s._id,
      complaint_images: s.complaint_raised_imgs
    })
  }
  complete_funct = (s) => {
    this.setState({
      complaint_id: s._id,
      employee_id: s.employee_id,
      selected_type: "",
      feedback: "",
      complete_date: new Date(),
    })
  }
  render() {
    const {
      login,
      complaints,
      next_btn,
      prev_btn,
      employee,
      prev_btn_unresolved,
      next_btn_unresolved,
      next_btn_incomplete,
      prev_btn_incomplete,
      snackbar,
      close_snack_bar,
      validate_complete_func,
      validate_allot_func
    } = this.props;
    var btn;
    var btn1;
    var btn2;

    if (complaints.total > complaints.limit) {
      if (complaints.end_var === complaints.total) {
        btn = <span>
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, complaints.limit, complaints.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {complaints.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total}
        </span>
      }
      else if (complaints.start_var > complaints.limit && complaints.end_var < complaints.total) {
        btn = <span>
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, complaints.limit, complaints.starting_after); }}
          >
            <Icon>navigate_before</Icon>12000
          </IconButton>
          <IconButton
            onClick={() => { next_btn(login.token, login.building_id, complaints.limit, complaints.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {complaints.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total}
        </span>
      }
      else {
        btn =
          <span>
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(login.token, login.building_id, complaints.limit, complaints.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {complaints.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total}
          </span>
      }
    }
    else {
      btn = <span>
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {complaints.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total}
      </span>
    }
    // New Complaint
    if (complaints.total_unresolved > complaints.limit_unresolved) {
      if (complaints.end_var_unresolved === complaints.total_unresolved) {
        btn1 = <span>
          <IconButton
            onClick={() => { prev_btn_unresolved(login.token, login.building_id, complaints.limit_unresolved, complaints.starting_after_unresolved); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {complaints.start_var_unresolved}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_unresolved}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_unresolved}
        </span>
      }
      else if (complaints.start_var_unresolved > complaints.limit_unresolved && complaints.end_var_unresolved < complaints.total_unresolved) {
        btn1 = <span>
          <IconButton
            onClick={() => { prev_btn_unresolved(login.token, login.building_id, complaints.limit_unresolved, complaints.starting_after_unresolved); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn_unresolved(login.token, login.building_id, complaints.limit_unresolved, complaints.starting_after_unresolved); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {complaints.start_var_unresolved}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_unresolved}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_unresolved}
        </span>
      }
      else {
        btn1 =
          <span>
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn_unresolved(login.token, login.building_id, complaints.limit_unresolved, complaints.starting_after_unresolved); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {complaints.start_var_unresolved}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_unresolved}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_unresolved}
          </span>
      }
    }
    else {
      btn1 = <span>
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {complaints.start_var_unresolved}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_unresolved}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_unresolved}
      </span>
    }
    if (complaints.total_incomplete > complaints.limit_incomplete) {
      if (complaints.end_var_incomplete === complaints.total_incomplete) {
        btn2 = <span>
          <IconButton
            onClick={() => { prev_btn_incomplete(login.token, login.building_id, complaints.limit_incomplete, complaints.starting_after_incomplete); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {complaints.start_var_incomplete}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_incomplete}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_incomplete}
        </span>
      }
      else if (complaints.start_var_incomplete > complaints.limit_incomplete && complaints.end_var_incomplete < complaints.total_incomplete) {
        btn2 = <span>
          <IconButton
            onClick={() => { prev_btn_incomplete(login.token, login.building_id, complaints.limit_incomplete, complaints.starting_after_incomplete); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn_incomplete(login.token, login.building_id, complaints.limit_incomplete, complaints.starting_after_incomplete); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {complaints.start_var_incomplete}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_incomplete}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_incomplete}
        </span>
      }
      else {
        btn2 =
          <span>
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn_incomplete(login.token, login.building_id, complaints.limit_incomplete, complaints.starting_after_incomplete); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {complaints.start_var_incomplete}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_incomplete}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_incomplete}
          </span>
      }
    }
    else {
      btn2 = <span>
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {complaints.start_var_incomplete}&nbsp;&nbsp;-&nbsp;&nbsp;{complaints.end_var_incomplete}&nbsp;&nbsp;out of&nbsp;&nbsp;{complaints.total_incomplete}
      </span>
    }

    return (
      <Grid>
        <Grid item xs={12} md={12}>

        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={6}>
            <Card style={card}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="rose">
                  <h5>
                    NEW COMPLAINTS
                  </h5>
                </CardIcon>
              </CardHeader>
              <CardContent >
                <Button
                  variant="outlined"
                  onClick={() => {
                    this.props.get_all_complaints(this.props.login.token, this.props.login.building_id, this.props.complaints.limit, 0)
                    this.props.get_unresolved_complaints(this.props.login.token, this.props.login.building_id, this.props.complaints.limit_unresolved, 0)
                    this.props.get_incomplete_complaints(this.props.login.token, this.props.login.building_id, this.props.complaints.limit_incomplete, 0)

                  }}
                >
                  Refresh
                </Button>
                <div style={{ float: "right" }}>


                  <Link to="/add_complaints">
                    <IconButton>
                      <Icon>
                        add12000
                      </Icon>
                    </IconButton>
                  </Link>
                  {btn1}
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Unit</TableCell>
                      <TableCell align="left">Complaint Title</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Raised Date</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {complaints.unresolved_complaints.map(row => (
                      <TableRow key={row._id}>
                        <TableCell align="left" className="table_cells">
                          {row.unit_no}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                        {row.complaint_title}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.complaint_desc}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.complaint_raised_date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="right" className="table_cells" style={{ padding: 0 }}>
                          <IconButton
                            onClick={() => {
                              this.allotfunct(row)
                              this.setState({
                                redirect: true,
                              });
                            }}
                          >
                            <Icon style={{ fontSize: 20 }}>work</Icon>
                          </IconButton>
                          <Tooltip title="Urgent complaint">
                            <IconButton>
                              <Icon style={{ color: "red", fontSize: 20 }}>
                                warning
                              </Icon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} xs={6} style={{ paddingLeft: 4 }}>
            <Card style={card}>
              <CardHeader color="warning" stats icon>
                <CardIcon color="rose">
                  <h5>
                    ONGOING COMPLAINTS
                  </h5>
                </CardIcon>
              </CardHeader>
              <CardContent >
                <div style={{ float: "right" }}>
                  {btn2}
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Units</TableCell>
                      <TableCell align="left">Complaint Title</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left">Raised Date</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {complaints.incomplete_complaints.map(row => (
                      <TableRow key={row._id}>
                        <TableCell align="left" className="table_cells">
                          {row.unit_no}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          &nbsp;&nbsp;{row.complaint_title}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.complaint_desc}
                        </TableCell>
                        <TableCell align="left">
                          &nbsp;&nbsp; {moment(row.complaint_raised_date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="right" className="table_cells">
                          <IconButton
                            onClick={() => {
                              this.complete_funct(row)
                              this.setState({
                                completeredirect: true,
                              })
                            }}
                          >
                            <Icon>done_outline</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h5>
                  RESOLVED COMPLAINTS
                </h5>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <div style={{ float: "right" }}>
                {btn}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Unit Name</TableCell>
                    <TableCell align="left">Complaint Title</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Raised Date</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {complaints.all_complaints.map(row => (
                    <TableRow key={row._id}>
                      <TableCell align="left" className="table_cells">
                        {row.unit_name}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        &nbsp;&nbsp;{row.complaint_title}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.complaint_desc}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {moment(row.complaint_raised_date).format("DD-MM-YYYY")}
                      </TableCell>

                      <TableCell align="right" className="table_cells">
                        <Tooltip title="View Complaints Details">
                          <IconButton
                            onClick={() => {
                              this.setState({
                                view_dialog: true,
                                complaints_details: row
                              })
                            }}
                          >
                            <Icon>
                              list
                          </Icon>
                          </IconButton>
                        </Tooltip>
                        {"complaint_completed_date" in row ? "Completed" :
                          "complaint_allotted_date" in row ? "Alloted" : "Pending"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Dialog
          open={this.state.redirect}
          onClose={() => {
            this.setState({
              redirect: false
            })
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Select a Employee and date and time to Allot</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogContent>
            <Grid container>
              {this.state.complaint_images.length === 0 ? "No images uploaded for this complaint"
                :
                this.state.complaint_images.map((s) => (
                  <Grid item xs={12} md={4}>
                    <Image
                      src={s}
                      style={{ width: "98%" }}
                      width="100%"
                    />
                  </Grid>
                ))}
              <Grid item lg={12} xs={12}>
                <TextField
                  label="Select Employee"
                  type="dropdown"
                  fullWidth
                  select
                  margin="normal"
                  variant="outlined"
                  value={this.state.employee_id}
                  onChange={(event) => { this.setState({ employee_id: (event.target.value) }) }}
                  InputProps={{ classes: { input: this.props.classes.dropdown } }}
                  InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                >
                  {employee.all_employee.map((single) => (
                    <MenuItem key={single.employee_name} value={single._id}>
                      {single.employee_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item lg={12} xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Assignment Date"
                    fullWidth
                    format="dd/MM/yyyy"
                    value={this.state.date}
                    onChange={(event) => { this.setState({ date: (event) }) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  redirect: false,
                  complaint_id: "",
                  employee_id: "",
                  complaint_images: [],
                  date: new Date()
                });
              }}
              color="primary">
              Close
            </Button>
            <Button
              onClick={() => {
                validate_allot_func(login.token, login.building_id, this.state.complaint_id, this.state.employee_id, this.state.date, complaints.limit_unresolved, complaints.starting_after_unresolved);
                this.setState({
                  redirect: false,
                  complaint_id: "",
                  employee_id: "",
                  complaint_images: [],
                  date: new Date()
                })
              }}
              color="primary">
              ALLOT
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.completeredirect}
          onClose={() => {
            this.setState({
              completeredirect: false
            })
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Enter Detail to Complete Complaint</DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogContent>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <TextField
                  label="Service Type"
                  type="dropdown"
                  fullWidth
                  select
                  margin="normal"
                  variant="outlined"
                  value={this.state.selected_type}
                  onChange={(event) => { this.setState({ selected_type: (event.target.value) }) }}
                  InputProps={{ classes: { input: this.props.classes.dropdown } }}
                  InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                >
                  {this.state.service_type.map((single) => (
                    <MenuItem key={single} value={single}>
                      {single}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {this.state.selected_type === "Paid" ?
                <Grid item lg={12} xs={12}>
                  <TextField
                    label="Complaint Fee"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.complaint_fee}
                    onChange={(event) => { this.setState({ complaint_fee: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                :
                ""
              }
              <Grid item lg={12} xs={12}>
                <TextField
                  label="Service Feedback"
                  type="text"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={this.state.feedback}
                  onChange={(event) => { this.setState({ feedback: (event.target.value) }) }}
                  InputProps={{ classes: { input: this.props.classes.textfield } }}
                  InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                Complete date:
                <br />
                <Grid item lg={12} xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={this.state.complete_date}
                    onChange={(event) => { this.setState({ complete_date: (event) }) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  completeredirect: false,
                  complaint_id: "",
                  employee_id: "",
                  complaint_fee: "",
                  complete_date: new Date(),
                  selected_type: "",
                  feedback: ""
                });
              }}
              color="primary">
              Close
            </Button>
            <Button
              onClick={() => {
                validate_complete_func(login.token, login.building_id, this.state.complaint_id, this.state.employee_id, this.state.complete_date, this.state.selected_type, this.state.complaint_fee, this.state.feedback, complaints.limit_unresolved, complaints.starting_after_unresolved);
                this.setState({
                  completeredirect: false,
                  complaint_id: "",
                  employee_id: "",
                  complaint_fee: "",
                  complete_date: "",
                  selected_type: "",
                  feedback: ""
                })
              }}
              color="primary"
              autoFocus
            >
              Complete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="lg"
          open={this.state.view_dialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">COMPLAINTS COMPLETED BY</DialogTitle>
          <DialogContent>
            <Grid container justify="center">
              {this.state.complaints_details.length !== 0 &&
                (this.state.complaints_details.complaint_raised_imgs.length === 0 ?
                  "No images uploaded for this complaint" :
                  this.state.complaints_details.complaint_raised_imgs.map((s) => (
                    <Grid item xs={12} md={4}>
                      <Image
                        src={s}
                        style={{ width: "98%" }}
                        width="98%"
                      />
                    </Grid>
                  )))}
              <Grid item xs={12}>
                {
                  parseFloat(this.state.complaints_details.ratings) > 0.0 ?
                    <div>
                      Ratings:
                      <Rating
                        name="hover-feedback"
                        value={parseFloat(this.state.complaints_details.ratings)}
                        precision={0.5}
                        disabled={true}
                      />
                      <br />
                      Resident Comments: {this.state.complaints_details.resident_feedback}
                    </div>
                    :
                    "No Ratings Available for this complaint"
                }
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Employee Name</TableCell>
                      <TableCell align="left">Employee Number</TableCell>
                      <TableCell align="left">Date Alloted</TableCell>
                      <TableCell align="left">Date Completed</TableCell>
                      <TableCell align="left">Service Type</TableCell>
                      <TableCell align="left">Fees</TableCell>
                      <TableCell align="left">Feedback </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={this.state.complaints_details._id} >
                      <TableCell align="left" className="table_cells">
                        {this.state.complaints_details.employee_name}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {this.state.complaints_details.employee_contact_info}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {moment(this.state.complaints_details.complaint_allotted_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {moment(this.state.complaints_details.complaint_completed_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {this.state.complaints_details.complaint_service_type}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {this.state.complaints_details.complaint_fee}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {this.state.complaints_details.servicer_feedback}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  view_dialog: false,
                  id: "",
                  complaints_details: []
                });
              }}
              color="primary"
            >
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
        <LoaderCon />
        <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        />
      </Grid>
    )
  }
}
export default withStyles(styles)(AllComplaints);