import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { styles } from "../../styles/style";
import { Redirect }
  from "react-router-dom";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AddBuidings extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    const {
      snackbar,
      close_snack_bar,
      login,
      buildings,
      set_building_name,
      set_building_poc_name,
      set_building_poc_number,
      set_building_account_no,
      set_building_bank_name,
      set_building_ifsc_code,
      validate_add_building,
      set_building_address,
      set_building_latitude,
      set_building_longitude
    } = this.props;
    if (snackbar.response_received) {
      return <Redirect to="all_buildings" />
    }
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD BUILDINGS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container item xs={12} lg={4}>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.building_name}
                    onChange={(event) => { set_building_name(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Address"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.building_address}
                    onChange={(event) => { set_building_address(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="POC_Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.building_poc_name}
                    onChange={(event) => { set_building_poc_name(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid><Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="POC_PhoneNumber"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.building_poc_number}
                    onChange={(event) => { set_building_poc_number(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Account Number"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.building_account_no}
                    onChange={(event) => { set_building_account_no(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="IFSC Code"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.building_ifsc_code}
                    onChange={(event) => { set_building_ifsc_code(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Bank Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.building_bank_name}
                    onChange={(event) => { set_building_bank_name(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Latitude"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.latitude}
                    onChange={(event) => { set_building_latitude(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Longitude"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={buildings.longitude}
                    onChange={(event) => { set_building_longitude(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => { validate_add_building(login.token, buildings); }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddBuidings);