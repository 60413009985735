import React, { Component } from "react";
import {
  TableCell,
  Table,
  TableBody,
  TableHead,
  Avatar,
  Button,
  Icon,
  IconButton,
  Tooltip,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Checkbox,
  withStyles
} from "@material-ui/core";
import { Slide } from 'react-slideshow-image';
import ImageViewer from "../../imageViewer/imageViewer";
import { styles } from "../../../styles/style";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true
}

class ResidentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idProofsDialog: false,
      idProofDetails: [],
      image: null,
      imageToBeUploded: null,
      resident_id_proof: null,
      idProofArrayToBeUploaded: null,
      idproofUpload: false
    }
  }

  render() {
    const {
      login,
      remove_resident,
      update_resident
    } = this.props;
    return (
      <React.Fragment>
        <Table style={{ width: "95%" }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Contact no</TableCell>
              <TableCell align="left">email</TableCell>
              <TableCell align="left">type</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.resident_details.map((s) => (
              <TableRow key={s._id}>
                <TableCell align="left" className="table_cells">
                  <Avatar
                    src={s.resident_img}
                    onClick={() => {
                      this.setState({
                        image: s.resident_img
                      })
                    }}
                    style={{ width: 35, height: 35 }}
                  />
                </TableCell>
                <TableCell align="left" className="table_cells">{s.resident_name}</TableCell>
                <TableCell align="left" className="table_cells">{s.resident_contact_info}</TableCell>
                <TableCell align="left" className="table_cells">{s.resident_email}</TableCell>
                <TableCell align="left" className="table_cells">
                  {s.is_owner === true ?
                    <Button color="primary" variant="outlined" size="small">
                      Owner
                  </Button>
                    :
                    <Button color="secondary" variant="outlined" size="small">
                      Tenant
                  </Button>
                  }
                </TableCell>
                <TableCell align="right" className="table_cells">
                  {!s.resident_id_proof || (s.resident_id_proof && s.resident_id_proof.length === 0) ?
                    <IconButton
                      disabled={true}
                    >
                      <Icon className="font" >visibility</Icon>
                    </IconButton> :
                    <Tooltip title="View All Id Proofs">
                      <IconButton

                        onClick={() => { this.setState({ idProofsDialog: true, idProofDetails: s.resident_id_proof }) }}
                      >
                        <Icon className="font" >visibility</Icon>
                      </IconButton>
                    </Tooltip>
                  }
                  <IconButton
                    size="small"
                    onClick={() => {
                      this.setState({
                        editModal: true,
                        resident_id: s.resident_id,
                        resident_name: s.resident_name,
                        resident_email: s.resident_email,
                        resident_contact_info: s.resident_contact_info,
                        resident_sec_contact_info: s.resident_sec_contact_info,
                        resident_permanent_address: s.permanent_address,
                        resident_img: s.resident_img,
                        is_owner: s.is_owner,
                        is_residing: s.is_residing,
                        is_sub_resident: s.is_sub_resident,
                        idproofUpload: Array.isArray(s.resident_id_proof) ? true : false,
                        resident_id_proof: s.resident_id_proof,
                        idProofName: Array.isArray(s.resident_id_proof) && s.resident_id_proof[0] ? s.resident_id_proof[0].idproof_type : "",
                        idProofNumber: Array.isArray(s.resident_id_proof) && s.resident_id_proof[0] ? s.resident_id_proof[0].idproof_id : "",

                      })
                    }}
                  >
                    <Icon>
                      edit
                  </Icon>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      remove_resident(login.token, s.resident_id, login.building_id, this.props.id)
                    }}
                  >
                    <Icon>
                      close
                  </Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog
          open={this.state.idProofsDialog}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Id Proofs Detail</DialogTitle>
          <DialogContent>
            <Table style={{ width: "90%" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">image</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Id</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.idProofDetails.map((s) => (
                  <TableRow key={s.resident_id}>
                    <TableCell align="left">
                      <Slide {...properties} style={{ height: 210, width: 180 }}>
                        {s.idproof_image.map(img => (
                          <Avatar
                            onClick={() => {
                              this.setState({
                                image: img
                              })
                            }}
                            key={img}
                            src={String(img)}
                            style={{
                              height: 180,
                              width: 180,
                              float: "center"
                            }}
                          />
                        ))}
                      </Slide>
                    </TableCell>
                    <TableCell align="left">{s.idproof_type}</TableCell>
                    <TableCell align="left">{s.idproof_id}</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  idProofsDialog: false
                });
              }}
              color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.editModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={'paper'}
        >
          <DialogTitle id="alert-dialog-title">Edit the fields and click on Update</DialogTitle>
          <DialogContent>
            Resident Image
            <Grid item xs={12} md={12} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

              {
                this.state.showUploadBtn || this.state.resident_img === undefined || this.state.resident_img === "" ?
                  <>
                    <input type="file" onChange={(event) => { this.setState({ imageToBeUploded: event.target.files[0] }) }} />
                    <Button
                      onClick={() => { this.setState({ showUploadBtn: false, imageToBeUploded: null }) }}
                    >
                      Cancel
                    </Button>
                  </>
                  :
                  <>
                    <Avatar style={{ width: 90, height: 90 }}>
                      <img src={this.state.resident_img} style={{ width: "100%" }} />
                    </Avatar>
                    <div>
                      &nbsp;
                <Button
                        onClick={() => { this.setState({ showUploadBtn: true }) }}
                      >
                        Change
              </Button>
                    </div>
                  </>
              }


            </Grid>
            <Grid item xs={12}>
              Id Proof Images:
              {
                this.state.idproofUpload ?
                  <>
                    {
                      this.state.resident_id_proof.map(s => (
                        <Slide {...properties} style={{ height: 210, width: 180 }}>
                          {s.idproof_image.map(img => (
                            <Avatar
                              onClick={() => {
                                this.setState({
                                  image: img
                                })
                              }}
                              key={img}
                              src={String(img)}
                              style={{
                                height: 180,
                                width: 180,
                                float: "center"
                              }}
                            />
                          ))}
                        </Slide>
                      ))
                    }
                    <Button
                      onClick={() => { this.setState({ idproofUpload: false }) }}
                    >Change</Button>
                  </>

                  :
                  <div>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Id proof Type"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={this.state.idProofName}
                        onChange={(e) => {
                          this.setState({ idProofName: e.target.value })
                        }}
                        InputProps={{ classes: { input: this.props.classes.dropDown } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="Id proof Number"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={this.state.idProofNumber}
                        onChange={(e) => {
                          this.setState({ idProofNumber: e.target.value })
                        }}
                        InputProps={{ classes: { input: this.props.classes.dropDown } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      Id proof Image(s):
                      <input
                        type="file"
                        multiple
                        onChange={(event) => { this.setState({ idProofArrayToBeUploaded: event.target.files }) }}
                      />
                    </Grid>

                  </div>
              }

            </Grid>
            <Grid item xs={12}>
              <TextField

                label="Resident Name"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.resident_name}
                onChange={(e) => { this.setState({ resident_name: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                fullWidth
                margin="normal"
                type="number"
                variant="outlined"
                value={this.state.resident_contact_info}
                onChange={(e) => { this.setState({ resident_contact_info: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Secondary contact info"
                fullWidth
                margin="normal"
                type="number"
                variant="outlined"
                value={this.state.resident_sec_contact_info}
                onChange={(e) => { this.setState({ resident_sec_contact_info: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField

                label="Address"
                fullWidth
                margin="normal"
                variant="outlined"
                value={this.state.resident_permanent_address}
                onChange={(e) => { this.setState({ resident_permanent_address: e.target.value }) }}
                InputProps={{ classes: { input: this.props.classes.dropDown } }}
                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Checkbox
                checked={this.state.is_residing}
                value={this.state.is_residing}
                size="small"
                onChange={(event) => this.setState({ is_residing: !this.state.is_residing, is_owner: true, is_sub_resident: false })}
              />
                Is person residing here?
              </Grid>
            <Grid xs={12} style={{ backgroundColor: "#f2f2f2", borderRadius: 10 }}>
              {this.state.is_residing === true &&
                <Grid item lg={12} xs={12}>
                  <Checkbox
                    checked={this.state.is_owner}
                    value={this.state.is_owner}
                    size="small"
                    onChange={(event) => this.setState({ is_owner: !this.state.is_owner })}
                  />
                    Person is owner?
                  </Grid>
              }
              {this.state.is_residing === true &&
                <Grid item lg={12} xs={12}>
                  <Checkbox
                    size="small"
                    checked={this.state.is_sub_resident}
                    value={this.state.is_sub_resident}
                    onChange={(event) => this.setState({ is_sub_resident: !this.state.is_sub_resident })}
                  />
                    Person is family member?
                  </Grid>
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  editModal: false
                });
              }}
              color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                update_resident(
                  login.token,
                  login.building_id,
                  this.state.resident_id,
                  this.state.resident_name,
                  this.state.resident_contact_info,
                  this.state.resident_sec_contact_info,
                  this.state.resident_permanent_address,
                  this.state.resident_img,
                  this.props.id,
                  this.state.is_residing,
                  this.state.is_owner,
                  this.state.is_sub_resident,
                  this.state.imageToBeUploded,
                  this.state.idProofArrayToBeUploaded,
                  this.state.idProofName,
                  this.state.idProofNumber,
                  this.state.resident_id_proof,
                )
                this.setState({
                  editModal: false
                })
              }}
              color="primary">
              UPDATE
            </Button>
          </DialogActions>
        </Dialog>


        <ImageViewer
          image={this.state.image}
          close={() => {
            this.setState({
              image: null
            })
          }}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ResidentTable);
