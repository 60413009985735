import {
    SET_CURRENT_PASSWORD,
    SET_NEW_PASSWORD,
    SET_CONFIRM_NEW_PASSWORD,
    RESET_PASSWORDS
} from "../../constants/employee/change_password";
const initial_state = {
    current_password: "",
    new_password: "",
    confirm_new_password: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_CURRENT_PASSWORD:
            return state = { ...state, current_password: action.payload };
        case SET_NEW_PASSWORD:
            return state = { ...state, new_password: action.payload };
        case SET_CONFIRM_NEW_PASSWORD:
            return state = { ...state, confirm_new_password: action.payload };
        case RESET_PASSWORDS:
            return state = {
                ...state,
                current_password: "",
                new_password: "",
                confirm_new_password: ""
            };
        default:
            return state;
    }
} 