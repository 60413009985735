import {
    SET_VEHICLE_TYPE,
    SET_VEHICLE_MANUFACTURER,
    SET_VEHICLE_NAME,
    RESET_VEHICLE,
    SET_ALL_VEHICLES,
    SET_STARTING_AFTER_FOR_VEHICLES,
    RESET_DEFAULT_VEHICLE,
    SET_ONLY_VEHICLES,
    SELECT_VEHICLE,
    SET_VEHICLE_NUMBER
} from "../../constants/vehicles/vehicles_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function set_vehicle_type(payload) {
    return {
        type: SET_VEHICLE_TYPE,
        payload: payload,
    }
}
export function set_vehicle_manufacturer(payload) {
    return {
        type: SET_VEHICLE_MANUFACTURER,
        payload: payload
    }
}
export function set_vehicle_number(payload) {
    return {
        type: SET_VEHICLE_NUMBER,
        payload: payload
    }
}
export function set_vehicle_name(payload) {
    return {
        type: SET_VEHICLE_NAME,
        payload: payload
    }
}
export function validate_add_vehicle(token, vehicles) {
    return (dispatch) => {
        if (vehicles.vehicle_type === "") {
            dispatch(set_snack_bar(true, "Please enter vehicle Type "));
        } else if (vehicles.vehicle_manufacturer === "") {
            dispatch(set_snack_bar(true, "Please enter Vehicle Manufacturer"));
        }
        else if (vehicles.vehicle_name === "") {
            dispatch(set_snack_bar(true, "Please select a Vehicle Name"));
        } else {
            dispatch(add_vehicle(token, vehicles))
        };
    }
}
export function add_vehicle(token, vehicles) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/add_vehicle", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                vehicle_type: vehicles.vehicle_type,
                vehicle_manufacturer: vehicles.vehicle_manufacturer,
                vehicle_name: vehicles.vehicle_name,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Vehicle Added Successfully"));
                    dispatch(set_reset_type())
                    dispatch(get_all_vehicles(token, vehicles.limit, vehicles.starting_after))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Vehicle could not be added"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    }
}
export function set_reset_type() {
    return {
        type: RESET_VEHICLE,
    }
}
export function reset_vehicles() {
    return {
        type: RESET_DEFAULT_VEHICLE
    }
}
export function get_all_vehicles(token, limit, starting_after) {
    starting_after = 0;
    return (dispatch) => {
        dispatch(set_loader());
        dispatch(set_starting_after(starting_after));
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_vehicles", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_vehicles(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_vehicles_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message))
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_all_vehicles_updated(token, limit, starting_after) {

    return (dispatch) => {
        dispatch(set_loader());
        dispatch(set_starting_after(starting_after));
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_vehicles", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_vehicles(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_vehicles_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    if (responseJson.message === "No Vehicles Found") {
                        dispatch(set_all_vehicles({ result: [], total: 0 }, 0, 0))
                    }
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn(token, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_loader());
        dispatch(set_starting_after(starting_after));
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_vehicles", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                limit: limit,
                starting_after: starting_after,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_vehicles(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_vehicles_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_starting_after(starting_after) {
    return {
        type: SET_STARTING_AFTER_FOR_VEHICLES,
        payload: starting_after,
    }
}
export function prev_btn(token, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_loader());
        dispatch(set_starting_after(starting_after));
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_vehicles", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                limit: limit,
                starting_after: starting_after,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_vehicles(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_vehicles_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function select_vehicle(vehicle_id, vehicle_name, vehicle_type) {
    var payload = {
        id: vehicle_id,
        name: vehicle_name,
        type: vehicle_type
    }
    return {
        type: SELECT_VEHICLE,
        payload: payload,
    }
}
export function toggleVehicleCheckbox(obj, array) {
    var index = array.indexOf(obj);
    array[index].checked = !array[index].checked;
    return {
        type: SET_ONLY_VEHICLES,
        payload: array,
    }
}
export function set_all_vehicles(responseJson, limit, starting_after) {
    var obj;
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        for (var i = 0; i < responseJson.result.length; i++) {
            responseJson.result[i].checked = false;
        }
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
    }
    return {
        type: SET_ALL_VEHICLES,
        payload: obj,
    }
}
export function set_all_vehicles_pagination(responseJson, limit, starting_after) {
    for (var i = 0; i < responseJson.result.length; i++) {
        responseJson.result[i].checked = false;
    }
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
    return {
        type: SET_ALL_VEHICLES,
        payload: obj,
    }
}
export function update_single_vehicle(token, vehicle_id, name, manufacturer, type, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/update_vehicle ", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                vehicle_id: vehicle_id,
                vehicle_type: type,
                vehicle_manufacturer: manufacturer,
                vehicle_name: name,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Vehicle Updated Succcessfully"));
                    dispatch(get_all_vehicles_updated(token, limit, starting_after))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Vehicle Could Not Be Updated"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function delete_single_vehicle(token, vehicles, limit, starting_after) {
    var vehicle_id = []
    for (var i = 0; i < vehicles.length; i++) {
        if (vehicles[i].checked === true) {
            vehicle_id.push(vehicles[i]._id)
        }
    }
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/delete_vehicle", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                vehicle_id: JSON.stringify(vehicle_id)
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(get_all_vehicles_updated(token, limit, starting_after))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Vehicle Could Not Be Deleted"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}