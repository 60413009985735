import React from "react";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
class ViewMaintenanceType extends React.Component {
  componentDidMount()
  {
    this.props.getAllMaintenaceType(this.props.login.token)
  }
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    const {
      getAllMaintenaceType,
      login,
      snackbar,
      close_snack_bar,
      maintenance,
    } = this.props;
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW MAINTENANCE TYPE
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item xs={12} >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="right">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maintenance.all_maintenance.map(row => (
                      <TableRow key={row._id}>
                        <TableCell align="left" className="table_cells">
                          {row.type}
                        </TableCell>
                        <TableCell align="right" className="table_cells">
                        {row.desc}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          {/* <Dialog
            open={this.state.redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And To Add</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={id_proofs.proof_name}
                    onChange={(event) => { set_proof_name(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                  <br /><br /><br />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <br /><br /><br />
              <Button
                onClick={() => {
                  this.setState({
                    redirect: false,
                  });
                }}
                color="primary">
                Close
              </Button>
              {id_proofs.proof_name === "" ?
                <Button
                  disabled={true}
                  color="primary"
                  autoFocus
                >
                  ADD
                </Button>
                :
                <Button
                  onClick={() => {
                    validate_proof_func(login.token, id_proofs)
                    this.setState({
                      redirect: false
                    })
                  }}
                  color="primary">
                  ADD
              </Button>
              }
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.proof_name}
                    onChange={(event) => { this.setState({ proof_name: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                  <br /><br /><br />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <br /><br /><br />
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_update_id_proof(login.token, this.state.proof_id, this.state.proof_name)
                  this.setState({
                    redirect: false,
                    editredirect: false
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.delete_redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete this ID?</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    delete_redirect: false,
                    id_proof_id: ""
                  });
                }}
                color="primary">
                NO
                </Button>
              <Button
                onClick={() => {
                  delete_id_proofs(login.token, id_proofs.all_id_proofs)
                  this.setState({
                    delete_redirect: false,
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog> */}
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(ViewMaintenanceType);