import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import { Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem"
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";

import Icon from "@material-ui/core/Icon";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AddHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUnitId: [],
      selectedUnitIdForHelper: ""
    }
  }
  componentDidMount() {
    this.props.reset_all();
    this.props.get_all_id_proofs(this.props.login.token);
    this.props.get_same_units(this.props.login.token, this.props.login.building_id);
  }
  formatData = (data) => {
    var view =
      <TreeView
        defaultCollapseIcon={<Icon className="drawer_icons">keyboard_arrow_right</Icon>}
        defaultExpandIcon={<Icon className="drawer_icons">keyboard_arrow_down</Icon>}
      >
        {this.getParent(data)}
      </TreeView>
    return view;
  }
  getParent = (data) => {
    return data.map((s, index) => (
      s.sub_units[0] && s.sub_units[0].sub_units.length > 0 ?
        <TreeItem key={s._id} nodeId={s._id} label={s.unit_name}>
          {this.getParent(s.sub_units)}
        </TreeItem>
        :
        <div key={s._id} style={{ backgroundColor: "#f2f2f2" }}>
          <Button
            onClick={() => {
              this.setState({ selectedUnitId: s.sub_units })
            }}
          >
            {s.unit_name}
          </Button>
        </div>
    ))
  }
  render() {
    const {
      helper,
      snackbar,
      close_snack_bar,
      login,
      id_proofs,
      validate_helper_func,
      set_helper_contact_info,
      set_helper_secondary_contact_info,
      set_helper_permanent_address,
      set_helper_id_proof_type,
      set_helper_id_proof_id,
      set_helper_name,
      set_helper_image,
      set_helper_service,
      set_is_private,
      set_id_proof_image
    } = this.props;
    return (
      <Grid container item xs={12} lg={12} spacing={8}  >
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD HELPER
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container justify="space-between">
                <Grid container item lg={4}>
                  <Grid item xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={helper.helper_name}
                      onChange={(event) => { set_helper_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    Image : <input type="file"
                      onChange={(event) => { set_helper_image(event); }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Contact_Info"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={helper.helper_contact_info}
                      onChange={(event) => { set_helper_contact_info(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Secondary contact info (Optional)"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={helper.helper_sec_contact_info}
                      onChange={(event) => { set_helper_secondary_contact_info(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Permanent Address"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={helper.helper_permanent_address}
                      onChange={(event) => { set_helper_permanent_address(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="ID Proof Type"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={helper.helper_id_proof_type}
                      onChange={(event) => { set_helper_id_proof_type(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {id_proofs.all_id_proofs.map((single) => (
                        <MenuItem key={single.proof_name} value={single.proof_name}>
                          {single.proof_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {helper.helper_id_proof_type !== "" ?
                    <Grid item lg={12} xs={12}>
                      <TextField
                        id="outlined-email-input"
                        label="ID Proof Number"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={helper.resident_id_proof_id}
                        onChange={(event) => { set_helper_id_proof_id(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                      Image :
                    <input type="file" multiple
                        onChange={(event) => { set_id_proof_image(event); }}
                      />
                    </Grid>
                    : ""}
                  <Grid item xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Service"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={helper.helper_service}
                      onChange={(event) => { set_helper_service(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <br />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        validate_helper_func(login.token, helper, login.building_id);
                        
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignContent={"flex-start"} item xs={6} md={6}>
                  <Grid item lg={12} xs={12}>
                    <Checkbox
                      checked={helper.is_private}
                      value={helper.is_private}
                      size="small"
                      onChange={(event) => { set_is_private(event.target.value) }}
                    />
                    Is Private
                </Grid>
                  {helper.is_private &&
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>

                        {this.formatData(this.props.units.unit_details)}

                      </Grid>
                      {this.state.selectedUnitId.length > 0 &&
                        <Grid item xs={12} md={12}>
                          <TextField
                            variant="outlined"
                            label="Select Unit"
                            select
                            fullWidth
                            value={helper.unit_id_for_private_helper}
                            onChange={(e) => {
                              this.props.setUnitIdForHelper(e.target.value)
                              // this.setState({ selectedUnitId: "" })
                            }}
                          >
                            {this.state.selectedUnitId.map(s => (
                              <MenuItem value={s._id} key={s._id}>{s.unit_name}</MenuItem>
                            ))}

                          </TextField>
                        </Grid>
                      }
                    </Grid>

                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddHelper);