import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle"
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { DialogContent, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AmenitiesBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unapprove_redirect: false,
      id: "",
      comment: "",
      guest_list: [],
      guest_view_dialog: false
    }
  }
  componentDidMount() {
    this.props.get_all_amenities(this.props.login.token, this.props.login.building_id)
    this.props.get_all_amenities_booking(this.props.login.token, this.props.login.building_id, this.props.amenities_booking.limit, this.props.amenities_booking.starting_after, this.props.amenities_booking.start_date, this.props.amenities_booking.end_date)
  }
  render() {
    const {
      amenities_booking,
      snackbar,
      prev_btn_unapproved,
      next_btn_unapproved,
      unapprove_booking,
      amenities,
      close_snack_bar,
      set_start_date,
      set_end_date,
      login,
      set_selected_amenity,
      next_btn,
      prev_btn,
      show_amenity_booking,
      prev_btn_single,
      next_btn_single,
      approve_booking,
    } = this.props;
    var btn;
    var btn1;
    if (amenities_booking.total > amenities_booking.limit) {
      if (amenities_booking.end_var === amenities_booking.total) {
        btn = <span >
          {amenities_booking.check === false ?
            <IconButton
              onClick={() => { prev_btn(amenities_booking(login.token, login.building_id, amenities_booking.limit, amenities_booking.starting_after)); }}
            >
              <Icon>navigate_before</Icon>
            </IconButton> :
            <IconButton
              onClick={() => { prev_btn_single(amenities_booking(login.token, amenities_booking.selected_amenity, login.building_id, amenities_booking.limit, amenities_booking.starting_after)); }}
            >
              <Icon>navigate_before</Icon>
            </IconButton>
          }
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {amenities_booking.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total}
        </span>
      }
      else if (amenities_booking.start_var > amenities_booking.limit && amenities_booking.end_var < amenities_booking.total) {
        btn = <span >
          {amenities_booking.check === false ?
            <IconButton
              onClick={() => { prev_btn(login.token, login.building_id, amenities_booking.limit, amenities_booking.starting_after); }}
            >
              <Icon>navigate_before</Icon>
            </IconButton> :
            <IconButton
              onClick={() => { prev_btn_single(login.token, amenities_booking.selected_amenity, login.building_id, amenities_booking.limit, amenities_booking.starting_after); }}
            >
              <Icon>navigate_before</Icon>
            </IconButton>}
          {amenities_booking.check === false ?
            <IconButton
              onClick={() => { next_btn(login.token, login.building_id, amenities_booking.limit, amenities_booking.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton> :
            <IconButton
              onClick={() => { next_btn_single(login.token, amenities_booking.selected_amenity, login.building_id, amenities_booking.limit, amenities_booking.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>}
          {amenities_booking.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            {amenities_booking.check === false ?
              <IconButton
                onClick={() => { next_btn(login.token, login.building_id, amenities_booking.limit, amenities_booking.starting_after); }}
              >
                <Icon>navigate_next</Icon>
              </IconButton> :
              <IconButton
                onClick={() => { next_btn_single(login.token, amenities_booking.selected_amenity, login.building_id, amenities_booking.limit, amenities_booking.starting_after); }}
              >
                <Icon>navigate_next</Icon>
              </IconButton>}
            {amenities_booking.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {amenities_booking.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total}
      </span>
    }
    if (amenities_booking.total_unapproved > amenities_booking.limit_unapproved) {
      if (amenities_booking.end_var_unapproved === amenities_booking.total_unapproved) {
        btn1 = <span >
          <IconButton
            onClick={() => { prev_btn_unapproved(login.token, login.building_id, amenities_booking.limit_unapproved, amenities_booking.starting_after_unapproved); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {amenities_booking.start_var_unapproved}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var_unapproved}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total_unapproved}
        </span>
      }
      else if (amenities_booking.start_var_unapproved > amenities_booking.limit_unapproved && amenities_booking.end_var_unapproved < amenities_booking.total_unapproved) {
        btn1 = <span >
          <IconButton
            onClick={() => { prev_btn_unapproved(login.token, login.building_id, amenities_booking.limit_unapproved, amenities_booking.starting_after_unapproved); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn_unapproved(login.token, amenities_booking.building_id, amenities_booking.limit_unapproved, amenities_booking.starting_after_unapproved); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {amenities_booking.start_var_unapproved}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var_unapproved}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total_unapproved}
        </span>
      }
      else {
        btn1 =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn_unapproved(amenities_booking.token, login.building_id, amenities_booking.limit_unapproved, amenities_booking.starting_after_unapproved); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {amenities_booking.start_var_unapproved}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var_unapproved}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total_unapproved}
          </span>
      }
    }
    else {
      btn1 = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {amenities_booking.start_var_unapproved}&nbsp;&nbsp;-&nbsp;&nbsp;{amenities_booking.end_var_unapproved}&nbsp;&nbsp;out of&nbsp;&nbsp;{amenities_booking.total_unapproved}
      </span>
    }
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  PENDING BOOKINGS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>


              <Link
                style={{ textDecoration: "none" }}
                to="add_amenities_booking"
              >
                <IconButton>
                  <Icon>add</Icon>
                </IconButton>
              </Link>
              <Button
                // className="refreshBtn1"
                variant="outlined"
                onClick={() => {
                  this.props.get_all_amenities(this.props.login.token, this.props.login.building_id)
                  this.props.get_all_amenities_booking(this.props.login.token, this.props.login.building_id, this.props.amenities_booking.limit, 0, this.props.amenities_booking.start_date, this.props.amenities_booking.end_date)
                }}
              >
                Refresh
             </Button>
              <div style={{ float: "right" }}>
                {btn1}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={1} align="left">Date</TableCell>
                    <TableCell colSpan={2} align="left">Time</TableCell>
                    <TableCell colSpan={2} align="left">Amenity Name</TableCell>
                    <TableCell colSpan={1} align="left">Unit Number</TableCell>
                    <TableCell colSpan={1} align="left">Resident Name</TableCell>
                    <TableCell colSpan={1} align="left">Number Of Guests</TableCell>
                    <TableCell colSpan={4} align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {amenities_booking.all_unapproved_booking.map(row => (
                    <TableRow key={row._id} >
                      <TableCell align="left" colSpan={1} className="table_cells">
                        {moment(row.booking_date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="left" colSpan={2} className="table_cells">
                        {row.starting_time}{"-"}{row.ending_time}
                      </TableCell>
                      <TableCell align="left" colSpan={2} className="table_cells">
                        {row.amenity_name}
                      </TableCell>
                      <TableCell align="left" colSpan={1} className="table_cells">
                        {row.unit_no}
                      </TableCell>
                      <TableCell align="left" colSpan={1} className="table_cells">
                        &nbsp;&nbsp;{row.resident_name}
                      </TableCell>
                      <TableCell align="center" colSpan={1} className="table_cells">
                        {row.guest_info.length}
                        <Tooltip title="Click to View Guest List">
                          <IconButton
                            onClick={() => {
                              this.setState({
                                guest_view_dialog: true,
                                guest_list: row.guest_info
                              })
                            }}
                          >
                            <Icon>
                              list
                          </Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" colSpan={4} className="table_cells" style={{ padding: 0 }}>
                        {row.status === false &&
                          <Button variant="outlined" color="primary" size="small" style={{ marginRight: 2 }}
                            onClick={() => { approve_booking(login.token, row._id, login.building_id, amenities_booking.limit_unapproved, amenities_booking.starting_after_unapproved, amenities_booking) }}
                          >
                            Approve
                          </Button>
                        }
                        {row.status === false &&
                          <Button variant="outlined" color="secondary" size="small"
                            onClick={() => { this.setState({ unapprove_redirect: true, id: row._id }) }}
                          >
                            cancel
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  BOOKINGS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid container justify="center">
                <Grid item lg={3} style={{ paddingRight: 30 }}>
                  <TextField
                    id="outlined-email-input"
                    label="Select Amenity"
                    type="dropdown"
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={amenities_booking.selected_amenity}
                    onChange={(event) => { set_selected_amenity(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    <MenuItem key={null} value="All" >
                      All
                    </MenuItem>
                    {amenities.all_amenities.map((single) => (
                      <MenuItem key={single.amenity_name} value={single._id}>
                        {single.amenity_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item lg={3} style={{ paddingRight: 30 }}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog1"
                      label="Start Date"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={amenities_booking.start_date}
                      onChange={(event) => { set_start_date(event); }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} style={{ paddingRight: 30 }}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog2"
                      label="End Date"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={amenities_booking.end_date}
                      onChange={(event) => { set_end_date(event); }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <Grid item lg={3}>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ position: "relative", top: 20 }}
                    onClick={() => { show_amenity_booking(login.token, login.building_id, amenities_booking); }}
                  >
                    Submit
                    </Button>
                </Grid>
              </Grid>
              <div style={{ float: "right" }}>
                {btn}
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Booking Date</TableCell>
                    <TableCell align="left">Time</TableCell>
                    <TableCell align="left">Amenity Name</TableCell>
                    <TableCell align="left">Unit Number</TableCell>
                    <TableCell align="left">Resident Name</TableCell>
                    <TableCell align="left">Number of Guests</TableCell>
                    <TableCell align="left">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {amenities_booking.all_amenities_booking.map(row => (
                    <TableRow key={row._id} >
                      <TableCell align="left" className="table_cells">
                        {moment(row.booking_date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.starting_time}{"-"}{row.ending_time}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.amenity_name}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.unit_no}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.resident_name}
                      </TableCell>
                      <TableCell align="center" className="table_cells">
                        {row.guest_info.length}
                        &nbsp;&nbsp;
                        <Tooltip title="Click to View Guest List">
                          <IconButton
                            onClick={() => {
                              this.setState({
                                guest_view_dialog: true,
                                guest_list: row.guest_info
                              })
                            }}
                          >
                            <Icon>
                              list
                          </Icon>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {row.status === true && row.approved === true &&
                          <Button variant="outlined" size="small">Approved</Button>
                        }
                        {(row.status === false && row.approved === false) &&
                          <Button variant="outlined" size="small">Pending</Button>
                        }
                        {(row.status === true && row.approved === false) &&
                          <Button variant="outlined" size="small">Disapproved</Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.unapprove_redirect}
            onClose={() => {
              this.setState({
                unapprove_redirect: false
              })
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Disapprove?</DialogTitle>
            <DialogContent>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Reason To Disapprove"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.comment}
                    onChange={(event) => { this.setState({ comment: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    unapprove_redirect: false,
                    id: ""
                  });
                }}
                color="primary">
                NO
                </Button>
              <Button
                onClick={() => {
                  unapprove_booking(login.token, this.state.id, login.building_id, this.state.comment, amenities_booking.limit_unapproved, amenities_booking.starting_after_unapproved, amenities_booking)
                  this.setState({
                    unapprove_redirect: false,
                    id: ""
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.guest_view_dialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">GUEST LIST</DialogTitle>
            <DialogContent>
              {this.state.guest_list.length > 0 ?
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Guest Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.guest_list.map((s) => (
                          <TableRow key={s} >
                            <TableCell align="center" className="table_cells">
                              {s}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid> :
                "No GUEST FOUND"
              }
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    guest_view_dialog: false,
                    id: ""
                  });
                }}
                color="primary">
                CLOSE
                </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid >
    )
  }
}
export default withStyles(styles)(AmenitiesBooking);