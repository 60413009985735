import {
    SET_DASHBOARD_DETAILS
} from "../../constants/dashboard/dashboard_const";
const initial_state = {
    dashboard_details: [],
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_DASHBOARD_DETAILS:
            return state = { ...state, dashboard_details: action.payload };
        default:
            return state;
    }
}
