import React, { Component } from "react";
import { connect } from "react-redux";
import ViewAllSalary from "../../components/salary/viewSalary";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    getAllBankAccounts
} from "../../actions/bankAccounts/bankAccountAction";
import {
    getAllSalaries,
    reset,
    getPendingWorkers,
    setSalaryArray,
    addSalary,
    dialogAction,
    setAllPendingWorkers,
} from "../../actions/salary/salaryAction";

export class ViewAllSalaryCont extends Component {
    render() {
        return (
            <ViewAllSalary {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        bank: store.bank,
        salary: store.salary
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        setAllPendingWorkers: (data) => {
            dispatch(setAllPendingWorkers(data));
        },
        getAllBankAccounts: (token, building_id) => {
            dispatch(getAllBankAccounts(token, building_id));
        },
        dialogAction: (value) => {
            dispatch(dialogAction(value));
        },
        reset: () => {
            dispatch(reset());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        getAllSalaries: (token, building_id, limit, starting_after, startDate, endDate) => {
            dispatch(getAllSalaries(token, building_id, limit, starting_after, startDate, endDate));
        },
        getPendingWorkers: (token, building_id, month, year) => {
            dispatch(getPendingWorkers(token, building_id, month, year));
        },
        addSalary: (token, building_id, state, salary) => {
            dispatch(addSalary(token, building_id, state, salary));
        },
        setSalaryArray: (arr) => {
            dispatch(setSalaryArray(arr));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAllSalaryCont);