import {
    SET_ALL_BANK_ACCOUNTS,
} from "../../constants/bankAccounts/bankAccountsConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
// import { RESET } from "../../constants/ActionTypes";
export function getAllBankAccounts(token, building_id) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/view_bank_accounts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(setAllBankAccounts(responseJson.result));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function setAllBankAccounts(data) {
    var arr = data
    for (var i = 0; i < arr.length; i++) {
        arr[i].bankAccountMix = arr[i].bank_name + " *****" + arr[i].account_no.substring(arr[i].account_no.length , arr[i].account_no.length - 4)
    }
    return {
        type: SET_ALL_BANK_ACCOUNTS,
        payload: arr
    };
}
export function addBankAccounts(token, building_id, bankName, accountNumber, ifscCode) {
    var obj = JSON.stringify({
        bank_name: bankName,
        account_no: accountNumber,
        ifsc_code: ifscCode,
    })
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/add_bank_account", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
                bank_account: obj
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(getAllBankAccounts(token, building_id));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function deleteSingleAccount(token, building_id, id) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/delete_bank_account", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
                bank_account_id: id
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(getAllBankAccounts(token, building_id));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
