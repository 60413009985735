import React, { Component } from "react";
import { connect } from "react-redux";
import AddVehicles from "../../components/vehicles/add_vehicles";
import {
    set_vehicle_type,
    set_vehicle_manufacturer,
    set_vehicle_name,
    validate_add_vehicle,
} from "../../actions/vehicles/vehicles_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class AddVehiclesCont extends Component {
    render() {
        return (
            <AddVehicles
                onClose={this.props.onClose}
                {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        vehicles: store.vehicles,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        set_vehicle_type: (payload) => {
            dispatch(set_vehicle_type(payload))
        },
        set_vehicle_manufacturer: (payload) => {
            dispatch(set_vehicle_manufacturer(payload))
        },
        set_vehicle_name: (payload) => {
            dispatch(set_vehicle_name(payload))
        },
        validate_add_vehicle: (token, vehicles) => {
            dispatch(validate_add_vehicle(token, vehicles))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddVehiclesCont);