import { LIMIT } from "../../constants/ActionTypes";
import {
    SET_ALL_SHAREWALL,
    SET_STARTING_AFTER,
    SET_IMAGE_URL,
    SET_POSTED_ON,
    SET_COMMENTS,
    SET_SHAREWALL_IMAGES,
    RESET_DEFAULT_SHAREWALL,
    SET_ONLY_SHAREWALL
} from "../../constants/sharewall/sharewall_const";
import moment from "moment";
const initial_state = {
    all_sharewall: [],
    comments: "",
    posted_on: moment(new Date()).format("YYYY-MM-DD"),
    starting_after: 0,
    start_var: 0,
    end_var: 0,
    check: false,
    limit: LIMIT,
    total: 0,
    image_url: [],
    img: [],
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_SHAREWALL:
            return state = { ...state, all_sharewall: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload, }
        case SET_COMMENTS:
            return state = { ...state, comments: action.payload, }
        case SET_POSTED_ON:
            return state = { ...state, posted_on: action.payload, }
        case SET_SHAREWALL_IMAGES:
            return state = { ...state, img: action.payload, }
        case SET_IMAGE_URL:
            return state = { ...state, image_url: action.payload, }
        case SET_ONLY_SHAREWALL:
            return state = { ...state, all_sharewall: action.payload }
        case RESET_DEFAULT_SHAREWALL:
            return state = {
                ...state,
                comments: "",
                posted_on: moment(new Date()).format("YYYY-MM-DD"),
                image_url: [],
            }
        default:
            return state;
    }
}
