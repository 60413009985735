import {
  SET_STARTING_AFTER,
  SET_ALL_HELPERS,
  RESET_ALL,
  SET_HELPER_EMAIL,
  SET_HELPER_CONTACT_INFO,
  SET_HELPER_SECONDARY_CONTACT_INFO,
  SET_HELPER_PERMANENT_ADDRESS,
  SET_HELPER_ID_PROOF_TYPE,
  SET_HELPER_ID_PROOF_ID,
  SET_HELPER_NAME,
  SET_HELPER_IMAGE,
  SET_HELPER_SERVICE,
  SET_ONLY_HELPERS,
  SELECT_HELPER,
  SET_IS_PRIVATE,
  SET_ID_PROOF_IMAGE,
  SET_HELPER_UNIT,
  SET_UNIT_ID_FOR_PRIVATE_HELPER
} from "../../constants/helper/helper_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";

export function setUnitIdForHelper(id) {
  return {
    type: SET_UNIT_ID_FOR_PRIVATE_HELPER,
    payload: id,
  }
}
export function get_all_helpers(token, building_id, limit, starting_after) {
  starting_after = 0;
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_helpers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_helpers(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_helpers_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_same_helpers(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_helpers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_helpers(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_helpers_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          if (responseJson.message === "No Helpers Found") {
            dispatch(set_all_helpers({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_helpers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_helpers(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_helpers_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_helpers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_helpers(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_helpers_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_starting_after(payload) {
  return {
    type: SET_STARTING_AFTER,
    payload: payload
  }
}
export function toggleCheckbox(obj, array) {
  var index = array.indexOf(obj);
  array[index].checked = !array[index].checked;
  return {
    type: SET_ONLY_HELPERS,
    payload: array,
  }
}
export function select_helper(helper_id) {
  return {
    type: SELECT_HELPER,
    payload: helper_id,
  }
}
export function set_id_proof_image(payload) {
  return {
    type: SET_ID_PROOF_IMAGE,
    payload: payload.target.files,
  }
}
export function set_all_helpers(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    for (var i = 0; i < responseJson.result.length; i++) {
      responseJson.result[i].checked = false;
    }
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_ALL_HELPERS,
    payload: obj,
  }
}
export function set_all_helpers_pagination(responseJson, limit, starting_after) {
  for (var i = 0; i < responseJson.result.length; i++) {
    responseJson.result[i].checked = false;
  }
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  return {
    type: SET_ALL_HELPERS,
    payload: obj,
  }
}
export function validate_helper_func(token, helper, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    if (helper.helper_name === "") {
      dispatch(set_snack_bar(true, "Please Enter Helper Name"));
      dispatch(unset_loader())
    }
    else if (helper.helper_contact_info === "") {
      dispatch(set_snack_bar(true, "Please Enter Contact Information"));
      dispatch(unset_loader())
    }
    else if (helper.helper_permanent_address === "") {
      dispatch(set_snack_bar(true, "Please Enter Permanent Address"));
      dispatch(unset_loader())
    }
    else if (helper.helper_id_proof_id === "") {
      dispatch(set_snack_bar(true, "Please Enter Your Id Proof Number"));
      dispatch(unset_loader())
    }
    else if (helper.image === "") {
      dispatch(set_snack_bar(true, "Please Upload Your ID Proof Image"));
      dispatch(unset_loader())
    }
    else if (helper.helper_image === "") {
      dispatch(set_snack_bar(true, "Please Upload Your Image"));
      dispatch(unset_loader())
    }
    else if (helper.helper_service === "") {
      dispatch(set_snack_bar(true, "Please Enter Helper service"));
      dispatch(unset_loader())
    }
    else if (helper.is_private && helper.unit_id_for_private_helper === "") {
      dispatch(set_snack_bar(true, "Please Assign the private helper to a unit"));
      dispatch(unset_loader())
    }
    else {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + helper.helper_name + ".png").put(helper.helper_image);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch(add_image(token, helper, building_id, downloadURL, 0, []));
        });
      });
    }
  };
}
export function add_image(token, helper, building_id, url, intial, array) {
  var arr = array
  return (dispatch) => {
    if (arr.length !== helper.image.length) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + helper.helper_name + intial + ".png").put(helper.image[intial]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          arr.push(downloadURL)
          dispatch(add_image(token, helper, building_id, url, parseInt(intial) + 1, arr));
        });
      });
    }
    if (helper.image.length === arr.length) {
      dispatch(add_helper(token, helper, url, building_id, arr))
    }
  }
}
export function add_helper(token, helper, downloadURL, building_id, idproof_image) {
  return (dispatch) => {
    var helper_id_proof = [{ "idproof_id": helper.helper_id_proof_id, "idproof_type": helper.helper_id_proof_type, "idproof_image": idproof_image }];
    helper_id_proof = JSON.stringify(helper_id_proof);
    return fetch(UNIVERSAL.BASEURL + "v1/add_helper", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        helper_name: helper.helper_name,
        helper_img: downloadURL,
        helper_contact_info: helper.helper_contact_info,
        helper_sec_contact_info: helper.helper_sec_contact_info,
        helper_permanent_address: helper.helper_permanent_address,
        helper_id_proofs: helper_id_proof,
        helper_service: helper.helper_service,
        is_private: helper.is_private,
        unit_id: helper.unit_id_for_private_helper
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.message === "New Helper Added Successfully") {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(reset_all())
          }
          else if (responseJson.message === "User Already Exist") {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
        }
        else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function reset_all() {
  return {
    type: RESET_ALL,
  }
}
export function set_helper_email(payload) {
  return {
    type: SET_HELPER_EMAIL,
    payload: payload
  }
}
export function set_helper_contact_info(payload) {
  return {
    type: SET_HELPER_CONTACT_INFO,
    payload: payload
  }
}
export function set_helper_secondary_contact_info(payload) {
  return {
    type: SET_HELPER_SECONDARY_CONTACT_INFO,
    payload: payload
  }
}
export function set_helper_permanent_address(payload) {
  return {
    type: SET_HELPER_PERMANENT_ADDRESS,
    payload: payload
  }
}
export function set_helper_id_proof_type(payload) {
  return {
    type: SET_HELPER_ID_PROOF_TYPE,
    payload: payload
  }
}
export function set_helper_id_proof_id(payload) {
  return {
    type: SET_HELPER_ID_PROOF_ID,
    payload: payload
  }
}
export function set_helper_name(payload) {
  return {
    type: SET_HELPER_NAME,
    payload: payload
  }
}
export function set_helper_image(payload) {
  return {
    type: SET_HELPER_IMAGE,
    payload: payload.target.files[0]
  }
}
export function set_helper_service(payload) {
  return {
    type: SET_HELPER_SERVICE,
    payload: payload,
  }
}
export function set_is_private(payload) {
  var payloads;
  if (payload === "false") {
    payloads = true;
  }
  else {
    payloads = false;
  }
  return {
    type: SET_IS_PRIVATE,
    payload: payloads,
  }
}
export function validate_helper_edit_func(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, helper_img, helper_img_old, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, idproof_image, helper) {
  return (dispatch) => {
    dispatch(set_loader());
    if (helper_name === "") {
      dispatch(set_snack_bar(true, "Please enter Resident Name"));
      dispatch(unset_loader())
    }
    else if (helper_contact_info === "") {
      dispatch(set_snack_bar(true, "Please enter Contact Information"));
      dispatch(unset_loader())
    }
    else if (helper_permanent_address === "") {
      dispatch(set_snack_bar(true, "Please enter Permanent Address"));
      dispatch(unset_loader())
    }
    else if (helper_img === "") {
      if (idproof_image === "") {
        dispatch(update_helper_info(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, helper_img_old, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, idproof_image));
      }
      else {
        dispatch(update_image(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, helper_img_old, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, helper, 0, []));
      }
    } else {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + helper_name + ".png").put(helper_img);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          if (idproof_image === "") {
            dispatch(update_helper_info(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, downloadURL, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, idproof_image));
          }
          else {
            dispatch(update_image(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, downloadURL, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, helper, 0, []));
          }
        });
      });
    }
  };
}
export function update_image(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, url, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, helper, intial, array) {
  var arr = array
  return (dispatch) => {
    if (arr.length !== helper.image.length) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + helper.helper_name + intial + ".png").put(helper.image[intial]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          arr.push(downloadURL)
          dispatch(update_image(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, url, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, helper, parseInt(intial) + 1, arr));
        });
      });
    }
    if (helper.image.length === arr.length) {
      dispatch(update_helper_info(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, url, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, arr))
    }
  }
}
export function update_helper_info(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, helper_img, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, idproof_image) {
  var helper_id_proof = [{ "idproof_id": helper_id_proof_id, "idproof_type": helper_id_proof_type, "idproof_image": idproof_image }];
  helper_id_proof = JSON.stringify(helper_id_proof);
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/update_helper", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        helper_id: helper_id,
        building_id: building_id,
        helper_name: helper_name,
        helper_img: helper_img,
        helper_email: helper_email,
        helper_contact_info: helper_contact_info,
        helper_sec_contact_info: helper_sec_contact_info,
        helper_permanent_address: helper_permanent_address,
        helper_id_proofs: helper_id_proof,
        helper_service: helper_service,
        is_private: is_private
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Helper Updated Succcessfully"));
          dispatch(get_same_helpers(token, building_id, limit, starting_after));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Helper Could Not Be Updated"));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function delete_helper_info(token, helpers, building_id, limit, starting_after) {
  var helper_id = []
  for (var i = 0; i < helpers.length; i++) {
    if (helpers[i].checked === true) {
      helper_id.push(helpers[i]._id)
    }
  }
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/delete_helper", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        helper_id: JSON.stringify(helper_id),
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Helper Deleted Succcessfully"));
          dispatch(get_same_helpers(token, building_id, limit, starting_after))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Helper Could Not Be Deleted"));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function toggleapprove(token, helper_id, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/toggle_kyc_active", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        helper_id: helper_id,
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_all_helpers(token, building_id))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function view_helper_unit(token, helper_id, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_helpers_units", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        helper_id: helper_id,
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_helper_unit(responseJson.result));
        }
        else {
          dispatch(set_helper_unit([]));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_helper_unit(payload) {
  return {
    type: SET_HELPER_UNIT,
    payload: payload
  }
}
export function del_helper_unit(token, building_id, helper_id, unit_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/remove_helper_from_unit", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        helper_id: helper_id,
        building_id: building_id,
        unit_id: unit_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(view_helper_unit(token, helper_id, building_id));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
