import {
  SET_ALL_POLLS,
  SET_HISTORY_POLLS,
  SET_POLL_RESULT
} from "../../constants/polling/pollingActionType";
const initial_state = {
  all_polls: [],
  all_history_polls: [],
  pollResult: []
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ALL_POLLS:
      return state = { ...state, all_polls: action.payload };
    case SET_HISTORY_POLLS:
      return state = { ...state, all_history_polls: action.payload };
    case SET_POLL_RESULT:
      return state = { ...state, pollResult: action.payload };
    default:
      return state;
  }
}
