import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Tooltip from "@material-ui/core/Tooltip";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import UNIVERSAL from "../../config/config";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from "moment";

class ViewAllSalary extends React.Component {

    componentDidMount() {
        this.props.reset()
        this.props.getAllSalaries(this.props.login.token, this.props.login.building_id, this.props.salary.limit, this.props.salary.startingAfter, null, null)
        this.props.getAllBankAccounts(this.props.login.token, this.props.login.building_id)
    }

    constructor(props) {
        super(props);
        this.state = {
            month: "",
            year: "",
            selectedAccountId: "",
            paymentMode: "",
            startDate: null,
            endDate: null,
            viewDialog: false,
            salariesArray: [],

        }
    }
    render() {
        const {
            login,
            snackbar,
            close_snack_bar,
            salary,
            bank,
            getAllSalaries,
        } = this.props;
        var btn;
        if (salary.total > salary.limit) {
            if (salary.end_var === salary.total) {
                btn = <span >
                    <IconButton
                        onClick={() => { getAllSalaries(login.token, login.building_id, salary.limit, parseInt(salary.startingAfter) - parseInt(salary.limit), this.state.startDate, this.state.endDate); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton disabled>
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {salary.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{salary.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{salary.total}
                </span>
            }
            else if (salary.start_var > salary.limit && salary.end_var < salary.total) {
                btn = <span >
                    <IconButton
                        onClick={() => { getAllSalaries(this.props.login.token, login.building_id, salary.limit, parseInt(salary.startingAfter) - parseInt(salary.limit), this.state.startDate, this.state.endDate); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton
                        onClick={() => { getAllSalaries(this.props.login.token, login.building_id, salary.limit, parseInt(salary.startingAfter) + parseInt(salary.limit), this.state.startDate, this.state.endDate); }}
                    >
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {salary.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{salary.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{salary.total}
                </span>
            }
            else {
                btn =
                    <span >
                        <IconButton disabled>
                            <Icon>navigate_before</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => { getAllSalaries(login.token, login.building_id, salary.limit, parseInt(salary.startingAfter) + parseInt(salary.limit), this.state.startDate, this.state.endDate); }}
                        >
                            <Icon>navigate_next</Icon>
                        </IconButton>
                        {salary.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{salary.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{salary.total}
                    </span>
            }
        }
        else {
            btn = <span >
                <IconButton disabled>
                    <Icon>navigate_before</Icon>
                </IconButton>
                <IconButton disabled>
                    <Icon>navigate_next</Icon>
                </IconButton>
                {salary.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{salary.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{salary.total}
            </span>
        }
        return (
            <Grid container justify="center">
                <Card>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="rose">
                            <h3>
                                View Salaries
                </h3>
                        </CardIcon>
                    </CardHeader>
                    <CardContent >
                        <Grid container item xs={12} md={12}>

                            <Grid item xs={12} md={2}>
                                <IconButton
                                    onClick={() => { this.props.dialogAction(true) }}
                                >
                                    <Icon>add</Icon>
                                </IconButton>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="date-picker-inline45"
                                        label="Start Date"
                                        value={this.state.startDate}
                                        onChange={(e) => {
                                            this.setState({ startDate: e }
                                            )
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="End Date"
                                        value={this.state.endDate}
                                        onChange={(e) => {
                                            this.setState({ endDate: e },
                                                function () {
                                                    getAllSalaries(this.props.login.token, this.props.login.building_id, this.props.salary.limit, this.props.salary.startingAfter, this.state.startDate, this.state.endDate)
                                                })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    className="refreshBtn"
                                    variant="outlined"
                                    onClick={() => {
                                        this.props.getAllSalaries(this.props.login.token, this.props.login.building_id, this.props.salary.limit, 0, this.state.startDate, this.state.endDate)
                                    }}
                                >
                                    Refresh
                               </Button>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                                {this.state.endDate === null || this.state.startDate === null ?
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        disabled={this.state.endDate !== null && this.state.startDate !== null ? false : true}
                                    >
                                        Export
                                     </Button>
                                    :
                                    <a
                                        style={{ textDecoration: "none" }}
                                        href={UNIVERSAL.BASEURL + "v1/export_salary_entries?building_id=" + login.building_id + "&start_date=" + this.state.startDate + "&end_date=" + this.state.endDate}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                        >
                                            Export
                                       </Button>

                                    </a>
                                }
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div style={{ float: "right" }}>
                                    {btn}
                                </div>
                            </Grid>
                        </Grid>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Entry Date</TableCell>
                                    <TableCell align="left">Paid month</TableCell>
                                    <TableCell align="left">Paid Year</TableCell>
                                    <TableCell align="left"> Payment Type</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {salary.all_salaries.map(row => (
                                    <TableRow key={row._id}>
                                        <TableCell align="left" className="table_cells">
                                            {moment(row.created_on).format(" DD/MM/YYYY")}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.payable_month}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.payable_year}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.payment_type === "Cash" ?
                                                row.payment_type :
                                                <span>
                                                    {row.payment_type}
                                                    &nbsp;&nbsp;Bank Name:-
                                                    {row.bank_account.bank_name}
                                                    &nbsp;&nbsp; Account Number:-
                                                    {(row.bank_account.account_no && row.bank_account.account_no.length > 0) ?
                                                        "****" +
                                                        row.bank_account.account_no.substring(row.bank_account.account_no.length , row.bank_account.account_no.length - 4)
                                                        : "NA"}
                                                </span>
                                            }
                                        </TableCell>
                                        <TableCell align="right" className="table_cells">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => { this.setState({ viewDialog: true, salariesArray: row.salary_paid }) }}
                                            >
                                                view
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>

                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
                <Dialog
                    open={this.state.viewDialog}
                    fullWidth={true}
                    maxWidth={"md"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">View Salaries</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Image</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left"> Phone Number</TableCell>
                                    <TableCell align="left">Salary Paid</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Bank Account Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.salariesArray.map(row => (
                                    <TableRow key={row._id}>
                                        <TableCell align="left" className="table_cells">
                                            <Avatar src={row.image} alt="no-img" />
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.phone_number}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.salary_amount}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.type}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.bank_account_details.account_no ?
                                                row.bank_account_details.bank_name + " -****" +
                                                row.bank_account_details.account_no.substring(row.bank_account_details.account_no.length , row.bank_account_details.account_no.length - 4)
                                                :
                                                "N/A"
                                            }
                                        </TableCell>

                                    </TableRow>
                                ))}


                            </TableBody>
                        </Table>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({
                                    viewDialog: false,
                                });
                            }}
                            color="primary">
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={salary.response}
                    fullWidth={true}
                    maxWidth={"md"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">ADD SALARIES</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} justify="center">

                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.paymentMode}
                                    select
                                    label="Select Payment Mode"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        if (e.target.value !== "Bank") {
                                            this.setState({
                                                paymentMode: e.target.value,
                                                selectedAccountId: "",
                                            })
                                        }
                                        else {
                                            this.setState({ paymentMode: e.target.value })
                                        }

                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                    {salary.mode.map(su => (
                                        <MenuItem key={su}
                                            value={su}
                                        >
                                            {su}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {this.state.paymentMode === "Bank" &&
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        value={this.state.selectedAccountId}
                                        select
                                        label="Select Bank Account"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            this.setState({ selectedAccountId: e.target.value })
                                        }}
                                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {bank.all_bank_accounts.map(su => (
                                            <MenuItem key={su.id}
                                                value={su.id}
                                            >
                                                {su.bankAccountMix}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            }
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.month}
                                    select
                                    label="Select Month"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({
                                            month: e.target.value,
                                            year: ""
                                        })
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                    {salary.month.map(su => (
                                        <MenuItem key={su}
                                            value={su}
                                        >
                                            {su}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {this.state.month !== "" &&
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        value={this.state.year}
                                        select
                                        label="Select Year"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            this.setState({ year: e.target.value },
                                                function () {
                                                    this.props.getPendingWorkers(this.props.login.token, this.props.login.building_id, this.state.month, this.state.year)
                                                }
                                            )
                                        }}
                                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {salary.year.map(su => (
                                            <MenuItem key={su}
                                                value={su}
                                            >
                                                {su}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            }
                            {salary.all_pending_payment.length > 0 ?
                                <Grid item xs={12} md={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Select</TableCell>
                                                <TableCell align="left">Image</TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Phone Number</TableCell>
                                                <TableCell align="left">Salary</TableCell>
                                                <TableCell align="left">Salary Paid</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {salary.all_pending_payment.map((row, i) => (
                                                <TableRow key={row._id}>
                                                    <TableCell align="left" className="table_cells">
                                                        <input type="checkbox"
                                                            value={row.checked}
                                                            onChange={() => {
                                                                var arr = salary.all_pending_payment;
                                                                arr[i].checked = !arr[i].checked;
                                                                this.props.setSalaryArray(arr);
                                                            }} />
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        <Avatar src={row.image} alt="no-img" />
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {row.phone_number}
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {row.salary}
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        <Grid item xs={12} md={12}>
                                                            <TextField
                                                                value={row.salaryPaid}
                                                                label="Salary Paid"
                                                                fullWidth
                                                                margin="normal"
                                                                variant="outlined"
                                                                onChange={(e) => {
                                                                    var arr = salary.all_pending_payment;
                                                                    arr[i].salaryPaid = e.target.value;
                                                                    this.props.setSalaryArray(arr);
                                                                }}
                                                                InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                                                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                                            >
                                                            </TextField>
                                                        </Grid>
                                                    </TableCell>


                                                </TableRow>
                                            ))}


                                        </TableBody>
                                    </Table>

                                </Grid>
                                : ""}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.props.dialogAction(false)
                                this.props.setAllPendingWorkers([])
                                this.setState({
                                    month: "",
                                    year: "",
                                    selectedAccountId: "",
                                    paymentMode: "",

                                })
                            }}
                            color="primary">
                            Close
              </Button>
                        <Button
                            disabled={salary.disabledButton}
                            onClick={() => {
                                this.setState({
                                    month: "",
                                    year: "",
                                    selectedAccountId: "",
                                    paymentMode: "",

                                })
                                this.props.addSalary(this.props.login.token, this.props.login.building_id, this.state, salary)
                            }}
                            color="primary"
                            autoFocus
                        >
                            Add Salary
              </Button>
                    </DialogActions>
                </Dialog>
            </Grid >
        )
    }
}
export default withStyles(styles)(ViewAllSalary);