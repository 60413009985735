import {
    SET_AD_NAME,
    SET_AD_DESCRIPTION,
    SET_AD_CONTACT_NUMBER,
    SET_AD_CONTACT_NAME,
    SET_CLASSIFIEDS,
    SET_AD_IMAGES,
    RESET_CLASSIFIED
} from "../../constants/classified/classified_const";
import { LIMIT } from "../../constants/ActionTypes";
const initial_state = {
    ad_name: "",
    ad_description: "",
    ad_contact_person: "",
    ad_contact_number: "",
    ad_images: [],
    all_classifieds: [],
    starting_after: 0,
    limit: LIMIT,
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_AD_NAME:
            return state = { ...state, ad_name: action.payload }
        case SET_AD_DESCRIPTION:
            return state = { ...state, ad_description: action.payload }
        case SET_AD_CONTACT_NUMBER:
            return state = { ...state, ad_contact_number: action.payload }
        case SET_AD_CONTACT_NAME:
            return state = { ...state, ad_contact_person: action.payload }
        case SET_CLASSIFIEDS:
            return state = { ...state, all_classifieds: action.payload }
        case SET_AD_IMAGES:
            return state = { ...state, ad_images: action.payload }
        case RESET_CLASSIFIED:
            return state = {
                ...state,
                ad_name: "",
                ad_description: "",
                ad_contact_person: "",
                ad_contact_number: "",
                ad_images: [],
                all_classifieds: [],
            }
        default:
            return state;
    }
} 