import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import {
  Icon,
  IconButton
} from "@material-ui/core"

class AddPoll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      options: [],
      answer: ""
    }
  }
  componentDidMount() {
    // this.props.reset_e_notice()
  }
  render() {

    const {
      polling,
      snackbar,
      close_snack_bar,
      login,
      addPoll
    } = this.props;

    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD POLL
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container spacing={8} style={{ padding: 40 }}>
                <Grid container item lg={6} xs={12}>
                  <Grid container lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Question"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.question}
                      onChange={(event) => { this.setState({ question: event.target.value }) }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Enter option"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.answer}
                      onChange={(event) => { this.setState({ answer: event.target.value }) }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                    <IconButton
                      onClick={() => {
                        this.state.options.push(this.state.answer);
                        this.setState({
                          options: this.state.options,
                          answer: ""
                        })
                      }}
                    >
                      <Icon>
                        add
                      </Icon>
                    </IconButton>
                  </Grid>
                  <Grid item lg={12} xs={12} style={{backgroundColor: "#f2f2f2", borderRadius: 20, padding: 10}}>
                      <ol>
                        {this.state.options.map( (s, index)=>(
                          <li key={index}>
                            {s}
                            <IconButton
                              onClick={()=>{
                                this.state.options.splice(index, 1);
                                this.setState({
                                  options: this.state.options
                                })
                              }}
                            >
                              <Icon>
                              delete
                              </Icon>
                            </IconButton>
                          </li>
                        ) )}
                      </ol>
                  </Grid>

                  <Grid item lg={12} xs={12}>
                    <br />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        addPoll(login.token, login.building_id, this.state.question, this.state.options);
                        this.setState({
                          question: "",
                          options: [],
                          answer: ""
                        })
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddPoll);