import React, { Component } from "react";
import { connect } from "react-redux";
import Enotice from "../../components/e-notice/e_notice";
import {
  get_all_e_notice,
  prev_btn,
  next_btn,
  validate_single_e_notice,
  delete_single_e_notice,
  toggleCheckbox,
} from "../../actions/e-notice/e_notice_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class E_notice_cont extends Component {
  render() {
    return (
      <Enotice {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    e_notice: store.e_notice,
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    get_all_e_notice: (token, building_id, limit, starting_after) => {
      dispatch(get_all_e_notice(token, building_id, limit, starting_after))
    },
    prev_btn: (token, building_id, limit, starting_after) => {
      dispatch(prev_btn(token, building_id, limit, starting_after))
    },
    next_btn: (token, building_id, limit, starting_after) => {
      dispatch(next_btn(token, building_id, limit, starting_after))
    },
    validate_single_e_notice: (token, building_id, e_notice_id, desc, title, target, generated_date, valid_till, limit, starting_after) => {
      dispatch(validate_single_e_notice(token, building_id, e_notice_id, desc, title, target, generated_date, valid_till, limit, starting_after))
    },
    delete_single_e_notice: (token, array, building_id, limit, starting_after) => {
      dispatch(delete_single_e_notice(token, array, building_id, limit, starting_after))
    },
    toggleCheckbox: (row, array) => {
      dispatch(toggleCheckbox(row, array))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(E_notice_cont);