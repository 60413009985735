import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import { styles } from "../../styles/style";
import CardContent from "@material-ui/core/CardContent";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class AddComplaints extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {
            complaints,
            snackbar,
            close_snack_bar,
            login,
            set_is_urgent,
            set_raised_date,
            set_complaints_desc,
            set_complaints_title,
            set_phone_number,
            find_resident,
            validate_add_complaints
        } = this.props;
        return (
            <Grid container spacing={8} justify="center">
                <Grid item sm={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                <h3>
                                    ADD COMPLAINTS
                                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent >
                            <Grid container spacing={2} >
                                <Grid container item lg={6} xs={12}>
                                    <Grid item lg={12} xs={12} >
                                        <TextField
                                            id="outlined-email-input"
                                            label="Title"
                                            type="text"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={complaints.title}
                                            onChange={(event) => { set_complaints_title(event.target.value); }}
                                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextField
                                            id="outlined-email-input"
                                            label="Description"
                                            type="text"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={complaints.desc}
                                            onChange={(event) => { set_complaints_desc(event.target.value); }}
                                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Raised Date"
                                                format="dd/MM/yyyy"
                                                value={complaints.raised_date}
                                                onChange={(event) => { set_raised_date(event); }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <Checkbox
                                            checked={complaints.is_urgent}
                                            size="small"
                                            onChange={() => {
                                                set_is_urgent(!complaints.is_urgent)
                                            }}
                                        />
                                        Is Urgent
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <br />
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => { validate_add_complaints(login, complaints); }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={6} xs={12}>
                                    <Grid item lg={8} xs={12} >
                                        <TextField
                                            id="outlined-email-input"
                                            label="Search by Phone number"
                                            type="text"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={complaints.phone_no}
                                            onChange={(event) => {
                                                set_phone_number(event.target.value);
                                            }}
                                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={12}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            style={{ position: "relative", top: 25 }}
                                            onClick={() => { find_resident(complaints.phone_no, login.token, login.building_id) }}
                                        >
                                            search
                                            </Button>
                                    </Grid>

                                    <Grid item lg={12} xs={12}>
                                        <br />
                                        <h2>Selected Resident : {complaints.resident_name}</h2>
                                        <h2>Unit Number : {complaints.unit_number}</h2>
                                        <h2>Building Name : {complaints.building_name}</h2>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {/* <LoaderCon /> */}
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(styles)(AddComplaints);