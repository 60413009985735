export const RESET_DEFAULT_HELPER = "RESET_DEFAULT_HELPER";
export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const SET_ALL_HELPERS = "SET_ALL_HELPERS";
export const RESET_ALL = "RESET_ALL";
export const SET_HELPER_EMAIL = "SET_HELPER_EMAIL";
export const SET_HELPER_CONTACT_INFO = "SET_HELPER_CONTACT_INFO";
export const SET_HELPER_SECONDARY_CONTACT_INFO = "SET_HELPER_SECONDARY_CONTACT_INFO";
export const SET_HELPER_PERMANENT_ADDRESS = "SET_HELPER_PERMANENT_ADDRESS";
export const SET_HELPER_ID_PROOF_TYPE = "SET_HELPER_ID_PROOF_TYPE";
export const SET_HELPER_ID_PROOF_ID = "SET_HELPER_ID_PROOF_ID";
export const SET_HELPER_NAME = "SET_HELPER_NAME";
export const SET_HELPER_IMAGE = "SET_HELPER_IMAGE";
export const SET_HELPER_SERVICE = "SET_HELPER_SERVICE";
export const SET_ONLY_HELPERS = "SET_ONLY_HELPERS";
export const SELECT_HELPER = "SELECT_HELPER";
export const SET_IS_PRIVATE = "SET_IS_PRIVATE";
export const SET_ID_PROOF_IMAGE = "SET_ID_PROOF_IMAGE";
export const SET_HELPER_UNIT = "SET_HELPER_UNIT";
export const SET_UNIT_ID_FOR_PRIVATE_HELPER = "SET_UNIT_ID_FOR_PRIVATE_HELPER";