import React, { Component } from "react";
import { connect } from "react-redux";
import CreateSharewall from "../../components/sharewall/create_sharewall";
import {
    set_comments,
    set_posted_on,
    set_sharewall_images,
    validate_create_sharewall,
    reset_sharewall
} from "../../actions/sharewall/sharewall_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_amenities_cont extends Component {
    render() {
        return (
            <CreateSharewall {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        sharewall: store.sharewall,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        set_comments: (payload) => {
            dispatch(set_comments(payload))
        },
        set_posted_on: (payload) => {
            dispatch(set_posted_on(payload))
        },
        set_sharewall_images: (payload, sharewall) => {
            dispatch(set_sharewall_images(payload, sharewall))
        },
        validate_create_sharewall: (token, building_id, user_id, sharewall) => {
            dispatch(validate_create_sharewall(token, building_id, user_id, sharewall))
        },
        reset_sharewall: () => {
            dispatch(reset_sharewall())
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_amenities_cont);