import React, { Component } from "react";
import { connect } from "react-redux";
import ViewClassified from "../../components/classified/view_classified";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    get_all_classifieds,
    toggleCheckbox,
    delete_classified,
    validate_edit_classified
} from "../../actions/classified/classified_action"
export class View_Classified_Cont extends Component {
    render() {
        return (
            <ViewClassified {...this.props} />
        );
    };
};
export const mapStateToProps = store => {
    return {
        classified: store.classified,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_classifieds: (token, building_id, starting_after, limit) => {
            dispatch(get_all_classifieds(token, building_id, starting_after, limit))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        toggleCheckbox: (obj, array) => {
            dispatch(toggleCheckbox(obj, array));
        },
        delete_classified: (token, classified, limit, starting_after, building_id) => {
            dispatch(delete_classified(token, classified, limit, starting_after, building_id));
        },
        validate_edit_classified: (token, building_id, classified, limit, starting_after) => {
            dispatch(validate_edit_classified(token, building_id, classified, limit, starting_after));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_Classified_Cont); 