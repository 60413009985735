import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
const buttonnn = {
    borderRadius: "5px",
    height: "36px",
    color: "white",
    paddingLeft: "30px",
    paddingRight: "30px",
    backgroundColor: "green",
    alignSelf: "left",
};
class AddDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        const {
            department,
            snackbar,
            close_snack_bar,
            login,
            set_department_name,
            set_department_desc,
            validate_add_department,
        } = this.props;
        return (
            <Grid container spacing={8} justify="center">
                <Grid item sm={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                {/* <Icon>content_copy</Icon> */}
                                <h3>
                                    ADD DEPARTMENT
              </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent >
                            <Grid container spacing={8} style={{ padding: 40 }}>
                                <Grid container item lg={6} xs={12}>
                                    <Grid container lg={12} xs={12} >
                                        <TextField
                                            id="outlined-email-input"
                                            label="Department Name"
                                            type="text"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={department.department_name}
                                            onChange={(event) => { set_department_name(event.target.value); }}
                                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <TextField
                                            id="outlined-email-input"
                                            label="Department Description"
                                            type="text"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={department.department_desc}
                                            onChange={(event) => { set_department_desc(event.target.value); }}
                                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <Button
                                            style={buttonnn}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => { validate_add_department(login.token, login.building_id, department); }}
                                        >
                                            Submit
                    </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(styles)(AddDepartment);