export const all_admin_option = [
  { id: 0, name: "Home", icon: "home", link: "dashboard" },
  { id: 1, name: "Units", icon: "dashboard", link: "all_units" },
  { id: 2, name: "E-Notice", icon: "chat", link: "all_e_notice" },
  { id: 3, name: "Vendors", icon: "supervised_user_circle", link: "all_admin_vendors" },
  { id: 4, name: "Amenities Booking", icon: "local_mall", link: "all_amenities_booking" },
  { id: 5, name: "Employees", icon: "work", link: "view_employees" },
  { id: 6, name: "Complaints", icon: "report", link: "all_complaints" },
  { id: 7, name: "Helpers", icon: "assignment_ind", link: "all_helpers" },
  {
    id: 8, name: "Payments",
    data: [
      { name: "Maintenance", link: "unit_maintenance" },
      { name: "Assets", link: "all_assests" },
      { name: "Salary", link: "all_salaries" },
      { name: "Loans", link: "all_loans" },
      { name: "Maintenance Credit", link: "all_maintenance_credit" },
    ]
    , icon: "money"
  },
  {
    id: 9, name: "Security",
    data: [
      { name: "All Visitors", link: "all_visitors" },
      { name: "Residents", link: "view_residents_visit" },
      { name: "Employees", link: "view_employee_visitor" },
      { name: "Helpers", link: "view_helper_visitor" },
    ], icon: "emoji_people"
  },
  {
    id: 10, name: "Master",
    data: [
      { name: "Unit Type", icon: "dashboard", link: "all_unit_type" },
      { name: "Departments", icon: "business", link: "all_departments" },
      { name: "Accounts", icon: "money", link: "view_accounts" },
      { name: "Amenities", icon: "apartment", link: "all_amenities" },
      { name: "Vehicles", icon: "drive_eta", link: "view_vehicles" },
      { name: "ID Proofs", icon: "class", link: "all_id_proofs" },
      { name: "Vendor Category", icon: "category", link: "all_vendor_category" },
      { name: "maintenance", icon: "money", link: "all_maintenance_category" },

    ], icon: "dashboard"
  },
  {
    id: 11, name: "Community",
    data: [
      { name: "ShareWall", link: "all_sharewall" },
      { name: "Classified", link: "all_classified" },
      { name: "Polling", link: "view_polling" },
    ], icon: "share"
  },
];

export const all_super_admin_option = [
  { id: 0, name: "Home", icon: "home", link: "dashboard" },
  { id: 1, name: "Vendors", icon: "supervised_user_circle", link: "all_vendors" },
  { id: 2, name: "Buildings", icon: "account_balance", link: "all_buildings" },
  { id: 3, name: "ID Proofs", icon: "class", link: "all_id_proofs" },
  { id: 4, name: "Vehicles", icon: "drive_eta", link: "view_vehicles" },
  { id: 5, name: "Vendor Category", icon: "category", link: "all_vendor_category" }
];