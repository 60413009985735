import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Tooltip from "@material-ui/core/Tooltip";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class CreateVisitorEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    this.props.get_same_units(this.props.login.token, this.props.login.building_id);
    this.props.get_all_vehicles(this.props.login.token)
  }
  formatData = (data) => {
    var view =
      <TreeView
        defaultCollapseIcon={<Icon className="drawer_icons">keyboard_arrow_right</Icon>}
        defaultExpandIcon={<Icon className="drawer_icons">keyboard_arrow_down</Icon>}
      >
        {this.getParent(data)}
      </TreeView>
    return view;
  }
  getParent = (data) => {
    return data.map((s, index) => (
      s.sub_units.length > 0 ?
        <TreeItem key={s._id} nodeId={s._id} label={s.unit_name}>
          {this.getParent(s.sub_units)}
        </TreeItem>
        :
        <div key={s._id} style={{ backgroundColor: "#f2f2f2" }}>
          <Button
            onClick={() => {
              this.props.set_visitor_unit_name(s._id);
            }}
          >
            {s.unit_name}
          </Button>
        </div>
    ))
  }
  render() {
    const {
      visitors,
      snackbar,
      close_snack_bar,
      login,
      units,
      vehicles,
      validate_visitor_func,
      set_visitor_date_of_visit,
      set_visitor_unit_name,
      set_visitor_vehicle_num,
      set_visitor_vehicle_type,
      set_visitor_mob_num,
      set_visitor_name,
      set_purpose_of_visit,
      set_purpose_of_visit_other
    } = this.props;
    return (
      <Grid container item xs={12} lg={12} spacing={8}>
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD VISITOR
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid container spacing={2}>
                <Grid container item lg={4}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Visitor Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={visitors.visitor_name}
                      onChange={(event) => { set_visitor_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Tooltip title="Select the unit from the tree structure on the right">
                      <TextField
                        id="outlined-email-input"
                        label="Selected Unit"
                        type="dropdown"
                        fullWidth
                        select
                        margin="normal"
                        disabled={true}
                        variant="outlined"
                        value={visitors.visitor_unit_name}
                        onChange={(event) => { set_visitor_unit_name(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      >
                        {units.all_units.map((single) => (
                          <MenuItem key={single.unit_name} value={single._id}>
                            {single.unit_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Tooltip>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Mobile Number"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={visitors.visitor_mob_num}
                      onChange={(event) => { set_visitor_mob_num(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Vehicle Type"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={visitors.visitor_vehicle_type}
                      onChange={(event) => { set_visitor_vehicle_type(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {vehicles.all_vehicles.map((single) => (
                        <MenuItem key={single.vehicle_name} value={single._id}>
                          {single.vehicle_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {visitors.visitor_vehicle_type !== "" ?
                    <Grid item lg={12} xs={12}>
                      <TextField
                        id="outlined-email-input"
                        label="Vehicle Number"
                        type="text"
                        placeholder="____ ____ ____ ____ ____ ____ ____ ____ ____ ____"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={visitors.visitor_vehicle_num}
                        onChange={(event) => { set_visitor_vehicle_num(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> : ""}
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Purpose of Visit"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={visitors.purpose_of_visit}
                      onChange={(event) => { set_purpose_of_visit(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {visitors.purpose_of_visit_options.map((single) => (
                        <MenuItem key={single.name} value={single.name}>
                          {single.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {visitors.purpose_of_visit === "Others" &&
                    <Grid item lg={12} xs={12}>
                      <TextField
                        id="outlined-email-input"
                        label="Other Purpose of Visit"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={visitors.purpose_of_visit_other}
                        onChange={(event) => { set_purpose_of_visit_other(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                  }
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item lg={12} style={{ paddingRight: 30 }}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog1"
                        label="Date of Visit"
                        fullWidth
                        format="dd/MM/yyyy"
                        value={visitors.visitor_date_of_visit}
                        onChange={(event) => { set_visitor_date_of_visit(event); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <Grid item lg={12} xs={12}>
                    <br />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => { validate_visitor_func(login.token, login.building_id, visitors); }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
                <Grid item lg={6}>
                  {this.formatData(this.props.units.unit_details)}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(CreateVisitorEntry);