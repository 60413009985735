import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
class AddVendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
    }
  }
  componentDidMount() {
    this.props.set_reset_type();
    this.props.get_all_vendor_category(this.props.login.token);
    this.props.get_all_id_proofs(this.props.login.token);
  }
  handleFunc = (event) => {
    this.setState({
      check: !this.state.check
    })
  }
  render() {
    const {
      snackbar,
      close_snack_bar,
      login,
      vendors,
      set_contact_num,
      set_contact_name,
      set_vendor_name,
      set_vendor_service,
      set_license_no,
      set_fixed_amount,
      set_account_no,
      set_bank_name,
      set_bank_ifsc,
      set_bank_branch,
      validate_add_vendors,
      set_vendor_contact,
      set_other_contact,
      vendor_category,
      set_vendor_type,
      set_vendor_image,
      employee,
      set_employee_id_proof_id,
      set_employee_id_proof_img,
      set_employee_id_proof_type,
      id_proofs,
      set_contact_image,
      remove_other_contact
    } = this.props;
    let other_contacts;
    if (vendors.contact_name === "" || vendors.contact_num === "" || vendors.contact_image === null) {
      other_contacts =
        <Button
          variant="outlined"
          size="small"
          disabled={true}
        >
          Add
        </Button>
    } else {
      other_contacts =
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => {
            set_other_contact(vendors);
            set_contact_num("");
            set_contact_name("");
            set_contact_image(null);
          }}
        >
          Add
        </Button>
    }
    return (
      <Grid container spacing={8} justify="center" >
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD VENDORS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container style={{ padding: 40 }}>
                <Grid container item lg={6} xs={12}>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      label="Vendor Type"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={vendors.vendor_type}
                      onChange={(event) => {
                        if (event.target.value === "I") {
                          set_contact_num("");
                          set_contact_name("");
                          set_contact_image(null);
                          set_vendor_type(event.target.value);
                          remove_other_contact(vendors.other_contact, "removeAllData")
                        }
                        else {
                          set_employee_id_proof_type("")
                          set_employee_id_proof_id("")
                          set_employee_id_proof_img(null)
                          set_vendor_type(event.target.value);


                        }
                      }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      <MenuItem value={"I"}>
                        Individual
                      </MenuItem>
                      <MenuItem value={"O"}>
                        Organisation
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      label="Vendor Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={vendors.vendor_name}
                      onChange={(event) => { set_vendor_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      label="Vendor Contact"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={vendors.contact}
                      onChange={(event) => { set_vendor_contact(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      label="Service"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={vendors.vendor_service}
                      onChange={(event) => { set_vendor_service(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {vendor_category.all_vendor_category.map((single) => (
                        <MenuItem key={single} value={single.vendor_category_id}>
                          {single.vendor_category_name}
                        </MenuItem>
                      ))}
                      <MenuItem key={"Add New Vendor Category"} >
                        <Link to={{
                          pathname: "/all_vendor_category",
                          state: {
                            redirect: true
                          }
                        }} style={{ textDecoration: "none", color: "black" }}>
                          Add New Vendor Category
                        </Link>
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    Vendor image:
                    <input
                      type="file"
                      onChange={(event) => { set_vendor_image(event); }}
                    />
                  </Grid>
                  {vendors.vendor_type === "O" &&
                    <Grid item lg={12} xs={12}>
                      <TextField
                        label="Govt License No(Optional)"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={vendors.license_no}
                        onChange={(event) => { set_license_no(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                  }
                  {vendors.is_fixed_payment === true ?
                    <Grid item lg={12} xs={12}>
                      <TextField
                        label="Amount(Optional)"
                        type="number"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={vendors.amount}
                        onChange={(event) => { set_fixed_amount(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                    :
                    ""}
                  <Grid item lg={12} xs={12}>
                    <TextField
                      label="Account No(Optional)"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={vendors.account_no}
                      onChange={(event) => { set_account_no(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      label="Bank Name(Optional)"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={vendors.Bank_name}
                      onChange={(event) => { set_bank_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      label="Bank IFSC(Optional)"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={vendors.bank_ifsc}
                      onChange={(event) => { set_bank_ifsc(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      label="Branch(Optional)"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={vendors.branch}
                      onChange={(event) => { set_bank_branch(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <br />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => { validate_add_vendors(login.token, login.building_id, vendors, employee) }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item lg={1} xs={3}></Grid>
                <Grid container item lg={5} xs={12}>
                  {/* OTHER CONTACTS INPUTS */}
                  {vendors.vendor_type === "O" &&
                    <Grid item lg={12} xs={12}>
                      Other Contact
                    <TextField
                        autoComplete="off"
                        label="Contact Name"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={vendors.contact_name}
                        onChange={(event) => { set_contact_name(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                      <TextField
                        autoComplete="off"
                        label="Contact Number"
                        type="number"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={vendors.contact_num}
                        onChange={(event) => { set_contact_num(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                      <input
                        type="file"
                        onChange={(event) => { set_contact_image(event.target.files[0]); }}
                        onClick={(event) => { event.target.value = null }}
                        ref={input => this.inputElement = input}
                        style={{ opacity: 0, width: 0 }}
                      />
                      <button onClick={() => {
                        this.inputElement.click();
                      }}>
                        Upload Image
                      </button>
                      {/* {vendors.contact_image !== null && vendors.contact_image.name} */}
                      <br />
                      {/* DISABLE ADD BUTTON WHEN NO DATA IS ENTERED */}
                      {other_contacts}
                      {/* SHOW ALL VENDOR CONTACTS ADDED */}
                      {vendors.other_contact.length === 0 ? "" :
                        <Grid item lg={12} xs={12}>
                          <h5>Other Contact Details</h5>
                          {vendors.other_contact.map(((row, index) => (
                            <Grid key={index} style={{ backgroundColor: "#e6e6e6", borderRadius: 10, padding: 10, marginTop: 10 }}>
                              {row.render_image !== null &&
                                <img src={row.render_image} style={{ height: 80 }} alt="vendors" />
                              }
                              <br />
                              {"Contact Person:  "}<i>{row.contact_person}</i><br />
                              {"Contact Phone Number :  "}<i>{row.contact_phonenumber}</i><br /><br />
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  remove_other_contact(vendors.other_contact, index)
                                }}
                              >
                                remove
                              </Button>
                            </Grid>
                          )))}
                        </Grid>
                      }
                    </Grid>
                  }
                  {vendors.vendor_type === "I" &&
                    <Grid item lg={12} xs={12}>
                      <Grid item lg={12} xs={12}>
                        <TextField
                          label="ID Proof Type"
                          type="dropdown"
                          fullWidth
                          select
                          margin="normal"
                          variant="outlined"
                          value={employee.employee_id_proof}
                          onChange={(event) => { set_employee_id_proof_type(event.target.value); }}
                          InputProps={{ classes: { input: this.props.classes.dropdown } }}
                          InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                        >
                          {id_proofs.all_id_proofs.map((single) => (
                            <MenuItem key={single.proof_name} value={single.proof_name}>
                              {single.proof_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {employee.employee_id_proof !== "" &&
                        <Grid item lg={12} xs={12}>
                          <TextField
                            label="ID Proof Number"
                            type="text"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={employee.employee_id_proof_id}
                            onChange={(event) => { set_employee_id_proof_id(event.target.value); }}
                            InputProps={{ classes: { input: this.props.classes.textfield } }}
                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                          />

                        </Grid>
                      }
                      {employee.employee_id_proof_id !== "" ?
                        <Grid item lg={12} xs={12}>
                          Image
                        <input type="file"
                            multiple
                            onChange={(event) => { set_employee_id_proof_img(event); }}
                          />
                        </Grid> :
                        <Grid item lg={12} xs={12}>
                          Image
                      <button
                            disabled
                          >Choose files
                      </button>
                        </Grid>
                      }
                    </Grid>
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddVendors);