import React, { Component } from "react";
import { connect } from "react-redux";
import ViewVehicles from "../../components/vehicles/view_vehicles";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  get_all_vehicles,
  update_single_vehicle,
  delete_single_vehicle,
  next_btn,
  prev_btn,
  reset_vehicles,
  toggleVehicleCheckbox
}
  from "../../actions/vehicles/vehicles_action";
export class View_vehicles_cont extends Component {
  render() {
    return (
      <ViewVehicles {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    units: store.units,
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    vehicles: store.vehicles,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    reset_vehicles: () => {
      dispatch(reset_vehicles())
    },
    update_single_vehicle: (token, vehicle_id, name, manufacturer, type, limit, starting_after) => {
      dispatch(update_single_vehicle(token, vehicle_id, name, manufacturer, type, limit, starting_after))
    },
    get_all_vehicles: (token, limit, starting_after) => {
      dispatch(get_all_vehicles(token, limit, starting_after))
    },
    delete_single_vehicle: (token, vehicle_id, limit, starting_after) => {
      dispatch(delete_single_vehicle(token, vehicle_id, limit, starting_after))
    },
    prev_btn: (token, limit, starting_after) => {
      dispatch(prev_btn(token, limit, starting_after))
    },
    next_btn: (token, limit, starting_after) => {
      dispatch(next_btn(token, limit, starting_after))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    toggleCheckbox: (obj, array) => {
      dispatch(toggleVehicleCheckbox(obj, array));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_vehicles_cont);