import {
  SET_ALL_MAINTENANCE_TYPE,
  SET_UNIT_MAINTENANCE,
  SET_UNIT_MAINTENANCE_DETAILS,
  RESET,
  SET_INVOICE_AMOUNT,
  SET_INVOICE_TYPE,
  SET_PENDING_PAYMENTS,
  SET_ALL_DATA_UNITS,
  SET_ALL_MAINTENANCE_CREDIT,
} from "../../constants/maintenance/maintenance_const";
import { LIMIT } from "../../constants/ActionTypes";

const initial_state = {
  all_maintenance: [],
  unit_data: [],
  unit_details: [],
  invoiceAmount: 0,
  invoiceType: "",
  pendingPayments: [],
  limit: 25,
  startingAfter: 0,
  start_var: "",
  end_var: "",
  total: "",
  all_unit_data: [],
  mode: ["Cash", "Cheque", "Bank", "UPI"],
  all_maintenance_credit: [],
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ALL_DATA_UNITS:
      return state = { ...state, all_unit_data: action.payload };
    case SET_ALL_MAINTENANCE_TYPE:
      return state = { ...state, all_maintenance: action.payload };
    case RESET:
      return state = {
        ...state,
        unit_details: [],
        all_maintenance: [],
        unit_data: [],
      };
    case SET_UNIT_MAINTENANCE:
      return state = { ...state, unit_data: action.payload };
    case SET_ALL_MAINTENANCE_CREDIT:
      return state = { ...state, all_maintenance_credit: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, startingAfter: action.payload.starting_after };
    case SET_UNIT_MAINTENANCE_DETAILS:
      return state = { ...state, unit_details: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, startingAfter: action.payload.starting_after };
    case SET_INVOICE_AMOUNT:
      return state = { ...state, invoiceAmount: action.payload };
    case SET_INVOICE_TYPE:
      return state = { ...state, invoiceType: action.payload };
    case SET_PENDING_PAYMENTS:
      return state = { ...state, pendingPayments: action.payload };
    default:
      return state;
  }
}
