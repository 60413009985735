import {
    SET_ALL_SALARIES,
    RESET_SALARIES,
    SET_ALL_PENDING_WORKERS,
    DIALOG_ACTION_RESPONSE,
} from "../../constants/salary/salaryConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
// import { RESET } from "../../constants/ActionTypes";
export function getAllSalaries(token, building_id, limit, starting_after, startDate, endDate) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_salary_entries", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
                start_date: startDate,
                end_date: endDate,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.result.length >= limit) {
                        dispatch(set_snack_bar(responseJson.status, responseJson.message));
                        dispatch(setAllSalaries(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(setAllSalariesPagination(responseJson, limit, starting_after))
                        dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    }
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    if (responseJson.message === "No Salary Entries Found") {
                        dispatch(setAllSalaries({ result: [], total: 0 }, 0, 0))
                    }
                }
                // dispatch(getAllAssetsTypes(token))
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function setAllSalaries(responseJson, limit, starting_after) {
    var obj;
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0, starting_after: starting_after };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.totaldata, starting_after: starting_after };
    }
    return {
        type: SET_ALL_SALARIES,
        payload: obj,
    }
}
export function setAllSalariesPagination(responseJson, limit, starting_after) {
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.totaldata), total: responseJson.totaldata, starting_after: starting_after };
    return {
        type: SET_ALL_SALARIES,
        payload: obj,
    }
}

export function reset() {
    return {
        type: RESET_SALARIES,
    };
}
export function getPendingWorkers(token, building_id, month, year) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_employee_vendor_list", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
                payable_month: month,
                payable_year: year,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(setAllPendingWorkers(responseJson.result));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function setAllPendingWorkers(data) {
    for (var i = 0; i < data.length; i++) {
        data[i].checked = false;
        data[i].salaryPaid = "";


    }
    var obj = { data: data, num: true }
    return {
        type: SET_ALL_PENDING_WORKERS,
        payload: obj
    };
}
export function setSalaryArray(data) {
    var num = true;
    var obj = {}
    for (var i = 0; i < data.length; i++) {
        if (data[i].checked) {
            num = false
        }
    }
    obj = { data: data, num: num }
    return {
        type: SET_ALL_PENDING_WORKERS,
        payload: obj
    };
}
export function addSalary(token, building_id, state, salary) {
    var arr = []
    for (var i = 0; i < salary.all_pending_payment.length; i++) {
        if (salary.all_pending_payment[i].checked) {
            arr.push({
                _id: salary.all_pending_payment[i]._id,
                name: salary.all_pending_payment[i].name,
                image: salary.all_pending_payment[i].image,
                type: salary.all_pending_payment[i].type,
                phone_number: salary.all_pending_payment[i].phone_number,
                salary: salary.all_pending_payment[i].salary,
                bank_account_details: salary.all_pending_payment[i].bank_account_details,
                salary_amount: salary.all_pending_payment[i].salaryPaid,
            })
        }
    }
    arr = JSON.stringify(arr)

    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/add_salary_entry", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
                payable_month: state.month,
                payable_year: state.year,
                payment_type: state.paymentMode,
                bank_account_id: state.selectedAccountId,
                salary_paid: arr,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(getAllSalaries(token, building_id, salary.limit, salary.startingAfter, null, null));
                    dispatch(setAllPendingWorkers([]));
                    dispatch(dialogAction(false))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function dialogAction(value) {
    return {
        type: DIALOG_ACTION_RESPONSE,
        payload: value,
    }
}