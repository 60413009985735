import React, { Component } from "react";
import {
  Dialog
} from "@material-ui/core";

export default class ImageViewer extends Component{
  render(){
    return(
      <Dialog
        open={this.props.image?true:false}
        onClose={()=>{
          this.props.close()
        }}
      >
        <img 
          src={this.props.image}
          style={{
            width: "100%"
          }}
        />
      </Dialog>
    )
  }
}