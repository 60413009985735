import {
  SET_ALL_RESIDENTS,
  SET_RESIDENT_EMAIL,
  SET_RESIDENT_CONTACT_INFO,
  SET_RESIDENT_SECONDARY_CONTACT_INFO,
  SET_RESIDENT_PERMANENT_ADDRESS,
  SET_RESIDENT_ID_PROOF_ID,
  SET_RESIDENT_VEHICLE_DETAILS,
  SET_RESIDENT_NAME,
  SET_RESIDENT_IMAGE,
  SET_IS_OWNER,
  SET_IS_RESIDING,
  SET_UNIT_ID,
  RESET_ALL_RESIDENTS,
  SET_STARTING_AFTER,
  RESET_DEFAULT_RESIDENT,
  SET_RESIDENT_ID_PROOF_TYPE,
  SET_RESIDENT_RC_NUM,
  SET_IS_SUB_RESIDENT,
  SET_ID_PROOF_IMAGE
}
  from "../../constants/residents/residents_const";
import { set_loader, unset_loader }
  from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
export function get_all_residents(token, building_id, limit, starting_after) {
  starting_after = 0;
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_residents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_residents(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_residents_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_same_residents(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_residents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_residents(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_residents_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          if (responseJson.message === "No Residents Found") {
            dispatch(set_all_residents({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function reset_residents() {
  return {
    type: RESET_DEFAULT_RESIDENT
  }
}
export function prev_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_residents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_residents(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_residents_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_residents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_residents(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_residents_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_starting_after(payload) {
  return {
    type: SET_STARTING_AFTER,
    payload: payload
  }
}
export function set_resident_id_proof_img(payload) {
  return {
    type: SET_ID_PROOF_IMAGE,
    payload: payload.target.files
  }
}
export function set_all_residents(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_ALL_RESIDENTS,
    payload: obj,
  }
}
export function set_all_residents_pagination(responseJson, limit, starting_after) {
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  return {
    type: SET_ALL_RESIDENTS,
    payload: obj,
  }
}
export function validate_resident_func(token, residents, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    if (residents.resident_name === "") {
      dispatch(set_snack_bar(true, "Please Enter Resident Name"));
      dispatch(unset_loader())
    } else if (residents.resident_email === "") {
      dispatch(set_snack_bar(true, "Please Enter  Description"));
      dispatch(unset_loader())
    }
    else if (residents.resident_contact_info === "") {
      dispatch(set_snack_bar(true, "Please Enter Contact Information"));
      dispatch(unset_loader())
    }
    else if (residents.resident_permanent_address === "") {
      dispatch(set_snack_bar(true, "Please Enter Permanent Address"));
      dispatch(unset_loader())
    }
    else if (residents.resident_id_proof_id === "") {
      dispatch(set_snack_bar(true, "Please Enter Your Id Proof Number"));
      dispatch(unset_loader())
    }
    else if (residents.resident_image === "") {
      dispatch(set_snack_bar(true, "Please Upload Your Image"));
      dispatch(unset_loader())
    } else {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + residents.resident_name + ".png").put(residents.resident_image);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch(add_image(token, residents, downloadURL, building_id, 0, []));
        });
      });
    }
  };
}
export function invite_resident(token, residents, building_id, unit_id, is_residing) {
  var is_sub_resident = residents.is_sub_resident;
  var is_owner = residents.is_owner;
  if (is_residing === false) {
    is_sub_resident = false;
    is_owner = true;
  }
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/create_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        resident_email: residents.resident_email,
        resident_contact_info: residents.resident_contact_info,
        unit_id: unit_id,
        is_sub_resident: is_sub_resident,
        is_owner: is_owner,
        is_residing: is_residing
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Invitation sent"));
          dispatch(reset_all());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function add_image(token, residents, url, building_id, intial, array) {
  var arr = array
  return (dispatch) => {
    if (arr.length !== residents.residents_id_proof_img.length) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + intial + residents.residents_name + ".png").put(residents.residents_id_proof_img[intial]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          arr.push(downloadURL)
          dispatch(add_image(token, residents, url, building_id, parseInt(intial) + 1, arr));
        });
      });
    }
    if (residents.residents_id_proof_img.length === arr.length) {
      dispatch(add_resident(token, residents, url, building_id, arr))
    }
  }
}
export function add_resident(token, residents, downloadURL, building_id, arr) {
  return (dispatch) => {
    var resident_id_proof = [{ "idproof_id": residents.resident_id_proof_id, "idproof_type": residents.resident_id_proof_type, "idproof_image": arr }];
    resident_id_proof = JSON.stringify(resident_id_proof);
    var is_sub_resident = residents.is_sub_resident;
    var is_owner = residents.is_owner;
    if (residents.is_residing === false) {
      is_sub_resident = false;
      is_owner = true;
    }
    return fetch(UNIVERSAL.BASEURL + "v1/add_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        resident_name: residents.resident_name,
        resident_img: downloadURL,
        resident_email: residents.resident_email,
        resident_contact_info: residents.resident_contact_info,
        resident_sec_contact_info: residents.resident_sec_contact_info,
        resident_permanent_address: residents.resident_permanent_address,
        resident_id_proof: resident_id_proof,
        unit_id: residents.selected_unit,
        is_owner: is_owner,
        is_residing: residents.is_residing,
        is_sub_resident: is_sub_resident
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.active) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(reset_all())
          }
          else {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function reset_all() {
  return {
    type: RESET_ALL_RESIDENTS,
  }
}
export function set_resident_email(payload) {
  return {
    type: SET_RESIDENT_EMAIL,
    payload: payload
  }
} export function set_resident_contact_info(payload) {
  return {
    type: SET_RESIDENT_CONTACT_INFO,
    payload: payload
  }
} export function set_resident_secondary_contact_info(payload) {
  return {
    type: SET_RESIDENT_SECONDARY_CONTACT_INFO,
    payload: payload
  }
} export function set_resident_permanent_address(payload) {
  return {
    type: SET_RESIDENT_PERMANENT_ADDRESS,
    payload: payload
  }
}
export function set_resident_id_proof_type(payload) {
  return {
    type: SET_RESIDENT_ID_PROOF_TYPE,
    payload: payload
  }
}
export function set_resident_id_proof_id(payload) {
  return {
    type: SET_RESIDENT_ID_PROOF_ID,
    payload: payload
  }
}
export function set_resident_vehicle_details(payload) {
  var data = JSON.parse(payload)
  var resident_vehicle_details = JSON.stringify({
    vehicle_id: data.vehicle_id,
    vehicle_name: data.vehicle_name,
    vehicle_type: data.vehicle_type
  })
  return {
    type: SET_RESIDENT_VEHICLE_DETAILS,
    payload: resident_vehicle_details
  }
}
export function set_resident_rc_num(payload) {
  var payloads = payload.toUpperCase();
  return {
    type: SET_RESIDENT_RC_NUM,
    payload: payloads
  }
}
export function set_resident_name(payload) {
  return {
    type: SET_RESIDENT_NAME,
    payload: payload
  }
}
export function set_resident_image(payload) {
  return {
    type: SET_RESIDENT_IMAGE,
    payload: payload.target.files[0]
  }
}
export function set_is_owner(payload) {
  var payloads;
  if (payload === "false") {
    payloads = true;
  }
  else {
    payloads = false;
  }
  return {
    type: SET_IS_OWNER,
    payload: payloads,
  }
}
export function set_is_residing(payload) {
  var payloads;
  if (payload === "false") {
    payloads = true;
  }
  else {
    payloads = false;
  }
  return {
    type: SET_IS_RESIDING,
    payload: payloads,
  }
}
export function set_is_subresidents(payload) {
  var payloads;
  if (payload === "false") {
    payloads = true;
  }
  else {
    payloads = false;
  }
  return {
    type: SET_IS_SUB_RESIDENT,
    payload: payloads,
  }
}
export function set_unit_id(payload) {
  return {
    type: SET_UNIT_ID,
    payload: payload,
  }
}
export function validate_resident_edit_func(token, resident_id_proof_id, resident_id_proof_type, resident_vehicle_details, resident_rc_no, resident_id, resident_name, resident_img, resident_img_old, resident_email, resident_contact_info, resident_sec_contact_info, resident_permanent_address, unit_id, is_owner, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    if (resident_name === "") {
      dispatch(set_snack_bar(true, "Please enter Resident Name"));
      dispatch(unset_loader())
    } else if (resident_email === "") {
      dispatch(set_snack_bar(true, "Please enter  Description"));
      dispatch(unset_loader())
    }
    else if (resident_contact_info === "") {
      dispatch(set_snack_bar(true, "Please enter Contact Information"));
      dispatch(unset_loader())
    }
    else if (resident_permanent_address === "") {
      dispatch(set_snack_bar(true, "Please enter Permanent Address"));
      dispatch(unset_loader())
    }
    else if (resident_img === "") {
      dispatch(update_resident_info(token, resident_id_proof_id, resident_id_proof_type, resident_vehicle_details, resident_rc_no, resident_id, resident_name, resident_img_old, resident_email, resident_contact_info, resident_sec_contact_info, resident_permanent_address, unit_id, is_owner, building_id, limit, starting_after));
    } else {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + resident_name + ".png").put(resident_img);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch(update_resident_info(token, resident_id_proof_id, resident_id_proof_type, resident_vehicle_details, resident_rc_no, resident_id, resident_name, downloadURL, resident_email, resident_contact_info, resident_sec_contact_info, resident_permanent_address, unit_id, is_owner, building_id, limit, starting_after));
        });
      });
    }
  };
}
export function update_resident_info(token, resident_id_proof_id, resident_id_proof_type, resident_vehicle_details, resident_rc_no, resident_id, resident_name, resident_img, resident_email, resident_contact_info, resident_sec_contact_info, resident_permanent_address, unit_id, is_owner, building_id, limit, starting_after) {
  var resident_id_proof = [{ "idproof_id": resident_id_proof_id, "idproof_type": resident_id_proof_type }];
  resident_id_proof = JSON.stringify(resident_id_proof);
  resident_vehicle_details = JSON.parse(resident_vehicle_details)
  resident_vehicle_details = [{ "vehicle_id": resident_vehicle_details.vehicle_id, vehicle_name: resident_vehicle_details.vehicle_name, vehicle_type: resident_vehicle_details.vehicle_type, vehicle_regd_num: resident_rc_no }]
  resident_vehicle_details = JSON.stringify(resident_vehicle_details)
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/update_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        resident_id: resident_id,
        building_id: building_id,
        resident_name: resident_name,
        resident_img: resident_img,
        resident_email: resident_email,
        resident_contact_info: resident_contact_info,
        resident_sec_contact_info: resident_sec_contact_info,
        resident_permanent_address: resident_permanent_address,
        resident_id_proof: resident_id_proof,
        resident_vehicle_details: resident_vehicle_details,
        unit_id: unit_id,
        is_owner: is_owner,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Resident Updated Succcessfully"));
          dispatch(get_same_residents(token, building_id, limit, starting_after));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Resident Could Not Be Updated"));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function delete_user_info(token, resident_id, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/delete_resident", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        resident_id: resident_id,
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Resident Deleted Succcessfully"));
          dispatch(get_same_residents(token, building_id, limit, starting_after))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Resident Could Not Be Deleted"));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
