import React, { Component } from "react";
import { connect } from "react-redux";
import InviteResidents from "../../components/residents/invite_residents";
import {
    set_resident_email,
    set_resident_contact_info,
    set_is_owner,
    reset_all,
    invite_resident,
    set_is_subresidents,
    set_is_residing
} from "../../actions/residents/residents_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Invite_Residents_cont extends Component {
    render() {
        return (
            <InviteResidents {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        residents: store.residents,
        vehicles: store.vehicles,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        id_proofs: store.id_proofs,
        units: store.units,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        set_resident_email: (payload) => {
            dispatch(set_resident_email(payload));
        },
        set_resident_contact_info: (payload) => {
            dispatch(set_resident_contact_info(payload));
        },
        set_is_owner: (payload) => {
            dispatch(set_is_owner(payload));
        },
        reset_all: () => {
            dispatch(reset_all());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        invite_resident: (token, residents, building_id, unit_id, is_residing) => {
            dispatch(invite_resident(token, residents, building_id, unit_id, is_residing));
        },
        set_is_subresidents: (payload) => {
            dispatch(set_is_subresidents(payload));
        },
        set_is_residing: (payload) => {
            dispatch(set_is_residing(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Invite_Residents_cont);