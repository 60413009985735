import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from '@material-ui/core/Checkbox';
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
class AddResidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    this.props.get_same_units(this.props.login.token, this.props.login.building_id);
    this.props.get_all_id_proofs(this.props.login.token);
    this.props.get_all_vehicles(this.props.login.token);
    this.props.set_unit_id(this.props.location.state.unit_id);
  }
  render() {
    const {
      residents,
      snackbar,
      close_snack_bar,
      login,
      id_proofs,
      validate_resident_func,
      set_resident_email,
      set_resident_contact_info,
      set_resident_secondary_contact_info,
      set_resident_permanent_address,
      set_resident_id_proof_type,
      set_resident_id_proof_id,
      set_resident_name,
      set_resident_image,
      set_is_owner,
      set_is_residing,
      set_resident_id_proof_img,
      set_is_subresidents
    } = this.props;
    return (
      <Grid container item xs={12} lg={12} spacing={8}  >
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD RESIDENT
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container>
                <Grid container item lg={4}>
                  <Grid item xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_name}
                      onChange={(event) => { set_resident_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    Image : <input type="file"
                      onChange={(event) => { set_resident_image(event); }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Email"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_email}
                      onChange={(event) => { set_resident_email(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Contact Info"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_contact_info}
                      onChange={(event) => { set_resident_contact_info(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Secondary contact number"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_sec_contact_info}
                      onChange={(event) => { set_resident_secondary_contact_info(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Permanent Address"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_permanent_address}
                      onChange={(event) => { set_resident_permanent_address(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="ID Proof Type"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={residents.resident_id_proof_type}
                      onChange={(event) => { set_resident_id_proof_type(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {id_proofs.all_id_proofs.map((single) => (
                        <MenuItem key={single.proof_name} value={single.proof_name}>
                          {single.proof_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {residents.resident_id_proof_type !== "" ?
                    <Grid item lg={12} xs={12}>
                      <TextField
                        id="outlined-email-input"
                        label="ID Proof Number"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={residents.resident_id_proof_id}
                        onChange={(event) => { set_resident_id_proof_id(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                      ID Proof Image :
                    <input type="file" multiple
                        onChange={(event) => { set_resident_id_proof_img(event); }}
                      />
                    </Grid>
                    :
                    ""
                  }
                  <Grid item lg={12} xs={12}>
                    <Checkbox
                      checked={residents.is_residing}
                      value={residents.is_residing}
                      onChange={(event) => { set_is_residing(event.target.value) }}
                      size="small"
                    />
                    Tick if person resides in the unit
                  </Grid>
                  <Grid xs={12} style={{ backgroundColor: "#f2f2f2", borderRadius: 10 }}>
                    {residents.is_residing === true &&
                      <Grid item lg={12} xs={12}>
                        <Checkbox
                          checked={residents.is_owner}
                          value={residents.is_owner}
                          onChange={(event) => { set_is_owner(event.target.value) }}
                          size="small"
                        />
                      Tick if person is <b>ALSO</b> the owner
                    </Grid>
                    }
                    {residents.is_residing === true &&
                      <Grid item lg={12} xs={12}>
                        <Checkbox
                          checked={residents.is_sub_resident}
                          value={residents.is_sub_resident}
                          onChange={(event) => { set_is_subresidents(event.target.value) }}
                          size="small"
                        />
                        Tick if person is a family member
                      </Grid>
                    }
                    <Grid item lg={12} xs={12} style={{ padding: 20 }}>
                      NOTE: do not tick anything if the person is an owner who <b>IS NOT</b> residing at the unit.
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => { validate_resident_func(login.token, residents, login.building_id); }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddResidents);