import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ImageViewer from "../imageViewer/imageViewer";

const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class ViewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kyc_approve: false
    }
  }
  componentDidMount() {
    if (this.props.location.state.name === "HELPER") {
      this.props.view_helper_unit(this.props.login.token, this.props.location.state.row._id, this.props.login.building_id)
    }
  }
  render() {
    const {
      login,
      snackbar,
      close_snack_bar,
      toggleapprove,
      helper,
      del_helper_unit
    } = this.props;
    return (
      <Grid container justify="center">
        <Grid container item xs={12} lg={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW {this.props.location.state.name} DETAILS
              </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              {this.props.location.state.name === "HELPER" ?
                <Grid container>
                  <Grid item lg={6} xs={12}>
                    <List
                      style={{ marginLeft: 10 }}
                    >
                      <ListItem>
                        <ListItemText primary="Helper Picture:"
                          style={{ textDecoration: "underline" }}
                        />
                      </ListItem>
                      <img
                        style={{ width: 170, marginLeft: 15 }}
                        src={this.props.location.state.row.helper_img}
                        alt="no-img"
                        onClick={() => {
                          this.setState({
                            image: this.props.location.state.row.helper_img
                          })
                        }}
                      />
                    </List>
                    <br />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <List>
                      <ListItem>
                        <ListItemText primary="Name"
                        />
                        {this.props.location.state.row.helper_name}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Service" />
                        {this.props.location.state.row.helper_service}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Phone Number" />
                        {this.props.location.state.row.helper_contact_info}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Address" />
                        {this.props.location.state.row.helper_permanent_address}
                        <Divider />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Secondary Contact" />
                        {this.props.location.state.row.helper_sec_contact_info}
                        <Divider />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <List>
                      <ListItem>
                        <ListItemText primary="ID-Proof Photos:"
                          style={{ textDecoration: "underline" }}
                        />
                      </ListItem>
                      {this.props.location.state.row.helper_id_proofs.length > 0 && this.props.location.state.row.helper_id_proofs[0].idproof_image ?
                        <div>
                          {this.props.location.state.row.helper_id_proofs.map((s) => (
                            s.idproof_image.map((su) => (
                              <img alt="no-img"
                                key={su._id}
                                src={su}
                                style={{ height: 180, width: 180, margin: 20 }}
                                onClick={() => {
                                  this.setState({
                                    image: su
                                  })
                                }}
                              />
                            ))
                          ))}
                        </div>
                        : ""}
                    </List>
                  </Grid>
                  <Grid item lg={6} xs={12} >
                    <br /><br />
                    {this.props.location.state.row.helper_id_proofs &&
                      <List >
                        <ListItem>
                          <ListItemText primary="ID-Proof Type:"
                            style={{ textDecoration: "underline" }}
                          />
                          {this.props.location.state.row.helper_id_proofs[0].idproof_type}
                          <Divider />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="ID-Proof Number:"
                            style={{ textDecoration: "underline" }}
                          />
                          {this.props.location.state.row.helper_id_proofs[0].idproof_id}
                          <Divider />
                        </ListItem>
                        {this.props.location.state.row.is_kyc_approved ?
                          <h2 style={{ color: "red", marginLeft: 15 }}>
                            KYC Approved
                          </h2>
                          :
                          <Button color="secondary" variant="outlined"
                            style={{ marginLeft: 15 }}
                            onClick={() => {
                              this.setState({
                                kyc_approve: true
                              })
                            }}
                          >
                            Approve KYC
                          </Button>
                        }
                      </List>
                    }
                  </Grid>
                  {
                    helper.helper_unit.length !== 0 ?
                      <Grid item lg={12} xs={12} >
                        {helper.helper_unit.length !== 0 &&
                          <List>
                            <ListItem>
                              <ListItemText primary="Helper Units:"
                                style={{ textDecoration: "underline" }}
                              />
                            </ListItem>
                            {helper.helper_unit.map(r => (
                              <Chip
                                style={{ marginLeft: 10 }}
                                label={r.unit_parent_name + " - " + r.unit_name}
                                onDelete={() => { del_helper_unit(this.props.login.token, this.props.login.building_id, this.props.location.state.row._id, r.unit_id) }}
                                color="secondary"
                              />
                            ))}
                          </List>
                        }
                      </Grid> :
                      <Grid item lg={6} xs={12} >
                        <ListItem>
                          <ListItemText primary="Helper Units:"
                            style={{ textDecoration: "underline" }}
                          />
                          <p style={{ color: "red" }}> Helper is not assigned to any Units</p>
                        </ListItem>
                      </Grid>
                  }
                </Grid>
                :
                this.props.location.state.name === "EMPLOYEE" ?
                  <Grid container >
                    <Grid item lg={6} xs={12}>
                      <List
                      >
                        <ListItem>
                          <ListItemText primary="Profile Picture:"
                            style={{ textDecoration: "underline" }}
                          />
                        </ListItem>
                        <img
                          style={{ width: 150, marginLeft: 20 }}
                          src={this.props.location.state.row.employee_img}
                          alt="no-img"
                          onClick={() => {
                            this.setState({
                              image: this.props.location.state.row.employee_img
                            })
                          }}
                        />
                      </List>
                      <br />
                      <List
                      >
                        {this.props.location.state.row.employee_id_proof.id_proof_image ?
                          <div>
                            <ListItemText primary="ID-proof Type:"
                              style={{ textDecoration: "underline" }}
                            />
                            {this.props.location.state.row.employee_id_proof.id_proof_type}
                            <ListItemText primary="ID-proof Nummber:"
                              style={{ textDecoration: "underline" }}
                            />
                            {this.props.location.state.row.employee_id_proof.id_proof_number}
                          </div>
                          : ""}
                        <ListItem>
                          <ListItemText primary="ID-proof Photos:"
                            style={{ textDecoration: "underline", marginLeft: -15 }}
                          />
                        </ListItem>
                        {this.props.location.state.row.employee_id_proof.id_proof_image &&
                          <div>
                            {this.props.location.state.row.employee_id_proof.id_proof_image.map((s) => (
                              <img alt="no-img"
                                key={s._id}
                                src={s}
                                style={{ height: 180, width: 180, marginRight: 20 }}
                                onClick={() => {
                                  this.setState({
                                    image: s
                                  })
                                }}
                              />
                            ))}
                          </div>
                        }
                      </List>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <List>
                        <ListItem style={{ paddingBottom: 0 }}>
                          <ListItemText primary="Name"
                          />
                          {this.props.location.state.row.employee_name}
                          <Divider />
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="Designation"
                          />
                          {this.props.location.state.row.employee_designation}
                          <Divider />
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="Phone Number" />
                          {this.props.location.state.row.employee_contact_info}
                          <Divider />
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="E-mail" />
                          {this.props.location.state.row.employee_email}
                          <Divider />
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="Employee Code" />
                          {this.props.location.state.row.employee_code}
                          <Divider />
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="Salary " />
                          {this.props.location.state.row.employee_salary_amount}
                          <Divider />
                        </ListItem>
                        {(this.props.location.state.vendorName !== "" && this.props.location.state.vendorName !== undefined )
                          &&
                          <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <ListItemText primary="Vendor Name " />
                            {this.props.location.state.vendorName.vendor_name}
                            <Divider />
                          </ListItem>
                        }
                      </List>
                      <div style={{ marginLeft: 15 }}>
                        <ListItemText primary="Roles: "
                          style={{ textDecoration: "underline" }}
                        />
                        {this.props.location.state.row.departments.map(r => (
                          <div>
                            {r}
                          </div>
                        ))}
                      </div><br /><br />
                      <ListItemText primary="Bank Details:"
                        style={{ textDecoration: "underline", marginLeft: 10 }}
                      />
                      <List>
                        <ListItem style={{ paddingBottom: 0 }}>
                          <ListItemText primary="Name:" />
                          {this.props.location.state.row.employee_bank_name}
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="Account Number:" />
                          {this.props.location.state.row.employee_bank_account_no}
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="Branch:" />
                          {this.props.location.state.row.employee_bank_branch}
                        </ListItem>
                        <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
                          <ListItemText primary="IFSC code:" />
                          {this.props.location.state.row.employee_bank_ifsc_code}
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                  : ""}
            </CardContent>
          </Card>
          <Dialog
            open={this.state.kyc_approve}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Approve Its KYC</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    kyc_approve: false
                  });
                }}
                color="primary">
                NO
              </Button>
              <Link
                style={{ textDecoration: "none" }}
                to="all_helpers">
                <Button
                  onClick={() => {
                    toggleapprove(login.token, this.props.location.state.row._id, login.building_id);
                    this.setState({
                      kyc_approve: false
                    });
                  }}
                  color="primary" autoFocus>
                  Yes
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
        <ImageViewer
          image={this.state.image}
          close={() => {
            this.setState({
              image: null
            })
          }}
        />
      </Grid>
    );
  }
}
export default withStyles(styles)(ViewDetails);