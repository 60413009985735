import React, { Component } from "react";
import { connect } from "react-redux";
import ViewHelper from "../../components/helper/view_helper";
import {
  get_all_helpers,
  validate_helper_edit_func,
  delete_helper_info,
  prev_btn,
  next_btn,
  toggleCheckbox,
  set_id_proof_image
} from "../../actions/helper/helpers_action";
import {
  get_all_id_proofs,
} from "../../actions/id_proofs/id_proofs_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";

export class View_helper_cont extends Component {
  render() {
    return (
      <ViewHelper {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    helper: store.helper,
    login: store.login,
    id_proofs: store.id_proofs,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    get_all_id_proofs: (token) => {
      dispatch(get_all_id_proofs(token))
    },
    set_id_proof_image: (payload) => {
      dispatch(set_id_proof_image(payload));
    },
    get_all_helpers: (token, building_id, limit, starting_after) => {
      dispatch(get_all_helpers(token, building_id, limit, starting_after))
    },
    delete_helper_info: (token, helper_id, building_id, limit, starting_after) => {
      dispatch(delete_helper_info(token, helper_id, building_id, limit, starting_after))
    },
    validate_helper_edit_func: (token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, helper_img, helper_img_old, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, idproof_image, helper) => {
      dispatch(validate_helper_edit_func(token, helper_id_proof_id, helper_id_proof_type, helper_id, helper_name, helper_img, helper_img_old, helper_email, helper_contact_info, helper_sec_contact_info, helper_permanent_address, helper_service, is_private, building_id, limit, starting_after, idproof_image, helper))
    },
    prev_btn: (token, building_id, limit, starting_after) => {
      dispatch(prev_btn(token, building_id, limit, starting_after))
    },
    next_btn: (token, building_id, limit, starting_after) => {
      dispatch(next_btn(token, building_id, limit, starting_after))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    toggleCheckbox: (obj, array) => {
      dispatch(toggleCheckbox(obj, array));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_helper_cont);