import React, { Component } from "react";
import { connect } from "react-redux";
import Amenities from "../../components/amenities/amenities";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
} from "../../constants/amenities/amenities_const";
import {
    get_all_amenities,
    reset_amenities,
    get_same_amenities,
    prev_btn,
    next_btn,
    delete_user_info,
    reset_amenities_pagination,
    update_amenities,
    set_images,
    add_amenity_images,
    set_del_images,
} from "../../actions/amenities/amenities_action"
export class Amenities_cont extends Component {
    render() {
        return (
            <Amenities {...this.props} />
        );
    };
};
export const mapStateToProps = store => {
    return {
        amenities: store.amenities,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_amenities: (token, building_id, limit, starting_after) => {
            dispatch(get_all_amenities(token, building_id, limit, starting_after))
        },
        reset_amenities: () => {
            dispatch(reset_amenities())
        },
        reset_amenities_pagination: () => {
            dispatch(reset_amenities_pagination())
        },
        get_same_amenities: (token, building_id, limit, starting_after) => {
            dispatch(get_same_amenities(token, building_id, limit, starting_after))
        },
        prev_btn: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn(token, building_id, limit, starting_after))
        },
        next_btn: (token, building_id, limit, starting_after) => {
            dispatch(next_btn(token, building_id, limit, starting_after))
        },
        delete_user_info: (token, amenity_id, limit, starting_after, building_id) => {
            dispatch(delete_user_info(token, amenity_id, limit, starting_after, building_id))
        },
        update_amenities: (token, building_id, amenity_id, amenity_name, amenity_desc, amenity_img, isfree, amenity_fullday_price, guest_fullday_price, start_time, end_time, no_of_slots, limit, starting_after, amenity_images) => {
            dispatch(update_amenities(token, building_id, amenity_id, amenity_name, amenity_desc, amenity_img, isfree, amenity_fullday_price, guest_fullday_price, start_time, end_time, no_of_slots, limit, starting_after, amenity_images))
        },
        set_images: (payload) => {
            dispatch(set_images(payload))
        },
        add_amenity_images: (payload, amenities) => {
            dispatch(add_amenity_images(payload, amenities))
        },
        set_del_images: (payload, amenities) => {
            dispatch(set_del_images(payload, amenities))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Amenities_cont); 