import {
  SET_VENDOR_NAME,
  SET_VENDOR_SERVICE,
  SET_LICENSE_NUMBER,
  SET_PAYEMENT_TYPE,
  SET_FIXED_AMOUNT,
  SET_ACCOUNT_NUMBER,
  SET_BANK_NAME,
  SET_BANK_IFSC,
  SET_BANK_BRANCH,
  RESET_VENDOR,
  SET_VENDOR_CONTACT,
  SET_ALL_VENDORS,
  SET_SINGLE_VENDOR,
  SET_IS_FIXED,
  SET_STARTING_AFTER_VENDORS,
  SET_ONLY_ALL_VENDORS,
  SET_CONTACT_NUM,
  SET_CONTACT_NAME,
  SET_OTHER_CONTACT,
  SET_VENDORS_DETAILS,
  SET_BUILDING_VENDORS,
  SET_VENDOR_TYPE,
  SET_VENDOR_IMAGE,
  SET_CONTACT_IMAGE,
  RESET,
  SET_ID_PROOF_NUMBER,
  SET_ID_PROOF_IMAGE,
  SET_ID_PROOF_ID
} from "../../constants/vendors/vendors_const";
import { reset_all } from "../employee/employee_action";
import {
  set_loader,
  unset_loader
} from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
import { RepeatOneSharp } from "@material-ui/icons";
export function set_employee_id_proof_id(payload) {
  return {
    type: SET_ID_PROOF_ID,
    payload: payload,
  }
}
export function set_employee_id_proof_img(payload) {
  return {
    type: SET_ID_PROOF_IMAGE,
    payload: payload
  }
}
export function set_employee_id_proof_type(payload) {
  return {
    type: SET_ID_PROOF_NUMBER,
    payload: payload
  }
}
export function set_vendor_name(payload) {
  return {
    type: SET_VENDOR_NAME,
    payload: payload,
  }
}
export function set_vendor_service(payload) {
  return {
    type: SET_VENDOR_SERVICE,
    payload: payload
  }
}
export function set_license_no(payload) {
  return {
    type: SET_LICENSE_NUMBER,
    payload: payload
  }
}
export function payment_type(payload) {
  return {
    type: SET_PAYEMENT_TYPE,
    payload: payload
  }
}
export function set_fixed_amount(payload) {
  return {
    type: SET_FIXED_AMOUNT,
    payload: payload
  }
}
export function set_account_no(payload) {
  return {
    type: SET_ACCOUNT_NUMBER,
    payload: payload
  }
}
export function set_bank_name(payload) {
  return {
    type: SET_BANK_NAME,
    payload: payload
  }
}
export function set_bank_ifsc(payload) {
  return {
    type: SET_BANK_IFSC,
    payload: payload
  }
}
export function set_bank_branch(payload) {
  return {
    type: SET_BANK_BRANCH,
    payload: payload
  }
}
export function set_vendor_type(payload) {
  return {
    type: SET_VENDOR_TYPE,
    payload: payload
  }
}
export function set_vendor_image(payload) {
  return {
    type: SET_VENDOR_IMAGE,
    payload: payload.target.files
  }
}
export function set_contact_image(payload) {
  return {
    type: SET_CONTACT_IMAGE,
    payload: payload
  }
}
export function remove_other_contact(contacts, index) {
  if (index === "removeAllData") {
    contacts = []
  }
  else {
    contacts.splice(index, 1);
  }

  return {
    type: SET_OTHER_CONTACT,
    payload: contacts,
  }
}
export function get_all_vendors_superAdmin(token, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_vendors", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_vendors_superAdmin(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_vendors_superAdmin_pagination(responseJson, limit, starting_after))
          }
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_all_vendors_superAdmin(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0, starting_after: starting_after };
  } else {
    for (var i = 0; i < responseJson.result.length; i++) {
      responseJson.result[i].checked = false;
    }
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total, starting_after: starting_after };
  }
  return {
    type: SET_ALL_VENDORS,
    payload: obj,
  }
}
export function set_all_vendors_superAdmin_pagination(responseJson, limit, starting_after) {
  for (var i = 0; i < responseJson.result.length; i++) {
    responseJson.result[i].checked = false;
  }
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total, starting_after: starting_after };
  return {
    type: SET_ALL_VENDORS,
    payload: obj,
  }
}
export function get_all_vendors(token, limit, starting_after, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_vendors", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_vendors(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_vendors_pagination(responseJson, limit, starting_after))
          }
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_vendor_details(token, vendor_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_vendor_detail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        vendor_id: vendor_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_vendor_details(responseJson))
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_building_vendors(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_building_vendor_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_building_vendors(responseJson, limit, starting_after))
          } else {
            dispatch(set_building_vendors_pagination(responseJson, limit, starting_after))
          }
        } else {
          dispatch(set_building_vendors_pagination({ result: [] }, limit, starting_after))
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_vendors", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_vendors(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_vendors_pagination(responseJson, limit, starting_after))
          }
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_vendors", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_starting_after(starting_after));
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_vendors(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_vendors_pagination(responseJson, limit, starting_after))
          }
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_starting_after(payload) {
  return {
    type: SET_STARTING_AFTER_VENDORS,
    payload: payload
  }
}
export function set_vendor_details(payload) {
  return {
    type: SET_VENDORS_DETAILS,
    payload: payload.result
  }
}
export function get_all_vendors_updated(token, vendors, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_vendors", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: vendors.limit,
        starting_after: vendors.starting_after,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= vendors.limit) {
            dispatch(set_all_vendors(responseJson, vendors.limit, vendors.starting_after))
          } else {
            dispatch(set_all_vendors_pagination(responseJson, vendors.limit, vendors.starting_after))
          }
        } else {
          if (responseJson.message === "No Vendor Found") {
            dispatch(set_all_vendors({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_single_vendors_updated(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_building_vendor_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_building_vendors(responseJson, limit, starting_after))
          } else {
            dispatch(set_building_vendors_pagination(responseJson, limit, starting_after))
          }
        } else {
          if (responseJson.message === "No Vendor Found") {
            dispatch(set_all_vendors({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_all_vendors(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  } else {
    for (var i = 0; i < responseJson.result.length; i++) {
      responseJson.result[i].checked = false;
    }
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_ALL_VENDORS,
    payload: obj,
  }
}
export function set_all_vendors_pagination(responseJson, limit, starting_after) {
  for (var i = 0; i < responseJson.result.length; i++) {
    responseJson.result[i].checked = false;
  }
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  return {
    type: SET_ALL_VENDORS,
    payload: obj,
  }
}
export function set_building_vendors(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  } else {
    for (var i = 0; i < responseJson.result.length; i++) {
      responseJson.result[i].checked = false;
    }
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_BUILDING_VENDORS,
    payload: obj,
  }
}
export function set_building_vendors_pagination(responseJson, limit, starting_after) {
  for (var i = 0; i < responseJson.result.length; i++) {
    responseJson.result[i].checked = false;
  }
  if (responseJson.result.length === 0) {

    var obj = { response: responseJson.result, f: "", s: "", total: "" };

  }
  else {
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  } return {
    type: SET_BUILDING_VENDORS,
    payload: obj,
  }
}
export function toggleCheckbox(obj, array) {
  var index = array.indexOf(obj);
  array[index].checked = true;
  var obj1 = { array: array, arr: true }
  return {
    type: SET_ONLY_ALL_VENDORS,
    payload: obj1,
  }
}
export function view_single_vendor_info(token, vendor_id) {
  return (dispatch) => {
    dispatch(set_loader());
    // dispatch(set_reset_type())
    return fetch(UNIVERSAL.BASEURL + "v1/view_vendor_detail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        vendor_id: vendor_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_single_vendor(responseJson.result))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_single_vendor(payload) {
  return {
    type: SET_SINGLE_VENDOR,
    payload: payload,
  }
}
export function set_contact_name(payload) {
  return {
    type: SET_CONTACT_NAME,
    payload: payload,
  }
}
export function set_contact_num(payload) {
  return {
    type: SET_CONTACT_NUM,
    payload: payload,
  }
}
export function set_other_contact(payload) {
  let data = {
    contact_person: payload.contact_name,
    contact_phonenumber: payload.contact_num,
    contact_image: payload.contact_image,
    render_image: null
  }
  var payloads = payload.other_contact;
  let reader = new FileReader();
  reader.onload = (e) => {
    data.render_image = e.target.result
  };
  reader.readAsDataURL(payload.contact_image);
  payloads.push(data);
  return {
    type: SET_OTHER_CONTACT,
    payload: payloads,
  }
}
export function set_is_fixed(payload) {
  var payloads;
  if (payload === "true") {
    payloads = false
  } else {
    payloads = true
  }
  return {
    type: SET_IS_FIXED,
    payload: payloads,
  }
}
export function validate_single_vendor(token, building_id, vendor_id, vendors) {

  return (dispatch) => {
    dispatch(set_loader());
    var index = 0;
    var storageRef = firebase.storage().ref();
    let upload_other_contacts = function () {
      if (typeof (vendors.other_contact[index].contact_image) !== "string") {
        var uploadTask = storageRef.child("images/" + vendors.other_contact[index].contact_person + ".png").put(vendors.other_contact[index].contact_image);
        uploadTask.on("state_changed", function (snapshot) {
        }, function (error) {
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            vendors.other_contact[index].contact_image = downloadURL;
            index++;
            if (index < vendors.other_contact.length) {
              upload_other_contacts();
            } else {
              dispatch(update_single_vendor(token, building_id, vendor_id, vendors))
            }
          });
        });
      } else {
        index++;
        if (index < vendors.other_contact.length) {
          upload_other_contacts();
        } else {
          dispatch(update_single_vendor(token, building_id, vendor_id, vendors))
        }
      }
    }

    if (vendors.other_contact.length > 0) {
      upload_other_contacts();
    } else {
      dispatch(update_single_vendor(token, building_id, vendor_id, vendors))
    }
  };
}
export function update_single_vendor(token, building_id, vendor_id, vendors) {
  return (dispatch) => {

    var imageUrl = ""
    var storageRef = firebase.storage().ref();
    if (typeof (vendors.vendorIdImageToUpload) !== "string") {
      var uploadTask = storageRef.child("images/" + (vendors.vendorIdNumber.replace(" ", "-") + parseInt(Math.random() * 100)) + ".png").put(vendors.vendorIdImageToUpload);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        // dispatch(set_snack_bar(true, "Employee Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          imageUrl = downloadURL;

          return fetch(UNIVERSAL.BASEURL + "v1/update_vendor", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "user-token": token
            },
            body: JSON.stringify({
              building_id: building_id,
              vendor_id: vendor_id,
              vendor_name: vendors.vendor_name,
              vendor_service: vendors.vendor_service,
              vendor_govt_license_no: vendors.license_no,
              vendor_account_no: vendors.account_no,
              vendor_bank_name: vendors.Bank_name,
              vendor_bank_ifsc: vendors.bank_ifsc,
              vendor_bank_branch: vendors.branch,
              vendor_contact_info: vendors.contact,
              vendor_other_contacts: JSON.stringify(vendors.other_contact),
              vendor_id_type: vendors.vendorIdType,
              vendor_id_number: vendors.vendorIdNumber,
              vendor_id_image: imageUrl === "" ? vendors.vendorIdImage : imageUrl,
            }),
          }).then((response) => response.json())
            .then((responseJson) => {
              if (responseJson.status) {
                dispatch(set_snack_bar(responseJson.status, responseJson.message));
                dispatch(get_all_vendors_updated(token, vendors))
                // dispatch(get_building_vendors(token, building_id, vendors.limit, vendors.starting_after))
              } else {
                dispatch(set_snack_bar(responseJson.status, responseJson.message));
              }
              dispatch(unset_loader())
            })
            .catch((error) => {
              console.error(error);
            });

        })
      })
    } else {
      return fetch(UNIVERSAL.BASEURL + "v1/update_vendor", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "user-token": token
        },
        body: JSON.stringify({
          building_id: building_id,
          vendor_id: vendor_id,
          vendor_name: vendors.vendor_name,
          vendor_service: vendors.vendor_service,
          vendor_govt_license_no: vendors.license_no,
          vendor_account_no: vendors.account_no,
          vendor_bank_name: vendors.Bank_name,
          vendor_bank_ifsc: vendors.bank_ifsc,
          vendor_bank_branch: vendors.branch,
          vendor_contact_info: vendors.contact,
          vendor_other_contacts: JSON.stringify(vendors.other_contact),
          vendor_id_type: vendors.vendorIdType,
          vendor_id_number: vendors.vendorIdNumber,
          vendor_id_image: imageUrl === "" ? vendors.vendorIdImage : imageUrl,
        }),
      }).then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(get_all_vendors_updated(token, vendors))
            // dispatch(get_building_vendors(token, building_id, vendors.limit, vendors.starting_after))
          } else {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
          dispatch(unset_loader())
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
export function delete_single_vendor(token, building_id, vendors) {
  var arr = [];
  for (var i = 0; i < vendors.all_vendors.length; i++) {
    if (vendors.all_vendors[i].checked) {
      arr.push(vendors.all_vendors[i].vendor_id)
    }
  }
  return (dispatch) => {
    dispatch(set_loader());
    var arr1 = JSON.stringify(arr)
    return fetch(UNIVERSAL.BASEURL + "v1/delete_vendor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        vendor_id: arr1,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_all_vendors_superAdmin(token, vendors.limit, 0))
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function validate_add_vendors(token, building_id, vendors, employee) {
  return (dispatch) => {
    dispatch(set_loader());
    if (vendors.vendor_name === "") {
      dispatch(set_snack_bar(true, "Please enter Vendor Name"));
      dispatch(unset_loader());
    } else if (vendors.vendor_service === "") {
      dispatch(set_snack_bar(true, "Please select vendor service"));
      dispatch(unset_loader());
    } else if (vendors.vendor_image === null) {
      dispatch(set_snack_bar(true, "Please upload vendor image"));
      dispatch(unset_loader());
    } else if ((vendors.vendor_type === "I" && employee.employee_id_proof_img.length === 0) || (vendors.vendor_type === "I" && employee.employee_id_proof_id === "")) {
      dispatch(set_snack_bar(true, "Vendor id image or Number missing"));
      dispatch(unset_loader());
    } else {
      // UPLOAD VENDOR IMAGE
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + vendors.vendor_name.replace(" ", "-") + ".png").put(vendors.vendor_image[0]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Vendor Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          vendors.vendor_image = downloadURL;
          if (vendors.vendor_type === "I") {
            var uploadTask = storageRef.child("images/" + employee.employee_id_proof_id.replace(" ", "-") + ".png").put(employee.employee_id_proof_img[0]);
            uploadTask.on("state_changed", function (snapshot) {
            }, function (error) {
              dispatch(set_snack_bar(true, "Employee Image Could Not Be Uploaded"));
            }, function () {
              uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                employee.employee_id_proof_img = downloadURL;
                dispatch(add_vendor(token, vendors, building_id, employee))
              })
            })
          } else {
            var index = 0;
            let upload_contacts = function () {
              var uploadTask = storageRef.child("images/" + vendors.other_contact[index].contact_person.replace(" ", "-") + ".png").put(vendors.other_contact[index].contact_image);
              uploadTask.on("state_changed", function (snapshot) {
              }, function (error) {
                dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
              }, function () {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                  vendors.other_contact[index].contact_image = downloadURL;
                  vendors.other_contact[index].render_image = null;
                  index++;
                  if (index < vendors.other_contact.length) {
                    upload_contacts();
                  } else {
                    dispatch(add_vendor(token, vendors, building_id, employee))
                  }
                });
              });
            }
            if (vendors.other_contact.length > 0) {
              upload_contacts();
            } else {
              dispatch(add_vendor(token, vendors, building_id, employee))
              dispatch(unset_loader());
            }
          }
        });
      });
    };
  }
}
export function add_vendor(token, vendors, building_id, employee) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/add_vendor", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        vendor_name: vendors.vendor_name,
        vendor_image: vendors.vendor_image,
        vendor_type: vendors.vendor_type,
        vendor_service: vendors.vendor_service,
        vendor_govt_license_no: vendors.license_no,
        vendor_account_no: vendors.account_no,
        vendor_bank_name: vendors.Bank_name,
        vendor_bank_ifsc: vendors.bank_ifsc,
        vendor_bank_branch: vendors.branch,
        vendor_contact_info: vendors.contact,
        vendor_id_type: employee.employee_id_proof,
        vendor_id_number: employee.employee_id_proof_id,
        vendor_id_image: employee.employee_id_proof_img,
        vendor_other_contacts: JSON.stringify(vendors.other_contact)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(set_reset_type())
          dispatch(reset_all())
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function set_reset_type() {
  return {
    type: RESET_VENDOR,
  }
}
export function set_vendor_contact(payload) {
  return {
    type: SET_VENDOR_CONTACT,
    payload: payload,
  }
}
export function assign_vendor(token, building_id, vendor_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/assign_vendor_to_building", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        vendor_id: vendor_id,
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_single_vendors_updated(token, building_id, limit, starting_after))
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function remove_vendor(token, id, building_id, vendors) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/remove_venodr_from_building", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        vendor_id: id,
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_building_vendors(token, building_id));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function import_file_func(files, token, vendors) {
  return dispatch => {
    var formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/vendor_excelsheet_import", {
      method: "POST",
      headers: {
        "user-token": token
      },
      body: formData
    })
      .then(response => response.json())
      .then(responseJson => {
        "";
        if (responseJson.status) {
          dispatch(unset_loader());
          dispatch(set_snack_bar(true, responseJson.message))
          dispatch(get_all_vendors(token, vendors.limit, vendors.starting_after))
        } else {
          dispatch(set_snack_bar(responseJson.status, "FILE COULD NOT BE UPLOADED"));
          dispatch(unset_loader())
        }
        dispatch(unset_loader())
      })
      .catch(error => {
      });
  };
}
export function import_file_func2(rows, index) {
  var arr_length = rows.length;
  var end = rows.length;
  var stop = false;
  if ((index + 50) < arr_length) {
    end = index + 50;
  } else {
    end = rows.length;
    stop = true;
  }
  return dispatch => {
    return fetch(UNIVERSAL.BASEURL + "v1/vendor_excelsheet_importoriginal", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        rows: JSON.stringify(rows.slice(index, end))
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status) {
          if (stop) {
            dispatch(set_snack_bar(responseJson.status, "FILE UPLOADED SUCCESSFULLY"));
            dispatch(unset_loader());
          } else {
            dispatch(import_file_func2(rows, end));
          }
        } else {
          dispatch(
            set_snack_bar(responseJson.status, "FILE COULD NOT BE UPLOADED")
          );
        }
      })
      .catch(error => {
        console.log(error)
      });
  };
}
export function reset() {
  return {
    type: RESET
  }
}