import { LIMIT } from "../../constants/ActionTypes";
import {
    SET_STARTING_AFTER,
    SET_ALL_HELPERS,
    RESET_ALL,
    SET_HELPER_EMAIL,
    SET_HELPER_CONTACT_INFO,
    SET_HELPER_SECONDARY_CONTACT_INFO,
    SET_HELPER_PERMANENT_ADDRESS,
    SET_HELPER_ID_PROOF_TYPE,
    SET_HELPER_ID_PROOF_ID,
    SET_HELPER_NAME,
    SET_HELPER_IMAGE,
    SET_HELPER_SERVICE,
    SET_ONLY_HELPERS,
    SELECT_HELPER,
    SET_IS_PRIVATE,
    SET_ID_PROOF_IMAGE,
    SET_HELPER_UNIT,
    SET_UNIT_ID_FOR_PRIVATE_HELPER
} from "../../constants/helper/helper_const";
const initial_state = {
    all_helper: [],
    helper_name: "",
    helper_email: "",
    helper_contact_info: "",
    helper_sec_contact_info: "",
    helper_permanent_address: "",
    helper_id_proof_type: "",
    helper_id_proof_id: "",
    helper_image: "",
    helper_service: "",
    starting_after: 0,
    start_var: "",
    end_var: "",
    limit: LIMIT,
    total: "",
    selected_helper: "",
    is_private: false,
    image: "",
    helper_unit: [],
    unit_id_for_private_helper: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_UNIT_ID_FOR_PRIVATE_HELPER:
            return state = { ...state, unit_id_for_private_helper: action.payload };
        case SET_ALL_HELPERS:
            return state = { ...state, all_helper: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
        case SET_HELPER_EMAIL:
            return state = { ...state, helper_email: action.payload };
        case SET_HELPER_CONTACT_INFO:
            return state = { ...state, helper_contact_info: action.payload };
        case SET_HELPER_SECONDARY_CONTACT_INFO:
            return state = { ...state, helper_sec_contact_info: action.payload };
        case SET_HELPER_PERMANENT_ADDRESS:
            return state = { ...state, helper_permanent_address: action.payload };
        case SET_HELPER_ID_PROOF_TYPE:
            return state = { ...state, helper_id_proof_type: action.payload };
        case SET_HELPER_ID_PROOF_ID:
            return state = { ...state, helper_id_proof_id: action.payload };
        case SET_ID_PROOF_IMAGE:
            return state = { ...state, image: action.payload };
        case SET_HELPER_NAME:
            return state = { ...state, helper_name: action.payload };
        case SET_HELPER_UNIT:
            return state = { ...state, helper_unit: action.payload };
        case SET_HELPER_IMAGE:
            return state = { ...state, helper_image: action.payload };
        case SET_HELPER_SERVICE:
            return state = { ...state, helper_service: action.payload };
        case SET_IS_PRIVATE:
            return state = { ...state, is_private: action.payload };
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload, }
        case RESET_ALL:
            return state = {
                ...state,
                all_helper: [],
                helper_name: "",
                helper_email: "",
                helper_contact_info: "",
                helper_sec_contact_info: "",
                helper_permanent_address: "",
                helper_id_proof_type: "",
                helper_id_proof_id: "",
                helper_image: "",
                helper_service: "",
                is_private: false,
                unit_id_for_private_helper: ""
            }
        case SET_ONLY_HELPERS:
            return state = { ...state, all_helper: action.payload }
        case SELECT_HELPER:
            return state = { ...state, selected_helper: action.payload }
        default:
            return state;
    }
}