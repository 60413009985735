import React, { Component } from "react";
import { connect } from "react-redux";
import AddUnitsType from "../../components/units/add_unit_type";
import {
  validate_add_unit_type_func,
  get_all_units,
  get_all_units_type,
  set_unit_type_name,
  set_unit_type_parent_name,
}
  from "../../actions/units/units_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_Units_type_cont extends Component {
  render() {
    return (
      <AddUnitsType {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    units: store.units,
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    set_unit_type_name: (payload) => {
      dispatch(set_unit_type_name(payload))
    },
    set_unit_type_parent_name: (payload) => {
      dispatch(set_unit_type_parent_name(payload))
    },
    validate_add_unit_type_func: (token, units, building_id) => {
      dispatch(validate_add_unit_type_func(token, units, building_id))
    },
    get_all_units: (token, building_id) => {
      dispatch(get_all_units(token, building_id))
    },
    get_all_units_type: (token, building_id) => {
      dispatch(get_all_units_type(token, building_id))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Units_type_cont);