import { LIMIT } from "../../constants/ActionTypes";
import {
    SET_ALL_RESIDENTS,
    SET_RESIDENT_EMAIL,
    SET_RESIDENT_CONTACT_INFO,
    SET_RESIDENT_SECONDARY_CONTACT_INFO,
    SET_RESIDENT_PERMANENT_ADDRESS,
    SET_RESIDENT_ID_PROOF_ID,
    SET_RESIDENT_VEHICLE_DETAILS,
    SET_RESIDENT_NAME,
    SET_RESIDENT_IMAGE,
    SET_IS_OWNER,
    SET_IS_RESIDING,
    SET_UNIT_ID,
    RESET_ALL_RESIDENTS,
    SET_STARTING_AFTER,
    RESET_DEFAULT_RESIDENT,
    SET_RESIDENT_ID_PROOF_TYPE,
    SET_RESIDENT_RC_NUM,
    SET_IS_SUB_RESIDENT,
    SET_ID_PROOF_IMAGE
} from "../../constants/residents/residents_const";
const initial_state = {
    all_residents: [],
    resident_name: "",
    resident_email: "",
    resident_contact_info: "",
    resident_sec_contact_info: "",
    resident_permanent_address: "",
    resident_vehicle_details: "",
    resident_id_proof_type: "",
    resident_id_proof_id: "",
    resident_vehicle_id: "",
    resident_vehicle_type: "",
    resident_vehicle_name: "",
    resident_rc_no: "",
    resident_image: "",
    selected_unit: "",
    is_owner: false,
    is_residing: false,
    is_sub_resident: false,
    starting_after: 0,
    start_var: "",
    end_var: "",
    check: false,
    limit: LIMIT,
    total: "",
    residents_id_proof_img: []
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_RESIDENTS:
            return state = { ...state, all_residents: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
        case SET_RESIDENT_EMAIL:
            return state = { ...state, resident_email: action.payload };
        case SET_RESIDENT_CONTACT_INFO:
            return state = { ...state, resident_contact_info: action.payload };
        case SET_RESIDENT_SECONDARY_CONTACT_INFO:
            return state = { ...state, resident_sec_contact_info: action.payload };
        case SET_RESIDENT_PERMANENT_ADDRESS:
            return state = { ...state, resident_permanent_address: action.payload };
        case SET_RESIDENT_ID_PROOF_TYPE:
            return state = { ...state, resident_id_proof_type: action.payload };
        case SET_RESIDENT_ID_PROOF_ID:
            return state = { ...state, resident_id_proof_id: action.payload };
        case SET_RESIDENT_VEHICLE_DETAILS:
            return state = { ...state, resident_vehicle_details: action.payload };
        case SET_RESIDENT_RC_NUM:
            return state = { ...state, resident_rc_no: action.payload };
        case SET_RESIDENT_NAME:
            return state = { ...state, resident_name: action.payload };
        case SET_RESIDENT_IMAGE:
            return state = { ...state, resident_image: action.payload };
        case SET_ID_PROOF_IMAGE:
            return state = { ...state, residents_id_proof_img: action.payload };
        case SET_IS_OWNER:
            return state = { ...state, is_owner: action.payload };
        case SET_IS_RESIDING:
            return state = { ...state, is_residing: action.payload };
        case SET_UNIT_ID:
            return state = { ...state, selected_unit: action.payload }
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload, }
        case RESET_ALL_RESIDENTS:
            return state = {
                ...state,
                resident_name: "",
                resident_email: "",
                resident_contact_info: "",
                resident_sec_contact_info: "",
                resident_permanent_address: "",
                resident_id_proof_id: "",
                resident_id_proof_type: "",
                resident_vehicle_details: "",
                resident_rc_no: "",
                resident_image: "",
                selected_unit: "",
                is_owner: false,
                is_residing: false,
                is_sub_resident: false
            }
        case RESET_DEFAULT_RESIDENT:
            return state = { ...state, start_var: 0, end_var: 0, starting_after: 0, total: 0 }
        case SET_IS_SUB_RESIDENT:
            return state = { ...state, is_sub_resident: action.payload }
        default:
            return state;
    }
}