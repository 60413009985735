export const SET_BUILDING_NAME = "SET_BUILDING_NAME";
export const SET_BUILDING_POC_NUMBER = "SET_BUILDING_POC_NUMBER";
export const SET_BUILDING_POC_NAME = "SET_BUILDING_POC_NAME";
export const SET_BUILDING_ACCOUNT_NO = "SET_BUILDING_ACCOUNT_NO";
export const SET_BUILDING_ADDRESS = "SET_BUILDING_ADDRESS";
export const RESET_ADD_BUILDING = "RESET_ADD_BUILDING";
export const SET_ALL_BUILDINGS = "SET_ALL_BUILDINGS";
export const SET_BUILDING_IFSC_CODE = "SET_BUILDING_IFSC_CODE";
export const SET_BUILDING_BANK_NAME = "SET_BUILDING_BANK_NAME";
export const SET_BUILDING_LATITUDE = "SET_BUILDING_LATITUDE";
export const SET_BUILDING_LONGITUDE = "SET_BUILDING_LONGITUDE";
export const SET_BUILDING_ADMINS = "SET_BUILDING_ADMINS";
export const SET_ALL_DEPARTMENT_IDS = "SET_ALL_DEPARTMENT_IDS";
export const SET_REDIRECT_OPTION = "SET_REDIRECT_OPTION";
export const SET_REDIRECT = "SET_REDIRECT";