import {
    SET_ALL_ID_PROOFS,
    SET_PROOF_NAME,
} from "../../constants/id_proofs/id_proofs_const";
import { RESET_ALL_RESIDENTS } from "../../constants/residents/residents_const";
const initial_state = {
    all_id_proofs: [],
    proof_name: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_ID_PROOFS:
            return state = { ...state, all_id_proofs: action.payload };
        case SET_PROOF_NAME:
            return state = { ...state, proof_name: action.payload }
        case RESET_ALL_RESIDENTS:
            return state = { ...state, proof_name: "" }
        default:
            return state;
    }
}