import {
    SET_DASHBOARD_DETAILS
} from "../../constants/dashboard/dashboard_const";
import {
    set_loader,
    unset_loader
} from "../loader/loader_action";
import UNIVERSAL from "../../config/config";
export function set_all_dashboard_details(payload) {
    return {
        type: SET_DASHBOARD_DETAILS,
        payload: payload
    }
}
export function getAdmindashboardFunc(token, building_id) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/get_admin_dashboard_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_all_dashboard_details(responseJson.result))
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_super_dashboard_details(token) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/get_super_admin_dashboard_details", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_all_dashboard_details(responseJson.result))
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
