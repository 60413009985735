var initial_admin_state = {
  admin_name: "",
  email_id: "",
  phone_number: "",
  alladmin: [],
  filtered_admin: [],
  response_received: false,
  message: "",
  admin_type: "",
  limit: 0,
  start_by: 0,
  filter: [],
  check: false,
  start_var: 0,
};
export default function reducer(state = initial_admin_state, action) {
  switch (action.type) {
    default:
      return state;
  }
}