import React, { Component } from "react";
import { connect } from "react-redux";
import ViewMaintenanceType from "../../components/maintenance/maintenance_types";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    getAllMaintenaceType
} from "../../actions/maintenance/maintenance_action";

export class ViewMaintenanceTypeCont extends Component {
  render() {
    return (
      <ViewMaintenanceType {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    maintenance: store.maintenance
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    getAllMaintenaceType: (token) => {
      dispatch(getAllMaintenaceType(token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewMaintenanceTypeCont);