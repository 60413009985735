import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Tooltip from "@material-ui/core/Tooltip";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import UNIVERSAL from "../../config/config";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


class MaintenancePaymentUnit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      unit_parent_id: "",
      btnShow: false,
      selectedChoice: "",
      selectDialog: false,
      selectedUnitId: "",
      blockName: ""
    }
  }
  componentDidMount() {
    if (this.props.location) {
      if (this.props.location.state) {
        this.props.getUnitMaintenance(this.props.login.token, this.props.location.state.id)
      }
    }
    this.props.reset()
    this.setState({ btnShow: false }, function () {
      this.props.get_same_units(this.props.login.token, this.props.login.building_id);
    })

  }
  formatData = (data) => {
    var view =
      <TreeView
        defaultCollapseIcon={<Icon className="drawer_icons">keyboard_arrow_right</Icon>}
        defaultExpandIcon={<Icon className="drawer_icons">keyboard_arrow_down</Icon>}
      >
        {this.getParent(data)}
      </TreeView>
    return view;
  }
  getParent = (data) => {
    return data.map((s, index) => (
      s.sub_units[0] && s.sub_units[0].sub_units.length > 0 ?
        <TreeItem key={s._id} nodeId={s._id} label={s.unit_name}>
          {this.getParent(s.sub_units)}
        </TreeItem>
        :
        <div key={s._id} style={{ backgroundColor: "#f2f2f2" }}>
          <Button
            onClick={() => {
              this.setState({
                selectedUnitId: s._id,
                blockName: s.unit_name
              })
            }}
          >
            {s.unit_name}
          </Button>
        </div>
    ))
  }
  render() {
    const {
      login,
      snackbar,
      close_snack_bar,
      maintenance,
      units,
      getUnitMaintenance,
      import_file_func
    } = this.props;

    return (
      <Grid container justify="center">
        <Grid container item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW UNIT MAINTENANCE
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container item xs={12} md={12} >
                <Grid item xs={12} md={4}>
                  <Button
                    onClick={() => { this.setState({ selectDialog: true }) }}
                    variant="outlined" color="primary"
                  >
                    select unit
                  </Button>
                </Grid>
                <Grid item xs={12} md={8}>
                  <span style={{ float: "right", marginTop: 45 }}>
                    {maintenance.unit_data.length > 0 &&
                      <span>
                        <input
                          id="outlined-name"
                          type="file"
                          label="Import"
                          margin="normal"
                          variant="outlined"
                          onChange={(event) => { import_file_func(event.target.files[0], login.token, login.building_id, this.state.selectedUnitId); }}
                          ref={input => this.inputElement = input}
                          style={{ opacity: 0, width: 0 }}
                        />
                        <Button
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            this.inputElement.click();
                          }}
                        >
                          Import
                        </Button>
                        <a
                          style={{ textDecoration: "none" }}
                          href={UNIVERSAL.BASEURL + "v1/export_maintenance_excelsheet?building_id=" + login.building_id + "&unit_parent_id=" + this.state.selectedUnitId}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                          >
                            Export
                          </Button>
                        </a>
                      </span>}
                  </span>
                </Grid>


                <br />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Unit Number</TableCell>
                      <TableCell align="left">Owner Name</TableCell>
                      <TableCell align="left">Pending Amount</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="right"> Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {maintenance.unit_data.map(row => (
                      <TableRow key={row.unit_no}>
                        <TableCell align="left" className="table_cells">
                          {row.unit_no}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.owner_name}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.pending_amount}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.status}
                        </TableCell>
                        <TableCell align="right" className="table_cells">

                          {row.validate_payment ?
                            <IconButton><Icon style={{ color: "red" }}>info</Icon></IconButton> :
                            ""}
                          <Link
                            style={{ textDecoration: "none" }}
                            to={{
                              pathname: "/unit_maintenance_details",
                              state: {
                                unit_id: row.unit_id,
                                selectedParentId: this.state.selectedUnitId=== "" ? this.props.location.state.id : this.state.selectedUnitId,
                                owner_name: row.owner_name,
                                unit_no: row.unit_no,
                                blockName: this.state.blockName === "" ? this.props.location.state.blockName : this.state.blockName
                              }
                            }}

                          >
                            <IconButton>
                              <Icon>
                                list
                              </Icon>
                            </IconButton>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>

          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
        <Dialog
          open={this.state.selectDialog}
          fullWidth={true}
          maxWidth={"xs"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Select Unit</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {this.formatData(this.props.units.unit_details)}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  selectDialog: false,
                  selectedUnitId: ""
                });
              }}
              color="primary">
              Close
              </Button>
            <Button
              disabled={this.state.selectedUnitId === "" ? true : false}
              onClick={() => {
                this.props.getUnitMaintenance(this.props.login.token, this.state.selectedUnitId)
                this.setState({
                  selectDialog: false
                })
              }}
              color="primary"
              autoFocus
            >
              Select
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    )
  }
}
export default withStyles(styles)(MaintenancePaymentUnit);