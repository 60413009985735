import { LIMIT } from "../../constants/ActionTypes";
import {
    SET_EMP_NAME,
    SET_EMP_DESIGNATION,
    SET_EMP_IMG,
    EMPLOYEE_CONTACT_NO,
    SET_EMP_ID_PROOF,
    SET_ID_PROOF_ID,
    EMPLOYEE_CODE,
    EMPLOYEE_EMAIL,
    ACCOUNT_NO,
    BANK_NAME,
    BANK_IFSC,
    BRANCH,
    SET_IS_PROVIDED_BY_VENDOR,
    SET_SELECTED_VENDOR,
    SET_EMP_SALARY,
    RESET_EMPLOYEE,
    SET_ALL_EMPLOYEE,
    SET_STARTING_AFTER,
    SET_ONLY_EMPLOYEE,
    SET_EMP_ID_PROOF_IMG,
    SET_DIALOG,
    SET_BUILDING_VENDORS_FOR_EMPLOYESS
} from "../../constants/employee/employee_const";
const initial_state = {
    all_employee: [],
    employee_name: "",
    employee_designation: "",
    image: "",
    employee_contact_info: "",
    employee_id_proof: "",
    employee_id_proof_id: "",
    employee_id_proof_img: [],
    employee_code: "",
    employee_email: "",
    account_no: "",
    bank_name: "",
    bank_ifsc: "",
    branch: "",
    is_provide_by_vendor: "false",
    starting_after: 0,
    start_var: "",
    end_var: "",
    check: false,
    limit: LIMIT,
    total: "",
    selected_vendor: "",
    employee_salary_amount: "",
    dialog: false,
    building_vendors: []
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_BUILDING_VENDORS_FOR_EMPLOYESS:
            return state = { ...state, building_vendors: action.payload };
        case SET_DIALOG:
            return state = { ...state, dialog: action.payload };
        case SET_EMP_NAME:
            return state = { ...state, employee_name: action.payload };
        case SET_EMP_DESIGNATION:
            return state = { ...state, employee_designation: action.payload, }
        case SET_EMP_IMG:
            return state = { ...state, image: action.payload, }
        case EMPLOYEE_CONTACT_NO:
            return state = { ...state, employee_contact_info: action.payload, }
        case SET_EMP_ID_PROOF:
            return state = { ...state, employee_id_proof: action.payload, }
        case SET_ID_PROOF_ID:
            return state = { ...state, employee_id_proof_id: action.payload, }
        case SET_EMP_ID_PROOF_IMG:
            return state = { ...state, employee_id_proof_img: action.payload, }
        case EMPLOYEE_CODE:
            return state = { ...state, employee_code: action.payload, }
        case EMPLOYEE_EMAIL:
            return state = { ...state, employee_email: action.payload, }
        case ACCOUNT_NO:
            return state = { ...state, account_no: action.payload, }
        case BANK_NAME:
            return state = { ...state, bank_name: action.payload }
        case BANK_IFSC:
            return state = { ...state, bank_ifsc: action.payload }
        case BRANCH:
            return state = { ...state, branch: action.payload }
        case SET_IS_PROVIDED_BY_VENDOR:
            return state = { ...state, is_provide_by_vendor: action.payload }
        case SET_SELECTED_VENDOR:
            return state = { ...state, selected_vendor: action.payload }
        case SET_EMP_SALARY:
            return state = { ...state, employee_salary_amount: action.payload }
        case SET_ALL_EMPLOYEE:
            return state = { ...state, all_employee: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload, }
        case SET_ONLY_EMPLOYEE:
            return state = { ...state, all_employee: action.payload, }
        case RESET_EMPLOYEE:
            return state = {
                ...state,
                employee_name: "",
                employee_designation: "",
                image: "",
                employee_contact_info: "",
                employee_id_proof: "",
                employee_id_proof_id: "",
                employee_code: "",
                employee_email: "",
                account_no: "",
                bank_name: "",
                bank_ifsc: "",
                branch: "",
                is_provide_by_vendor: false,
                limit: LIMIT,
                selected_vendor: "",
                employee_salary_amount: "",
            }
        default:
            return state;
    }
} 