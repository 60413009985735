import React, { Component } from "react";
import { connect } from "react-redux";
import AddDepartment from "../../components/department/add_department";
import {
    set_department_name,
    set_department_desc,
    validate_add_department,
} from "../../actions/department/department_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_department_cont extends Component {
    render() {
        return (
            <AddDepartment {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        department: store.department,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        validate_add_department: (token, building_id, department) => {
            dispatch(validate_add_department(token, building_id, department))
        },
        set_department_name: (token) => {
            dispatch(set_department_name(token))
        },
        set_department_desc: (token) => {
            dispatch(set_department_desc(token))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_department_cont);