import {
    SET_CURRENT_PASSWORD,
    SET_NEW_PASSWORD,
    SET_CONFIRM_NEW_PASSWORD,
    RESET_PASSWORDS
} from "../../constants/employee/change_password";
import {
    set_loader,
    unset_loader
} from "../loader/loader_action";
import UNIVERSAL from "../../config/config";
import { set_snack_bar } from "../snackbar/snackbar_action";
export function set_current_password(payload) {
    return {
        type: SET_CURRENT_PASSWORD,
        payload: payload
    }
}
export function set_new_password(payload) {
    return {
        type: SET_NEW_PASSWORD,
        payload: payload
    }
}
export function set_confirm_new_password(payload) {
    return {
        type: SET_CONFIRM_NEW_PASSWORD,
        payload: payload
    }
}
export function reset_password() {
    return {
        type: RESET_PASSWORDS
    }
}
export function change_password_function(token, new_password, old_password) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/change_password", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                new_password: new_password,
                old_password: old_password
            })
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Password Updated"));
                    dispatch(reset_password());
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Password Could Not Be Updated"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
