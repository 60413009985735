import React, { Component } from "react";
import { connect } from "react-redux";
import AddResidents from "../../components/residents/add_residents";
import {
    validate_resident_func,
    set_resident_email,
    set_resident_contact_info,
    set_resident_secondary_contact_info,
    set_resident_permanent_address,
    set_resident_id_proof_id,
    set_resident_id_proof_type,
    set_resident_vehicle_details,
    set_resident_name,
    set_resident_image,
    set_is_owner,
    set_is_residing,
    set_unit_id,
    reset_all,
    set_resident_rc_num,
    set_resident_id_proof_img,
    set_is_subresidents
} from "../../actions/residents/residents_action";
import {
    get_all_id_proofs,
} from "../../actions/id_proofs/id_proofs_action";
import {
    get_all_vehicles
} from "../../actions/vehicles/vehicles_action"
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    get_same_units,
} from "../../actions/units/units_action";
export class Add_Residents_cont extends Component {
    render() {
        return (
            <AddResidents {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        residents: store.residents,
        vehicles: store.vehicles,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        id_proofs: store.id_proofs,
        units: store.units,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_same_units: (token, building_id) => {
            dispatch(get_same_units(token, building_id))
        },
        get_all_vehicles: (token) => {
            dispatch(get_all_vehicles(token))
        },
        set_resident_rc_num: (payload) => {
            dispatch(set_resident_rc_num(payload))
        },
        get_all_id_proofs: (token) => {
            dispatch(get_all_id_proofs(token))
        },
        set_resident_id_proof_img: (token) => {
            dispatch(set_resident_id_proof_img(token))
        },
        validate_resident_func: (token, residents, units) => {
            dispatch(validate_resident_func(token, residents, units))
        },
        set_resident_email: (payload) => {
            dispatch(set_resident_email(payload));
        },
        set_resident_contact_info: (payload) => {
            dispatch(set_resident_contact_info(payload));
        },
        set_resident_secondary_contact_info: (payload) => {
            dispatch(set_resident_secondary_contact_info(payload));
        },
        set_resident_permanent_address: (payload) => {
            dispatch(set_resident_permanent_address(payload));
        },
        set_resident_id_proof_id: (payload) => {
            dispatch(set_resident_id_proof_id(payload));
        },
        set_resident_id_proof_type: (payload) => {
            dispatch(set_resident_id_proof_type(payload));
        },
        set_resident_vehicle_details: (payload) => {
            dispatch(set_resident_vehicle_details(payload));
        },
        set_resident_name: (payload) => {
            dispatch(set_resident_name(payload));
        },
        set_resident_image: (payload) => {
            dispatch(set_resident_image(payload));
        },
        set_unit_id: (payload) => {
            dispatch(set_unit_id(payload))
        },
        set_is_owner: (payload) => {
            dispatch(set_is_owner(payload));
        },
        set_is_residing: (payload) => {
            dispatch(set_is_residing(payload));
        },
        reset_all: () => {
            dispatch(reset_all());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        set_is_subresidents: (payload) => {
            dispatch(set_is_subresidents(payload));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Residents_cont);