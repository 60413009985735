import React, { Component } from "react";
import { connect } from "react-redux";
import Residents from "../../components/residents/residents";
import {
  get_all_residents,
  validate_resident_edit_func,
  delete_user_info,
  prev_btn,
  next_btn,
  reset_residents,
} from "../../actions/residents/residents_action";
import {
  get_all_unit,
} from "../../actions/units/units_action";
import {
  get_all_vehicles
} from "../../actions/vehicles/vehicles_action"
import {
  get_all_id_proofs,
} from "../../actions/id_proofs/id_proofs_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Residents_cont extends Component {
  render() {
    return (
      <Residents {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    residents: store.residents,
    vehicles: store.vehicles,
    login: store.login,
    id_proofs: store.id_proofs,
    loader: store.loader,
    snackbar: store.snackbar,
    units: store.units,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    get_all_units: (token, building_id) => {
      dispatch(get_all_unit(token, building_id))
    },
    get_all_id_proofs: (token) => {
      dispatch(get_all_id_proofs(token))
    },
    get_all_vehicles: (token) => {
      dispatch(get_all_vehicles(token))
    },
    reset_residents: () => {
      dispatch(reset_residents())
    },
    get_all_residents: (token, building_id, limit, starting_after) => {
      dispatch(get_all_residents(token, building_id, limit, starting_after))
    },
    delete_user_info: (token, resident_id, building_id, limit, starting_after) => {
      dispatch(delete_user_info(token, resident_id, building_id, limit, starting_after))
    },
    validate_resident_edit_func: (token, resident_id_proof_id, resident_id_proof_type, resident_vehicle_details, resident_rc_no, resident_id, resident_name, resident_img, resident_img_old, resident_email, resident_contact_info, resident_sec_contact_info, resident_permanent_address, unit_id, is_owner, building_id, limit, starting_after) => {
      dispatch(validate_resident_edit_func(token, resident_id_proof_id, resident_id_proof_type, resident_vehicle_details, resident_rc_no, resident_id, resident_name, resident_img, resident_img_old, resident_email, resident_contact_info, resident_sec_contact_info, resident_permanent_address, unit_id, is_owner, building_id, limit, starting_after))
    },
    prev_btn: (token, building_id, limit, starting_after) => {
      dispatch(prev_btn(token, building_id, limit, starting_after))
    },
    next_btn: (token, building_id, limit, starting_after) => {
      dispatch(next_btn(token, building_id, limit, starting_after))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Residents_cont);