import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class Amenities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
      posted_on: "",
      confirm_delete: false,
      post_id: "",
      img: [],
      del_check: false,
      editredirect: false
    }
  }
  componentDidMount() {
    this.props.get_all_sharewall(this.props.login.token, this.props.login.building_id, this.props.sharewall.limit, this.props.sharewall.starting_after);
  }
  ed = (s) => {
    this.setState({
      comments: s.comments,
      post_id: s._id,
      img: s.img
    })
  }
  del_single_row = (row) => {
    this.setState({
      confirm_delete: true,
      id: row._id,
    })
  }
  set_del_check() {
    for (var i = 0; i < this.props.sharewall.all_sharewall.length; i++) {
      if (this.props.sharewall.all_sharewall[i].checked === true) {
        this.setState({
          del_check: true
        });
        break;
      }
      else {
        this.setState({
          del_check: false
        })
      }
    }
  }
  render() {
    const {
      sharewall,
      prev_btn,
      next_btn,
      login,
      delete_sharewall_post,
      snackbar,
      close_snack_bar,
      validate_edit_sharewall,
      add_sharewall_images,
      set_images,
      set_del_images,
      toggleCheckbox
    } = this.props;
    var btn;
    if (sharewall.total > sharewall.limit) {
      if (sharewall.end_var === sharewall.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, sharewall.limit, sharewall.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {sharewall.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{sharewall.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{sharewall.total}
        </span>
      }
      else if (sharewall.start_var > sharewall.limit && sharewall.end_var < sharewall.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, sharewall.limit, sharewall.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(login.token, login.building_id, sharewall.limit, sharewall.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {sharewall.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{sharewall.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{sharewall.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(sharewall.token, login.building_id, sharewall.limit, sharewall.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {sharewall.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{sharewall.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{sharewall.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {sharewall.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{sharewall.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{sharewall.total}
      </span>
    }
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW SHAREWALL
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item lg={12}>
                <Link to="create_sharewall" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                {this.state.del_check === true ? <IconButton
                  onClick={() => {
                    this.setState({
                      confirm_delete: true
                    })
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton> : ""}
                <br /><br />
                <div style={{ float: "right" }}>
                  {btn}
                </div>
              </Grid>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">&nbsp;&nbsp;Comment</TableCell>
                    <TableCell align="left">Posted On</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sharewall.all_sharewall.map(row => (
                    <TableRow key={row._id} >
                      <TableCell align="left" className="table_cells">
                        <Checkbox
                          checked={row.checked}
                          size="small"
                          onChange={() => {
                            toggleCheckbox(row, sharewall.all_sharewall);
                            this.set_del_check();
                          }}
                        />
                        &nbsp;&nbsp;{row.comments}
                      </TableCell>
                      <TableCell align="left" className="table_cells">
                        {moment(row.posted_on).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="right" className="table_cells">
                        <IconButton
                          onClick={() => {
                            this.ed(row)
                            set_images(row.img)
                            this.setState({
                              editredirect: true,
                            });
                          }}
                        >
                          <Icon >edit</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12} >
                  <TextField
                    id="outlined-email-input"
                    label="Comment"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.comments}
                    onChange={(event) => {
                      if (event.target.value.length < 125) {
                        this.setState({
                          comments: (event.target.value)
                        })
                      }

                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Grid container>
                    {sharewall.img.map(img => (
                      <Grid key={img} item lg={3} xs={3}>
                        <img src={img} style={{ height: 100, width: 100 }} alt='sharewall' />
                        <IconButton
                          onClick={(img) => { set_del_images(img, sharewall) }}
                        ><Icon>delete </Icon></IconButton>
                        &nbsp;&nbsp;&nbsp;
                      </Grid>
                    ))}
                  </Grid>
                  add :
                                    <input type="file" multiple
                    onChange={(event) => { add_sharewall_images(event, sharewall) }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    redirect: false,
                    editredirect: false,
                    comments: "",
                    img: []
                  });
                }}
                color="primary">
                Close
                            </Button>
              <Button
                onClick={() => {
                  validate_edit_sharewall(login.token, login.building_id, login.user_id, this.state.post_id, this.state.comments, sharewall.img, sharewall.limit, sharewall.starting_after)
                  this.setState({
                    redirect: false,
                    editredirect: false,
                    comments: "",
                    img: []
                  })
                }}
                color="primary" autoFocus>
                UPDATE
                            </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirm_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirm_delete: false,
                    id: "",
                  });
                }}
                color="primary">
                No
              </Button>
              <Button
                onClick={() => {
                  delete_sharewall_post(login.token, sharewall.all_sharewall, sharewall.limit, sharewall.starting_after, login.building_id)
                  this.setState({
                    confirm_delete: false,
                    id: "",
                    del_check: false
                  })
                }}
                color="primary" autoFocus>
                Yes
                            </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(Amenities);