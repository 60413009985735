import React, { Component } from "react";
import { connect } from "react-redux";
import ViewUnitsType from "../../components/units/view_unit_type";
import {
  update_unit_type,
  delete_unit_type,
  get_all_units_type,
  get_all_units,
  get_sublevel,
  toggleCheckbox,
  reset_del_values,
} from "../../actions/units/units_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class View_Units_Type_Cont extends Component {
  render() {
    return (
      <ViewUnitsType {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    units: store.units,
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    get_all_units_type: (token, building_id) => {
      dispatch(get_all_units_type(token, building_id))
    },
    update_unit_type: (token, name, parent_name, all_data, building_id) => {
      dispatch(update_unit_type(token, name, parent_name, all_data, building_id))
    },
    delete_unit_type: (token, unit_id, building_id) => {
      dispatch(delete_unit_type(token, unit_id, building_id))
    },
    get_all_units: (token) => {
      dispatch(get_all_units(token))
    },
    get_sublevel: (token, building_id, parent_id) => {
      dispatch(get_sublevel(token, building_id, parent_id))
    },
    reset_del_values: () => {
      dispatch(reset_del_values());
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    toggleCheckbox: (obj, array) => {
      dispatch(toggleCheckbox(obj, array));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_Units_Type_Cont);