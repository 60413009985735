import React, { Component } from "react";
import { connect } from "react-redux";
import AddVendors from "../../components/vendors/add_vendors";
import {
    set_vendor_name,
    set_vendor_service,
    set_license_no,
    payment_type,
    set_fixed_amount,
    set_account_no,
    set_bank_name,
    set_bank_ifsc,
    set_bank_branch,
    validate_add_vendors,
    set_vendor_contact,
    set_reset_type,
    set_is_fixed,
    set_contact_num,
    set_contact_name,
    set_other_contact,
    set_vendor_type,
    set_vendor_image,
    set_contact_image,
    remove_other_contact
} from "../../actions/vendors/vendors_action";
import { get_all_vendor_category } from "../../actions/vendor_category/vendor_category_action"
import { get_all_units } from "../../actions/units/units_action";
import { close_snack_bar } from "../../actions/snackbar/snackbar_action";
import { get_all_id_proofs } from "../../actions/id_proofs/id_proofs_action";
import {
    set_employee_id_proof_id,
    set_employee_id_proof_img,
    set_employee_id_proof_type
} from "../../actions/employee/employee_action";
export class Add_vendors_cont extends Component {
    render() {
        return (
            <AddVendors {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        units: store.units,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        vendors: store.vendors,
        vendor_category: store.vendor_category,
        employee: store.employee,
        id_proofs: store.id_proofs
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_vendor_category: (token) => {
            dispatch(get_all_vendor_category(token))
        },
        set_is_fixed: (payload) => {
            dispatch(set_is_fixed(payload))
        },
        set_reset_type: () => {
            dispatch(set_reset_type())
        },
        set_vendor_contact: (payload) => {
            dispatch(set_vendor_contact(payload))
        },
        set_contact_num: (payload) => {
            dispatch(set_contact_num(payload))
        },
        set_contact_name: (payload) => {
            dispatch(set_contact_name(payload))
        },
        get_all_units: (token) => {
            dispatch(get_all_units(token))
        },
        validate_add_vendors: (token, building_id, vendors, employee) => {
            dispatch(validate_add_vendors(token, building_id, vendors, employee))
        },
        set_vendor_name: (payload) => {
            dispatch(set_vendor_name(payload))
        },
        set_vendor_service: (payload) => {
            dispatch(set_vendor_service(payload))
        },
        set_license_no: (payload) => {
            dispatch(set_license_no(payload))
        },
        payment_type: (payload) => {
            dispatch(payment_type(payload))
        },
        set_fixed_amount: (payload) => {
            dispatch(set_fixed_amount(payload))
        },
        set_account_no: (payload) => {
            dispatch(set_account_no(payload))
        },
        set_bank_name: (payload) => {
            dispatch(set_bank_name(payload))
        },
        set_bank_ifsc: (payload) => {
            dispatch(set_bank_ifsc(payload))
        },
        set_bank_branch: (payload) => {
            dispatch(set_bank_branch(payload))
        },
        set_other_contact: (payload) => {
            dispatch(set_other_contact(payload))
        },
        remove_other_contact: (contacts, index) => {
            dispatch(remove_other_contact(contacts, index))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        set_vendor_type: (payload) => {
            dispatch(set_vendor_type(payload))
        },
        set_vendor_image: (payload) => {
            dispatch(set_vendor_image(payload))
        },
        get_all_id_proofs: (payload) => {
            dispatch(get_all_id_proofs(payload))
        },
        set_employee_id_proof_id: (proof_number) => {
            dispatch(set_employee_id_proof_id(proof_number));
        },
        set_employee_id_proof_img: (file) => {
            dispatch(set_employee_id_proof_img(file));
        },
        set_contact_image: (file) => {
            dispatch(set_contact_image(file));
        },
        set_employee_id_proof_type: (payload) => {
            dispatch(set_employee_id_proof_type(payload))
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_vendors_cont);