import React, { Component } from "react";
import { connect } from "react-redux";
import ViewAllAssets from "../../components/assets/viewAssets";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    getAllBankAccounts
} from "../../actions/bankAccounts/bankAccountAction";
import {
    getAllAssets,
    reset,
    addAsset,
} from "../../actions/assets/assetsAction";

export class ViewAllAssetsCont extends Component {
    render() {
        return (
            <ViewAllAssets {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        bank: store.bank,
        assets: store.assets
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        reset: () => {
            dispatch(reset());
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        getAllAssets: (token, building_id, limit, starting_after, startDate, endDate) => {
            dispatch(getAllAssets(token, building_id, limit, starting_after, startDate, endDate));
        },
        getAllBankAccounts: (token, building_id) => {
            dispatch(getAllBankAccounts(token, building_id));
        },
        addAsset: (token, building_id, state, assets) => {
            dispatch(addAsset(token, building_id, state, assets));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAllAssetsCont);