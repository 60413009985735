import React, { Component } from "react";
import { connect } from "react-redux";
import Sharewall from "../../components/sharewall/sharewall";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
} from "../../constants/sharewall/sharewall_const";
import {
    get_all_sharewall,
    prev_btn,
    next_btn,
    delete_sharewall_post,
    validate_edit_sharewall,
    set_images,
    add_sharewall_images,
    set_del_images,
    toggleCheckbox
} from "../../actions/sharewall/sharewall_action"
export class Sharewall_cont extends Component {
    render() {
        return (
            <Sharewall {...this.props} />
        );
    };
};
export const mapStateToProps = store => {
    return {
        sharewall: store.sharewall,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_sharewall: (token, building_id, limit, starting_after) => {
            dispatch(get_all_sharewall(token, building_id, limit, starting_after))
        },
        prev_btn: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn(token, building_id, limit, starting_after))
        },
        next_btn: (token, building_id, limit, starting_after) => {
            dispatch(next_btn(token, building_id, limit, starting_after))
        },
        delete_sharewall_post: (token, post_id, limit, starting_after, building_id) => {
            dispatch(delete_sharewall_post(token, post_id, limit, starting_after, building_id))
        },
        validate_edit_sharewall: (token, building_id, user_id, post_id, comments, img, limit, starting_after) => {
            dispatch(validate_edit_sharewall(token, building_id, user_id, post_id, comments, img, limit, starting_after))
        },
        set_images: (payload) => {
            dispatch(set_images(payload))
        },
        add_sharewall_images: (payload, sharewall) => {
            dispatch(add_sharewall_images(payload, sharewall))
        },
        set_del_images: (payload, sharewall) => {
            dispatch(set_del_images(payload, sharewall))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        toggleCheckbox: (obj, array) => {
            dispatch(toggleCheckbox(obj, array));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sharewall_cont); 