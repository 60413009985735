import {
    SET_ALL_ID_PROOFS,
    SET_PROOF_NAME,
    RESET_ALL,
} from "../../constants/id_proofs/id_proofs_const";
import { set_loader, unset_loader }
    from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function get_all_id_proofs(token) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_proofs", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_all_id_proofs(responseJson.result))
                }
                else {
                    dispatch(set_all_id_proofs([]));
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function toggleCheckbox(obj, array) {
    var index = array.indexOf(obj);
    array[index].checked = !array[index].checked;
    return {
        type: SET_ALL_ID_PROOFS,
        payload: array,
    }
}
export function set_all_id_proofs(payload) {
    for (var i = 0; i < payload.length; i++) {
        payload[i].checked = false;
    }
    return {
        type: SET_ALL_ID_PROOFS,
        payload: payload,
    }
}
export function reset_all() {
    return {
        type: RESET_ALL,
    }
}
export function set_proof_name(payload) {
    return {
        type: SET_PROOF_NAME,
        payload: payload,
    }
}
export function validate_proof_func(token, id_proofs) {
    return (dispatch) => {
        if (id_proofs.proof_name === "") {
            dispatch(set_snack_bar(true, "Please enter ID Proof Name"));
        }
        else {
            dispatch(add_id_proof(token, id_proofs));
        }
    };
}
export function add_id_proof(token, id_proofs) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/add_id_proof", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                proof_name: id_proofs.proof_name
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "ID Proof Added Succcessfully"));
                    dispatch(reset_all())
                    dispatch(get_all_id_proofs(token))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Resident Could Not Be Added"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function validate_update_id_proof(token, proof_id, proof_name) {
    return (dispatch) => {
        if (proof_name === "") {
            dispatch(set_snack_bar(true, "Please enter ID Proof Name"));
        }
        else {
            dispatch(update_id_proof(token, proof_id, proof_name));
        }
    };
}
export function update_id_proof(token, proof_id, proof_name) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/update_id_proof", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                proof_id: proof_id,
                proof_name: proof_name
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Id Proof Updated Succcessfully"));
                    dispatch(get_all_id_proofs(token))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Id Proof Could Not Be Updated"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function delete_id_proofs(token, id_proofs) {
    var proof_id = []
    for (var i = 0; i < id_proofs.length; i++) {
        if (id_proofs[i].checked === true) {
            proof_id.push(id_proofs[i]._id)
        }
    }
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/delete_id_proof", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                proof_id: JSON.stringify(proof_id),
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Id Proof Deleted Succcessfully"));
                    dispatch(get_all_id_proofs(token))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Id Proof Could Not Be Deleted"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}