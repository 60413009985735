import React, { Component } from "react";
import { connect } from "react-redux";
import AddAmenities from "../../components/amenities/add_amenities";
import {
    set_guest_halfday_price,
    set_guest_fullday_price,
    set_amenity_halfday_price,
    set_amenity_fullday_price,
    set_amenity_images,
    set_amenity_desc,
    set_amenity_name,
    set_no_of_slot,
    set_start_time,
    set_end_time,
    set_isfree,
    validate_add_amenities,
    reset_amenities
} from "../../actions/amenities/amenities_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_amenities_cont extends Component {
    render() {
        return (
            <AddAmenities {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        amenities: store.amenities,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        set_amenity_name: (payload) => {
            dispatch(set_amenity_name(payload))
        },
        set_amenity_desc: (payload) => {
            dispatch(set_amenity_desc(payload))
        },
        set_amenity_images: (payload, amenities) => {
            dispatch(set_amenity_images(payload, amenities))
        },
        set_amenity_fullday_price: (payload) => {
            dispatch(set_amenity_fullday_price(payload))
        },
        set_amenity_halfday_price: (payload) => {
            dispatch(set_amenity_halfday_price(payload))
        },
        set_guest_fullday_price: (payload) => {
            dispatch(set_guest_fullday_price(payload))
        },
        set_guest_halfday_price: (payload) => {
            dispatch(set_guest_halfday_price(payload))
        },
        set_no_of_slot: (payload) => {
            dispatch(set_no_of_slot(payload))
        },
        set_start_time: (payload) => {
            dispatch(set_start_time(payload))
        },
        set_end_time: (payload) => {
            dispatch(set_end_time(payload))
        },
        set_isfree: (payload) => {
            dispatch(set_isfree(payload))
        },
        validate_add_amenities: (token, building_id, amenities) => {
            dispatch(validate_add_amenities(token, building_id, amenities))
        },
        reset_amenities: () => {
            dispatch(reset_amenities())
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_amenities_cont);