import {
  SET_EMP_NAME,
  SET_EMP_DESIGNATION,
  SET_EMP_IMG,
  EMPLOYEE_CONTACT_NO,
  SET_EMP_ID_PROOF,
  EMPLOYEE_CODE,
  EMPLOYEE_EMAIL,
  ACCOUNT_NO,
  BANK_NAME,
  BANK_IFSC,
  BRANCH,
  SET_IS_PROVIDED_BY_VENDOR,
  SET_SELECTED_VENDOR,
  SET_EMP_SALARY,
  RESET_EMPLOYEE,
  SET_STARTING_AFTER,
  SET_ALL_EMPLOYEE,
  SET_ONLY_EMPLOYEE,
  SET_EMP_ID_PROOF_IMG,
  SET_ID_PROOF_ID,
  SET_DIALOG,
  SET_BUILDING_VENDORS_FOR_EMPLOYESS
} from "../../constants/employee/employee_const";
import {
  set_loader,
  unset_loader
} from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
import { get_all_vendors } from "../vendors/vendors_action";
import { get_all_id_proofs } from "../id_proofs/id_proofs_action";
import { get_all_departments } from "../department/department_action";
import { SET_BUILDING_VENDORS } from "../../constants/vendors/vendors_const";

export function set_employee_name(payload) {
  return {
    type: SET_EMP_NAME,
    payload: payload
  }
}
export function set_salary_amount(payload) {
  return {
    type: SET_EMP_SALARY,
    payload: payload
  }
}
export function set_employee_designation(payload) {
  return {
    type: SET_EMP_DESIGNATION,
    payload: payload
  }
}
export function set_employee_image(payload) {
  return {
    type: SET_EMP_IMG,
    payload: payload.target.files[0]
  }
}
export function set_employee_id_proof_type(payload) {
  return {
    type: SET_EMP_ID_PROOF,
    payload: payload
  }
}
export function set_employee_id_proof_id(payload) {
  return {
    type: SET_ID_PROOF_ID,
    payload: payload
  }
}
export function set_employee_id_proof_img(payload) {
  if (payload) {
    var files = payload.target.files
  }
  else {
    var files = null
  }
  return {
    type: SET_EMP_ID_PROOF_IMG,
    payload: files
  }
}
export function set_employee_contact_info(payload) {
  return {
    type: EMPLOYEE_CONTACT_NO,
    payload: payload
  }
}
export function set_employee_code(payload) {
  return {
    type: EMPLOYEE_CODE,
    payload: payload
  }
}
export function set_employee_email(payload) {
  return {
    type: EMPLOYEE_EMAIL,
    payload: payload
  }
}
export function set_emp_acc_no(payload) {
  return {
    type: ACCOUNT_NO,
    payload: payload
  }
}
export function set_emp_bank_name(payload) {
  return {
    type: BANK_NAME,
    payload: payload
  }
}
export function set_emp_bank_ifsc(payload) {
  return {
    type: BANK_IFSC,
    payload: payload
  }
}
export function set_emp_branch(payload) {
  return {
    type: BRANCH,
    payload: payload
  }
}
export function set_is_provided(payload) {
  var payloads;
  if (payload === "true") {
    payloads = false
  }
  else {
    payloads = true
  }
  return {
    type: SET_IS_PROVIDED_BY_VENDOR,
    payload: payloads
  }
}
export function set_selected_vendor(payload) {
  return {
    type: SET_SELECTED_VENDOR,
    payload: payload
  }
}
export function validate_add_employee(token, employee, building_id, departments) {
  return (dispatch) => {
    dispatch(set_loader());
    if (employee.employee_name === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee Name"));
      dispatch(unset_loader());
    } else if (employee.employee_designation === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee  Designation"));
      dispatch(unset_loader());
    } else if (employee.image === "") {
      dispatch(set_snack_bar(true, "Please Select Employee Image"));
      dispatch(unset_loader());
    } else if (employee.employee_salary_amount === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee Salary Amount"));
      dispatch(unset_loader());
    } else if (employee.employee_contact_info === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee Contact Info"));
      dispatch(unset_loader());
    } else if (employee.employee_id_proof === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee ID"));
      dispatch(unset_loader());
    } else if (employee.employee_id_proof_id === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee ID Number"));
      dispatch(unset_loader());
    } else if (employee.employee_email === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee Email address"));
      dispatch(unset_loader());
    }
    else if (employee.employee_id_proof_img === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee ID Proof Image"));
      dispatch(unset_loader());
    } else {
      if (employee.image === "") {
        dispatch(add_image(token, employee, building_id, departments, "", 0, []))
      } else {
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child("images/" + employee.employee_name + ".png").put(employee.image);
        uploadTask.on("state_changed", function (snapshot) {
        }, function (error) {
          dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            dispatch(add_image(token, employee, building_id, departments, downloadURL, 0, []))
          });
        });
      }
    }
  };
}
export function add_image(token, employee, building_id, departments, url, intial, array) {
  var arr = array;
  return (dispatch) => {
    if (arr.length !== employee.employee_id_proof_img.length) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + intial + employee.employee_name + ".png").put(employee.employee_id_proof_img[intial]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          arr.push(downloadURL)
          dispatch(add_image(token, employee, building_id, departments, url, parseInt(intial) + 1, arr));
        });
      });
    }
    if (employee.employee_id_proof_img.length === arr.length) {
      dispatch(add_employee(token, employee, building_id, departments, arr, url))
    }
  }
}
export function add_employee(token, employee, building_id, departments, array, url) {
  var dept = [];
  for (var i = 0; i < departments.length; i++) {
    if (departments[i].checked) {
      dept.push(departments[i]._id);
    }
  }
  var employee_id_proof = { id_proof_type: employee.employee_id_proof, id_proof_number: employee.employee_id_proof_id, id_proof_image: array }
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/add_employee", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        employee_name: employee.employee_name,
        employee_designation: employee.employee_designation,
        employee_img: url,
        employee_contact_info: employee.employee_contact_info,
        employee_id_proof: JSON.stringify(employee_id_proof),
        employee_code: employee.employee_code,
        employee_email: employee.employee_email,
        employee_salary_amount: employee.employee_salary_amount,
        employee_bank_account_no: employee.account_no,
        employee_bank_name: employee.bank_name,
        employee_bank_ifsc_code: employee.bank_ifsc,
        employee_bank_branch: employee.branch,
        is_provided_by_vendor: employee.is_provide_by_vendor,
        vendor_id: employee.selected_vendor,
        departments: JSON.stringify(dept)
      })
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.active) {
            dispatch(set_snack_bar(responseJson.status, "Employee Added Succcessfully"));
            dispatch(reset_all())
          }
          else {
            dispatch(set_snack_bar(responseJson.status, "Employee Already Exist"));
          }
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Employee Could Not Be Added"));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function validate_update_employee(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, employee_image, employee_image_old, departments, limit, starting_after, employee) {
  return (dispatch) => {
    dispatch(set_loader());
    if (employee_name === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee Name"));
      dispatch(unset_loader());
    }
    else if (employee_designation === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee  Designation"));
      dispatch(unset_loader());
    }
    else if (is_provide_by_vendor && selected_vendor === "") {
      dispatch(set_snack_bar(true, "Please Select a Vendor"));
      dispatch(unset_loader());
    }
    else if (employee_salary_amount === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee Salary Amount"));
      dispatch(unset_loader());
    }
    else if (employee_contact_info === "") {
      dispatch(set_snack_bar(true, "Please Enter Employee Contact Info"));
      dispatch(unset_loader());
    }
    // else if (employee_email === "") {
    //     dispatch(set_snack_bar(true, "Please Enter Employee Email"));
    //     dispatch(unset_loader());
    // }
    // else if (account_no === "") {
    //     dispatch(set_snack_bar(true, "Please Enter Employee Account Number"));
    //     dispatch(unset_loader());
    // }
    // else if (bank_name === "") {
    //     dispatch(set_snack_bar(true, "Please Enter Employee Bank No"));
    //     dispatch(unset_loader());
    // }
    // else if (bank_ifsc === "") {
    //     dispatch(set_snack_bar(true, "Please Enter Employee Bank IFSC Code"));
    //     dispatch(unset_loader());
    // }
    // else if (branch === "") {
    //     dispatch(set_snack_bar(true, "Please Enter Bank Branch"));
    //     dispatch(unset_loader());
    // }
    else if (employee_image === "") {
      dispatch(update_image(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, employee_image_old, departments, limit, starting_after, employee, 0, []));
    }
    else {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + employee_name + ".png").put(employee_image);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch(update_employee(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, downloadURL, departments, limit, starting_after, employee, 0, []));
        });
      });
    }
  };
}
export function update_image(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, url, departments, limit, starting_after, employee, intial, array) {
  var arr = array
  return (dispatch) => {
    if (arr.length !== employee.employee_id_proof_img.length) {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + intial + employee_name + ".png").put(employee.employee_id_proof_img[intial]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          arr.push(downloadURL)
          dispatch(update_image(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, url, departments, limit, starting_after, employee, parseInt(intial) + 1, arr));
        });
      });
    }
    if (employee.employee_id_proof_img.length === arr.length) {
      dispatch(update_employee(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, url, departments, limit, starting_after, arr))
    }
  }
}
export function update_employee(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, downloadURL, departments, limit, starting_after, arr) {

  var dept = [];
  for (var i = 0; i < departments.length; i++) {
    if (departments[i].checked === true) {
      dept.push(departments[i]._id);
    }
  } employee_id_proof.id_proof_image = arr;
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/update_employee", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        employee_id: employee_id,
        employee_name: employee_name,
        employee_designation: employee_designation,
        employee_img: downloadURL,
        employee_contact_info: employee_contact_info,
        employee_id_proof: JSON.stringify(employee_id_proof),
        employee_code: employee_code,
        employee_email: employee_email,
        employee_salary_amount: employee_salary_amount,
        employee_bank_account_no: account_no,
        employee_bank_name: bank_name,
        employee_bank_ifsc_code: bank_ifsc,
        employee_bank_branch: branch,
        is_provided_by_vendor: is_provide_by_vendor,
        vendor_id: selected_vendor,
        departments: JSON.stringify(dept)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_same_employee(token, building_id, limit, starting_after))
          dispatch(dialogAction(false))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function dialogAction(value) {
  return {
    type: SET_DIALOG,
    payload: value
  }
}

export function updatePassword(token, employee_id, password) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/update_employee_password", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        employee_id: employee_id,
        password: password
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        dispatch(set_snack_bar(true, responseJson.message))

        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function get_all_employee(token, building_id, limit, starting_after) {
  starting_after = 0;
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_employees", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_employee(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_employee_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          dispatch(set_snack_bar(true, responseJson.message))
        }
        dispatch(get_building_vendors(token, building_id));
        dispatch(get_all_id_proofs(token, building_id));
        dispatch(get_all_departments(token, building_id, 25, starting_after));
        dispatch(unset_loader());

      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_same_employee(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_employees", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_employee(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_employee_pagination(responseJson, limit, starting_after))
          }
        }
        else {
          if (responseJson.message === "No Employees Found") {
            dispatch(set_all_employee({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_employees", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_employee(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_employee_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_employees", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_employee(responseJson, limit, starting_after))
          }
          else {
            dispatch(set_all_employee_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_starting_after(payload) {
  return {
    type: SET_STARTING_AFTER,
    payload: payload
  }
}
export function toggleCheckbox(obj, array) {
  var index = array.indexOf(obj);
  array[index].checked = !array[index].checked;
  return {
    type: SET_ONLY_EMPLOYEE,
    payload: array,
  }
}
export function set_all_employee(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    for (var i = 0; i < responseJson.result.length; i++) {
      responseJson.result[i].checked = false;
    }
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_ALL_EMPLOYEE,
    payload: obj,
  }
}
export function set_all_employee_pagination(responseJson, limit, starting_after) {
  for (var i = 0; i < responseJson.result.length; i++) {
    responseJson.result[i].checked = false;
  }
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  return {
    type: SET_ALL_EMPLOYEE,
    payload: obj,
  }
}
export function reset_all() {
  return {
    type: RESET_EMPLOYEE
  }
}
export function delete_single_employee(token, building_id, employees, limit, starting_after) {
  var employee_id = []
  for (var i = 0; i < employees.length; i++) {
    if (employees[i].checked === true) {
      employee_id.push(employees[i]._id)
    }
  }
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/delete_employee", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        employee_id: JSON.stringify(employee_id),
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Employee Deleted Succcessfully"));
          dispatch(get_same_employee(token, building_id, limit, starting_after))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Employee Could Not Be Deleted"));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}


export function get_building_vendors(token, building_id) {

  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/get_building_vendors", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_building_employees(responseJson.result))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_building_employees(payload) {
  return {
    type: SET_BUILDING_VENDORS_FOR_EMPLOYESS,
    payload: payload
  }
}