import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import TextField from "@material-ui/core/TextField";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AddClassified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editDialog: false,
      ad_id: "",
      ad_name: "",
      ad_desc: "",
      ad_contact_person: "",
      ad_contact_number: "",
      ad_images: [],
      del_check: false
    }
  }
  componentDidMount() {
    this.props.reset_classified();
  }
  render() {
    const {
      classified,
      login,
      snackbar,
      close_snack_bar,
      validate_add_classified,
      set_ad_name,
      set_ad_desc,
      set_ad_contact_name,
      set_ad_contact_number,
      set_ad_images
    } = this.props;
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD CLASSIFIED
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid item xs={12} md={6}>
                <Grid item lg={12} xs={12} >
                  <TextField
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={classified.ad_name}
                    onChange={(event) => {
                      set_ad_name(event.target.value)
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    label="Description"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={classified.ad_description}
                    onChange={(event) => {
                      set_ad_desc(event.target.value)
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12} >
                  <TextField
                    label="Contact Person"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={classified.ad_contact_person}
                    onChange={(event) => {
                      set_ad_contact_name(event.target.value)
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    label="Contact Number"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={classified.ad_contact_number}
                    onChange={(event) => {
                      set_ad_contact_number(event.target.value)
                    }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  Classified images:
                  <input
                    type="file"
                    multiple
                    onChange={(event) => {
                      set_ad_images(event)
                    }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      validate_add_classified(
                        login.token,
                        login.building_id,
                        {
                          ad_name: classified.ad_name,
                          ad_desc: classified.ad_description,
                          ad_contact_person: classified.ad_contact_person,
                          ad_contact_number: classified.ad_contact_number,
                          ad_images: classified.ad_images
                        }
                      )
                    }}
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(AddClassified);