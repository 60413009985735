import React, { Component } from "react";
import { connect } from "react-redux";
import ViewEmployee from "../../components/employee/view_employee";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  get_all_employee,
  prev_btn,
  next_btn,
  set_starting_after,
  validate_update_employee,
  delete_single_employee,
  toggleCheckbox,
  set_employee_id_proof_img,
  dialogAction,
  updatePassword
}
  from "../../actions/employee/employee_action";
import { get_all_vendors } from "../../actions/vendors/vendors_action";
import { get_all_id_proofs } from "../../actions/id_proofs/id_proofs_action";
import { get_all_departments } from "../../actions/department/department_action"

export class View_Employee_cont extends Component {
  render() {
    return (
      <ViewEmployee {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    employee: store.employee,
    vendors: store.vendors,
    id_proofs: store.id_proofs,
    department: store.department
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    dialogAction: (value) => {
      dispatch(dialogAction(value))
    },
    get_all_employee: (token, building_id, limit, starting_after) => {
      dispatch(get_all_employee(token, building_id, limit, starting_after))
    },
    get_all_vendors: (token, limit, starting_after, building_id) => {
      dispatch(get_all_vendors(token, limit, starting_after, building_id))
    },
    get_all_id_proofs: (payload) => {
      dispatch(get_all_id_proofs(payload))
    },
    validate_update_employee: (token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, employee_image, employee_image_old, departments, limit, starting_after, employee) => {
      dispatch(validate_update_employee(token, building_id, employee_id, employee_name, employee_designation, employee_contact_info, employee_salary_amount, employee_id_proof, employee_code, employee_email, account_no, bank_name, bank_ifsc, branch, is_provide_by_vendor, selected_vendor, employee_image, employee_image_old, departments, limit, starting_after, employee))
    },
    delete_single_employee: (token, building_id, employee_id, limit, starting_after) => {
      dispatch(delete_single_employee(token, building_id, employee_id, limit, starting_after))
    },
    prev_btn: (token, building_id, limit, starting_after) => {
      dispatch(prev_btn(token, building_id, limit, starting_after))
    },
    next_btn: (token, building_id, limit, starting_after) => {
      dispatch(next_btn(token, building_id, limit, starting_after))
    },
    set_starting_after: (payload) => {
      dispatch(set_starting_after(payload))
    },
    updatePassword: (token, employee_id, password) => {
      dispatch(updatePassword(token, employee_id, password))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    toggleCheckbox: (obj, array) => {
      dispatch(toggleCheckbox(obj, array));
    },
    get_all_departments: (token, building_id) => {
      dispatch(get_all_departments(token, building_id));
    },
    set_employee_id_proof_img: (file) => {
      dispatch(set_employee_id_proof_img(file));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_Employee_cont);