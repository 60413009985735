import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Tooltip from "@material-ui/core/Tooltip";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import UNIVERSAL from "../../config/config";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from "moment";



class ViewAllAssets extends React.Component {

    componentDidMount() {
        // this.props.reset()
        this.props.getAllAssets(this.props.login.token, this.props.login.building_id, this.props.assets.limit, this.props.assets.startingAfter, null, null)
        this.props.getAllBankAccounts(this.props.login.token, this.props.login.building_id)
    }

    constructor(props) {
        super(props);
        this.state = {
            addDialog: false,
            bankAccount: "",
            assetType: "",
            assetName: "",
            amount: "",
            paymentMode: "",
            gstAmount: "",
            gstPercentage: "",
            comment: "",
            startDate: null,
            endDate: null,
            transactionId: "",

        }
    }
    render() {
        const {
            login,
            snackbar,
            close_snack_bar,
            assets,
            bank,
            getAllAssets,
        } = this.props;
        var btn;
        if (assets.total > assets.limit) {
            if (assets.end_var === assets.total) {
                btn = <span >
                    <IconButton
                        onClick={() => { getAllAssets(login.token, login.building_id, assets.limit, parseInt(assets.startingAfter) - parseInt(assets.limit), this.state.startDate, this.state.endDate); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton disabled>
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {assets.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{assets.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{assets.total}
                </span>
            }
            else if (assets.start_var > assets.limit && assets.end_var < assets.total) {
                btn = <span >
                    <IconButton
                        onClick={() => { getAllAssets(this.props.login.token, login.building_id, assets.limit, parseInt(assets.startingAfter) - parseInt(assets.limit), this.state.startDate, this.state.endDate); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton
                        onClick={() => { getAllAssets(this.props.login.token, login.building_id, assets.limit, parseInt(assets.startingAfter) + parseInt(assets.limit), this.state.startDate, this.state.endDate); }}
                    >
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {assets.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{assets.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{assets.total}
                </span>
            }
            else {
                btn =
                    <span >
                        <IconButton disabled>
                            <Icon>navigate_before</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => { getAllAssets(login.token, login.building_id, assets.limit, parseInt(assets.startingAfter) + parseInt(assets.limit), this.state.startDate, this.state.endDate); }}
                        >
                            <Icon>navigate_next</Icon>
                        </IconButton>
                        {assets.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{assets.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{assets.total}
                    </span>
            }
        }
        else {
            btn = <span >
                <IconButton disabled>
                    <Icon>navigate_before</Icon>
                </IconButton>
                <IconButton disabled>
                    <Icon>navigate_next</Icon>
                </IconButton>
                {assets.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{assets.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{assets.total}
            </span>
        }
        return (
            <Grid container justify="center">
                <Card>
                    <CardHeader color="warning" stats icon>
                        <CardIcon color="rose">
                            <h3>
                                VIEW ASSETS
                </h3>
                        </CardIcon>
                    </CardHeader>
                    <CardContent >
                        <Grid container item xs={12} md={12}>

                            <Grid item xs={12} md={2}>
                                <IconButton
                                    onClick={() => { this.setState({ addDialog: true }) }}
                                >
                                    <Icon>add</Icon>
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="date-picker-inline45"
                                        label="Start Date"
                                        value={this.state.startDate}
                                        onChange={(e) => {
                                            this.setState({ startDate: e }
                                            )
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="End Date"
                                        value={this.state.endDate}
                                        onChange={(e) => {
                                            this.setState({ endDate: e },
                                                function () {
                                                    this.props.getAllAssets(this.props.login.token, this.props.login.building_id, this.props.assets.limit, this.props.assets.startingAfter, this.state.startDate, this.state.endDate)
                                                })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    className="refreshBtn"
                                    variant="outlined"
                                    onClick={() => {
                                        this.props.getAllAssets(this.props.login.token, this.props.login.building_id, this.props.assets.limit, 0, this.state.startDate, this.state.endDate)
                                    }}
                                >
                                    Refresh
                               </Button>
                            </Grid>
                            <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                                {this.state.endDate === null || this.state.startDate === null ?
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        disabled={this.state.endDate !== null && this.state.startDate !== null ? false : true}
                                    >
                                        Export
                                     </Button>
                                    :
                                    <a
                                        style={{ textDecoration: "none" }}
                                        href={UNIVERSAL.BASEURL + "v1/export_asset_entries?building_id=" + login.building_id + "&start_date=" + this.state.startDate + "&end_date=" + this.state.endDate}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                        >
                                            Export
                                       </Button>

                                    </a>
                                }
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div style={{ float: "right" }}>
                                    {btn}
                                </div>
                            </Grid>


                        </Grid>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Entry Date</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">GST %</TableCell>
                                    <TableCell align="left">GST Amount</TableCell>
                                    <TableCell align="left">Total Amount</TableCell>
                                    <TableCell align="left">Payment Mode</TableCell>
                                    <TableCell align="left">Transaction-ID</TableCell>
                                    <TableCell align="left">Account Credited</TableCell>
                                    <TableCell align="left">Comment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assets.all_assets.map(row => (
                                    <TableRow key={row._id}>
                                        <TableCell align="left" className="table_cells">
                                            {moment(row.created_on).format(" DD/MM/YYYY")}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.asset_name}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.amount}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.asset_type}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.gst_percentage}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.gst_amount}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.total_amount}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.payment_mode}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.transaction_id !== "" ? row.transaction_id : "N/A"}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">

                                            {row.bank_account.bank_name ?
                                                row.bank_account.bank_name + "- ****"
                                                + row.bank_account.account_no.substring(row.bank_account.account_no.length , row.bank_account.account_no.length - 4)

                                                : "N/A"}
                                        </TableCell>
                                        <TableCell align="left" className="table_cells">
                                            {row.comments}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>

                <LoaderCon />
                <Snackbar
                    open={snackbar.response_received}
                    close_snack_bar={close_snack_bar}
                    message={snackbar.message}
                />
                <Dialog
                    open={this.state.addDialog}
                    fullWidth={true}
                    maxWidth={"xs"}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Add Assets</DialogTitle>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.paymentMode}
                                    select
                                    label="Payment Mode"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        if (e.target.value !== "Bank") {
                                            if (e.target.value === "Cash") {
                                                this.setState({ paymentMode: e.target.value, transactionId: "" })
                                            }
                                            else {
                                                this.setState({
                                                    paymentMode: e.target.value,
                                                    bankAccount: "",
                                                })
                                            }

                                        }
                                        else {
                                            this.setState({
                                                paymentMode: e.target.value,
                                                bankAccount: "",
                                            })

                                        }
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                    {assets.mode.map(s => (
                                        <MenuItem value={s} key={s}>
                                            {s}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Grid>
                            {(this.state.paymentMode !== "Cash" && this.state.paymentMode !== "") &&
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        value={this.state.bankAccount}
                                        select
                                        label="Select Bank Account"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(e) => {
                                            this.setState({ bankAccount: e.target.value })
                                        }}
                                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {bank.all_bank_accounts.map(su => (
                                            <MenuItem key={su.id}
                                                value={su.id}
                                            >
                                                {su.bankAccountMix}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            }
                            {
                                this.state.paymentMode === "Bank" || this.state.paymentMode === "UPI" || this.state.paymentMode === "Cheque" ?
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            value={this.state.transactionId}
                                            label={this.state.paymentMode === "Cheque" ? "Check NO" : "Transaction ID"}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => {
                                                this.setState({ transactionId: e.target.value })
                                            }}
                                            InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        >
                                        </TextField>
                                    </Grid> : ""
                            }
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.assetType && this.state.assetType}
                                    select
                                    label="Asset Type"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ assetType: e.target.value })
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                    {assets.all_assets_types.map(su => (
                                        <MenuItem key={su._id}
                                            value={su._id}
                                        >
                                            {su.type}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.assetName}

                                    label="Name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ assetName: e.target.value })
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                </TextField>

                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.amount}

                                    label="Amount"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ amount: e.target.value })
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                </TextField>

                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.gstPercentage}

                                    label="GST %"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ gstPercentage: e.target.value })
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                </TextField>

                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.gstAmount}

                                    label="GST Amount"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ gstAmount: e.target.value })
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                </TextField>

                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    value={this.state.comment}

                                    label="Comment"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({ comment: e.target.value })
                                    }}
                                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                >
                                </TextField>

                            </Grid>


                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({
                                    addDialog: false,
                                    bankAccount: "",
                                    assetType: "",
                                    bankAccount: "",
                                    assetType: "",
                                    assetName: "",
                                    amount: "",
                                    paymentMode: "",
                                    gstAmount: "",
                                    gstPercentage: "",
                                    comment: "",
                                    transactionId: "",
                                });
                            }}
                            color="primary">
                            Close
              </Button>
                        <Button
                            disabled={
                                this.state.assetType === "" ||
                                    this.state.assetName === "" ||
                                    this.state.amount === "" ||
                                    this.state.paymentMode === ""
                                    ? true : false}
                            onClick={() => {
                                this.props.addAsset(this.props.login.token, this.props.login.building_id, this.state, assets)
                                this.setState({
                                    addDialog: false,
                                    bankAccount: "",
                                    assetType: "",
                                    assetName: "",
                                    amount: "",
                                    paymentMode: "",
                                    gstAmount: "",
                                    gstPercentage: "",
                                    comment: "",
                                    transactionId: "",


                                })
                            }}
                            color="primary"
                            autoFocus
                        >
                            Add Asset
              </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}
export default withStyles(styles)(ViewAllAssets);