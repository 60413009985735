export const SET_ALL_VISITORS = "SET_ALL_VISITORS";
export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_VISITOR_NAME = "SET_VISITOR_NAME";
export const SET_VISITOR_MOB_NUM = "SET_VISITOR_MOB_NUM";
export const SET_VISITOR_VEHICLE_TYPE = "SET_VISITOR_VEHICLE_TYPE";
export const SET_VISITOR_VEHICLE_NUM = "SET_VISITOR_VEHICLE_NUM";
export const SET_VISITOR_UNIT_NAME = "SET_VISITOR_UNIT_NAME";
export const RESET_DEFAULT_VISITOR = "RESET_DEFAULT_VISITOR";
export const SET_VISITOR_DATE_OF_VISIT = "SET_VISITOR_DATE_OF_VISIT";
export const SET_SEARCH_HELPERS = "SET_SEARCH_HELPERS";