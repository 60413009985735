import React, { Component } from "react";
import { connect } from "react-redux";
import Building from "../../components/buildings/buildings";
import {
    get_all_buildings,
    update_buiding_single,
    delete_single_building,
    validate_create_credential,
    get_building_admins,
    remove_building_admin,
    reset_all,
    set_all_buildings,
    setRedirect

} from "../../actions/buildings/buildings_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Buildings_Container extends Component {
    render() {
        return (
            <Building {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        buildings: store.buildings,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        reset_all: (token) => {
            dispatch(reset_all(token))
        },
        setRedirect: (value) => {
            dispatch(setRedirect(value))
        },
        set_all_buildings: (token) => {
            dispatch(set_all_buildings(token))
        },
        get_all_buildings: (token) => {
            dispatch(get_all_buildings(token))
        },
        validate_create_credential: (token, e_name, e_building_id, e_img, e_email, e_contact_info) => {
            dispatch(validate_create_credential(token, e_name, e_building_id, e_img, e_email, e_contact_info))
        },
        update_buiding_single: (token, name, account_num, ifsc_code, bank_name, building_address, building_poc_name, building_poc_phonenumber, building_id, latitude, longitude) => {
            dispatch(update_buiding_single(token, name, account_num, ifsc_code, bank_name, building_address, building_poc_name, building_poc_phonenumber, building_id, latitude, longitude))
        },
        delete_single_building: (token, building_id) => {
            dispatch(delete_single_building(token, building_id))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        get_building_admins: (token, building_id) => {
            dispatch(get_building_admins(token, building_id))
        },
        remove_building_admin: (token, admin_id, building_id) => {
            dispatch(remove_building_admin(token, admin_id, building_id))
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Buildings_Container);