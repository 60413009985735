import {
  SET_ALL_AMENITIES,
  SET_STARTING_AFTER,
  RESET_DEFAULT_AMENITIES,
  SET_GUEST_HALFDAY_PRICE,
  SET_AMINITY_NAME,
  SET_AMINITY_DESC,
  SET_AMINITY_IMAGES,
  SET_AMINITY_FULLDAY_PRICE,
  SET_AMINITY_HALFDAY_PRICE,
  SET_GUEST_FULLDAY_PRICE,
  SET_NO_OF_SLOTS,
  SET_START_TIME,
  SET_END_TIME,
  SET_ISFREE,
  SET_IMAGE_URL,
  RESET_AMENITIES_PAGINATION,
} from "../../constants/amenities/amenities_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
import moment from "moment";

export function get_all_amenities(token, building_id, limit, starting_after) {
  starting_after = 0;
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_amenities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_amenities_pagination(responseJson, limit, starting_after))
          }
        } else {
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_same_amenities(token, building_id, limit, starting_after) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_amenities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_amenities_pagination(responseJson, limit, starting_after))
          }
        } else {
          if (responseJson.message === "No Amenities Found") {
            dispatch(set_all_amenities({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function prev_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) - parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_amenities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_amenities_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function next_btn(token, building_id, limit, starting_after) {
  starting_after = parseInt(starting_after) + parseInt(limit);
  return (dispatch) => {
    dispatch(set_starting_after(starting_after));
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_amenities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.totaldata >= limit) {
            dispatch(set_all_amenities(responseJson, limit, starting_after))
          } else {
            dispatch(set_all_amenities_pagination(responseJson, limit, starting_after))
          }
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_starting_after(payload) {
  return {
    type: SET_STARTING_AFTER,
    payload: payload
  }
}
export function set_all_amenities(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
  }
  return {
    type: SET_ALL_AMENITIES,
    payload: obj,
  }
}
export function set_all_amenities_pagination(responseJson, limit, starting_after) {
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
  return {
    type: SET_ALL_AMENITIES,
    payload: obj,
  }
}
export function reset_amenities() {
  return {
    type: RESET_DEFAULT_AMENITIES
  }
}
export function reset_amenities_pagination() {
  return {
    type: RESET_AMENITIES_PAGINATION
  }
}
export function delete_user_info(token, amenity_id, limit, starting_after, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/delete_amenity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        amenity_id: amenity_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_same_amenities(token, building_id, limit, starting_after));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function validate_add_amenities(token, building_id, amenities) {
  return (dispatch) => {
    dispatch(set_loader());
    if (amenities.amenity_name === "") {
      dispatch(set_snack_bar(true, "Please Enter Amenity Name"));
    } else if (amenities.amenity_desc === "") {
      dispatch(set_snack_bar(true, "Please Enter  Description"));
    }
    //  else if (amenities.no_of_slots === "") {
    //   dispatch(set_snack_bar(true, "Please Enter No of Slots"));
    // } 
    else if (amenities.image_url[0] === "") {
      dispatch(set_snack_bar(true, "Please Upload Your Image"));
    } else if (amenities.start_time.getTime() > amenities.end_time.getTime()) {
      dispatch(set_snack_bar(true, "Start time cannot be more than end time"));
    } else {
      dispatch(add_amenities(token, amenities, building_id));
    }
    dispatch(unset_loader())
  };
}
export function set_images_url(payload, amenities) {
  var arr = amenities.image_url;
  arr.push(payload);
  return {
    type: SET_IMAGE_URL,
    payload: arr
  }
}
export function add_amenities(token, amenities, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    var amenity_price_info = JSON.stringify({ "Hourly_price": amenities.amenity_fullday_price });
    var guest_price_info = JSON.stringify({ "Hourly_price": amenities.guest_fullday_price });
    var available_timing = JSON.stringify([moment(amenities.start_time).format("YYYY-MM-DD HH:mm:ss"), moment(amenities.end_time).format("YYYY-MM-DD HH:mm:ss")]);
    var amenity_imgs = JSON.stringify(amenities.image_url);
    return fetch(UNIVERSAL.BASEURL + "v1/add_amenity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        amenity_name: amenities.amenity_name,
        amenity_desc: amenities.amenity_desc,
        amenity_imgs: amenity_imgs,
        is_free: amenities.isfree,
        amenity_price_info: amenity_price_info,
        guest_price_info: guest_price_info,
        available_timings: available_timing,
        // no_of_slots: amenities.no_of_slots,
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(reset_amenities())
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_images(payload) {
  return {
    type: SET_AMINITY_IMAGES,
    payload: payload
  }
}
export function add_amenity_images(payload, amenities) {
  var i = 0;
  return (dispatch) => {
    if (payload.target.files[0] !== "") {
      while (i < payload.target.files.length) {
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child("images/" + (amenities.amenity_name + i) + ".png").put(payload.target.files[i]);
        i = i + 1;
        uploadTask.on("state_changed", function (snapshot) {
        }, function (error) {
          dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            dispatch(set_add_images(downloadURL, amenities))
          });
        });
      };
    }
  }
}
export function set_add_images(payload, amenities) {
  var arr = amenities.amenity_img;
  arr.push(payload);
  return {
    type: SET_AMINITY_IMAGES,
    payload: arr
  }
}
export function set_del_images(payload, amenities) {
  var index = amenities.amenity_img.indexOf(payload);
  var arr = amenities.amenity_img;
  arr.splice(index, 1);
  return {
    type: SET_AMINITY_IMAGES,
    payload: arr,
  }
}
export function update_amenities(token, building_id, amenity_id, amenity_name, amenity_desc, amenity_imgs, isfree, amenity_fullday_price, guest_fullday_price, start_time, end_time, no_of_slots, limit, starting_after, amenity_images) {
  return (dispatch) => {
    var index = 0;
    var storageRef = firebase.storage().ref();
    var upload_images = function () {
      var uploadTask = storageRef.child("images/" + (amenity_name.replace(" ", "-") + parseInt(Math.random() * 100)) + ".png").put(amenity_images[index]);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          amenity_images[index] = downloadURL;
          index++;
          if (index < amenity_images.length) {
            upload_images();
          } else {
            dispatch(set_loader());
            var amenity_price_info = JSON.stringify({ "Hourly_price": amenity_fullday_price });
            var guest_price_info = JSON.stringify({ "Hourly_price": guest_fullday_price });
            var available_timing = JSON.stringify(
              [
                moment(start_time).format("YYYY-MM-DD HH:mm:ss"),
                moment(end_time).format("YYYY-MM-DD HH:mm:ss")
              ]
            );
            amenity_imgs = amenity_imgs.concat(amenity_images);
            amenity_imgs = JSON.stringify(amenity_imgs);
            return fetch(UNIVERSAL.BASEURL + "v1/update_amenity", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
              },
              body: JSON.stringify({
                amenity_id: amenity_id,
                amenity_name: amenity_name,
                amenity_desc: amenity_desc,
                amenity_imgs: amenity_imgs,
                is_free: isfree,
                amenity_price_info: amenity_price_info,
                guest_price_info: guest_price_info,
                available_timings: available_timing,
                // no_of_slots: no_of_slots,
                building_id: building_id,
              }),
            }).then((response) => response.json())
              .then((responseJson) => {
                if (responseJson.status) {
                  dispatch(set_snack_bar(responseJson.status, responseJson.message));
                  dispatch(get_same_amenities(token, building_id, limit, starting_after));
                }
                else {
                  dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
      });
    }
    if (amenity_images.length > 0) {
      upload_images();
    } else {
      dispatch(set_loader());
      var amenity_price_info = JSON.stringify({ "Hourly_price": amenity_fullday_price });
      var guest_price_info = JSON.stringify({ "Hourly_price": guest_fullday_price });
      // var available_timing = JSON.stringify([start_time, end_time]);
      var available_timing = JSON.stringify(
        [
          moment(start_time).format("YYYY-MM-DD HH:mm:ss"),
          moment(end_time).format("YYYY-MM-DD HH:mm:ss")
        ]
      );
      amenity_imgs = JSON.stringify(amenity_imgs);
      return fetch(UNIVERSAL.BASEURL + "v1/update_amenity", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "user-token": token
        },
        body: JSON.stringify({
          amenity_id: amenity_id,
          amenity_name: amenity_name,
          amenity_desc: amenity_desc,
          amenity_imgs: amenity_imgs,
          is_free: isfree,
          amenity_price_info: amenity_price_info,
          guest_price_info: guest_price_info,
          available_timings: available_timing,
          // no_of_slots: no_of_slots,
          building_id: building_id,
        }),
      }).then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(get_same_amenities(token, building_id, limit, starting_after));
          } else {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
          dispatch(unset_loader())
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
}
export function set_amenity_name(payload) {
  return {
    type: SET_AMINITY_NAME,
    payload: payload
  }
}
export function set_amenity_desc(payload) {
  return {
    type: SET_AMINITY_DESC,
    payload: payload
  }
}
export function set_amenity_images(payload, amenities) {
  var i = 0;
  return (dispatch) => {

    if (payload.target.files[0] !== "") {
      Array.from(payload.target.files).forEach(file => {
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child("images/" + (amenities.amenity_name + i) + ".png").put(file);
        i = i + 1;
        uploadTask.on("state_changed", function (snapshot) {
        }, function (error) {
          dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
        }, function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            dispatch(set_images_url(downloadURL, amenities))
          });
        });
      });
      // while (i < payload.target.files.length) {
      //   var storageRef = firebase.storage().ref();
      //   var uploadTask = storageRef.child("images/" + (amenities.amenity_name + i) + ".png").put(payload.target.files[i]);
      //   i = i + 1;
      //   uploadTask.on("state_changed", function (snapshot) {
      //   }, function (error) {
      //     dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      //   }, function () {
      //     uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
      //       dispatch(set_images_url(downloadURL, amenities))
      //     });
      //   });
      // };
    }
  }
}
export function set_amenity_fullday_price(payload) {
  return {
    type: SET_AMINITY_FULLDAY_PRICE,
    payload: payload
  }
}
export function set_amenity_halfday_price(payload) {
  return {
    type: SET_AMINITY_HALFDAY_PRICE,
    payload: payload
  }
}
export function set_guest_fullday_price(payload) {
  return {
    type: SET_GUEST_FULLDAY_PRICE,
    payload: payload
  }
}
export function set_guest_halfday_price(payload) {
  return {
    type: SET_GUEST_HALFDAY_PRICE,
    payload: payload
  }
}
export function set_no_of_slot(payload) {
  return {
    type: SET_NO_OF_SLOTS,
    payload: payload
  }
}
export function set_start_time(payload) {
  return {
    type: SET_START_TIME,
    payload: payload
  }
}
export function set_end_time(payload) {
  return {
    type: SET_END_TIME,
    payload: payload
  }
}
export function set_isfree(payload) {
  var payloads;
  if (payload === "false") {
    payloads = true;
  }
  else {
    payloads = false;
  }
  return {
    type: SET_ISFREE,
    payload: payloads
  }
}