import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
// UNITS ROUTES
import View_Units_Container from "../../containers/units/view_units_cont";
import Add_Units from "../../containers/units/add_units_cont";
import View_Units_Type_Cont from "../../containers/units/unit_type_cont";
import Add_Units_type_cont from "../../containers/units/add_unit_type_cont";
import Unit_Details_cont from "../../containers/units/unit_details_cont";
import Login from "../../containers/loginCon";
import Admin_dashboard from "../../containers/admin_container/admin_container";
// RESIDENTS ROUTES
import Residents_cont from "../../containers/residents/residents_cont";
import Add_Residents from "../../containers/residents/add_residents_cont";
import Invite_Residents_cont from "../../containers/residents/invite_residents_cont";
// E_NOTICE
import E_notice from "../../containers/e-notice/e-notice_cont";
import Add_e_notice from "../../containers/e-notice/add_e-notice_cont"
// BUILDINGS ROUTES
import Buildings_Container from "../../containers/buildings/buildings_cont";
import Add_buildings_cont from "../../containers/buildings/add_buildings_cont";
import Building_details_container from "../../containers/buildings/view_building_details_cont";
// VENDORS ROUTES
import View_Vendors_SA_Cont from "../../containers/vendors/view_vendors_sa_cont";
import View_admin_vendors_cont from "../../containers/vendors/admin_vendors_cont";
import Add_vendors_cont from "../../containers/vendors/add_vendors_cont";
// VENDORS CATEGORY ROUTES
import View_vendor_category_cont from "../../containers/vendor_category/vendor_category_cont";
// ID_PROOFS ROUTES
import View_id_proofs_cont from "../../containers/id_proofs/id_proofs_cont";
// VEHICLES ROUTES
import View_vehicles_cont from "../../containers/vehicles/view_vehicles_cont";
import Add_vehicles_cont from "../../containers/vehicles/add_vehicles_cont";
// AMENITIES ROUTES
import Amenities_cont from "../../containers/amenities/amenities_cont";
import Add_amenities_cont from "../../containers/amenities/add_amenities_cont";
// AMENITIES BOOKING ROUTES
import Amenities_booking_cont from "../../containers/amenities_booking/amenity_booking_cont";
import Add_Amenities_booking_cont from "../../containers/amenities_booking/add_amenity_booking_cont";
//DEPARTMENT ROUTES
import View_department_cont from "../../containers/department/view_department_cont";
import Add_department_cont from "../../containers/department/add_department_cont";
// DRAWER AND CONTROLLER ROUTES
import Controller_con from "../../containers/router/controller_cont";
import DrawerOptionCon from "../../containers/router/drawer_cont";
// EMPLOYEE ROUTES
import View_Employee_cont from "../../containers/employee/view_employee_cont";
import Add_Employee_cont from "../../containers/employee/add_employee_cont";
import View_details_cont from "../../containers/employee/view_details_cont";
// HELPER ROUTES
import View_helper_cont from "../../containers/helper/view_helper_cont";
import Add_helper_cont from "../../containers/helper/add_helper_cont";
// COMPLAINTS ROUTES
import All_complaints_cont from "../../containers/complaints/all_complaints_cont";
import Add_Complaints_cont from "../../containers/complaints/add_complaints_cont";
// VISITORS ROUTES
import View_vistors_cont from "../../containers/visitors/view_visitors_cont";
import Create_visitor_cont from "../../containers/visitors/create_visitor_cont";
import View_Helper_Visitors_Cont from "../../containers/visitors/helper/view_helper_visitors_cont";
import View_Employee_Visitors_Cont from "../../containers/visitors/employee/view_employee_visitors_cont";
import Add_Employee_Visitors_Cont from "../../containers/visitors/employee/add_employee_visitors_cont";
import Add_Helper_Visitors_Cont from "../../containers/visitors/helper/add_helper_visitors_cont";
import View_Resident_Visitors_Cont from "../../containers/visitors/resident/view_resident_visitors_cont";
//CLASSIFIED ROUTES
import View_Classified_Cont from "../../containers/classified/view_classified_cont";
import Add_Classified_Cont from "../../containers/classified/add_classified_cont";
// SHAREWALL ROUTES
import Sharewall_cont from "../../containers/sharewall/sharewall_cont";
import Create_sharewall_cont from "../../containers/sharewall/create_sharewall_cont";
import Change_Password_Cont from "../../containers/router/change_password_cont";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
//POLLING
import ViewPollingCont from "../../containers/polling/viewPollingCont";
import AddPollingCont from "../../containers/polling/addPollingCont";
//MAINTENANCE
import ViewMaintenanceTypeCont from "../../containers/maintenance/maintenance_cont";
import MaintenancePaymentUnitCont from "../../containers/maintenance/maintenanceUnitCont";
import MaintenancePaymentUnitDetailsCont from "../../containers/maintenance/maintenanceDetailsCont";
import MaintenaceCreditEntriesCont from "../../containers/maintenance/maintenanceCreditCont";
// BANK ACCOUNTS
import ViewBankAccountsCont from "../../containers/bankAccounts/bankAccountsCont";
// ASSETS
import ViewAllAssetsCont from "../../containers/assets/assetsCont";
// SALARY
import ViewAllSalaryCont from "../../containers/salary/viewSalaryCont";

// LOANS
import ViewLoansCont from "../../containers/loans/view_loans_cont";


const styles = theme => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1) * 3,
  },
  content2: {
    flexGrow: 1,
  },
});
class Routes extends Component {
  render() {
    const { login, classes, onLogout } = this.props;
    let body;
    body =
      login.isHome ?
        <div className={classes.root}>
          <main className={classes.content2}>
            <Route path="/" component={Login} />
            <Route path="/" component={Controller_con} />
          </main>
        </div>
        :
        <div className={classes.root}>
          <DrawerOptionCon
            type={login.type}
            onLogout={onLogout}
            departments={login.departments}
          />
          <main className={classes.content}
            style={{ marginTop: 50 }}
          >
            <Route exact path="/" component={Login} />
            {/* <Route path="/" component={Controller_con} /> */}
            <Route exact path="/dashboard" component={Admin_dashboard} />
            {/* UNITS ROUTES */}
            <Route path="/add_units" component={Add_Units} />
            <Route path="/all_units" component={View_Units_Container} />
            <Route path="/all_unit_type" component={View_Units_Type_Cont} />
            <Route path="/add_unit_type" component={Add_Units_type_cont} />
            <Route path="/unit_details" component={Unit_Details_cont} />
            {/* RESIDENTS ROUTES */}
            <Route path="/all_residents" component={Residents_cont} />
            <Route path="/add_resident" component={Add_Residents} />
            <Route path="/invite_residents" component={Invite_Residents_cont} />
            {/* E-NOTICE ROUTES */}
            <Route path="/all_e_notice" component={E_notice} />
            <Route path="/add_e_notice" component={Add_e_notice} />
            {/* BUILDINGS ROUTES */}
            <Route path="/building_details" component={Building_details_container} />
            <Route path="/all_buildings" component={Buildings_Container} />
            <Route path="/add_buildings" component={Add_buildings_cont} />
            {/* VENDORS ROUTES */}
            <Route path="/all_vendors" component={View_Vendors_SA_Cont} />
            <Route path="/all_admin_vendors" component={View_admin_vendors_cont} />
            <Route path="/add_vendor" component={Add_vendors_cont} />
            {/* VENDORS CATEGORY ROUTES */}
            <Route path="/all_vendor_category" component={View_vendor_category_cont} />
            {/* ID_PROOFS ROUTES */}
            <Route path="/all_id_proofs" component={View_id_proofs_cont} />
            {/* <Route path="/add_id_proofs" component={Add_id_proofs_cont} /> */}
            {/* VEHICLES ROUTES */}
            <Route path="/view_vehicles" component={View_vehicles_cont} />
            <Route path="/add_vehicles" component={Add_vehicles_cont} />
            {/* AMENITIES ROUTES */}
            <Route path="/all_amenities" component={Amenities_cont} />
            <Route path="/add_amenities" component={Add_amenities_cont} />
            {/* AMENITIES BOOKING ROUTES */}
            <Route path="/all_amenities_booking" component={Amenities_booking_cont} />
            <Route path="/add_amenities_booking" component={Add_Amenities_booking_cont} />
            {/* EMPLOYEE ROUTES */}
            <Route path="/view_employees" component={View_Employee_cont} />
            <Route path="/add_employee" component={Add_Employee_cont} />
            <Route path="/view_details" component={View_details_cont} />
            {/* COMPLAINTS ROUTES */}
            <Route path="/all_complaints" component={All_complaints_cont} />
            <Route path="/add_complaints" component={Add_Complaints_cont} />
            {/* DEPARTMENT ROUTES */}
            <Route path="/all_departments" component={View_department_cont} />
            <Route path="/add_department" component={Add_department_cont} />
            {/* HELPER ROUTES */}
            <Route path="/all_helpers" component={View_helper_cont} />
            <Route path="/add_helper" component={Add_helper_cont} />
            {/* VISITORS ROUTES */}
            <Route path="/all_visitors" component={View_vistors_cont} />
            <Route path="/create_visitor_entry" component={Create_visitor_cont} />
            <Route path="/view_helper_visitor" component={View_Helper_Visitors_Cont} />
            <Route path="/view_employee_visitor" component={View_Employee_Visitors_Cont} />
            <Route path="/add_employee_visitor" component={Add_Employee_Visitors_Cont} />
            <Route path="/add_helper_visitor" component={Add_Helper_Visitors_Cont} />
            <Route path="/view_residents_visit" component={View_Resident_Visitors_Cont} />
            {/* SHAREWALL ROUTES */}
            <Route path="/all_sharewall" component={Sharewall_cont} />
            <Route path="/create_sharewall" component={Create_sharewall_cont} />
            <Route path="/change_password" component={Change_Password_Cont} />
            {/* CLASSIFIED ROUTES */}
            <Route path="/all_classified" component={View_Classified_Cont} />
            <Route path="/add_classified" component={Add_Classified_Cont} />
            {/* POLLING */}
            <Route path="/view_polling" component={ViewPollingCont} />
            <Route path="/add_polling" component={AddPollingCont} />
            {/* MAINTENANCE */}
            <Route path="/all_maintenance_category" component={ViewMaintenanceTypeCont} />
            <Route path="/unit_maintenance" component={MaintenancePaymentUnitCont} />
            <Route path="/unit_maintenance_details" component={MaintenancePaymentUnitDetailsCont} />
            <Route path="/all_maintenance_credit" component={MaintenaceCreditEntriesCont} />
            {/* BANK */}
            <Route path="/view_accounts" component={ViewBankAccountsCont} />
            {/* ASSETS */}
            <Route path="/all_assests" component={ViewAllAssetsCont} />
            {/* SALARY */}
            <Route path="/all_salaries" component={ViewAllSalaryCont} />
            {/* LOANS */}
            <Route path="/all_loans" component={ViewLoansCont} />


          </main>
        </div>
      ;
    return (
      <Router>
        {body}
      </Router>
    );
  }
}
Routes.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Routes);
