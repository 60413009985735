import React, { Component } from "react";
import { connect } from "react-redux";
import AddAmenitiesbooking from "../../components/amenities_booking/add_amenity_booking";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    set_booking_date,
    set_end_time,
    set_start_time,
    set_selected_amenity,
    add_amenity_booking,
    reset_amenity_booking,
    setCharges
} from "../../actions/amenities_booking/amenity_booking_action";
import { get_all_amenities } from "../../actions/amenities/amenities_action";
import { find_resident, set_phone_number } from "../../actions/complaints/complaints_action";
export class Add_Amenities_booking_cont extends Component {
    render() {
        return (
            <AddAmenitiesbooking {...this.props} />
        );
    };
};
export const mapStateToProps = store => {
    return {
        amenities_booking: store.amenities_booking,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        amenities: store.amenities,
        complaints: store.complaints
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        get_all_amenities: (token, amenity_id, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(get_all_amenities(token, amenity_id, building_id, limit, starting_after, start_date, end_date))
        },
        set_start_time: (start_time) => {
            dispatch(set_start_time(start_time));
        },
        set_end_time: (end_time) => {
            dispatch(set_end_time(end_time));
        },
        set_booking_date: (booking_date) => {
            dispatch(set_booking_date(booking_date));
        },
        set_selected_amenity: (amenity_id) => {
            dispatch(set_selected_amenity(amenity_id));
        },
        find_resident: (value, token, building_id) => {
            dispatch(find_resident(value, token, building_id));
        },
        set_phone_number: (phone_number) => {
            dispatch(set_phone_number(phone_number));
        },
        add_amenity_booking: (user_token, building_id, amenity_id, start_time, end_time, booking_date, resident_id, unit_id, charges) => {
            dispatch(add_amenity_booking(user_token, building_id, amenity_id, start_time, end_time, booking_date, resident_id, unit_id, charges));
        },
        reset_amenity_booking: () => {
            dispatch(reset_amenity_booking());
        },
        setCharges: (charges) => {
            dispatch(setCharges(charges));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Amenities_booking_cont); 