import { LIMIT } from "../../constants/ActionTypes";
import {
  SET_ALL_AMENITIES_BOOKING,
  SET_SELECTED_AMENITY,
  SET_START_DATE,
  SET_END_DATE,
  SET_STARTING_AFTER,
  SET_CHECK,
  SET_UNAPPROVED_AMENITIES_BOOKING,
  SET_START_TIME,
  SET_END_TIME,
  SET_BOOKING_DATE,
  RESET_AMENITY_BOOKING,
  SET_CHARGES
} from "../../constants/amenities_booking/amenity_booking_const";

var date = new Date();
const initial_state = {
  all_amenities_booking: [],
  selected_amenity: "",
  end_date: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
  start_date: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 1),
  starting_after: 0,
  start_var: "",
  end_var: "",
  check: false,
  limit: LIMIT,
  total: "",
  starting_after_unapproved: 0,
  start_var_unapproved: "",
  end_var_unapproved: "",
  limit_unapproved: LIMIT,
  total_unapproved: "",
  all_unapproved_booking: [],
  start_time: new Date(),
  end_time: new Date(),
  booking_date: new Date(),
  charges: 0,
  invoiceAmount: 0,
  invoiceType: ""
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ALL_AMENITIES_BOOKING:
      return state = { ...state, all_amenities_booking: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total };
    case SET_UNAPPROVED_AMENITIES_BOOKING:
      return state = { ...state, all_unapproved_booking: action.payload.response, start_var_unapproved: action.payload.f, end_var_unapproved: action.payload.s, total_unapproved: action.payload.total };
    case SET_SELECTED_AMENITY:
      return state = { ...state, selected_amenity: action.payload };
    case SET_START_DATE:
      return state = { ...state, start_date: action.payload };
    case SET_END_DATE:
      return state = { ...state, end_date: action.payload };
    case SET_STARTING_AFTER:
      return state = { ...state, starting_after: action.payload, };
    case SET_CHECK:
      return state = { ...state, check: action.payload };
    case SET_START_TIME:
      return state = { ...state, start_time: action.payload };
    case SET_END_TIME:
      return state = { ...state, end_time: action.payload };
    case SET_BOOKING_DATE:
      return state = { ...state, booking_date: action.payload };
    case SET_CHARGES:
      return state = { ...state, charges: action.payload };
    case RESET_AMENITY_BOOKING:
      return state = {
        ...state,
        start_time: new Date(),
        end_time: new Date(),
        booking_date: new Date(),
        selected_amenity: "",
        all_unapproved_booking: [],
        all_amenities_booking: [],
      };
    default:
      return state;
  }
}
