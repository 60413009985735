import {
    SET_ALL_LOANS,
    RESET,
} from "../../constants/loans/loans_const";
import { LIMIT } from "../../constants/ActionTypes";

const initial_state = {
    all_loans: [],
    month: ["January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"],
    mode: ["Cash", "Cheque", "Bank"],
};
// case SET_ALL_E_NOTICE:
// return state = { ...state, all_e_notices: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };

export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_LOANS:
            return state = { ...state, all_loans: action.payload };
        case RESET:
            return state = {
                ...state,
                all_loans: [],
            };
        default:
            return state;
    }
}
