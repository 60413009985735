import React from "react";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
class ViewIdProofs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      editredirect: false,
      delete_redirect: false,
      id_proof_id: "",
      del_check: false
    }
  }
  componentDidMount() {
    this.props.get_all_id_proofs(this.props.login.token)
  }
  checkRedirect = () => {
    this.setState({
      redirect: true,
    })
  }
  ed = (s) => {
    this.setState({
      proof_id: s._id,
      proof_name: s.proof_name
    })
  }
  set_del_check() {
    for (var i = 0; i < this.props.id_proofs.all_id_proofs.length; i++) {
      if (this.props.id_proofs.all_id_proofs[i].checked === true) {
        this.setState({
          del_check: true
        });
        break;
      }
      else {
        this.setState({
          del_check: false
        })
      }
    }
  }
  render() {
    const {
      login,
      id_proofs,
      validate_update_id_proof,
      delete_id_proofs,
      snackbar,
      validate_proof_func,
      set_proof_name,
      close_snack_bar,
      toggleCheckbox
    } = this.props;
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW ID PROOFS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item xs={12} >
                <IconButton
                  onClick={this.checkRedirect.bind(this)}
                >
                  <Icon >add</Icon>
                </IconButton>
                {this.state.del_check === true ? <IconButton
                  onClick={() => {
                    this.setState({
                      delete_redirect: true
                    })
                  }}>
                  <Icon>delete</Icon>
                </IconButton> : ""}<br /><br />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                      {this.props.login.type === "SA" &&
                        <TableCell align="right">Action</TableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {id_proofs.all_id_proofs.map(row => (
                      <TableRow >
                        <TableCell align="left" className="table_cells">
                          {login.type === "SA" &&
                            <Checkbox
                              checked={row.checked}
                              size="small"
                              onChange={() => {
                                toggleCheckbox(row, id_proofs.all_id_proofs);
                                this.set_del_check();
                              }}
                            />
                          }
                          &nbsp;&nbsp;{row.proof_name}
                        </TableCell>
                        {this.props.login.type === "SA" &&
                          <TableCell align="right" className="table_cells">
                            <IconButton
                              onClick={() => {
                                this.ed(row)
                                this.setState({
                                  editredirect: true,
                                  ro: row
                                });
                              }}>
                              <Icon>edit</Icon>
                            </IconButton>
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And To Add</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={id_proofs.proof_name}
                    onChange={(event) => { set_proof_name(event.target.value); }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                  <br /><br /><br />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <br /><br /><br />
              <Button
                onClick={() => {
                  this.setState({
                    redirect: false,
                  });
                }}
                color="primary">
                Close
              </Button>
              {id_proofs.proof_name === "" ?
                <Button
                  disabled={true}
                  color="primary"
                  autoFocus
                >
                  ADD
                </Button>
                :
                <Button
                  onClick={() => {
                    validate_proof_func(login.token, id_proofs)
                    this.setState({
                      redirect: false
                    })
                  }}
                  color="primary">
                  ADD
              </Button>
              }
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.proof_name}
                    onChange={(event) => { this.setState({ proof_name: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                  <br /><br /><br />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <br /><br /><br />
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_update_id_proof(login.token, this.state.proof_id, this.state.proof_name)
                  this.setState({
                    redirect: false,
                    editredirect: false
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.delete_redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete this ID?</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    delete_redirect: false,
                    id_proof_id: ""
                  });
                }}
                color="primary">
                NO
                </Button>
              <Button
                onClick={() => {
                  delete_id_proofs(login.token, id_proofs.all_id_proofs)
                  this.setState({
                    delete_redirect: false,
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(ViewIdProofs);