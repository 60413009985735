import React, { Component } from "react";
import { connect } from "react-redux";
import ViewHelperVisitor from "../../../components/visitors/helper_visitor/view_helper_visitor";
import {
    get_all_helper_visitors,
    allow_visitor_entry,
    set_start_date,
    set_end_date,
    prev_btn,
    next_btn,
    exit_helper
} from "../../../actions/visitors/helper/helper_visitors_action";
import {
    close_snack_bar
} from "../../../actions/snackbar/snackbar_action";
export class View_Helper_Visitors_Cont extends Component {
    render() {
        return (
            <ViewHelperVisitor {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        visitors: store.helper_visitor_reducer,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_helper_visitors: (token, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(get_all_helper_visitors(token, building_id, limit, starting_after, start_date, end_date))
        },
        allow_visitor_entry: (token, building_id, visitor_id, limit, starting_after, start_date, end_date) => {
            dispatch(allow_visitor_entry(token, building_id, visitor_id, limit, starting_after, start_date, end_date))
        },
        set_end_date: (payload) => {
            dispatch(set_end_date(payload))
        },
        set_start_date: (payload) => {
            dispatch(set_start_date(payload))
        },
        prev_btn: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn(token, building_id, limit, starting_after))
        },
        next_btn: (token, building_id, limit, starting_after) => {
            dispatch(next_btn(token, building_id, limit, starting_after))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        exit_helper: (token, building_id, visit_id, exit_time, visitors) => {
            dispatch(exit_helper(token, building_id, visit_id, exit_time, visitors))
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_Helper_Visitors_Cont);