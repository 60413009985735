import React, { Component } from "react";
import { connect } from "react-redux";
import ViewVendorsSA from "../../components/vendors/view_vendors_sa";
import {
  get_all_units,
} from "../../actions/units/units_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  get_all_vendors_superAdmin,
  view_single_vendor_info,
  delete_single_vendor,
  set_vendor_name,
  set_vendor_service,
  set_license_no,
  payment_type,
  set_fixed_amount,
  set_account_no,
  set_bank_name,
  set_bank_ifsc,
  set_vendor_contact,
  set_bank_branch,
  set_is_fixed,
  validate_single_vendor,
  next_btn,
  prev_btn,
  import_file_func,
  toggleCheckbox,
  remove_other_contact,
  set_contact_image,
  set_contact_num,
  set_contact_name,
  set_other_contact,
  reset,
  set_employee_id_proof_id,
  set_employee_id_proof_img,
  set_employee_id_proof_type,
  get_vendor_details,

}
  from "../../actions/vendors/vendors_action";

import { get_all_id_proofs } from "../../actions/id_proofs/id_proofs_action";
import {
  get_all_vendor_category
} from "../../actions/vendor_category/vendor_category_action"

export class View_Vendors_SA_Cont extends Component {
  render() {
    return (
      <ViewVendorsSA {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    units: store.units,
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    vendors: store.vendors,
    vendor_category: store.vendor_category,
    id_proofs: store.id_proofs,


  };
};
export const mapDispatchToProps = dispatch => {
  return {
    get_vendor_details: (token, vendor_id, building_id) => {
      dispatch(get_vendor_details(token, vendor_id, building_id))
    },
    get_all_id_proofs: (payload) => {
      dispatch(get_all_id_proofs(payload))
    },
    get_all_vendor_category: (token) => {
      dispatch(get_all_vendor_category(token))
    },
    import_file_func: (files, token, vendors) => {
      dispatch(import_file_func(files, token, vendors))
    },
    view_single_vendor_info: (token, vendor_id) => {
      dispatch(view_single_vendor_info(token, vendor_id))
    },
    get_all_units: (token) => {
      dispatch(get_all_units(token))
    },
    get_all_vendors_superAdmin: (token, limit, starting_after) => {
      dispatch(get_all_vendors_superAdmin(token, limit, starting_after))
    },
    set_vendor_name: (payload) => {
      dispatch(set_vendor_name(payload))
    },
    remove_other_contact: (contacts, index) => {
      dispatch(remove_other_contact(contacts, index))
    },
    set_vendor_service: (payload) => {
      dispatch(set_vendor_service(payload))
    },
    set_license_no: (payload) => {
      dispatch(set_license_no(payload))
    },
    payment_type: (payload) => {
      dispatch(payment_type(payload))
    },
    set_fixed_amount: (payload) => {
      dispatch(set_fixed_amount(payload))
    },
    set_account_no: (payload) => {
      dispatch(set_account_no(payload))
    },
    set_bank_name: (payload) => {
      dispatch(set_bank_name(payload))
    },
    set_bank_ifsc: (payload) => {
      dispatch(set_bank_ifsc(payload))
    },
    set_bank_branch: (payload) => {
      dispatch(set_bank_branch(payload))
    },
    set_vendor_contact: (payload) => {
      dispatch(set_vendor_contact(payload))
    },
    prev_btn: (token, building_id, limit, starting_after) => {
      dispatch(prev_btn(token, building_id, limit, starting_after))
    },
    next_btn: (token, building_id, limit, starting_after) => {
      dispatch(next_btn(token, building_id, limit, starting_after))
    },
    set_is_fixed: (payload) => {
      dispatch(set_is_fixed(payload))
    },
    validate_single_vendor: (token, building_id, vendor_id, vendors) => {
      dispatch(validate_single_vendor(token, building_id, vendor_id, vendors))
    },
    delete_single_vendor: (token, building_id, vendors) => {
      dispatch(delete_single_vendor(token, building_id, vendors))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    toggleCheckbox: (obj, array) => {
      dispatch(toggleCheckbox(obj, array));
    },
    set_contact_num: (payload) => {
      dispatch(set_contact_num(payload))
    },
    set_contact_name: (payload) => {
      dispatch(set_contact_name(payload))
    },
    set_contact_image: (file) => {
      dispatch(set_contact_image(file));
    },
    set_other_contact: (payload) => {
      dispatch(set_other_contact(payload))
    },
    set_employee_id_proof_id: (proof_number) => {
      dispatch(set_employee_id_proof_id(proof_number));
    },
    set_employee_id_proof_img: (file) => {
      dispatch(set_employee_id_proof_img(file));
    },
    set_employee_id_proof_type: (payload) => {
      dispatch(set_employee_id_proof_type(payload))
    },
    reset: () => {
      dispatch(reset())
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_Vendors_SA_Cont);