import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class ViewUnitsType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editredirect: false,
      parent_name: "",
      name: "",
      all_data: {},
      deleteredirect: false,
      units_type_id: "",
      del_check: false,
    }
  }
  componentDidMount() {
    this.props.get_all_units(this.props.login.token, this.props.login.building_id)
    this.props.get_all_units_type(this.props.login.token, this.props.login.building_id)
  }
  set_del_check() {
    for (var i = 0; i < this.props.units.units_types.length; i++) {
      if (this.props.units.units_types[i].checked === true) {
        this.setState({
          del_check: true
        });
        break;
      }
      else {
        this.setState({
          del_check: false
        })
      }
    }
  }
  edit_unit_type = (s) => {
    this.setState({
      editredirect: true,
      name: s.type_name,
      parent_name: s.type_parent_name,
      all_data: s,
    })
  }
  render() {
    const {
      units,
      snackbar,
      close_snack_bar,
      update_unit_type,
      delete_unit_type,
      login,
      toggleCheckbox,
      reset_del_values
    } = this.props;
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW UNITS TYPE
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item xs={12}>
                <Link to="add_unit_type" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                &nbsp;&nbsp;
                {this.state.del_check === true ?
                  <IconButton
                    onClick={() => {
                      this.setState({
                        deleteredirect: true
                      })
                    }}
                  >
                    <Icon >delete</Icon>
                  </IconButton> : ""}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                      <TableCell align="left">Unit Parent Name</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {units.units_types.map(row => (
                      <TableRow >
                        <TableCell align="left" className="table_cells">
                          {row.type_parent_name != null ?
                            <Checkbox
                              checked={row.checked}
                              size="small"
                              onChange={() => {
                                toggleCheckbox(row, units.units_types);
                                this.set_del_check();
                              }}
                            />
                            :
                            <Checkbox
                              checked={row.checked}
                              size="small"
                              disabled={true}
                              onChange={() => {
                                toggleCheckbox(row, units.units_types);
                                this.set_del_check();
                              }}
                            />
                          }
                          {row.type_name}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.type_parent_name}
                        </TableCell>
                        <TableCell align="right" className="table_cells">
                          {row.type_parent_name !== null && <IconButton
                            onClick={() => {
                              this.edit_unit_type(row)
                            }}
                          >
                            <Icon >edit</Icon>
                          </IconButton>}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  &nbsp;&nbsp;NAME:
                  <TextField
                    type="text"
                    style={{ backgroundColor: "white" }}
                    placeholder={this.state.name}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.name}
                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                  />
                </Grid>
                <Grid container>
                  &nbsp;&nbsp;Parent Name:
                  <TextField
                    type="dropdown"
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={this.state.parent_name}
                    onChange={(event) => { this.setState({ parent_name: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {units.units_types.map((single) => (
                      <MenuItem key={single.type_name} value={single.type_name}>
                        {single.type_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                  });
                }}
                color="primary">
                Close
            </Button>
              <Button
                onClick={() => {
                  update_unit_type(login.token, this.state.name, this.state.parent_name, this.state.all_data, login.building_id);
                  this.setState({
                    editredirect: false,
                  })
                }}
                color="primary" autoFocus>
                UPDATE
            </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.deleteredirect}
            onClose={() => {
              this.setState({
                deleteredirect: false
              })
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete The Unit Type</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    deleteredirect: false,
                    units_type_id: ""
                  });
                }}
                color="primary">
                NO
                </Button>
              <Button
                onClick={() => {
                  delete_unit_type(this.props.login.token, units.units_types, login.building_id);
                  reset_del_values();
                  this.setState({
                    deleteredirect: false,
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(ViewUnitsType);