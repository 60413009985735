import {
    SET_BUILDING_NAME,
    SET_BUILDING_POC_NUMBER,
    SET_BUILDING_POC_NAME,
    SET_BUILDING_ACCOUNT_NO,
    SET_BUILDING_ADDRESS,
    RESET_ADD_BUILDING,
    SET_ALL_BUILDINGS,
    SET_BUILDING_IFSC_CODE,
    SET_BUILDING_BANK_NAME,
    SET_BUILDING_LATITUDE,
    SET_BUILDING_LONGITUDE,
    SET_BUILDING_ADMINS,
    SET_ALL_DEPARTMENT_IDS,
    SET_REDIRECT_OPTION,
    SET_REDIRECT,
} from "../../constants/buildings/buildings_const";
const initial_state = {
    building_address: "",
    building_poc_name: "",
    building_poc_number: "",
    building_account_no: "",
    building_ifsc_code: "",
    building_bank_name: "",
    building_name: "",
    latitude: "",
    longitude: "",
    all_buildings: [],
    building_admins: [],
    duration: [{ value: "15 Days" }
        , { value: "1 Month" }
        , { value: "3 Months" },
    { value: "6 Months" },
    { value: "12 Months" }],
    current_date: new Date(),
    ids_array: [],
    redirect_value: false,
    redirect_Building: false
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_REDIRECT:
            return state = { ...state, redirect_Building: action.payload };
        case SET_ALL_BUILDINGS:
            return state = { ...state, all_buildings: action.payload };
        case SET_REDIRECT_OPTION:
            return state = { ...state, redirect_value: true };
        case SET_ALL_DEPARTMENT_IDS:
            return state = { ...state, ids_array: action.payload };
        case SET_BUILDING_ADDRESS:
            return state = { ...state, building_address: action.payload };
        case SET_BUILDING_LATITUDE:
            return state = { ...state, latitude: action.payload };
        case SET_BUILDING_LONGITUDE:
            return state = { ...state, longitude: action.payload };
        case SET_BUILDING_NAME:
            return state = { ...state, building_name: action.payload };
        case SET_BUILDING_POC_NUMBER:
            return state = { ...state, building_poc_number: action.payload, }
        case SET_BUILDING_POC_NAME:
            return state = { ...state, building_poc_name: action.payload, }
        case SET_BUILDING_ACCOUNT_NO:
            return state = { ...state, building_account_no: action.payload, }
        case SET_BUILDING_IFSC_CODE:
            return state = { ...state, building_ifsc_code: action.payload }
        case SET_BUILDING_BANK_NAME:
            return state = { ...state, building_bank_name: action.payload }
        case RESET_ADD_BUILDING:
            return state = {
                ...state,
                building_address: "",
                building_name: "",
                building_poc_name: "",
                building_poc_number: "",
                building_account_no: "",
                building_ifsc_code: "",
                building_bank_name: "",
                latitude: "",
                longitude: "",
                building_admins: [],
                all_buildings: [],
                redirect_value: false
            }
        case SET_BUILDING_ADMINS:
            return state = { ...state, building_admins: action.payload }
        default:
            return state;
    }
} 