import {
    SET_AD_NAME,
    SET_AD_DESCRIPTION,
    SET_AD_CONTACT_NUMBER,
    SET_AD_CONTACT_NAME,
    SET_CLASSIFIEDS,
    SET_AD_IMAGES,
    RESET_CLASSIFIED
} from "../../constants/classified/classified_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
export function set_ad_name(payload) {
    return {
        type: SET_AD_NAME,
        payload: payload
    }
}
export function set_ad_desc(payload) {
    return {
        type: SET_AD_DESCRIPTION,
        payload: payload
    }
}
export function set_ad_contact_name(payload) {
    return {
        type: SET_AD_CONTACT_NAME,
        payload: payload
    }
}
export function set_ad_contact_number(payload) {
    return {
        type: SET_AD_CONTACT_NUMBER,
        payload: payload
    }
}
export function reset_classified() {
    return {
        type: RESET_CLASSIFIED
    }
}
export function set_ad_images(payload) {
    let images = []
    for (var i = 0; i < payload.target.files.length; i++) {
        images.push(payload.target.files[i]);
    }
    return {
        type: SET_AD_IMAGES,
        payload: images
    }
}
export function set_classifieds(payload) {
    for (var i = 0; i < payload.length; i++) {
        payload[i].checked = false;
    }
    return {
        type: SET_CLASSIFIEDS,
        payload: payload
    }
}
export function toggleCheckbox(obj, array) {
    var index = array.indexOf(obj);
    array[index].checked = !array[index].checked;
    return {
        type: SET_CLASSIFIEDS,
        payload: array
    }
}
export function get_all_classifieds(token, building_id, starting_after, limit) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/get_classifieds", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                starting_after: starting_after,
                limit: limit
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_classifieds(responseJson.result));
                } else {
                    dispatch(set_classifieds([]));
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function delete_classified(token, classified, starting_after, limit, building_id) {
    var classified_id = []
    for (var i = 0; i < classified.length; i++) {
        if (classified[i].checked === true) {
            classified_id.push(classified[i]._id)
        }
    }
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/delete_classified_ad", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                ad_id: JSON.stringify(classified_id)
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Classified Deleted Succcessfully"));
                    dispatch(get_all_classifieds(token, building_id, starting_after, limit))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Classied Could Not Be Deleted"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function validate_edit_classified(token, building_id, classified, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        if (classified.ad_name === "") {
            dispatch(set_snack_bar(true, "Please Enter Name"));
        } else if (classified.ad_desc === "") {
            dispatch(set_snack_bar(true, "Please Enter Description"));
        } else if (classified.ad_contact_person === "") {
            dispatch(set_snack_bar(true, "Please Enter Contact Name"));
        } else if (classified.ad_contact_number === "") {
            dispatch(set_snack_bar(true, "Please Enter Contact Number"));
        } else {
            dispatch(update_classified(token, building_id, classified, starting_after, limit));
        }
        dispatch(unset_loader());
    };
}
export function validate_add_classified(token, building_id, classified) {
    return (dispatch) => {
        dispatch(set_loader());
        if (classified.ad_name === "") {
            dispatch(set_snack_bar(true, "Please Enter Name"));
            dispatch(unset_loader());
        } else if (classified.ad_desc === "") {
            dispatch(set_snack_bar(true, "Please Enter Description"));
            dispatch(unset_loader());
        } else if (classified.ad_contact_person === "") {
            dispatch(set_snack_bar(true, "Please Enter Contact Name"));
            dispatch(unset_loader());
        } else if (classified.ad_contact_number === "") {
            dispatch(set_snack_bar(true, "Please Enter Contact Number"));
            dispatch(unset_loader());
        } else if (classified.ad_images.length === 0) {
            dispatch(set_snack_bar(true, "Please upload images"));
            dispatch(unset_loader());
        } else {
            dispatch(upload_classified_image(0, classified, token, building_id))
        }

    };
}
export function upload_classified_image(index, classified, token, building_id) {
    return (dispatch) => {
        dispatch(set_loader());
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child("images/" + (classified.ad_name.replace(" ", "-") + index) + ".png").put(classified.ad_images[index]);
        uploadTask.on("state_changed", function (snapshot) {
        }, function (error) {
            dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
        }, function () {
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                classified.ad_images[index] = downloadURL;
                index++;
                if (index < classified.ad_images.length) {
                    dispatch(upload_classified_image(index, classified, token, building_id))
                } else {
                    dispatch(add_classified(token, building_id, classified));
                }
            });
        });
        dispatch(unset_loader());
    }
}
export function update_classified(token, building_id, classified, starting_after, limit) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/update_classified_ad", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                ad_id: classified.ad_id,
                ad_name: classified.ad_name,
                ad_desc: classified.ad_desc,
                ad_images: JSON.stringify(classified.ad_images),
                ad_contact_person: classified.ad_contact_person,
                ad_contact_number: classified.ad_contact_number,
                starting_after: starting_after,
                limit: limit,
                building_id: building_id
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(get_all_classifieds(token, building_id, starting_after, limit));
                }
                dispatch(set_snack_bar(responseJson.status, responseJson.message));
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function add_classified(token, building_id, classified) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/create_classified_ad", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                ad_name: classified.ad_name,
                ad_desc: classified.ad_desc,
                ad_images: JSON.stringify(classified.ad_images),
                ad_contact_person: classified.ad_contact_person,
                ad_contact_number: classified.ad_contact_number,
                building_id: building_id
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(reset_classified());
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}