import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import { TextField, MenuItem } from "@material-ui/core"
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Avatar from "@material-ui/core/Avatar";
import Image from "react-graceful-image";
import { Link } from "react-router-dom";
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class ViewAdminVendors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            view_redirect: false,
            editredirect: false,
            parent_name: "",
            name: "",
            all_data: {},
            view_single_vendor: false,
            vendor_id: "",
            id: "",
            vendor_payment_type: "",
            amount: "",
            confirm_delete: false,
            add_vendor_redirect: false,
            is_fixed_payment: false,
        }
    }
    componentDidMount() {
        this.props.get_building_vendors(this.props.login.token, this.props.login.building_id, this.props.vendors.limit, this.props.vendors.starting_after)
        this.props.get_all_vendor_category(this.props.login.token);
        this.props.get_all_id_proofs(this.props.login.token)
    }
    checkRedirect = () => {
        this.setState({
            redirect: true,
        })
    }
    handleFunc = (event) => {
        this.setState({
            check: !this.state.check
        })
    }
    del_single_row = (row) => {
        this.setState({
            confirm_delete: true,
            id: row.vendor_id,
        })
    }
    render() {
        if (this.state.add_vendor_redirect === true) {
            return <Redirect to="/add_vendor" />
        }
        var btn;
        const {
            vendors,
            vendor_category,
            login,
            assign_vendor,
            snackbar,
            close_snack_bar,
            prev_btn,
            next_btn,
            get_vendor_details,
            remove_vendor,
            view_single_vendor_info,
            set_vendor_name,
            set_vendor_service,
            set_license_no,
            set_account_no,
            set_bank_name,
            set_bank_ifsc,
            set_vendor_contact,
            validate_single_vendor,
            set_bank_branch,
            toggleCheckbox,
            remove_other_contact,
            set_contact_num,
            set_contact_image,
            set_contact_name,
            set_other_contact,
            employee,
            set_employee_id_proof_id,
            set_employee_id_proof_img,
            set_employee_id_proof_type,
            id_proofs
        } = this.props;
        if (vendors.total2 > vendors.limit) {
            if (vendors.end_var2 === vendors.total2) {
                btn = <span >
                    <IconButton
                        onClick={() => { prev_btn(login.token, login.building_id, vendors.limit, vendors.starting_after); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton disabled>
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {vendors.start_var2}&nbsp;&nbsp;-&nbsp;&nbsp;{vendors.end_var2}&nbsp;&nbsp;out of&nbsp;&nbsp;{vendors.total2}
                </span>
            }
            else if (vendors.start_var2 > vendors.limit && vendors.end_var2 < vendors.total2) {
                btn = <span >
                    <IconButton
                        onClick={() => { prev_btn(login.token, login.building_id, vendors.limit, vendors.starting_after); }}
                    >
                        <Icon>navigate_before</Icon>
                    </IconButton>
                    <IconButton
                        onClick={() => { next_btn(login.token, login.building_id, vendors.limit, vendors.starting_after); }}
                    >
                        <Icon>navigate_next</Icon>
                    </IconButton>
                    {vendors.start_var2}&nbsp;&nbsp;-&nbsp;&nbsp;{vendors.end_var2}&nbsp;&nbsp;out of&nbsp;&nbsp;{vendors.total2}
                </span>
            }
            else {
                btn =
                    <span >
                        <IconButton disabled>
                            <Icon>navigate_before</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => { next_btn(login.token, login.building_id, vendors.limit, vendors.starting_after); }}
                        >
                            <Icon>navigate_next</Icon>
                        </IconButton>
                        {vendors.start_var2}&nbsp;&nbsp;-&nbsp;&nbsp;{vendors.end_var2}&nbsp;&nbsp;out of&nbsp;&nbsp;{vendors.total2}
                    </span>
            }
        }
        else {
            btn = <span >
                <IconButton disabled>
                    <Icon>navigate_before</Icon>
                </IconButton>
                <IconButton disabled>
                    <Icon>navigate_next</Icon>
                </IconButton>
                {vendors.start_var2}&nbsp;&nbsp;-&nbsp;&nbsp;{vendors.end_var2}&nbsp;&nbsp;out of&nbsp;&nbsp;{vendors.total2}
            </span>
        }
        return (
            <Grid container spacing={8} justify="center">
                <Grid item sm={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                <h3>
                                    VIEW VENDORS
                                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent>
                            <Grid item xs={12} >
                                <IconButton
                                    onClick={() => {
                                        this.props.get_all_vendors(login.token, vendors.limit, vendors.starting_after, login.building_id);
                                        this.checkRedirect()
                                    }}
                                >
                                    <Icon >add</Icon>
                                </IconButton>
                                <div style={{ float: "right" }}>
                                    {btn}
                                </div><br /><br />
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left">&nbsp;&nbsp;&nbsp;Name</TableCell>
                                            <TableCell align="left">Service</TableCell>
                                            <TableCell align="left">Contact number</TableCell>
                                            <TableCell align="left">Added By</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {vendors.building_vendor.map(row => (
                                            <TableRow key={row.vendor_name} >
                                                <TableCell align="left" className="table_cells">
                                                    <Avatar src={row.vendor_image} />
                                                </TableCell>
                                                <TableCell align="left" className="table_cells">
                                                    {row.vendor_name}
                                                </TableCell>
                                                <TableCell align="left" className="table_cells">
                                                    {row.vendor_service}
                                                </TableCell>
                                                <TableCell align="left" className="table_cells">
                                                    {row.vendor_contact_info}
                                                </TableCell>
                                                <TableCell align="left" className="table_cells">
                                                    {row.added_by}
                                                </TableCell>
                                                <TableCell align="right" className="table_cells">
                                                    <IconButton variant="outlined" size="small"
                                                        onClick={() => {
                                                            get_vendor_details(login.token, row.vendor_id, login.building_id);
                                                            this.setState({
                                                                view_redirect: true
                                                            })
                                                        }}>
                                                        <Icon>
                                                            list
                                                        </Icon>
                                                    </IconButton>&nbsp;
                                                    {
                                                        this.props.login.type === "SA" &&

                                                        <IconButton variant="outlined" size="small"
                                                            onClick={() => { this.del_single_row(row) }}
                                                        >
                                                            <Icon>
                                                                delete
                                                        </Icon>
                                                        </IconButton>
                                                    }
                                                    {
                                                        row.can_edit &&

                                                        <IconButton variant="outlined" size="small"
                                                            onClick={() => {
                                                                view_single_vendor_info(login.token, row.vendor_id)
                                                                this.setState({
                                                                    view_single_vendor: true,
                                                                    vendor_id: row.vendor_id,
                                                                })
                                                            }}
                                                        >
                                                            <Icon>
                                                                edit
                                                        </Icon>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Dialog
                        open={this.state.view_single_vendor}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Edit information and click on update</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <img src={vendors.vendor_image} style={{ width: "50%", margin: "auto" }} />
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        label="Vendor Name"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.vendor_name}
                                        onChange={(event) => { set_vendor_name(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        label="Vendor Contact"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.contact}
                                        onChange={(event) => { set_vendor_contact(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        label="Service"
                                        type="dropdown"
                                        fullWidth
                                        select
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.vendor_service}
                                        onChange={(event) => { set_vendor_service(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {vendor_category.all_vendor_category.map((single) => (
                                            <MenuItem key={single} value={single.vendor_category_id}>
                                                {single.vendor_category_name}
                                            </MenuItem>
                                        ))}
                                        <MenuItem key={"Add New Vendor Category"} >
                                            <Link to={{
                                                pathname: "/all_vendor_category",
                                                state: {
                                                    redirect: true
                                                }
                                            }} style={{ textDecoration: "none", color: "black" }}>
                                                Add New Vendor Category
                        </Link>
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        label="Govt License No(Optional)"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.license_no}
                                        onChange={(event) => { set_license_no(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        label="Account No(Optional)"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.account_no}
                                        onChange={(event) => { set_account_no(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        label="Bank Name(Optional)"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.Bank_name}
                                        onChange={(event) => { set_bank_name(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        label="Bank IFSC(Optional)"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.bank_ifsc}
                                        onChange={(event) => { set_bank_ifsc(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        label="Branch(Optional)"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={vendors.branch}
                                        onChange={(event) => { set_bank_branch(event.target.value); }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    {vendors.vendor_type === "I" &&
                                        <Grid item lg={12} xs={12}>
                                            <TextField
                                                label="ID Proof Type"
                                                type="dropdown"
                                                fullWidth
                                                select
                                                margin="normal"
                                                variant="outlined"
                                                value={vendors.vendorIdType}
                                                onChange={(event) => { set_employee_id_proof_type(event.target.value); }}
                                                InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                            >
                                                {id_proofs.all_id_proofs.map((single) => (
                                                    <MenuItem key={single.proof_name} value={single.proof_name}>
                                                        {single.proof_name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                autoComplete="off"
                                                label="Id proof Number"

                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                value={vendors.vendorIdNumber}
                                                onChange={(event) => { set_employee_id_proof_id(event.target.value); }}
                                                InputProps={{ classes: { input: this.props.classes.textfield } }}
                                                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                            />
                                            {
                                                this.state.idProofImageChange ?
                                                    <input
                                                        type="file"
                                                        onChange={(event) => {
                                                            set_employee_id_proof_img(event.target.files[0])
                                                        }}
                                                    /> :
                                                    <span>
                                                        <img
                                                            src={vendors.vendorIdImage}
                                                            style={{ width: "50%", margin: "auto" }}
                                                        />
                                                        <Button
                                                            onClick={() => { this.setState({ idProofImageChange: true }) }}
                                                        >
                                                            Change image
                            </Button>
                                                    </span>
                                            }
                                        </Grid>
                                    }
                                    {vendors.vendor_type === "O" &&
                                        <Grid item lg={12} xs={12}>
                                            Other Contact
                      <TextField
                                                autoComplete="off"
                                                label="Contact Name"
                                                type="text"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                value={vendors.contact_name}
                                                onChange={(event) => { set_contact_name(event.target.value); }}
                                                InputProps={{ classes: { input: this.props.classes.textfield } }}
                                                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                            />
                                            <TextField
                                                autoComplete="off"
                                                label="Contact Number"
                                                type="number"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                value={vendors.contact_num}
                                                onChange={(event) => { set_contact_num(event.target.value); }}
                                                InputProps={{ classes: { input: this.props.classes.textfield } }}
                                                InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                            />
                                            {vendors.contact_name !== "" && vendors.contact_num !== "" ?
                                                < Grid item lg={12} xs={12}>
                                                    Image
                          <input
                                                        type="file"
                                                        onChange={(event) => { set_contact_image(event.target.files[0]); }}
                                                    />
                                                </Grid> :
                                                <Grid item lg={12} xs={12}>
                                                    Image
                      <button
                                                        disabled
                                                    >Choose files
                      </button>
                                                </Grid>
                                            }

                                            <br />
                                            {/* DISABLE ADD BUTTON WHEN NO DATA IS ENTERED */}
                                            {(vendors.contact_name === "" || vendors.contact_num === "" || vendors.contact_image === null) ?
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    disabled={true}
                                                >
                                                    Add contact
                        </Button>
                                                :
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => {
                                                        set_other_contact(vendors);
                                                        set_contact_num("");
                                                        set_contact_name("");
                                                        set_contact_image(null);
                                                    }}
                                                >
                                                    Add contact
                      </Button>
                                            }
                                            {vendors.other_contact.map(((row, index) => (
                                                <Grid key={index} style={{ backgroundColor: "#e6e6e6", borderRadius: 10, padding: 10, marginTop: 10 }}>
                                                    {"Contact Person:  "}<i>{row.contact_person}</i><br />
                                                    {"Contact Phone Number :  "}<i>{row.contact_phonenumber}</i><br /><br />
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={() => {
                                                            remove_other_contact(vendors.other_contact, index)
                                                        }}
                                                    >
                                                        remove
                          </Button>
                                                </Grid>
                                            )))}
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        view_single_vendor: false,
                                        idProofImageChange: false
                                    });
                                }}
                                color="primary">
                                Close
              </Button>
                            <Button
                                onClick={() => {
                                    validate_single_vendor(
                                        this.props.login.token,
                                        this.props.login.building_id,
                                        this.state.vendor_id,
                                        vendors
                                    );
                                    this.setState({
                                        view_single_vendor: false,
                                        idProofImageChange: false
                                    })
                                }}
                                color="primary" autoFocus>
                                UPDATE
              </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog

                        open={this.state.confirm_delete}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Are you sure you want to remove it</DialogTitle>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        confirm_delete: false,
                                        id: "",
                                    });
                                }}
                                color="primary">
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    remove_vendor(this.props.login.token, this.state.id, login.building_id, vendors)
                                    this.setState({
                                        confirm_delete: false,
                                        id: "",
                                    })
                                }}
                                color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog

                        open={this.state.redirect}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        scroll={'paper'}
                    >
                        <DialogTitle id="alert-dialog-title">Select Vendor</DialogTitle>
                        <DialogContent>
                            <Button variant="outlined" color="primary"
                                onClick={() => {
                                    this.setState({
                                        add_vendor_redirect: true,
                                        redirect: false
                                    })
                                }}
                            >
                                ADD A New Vendor
                            </Button>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">&nbsp;&nbsp;Vendor Name</TableCell>
                                        <TableCell align="left">Service</TableCell>
                                        <TableCell align="left">Contact number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vendors.all_vendors.map(row => (
                                        <TableRow key={row._id} >
                                            <TableCell align="left" className="table_cells">
                                                <input
                                                    type="radio"
                                                    value={row.vendor_id}
                                                    name="vehicles"
                                                    onChange={(e) => {
                                                        this.setState({ id: e.target.value })
                                                    }}
                                                />
                                                {row.vendor_name}
                                            </TableCell>
                                            <TableCell align="left" className="table_cells">
                                                {row.vendor_service}
                                            </TableCell>
                                            <TableCell align="left" className="table_cells">
                                                {row.vendor_contact_info}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        redirect: false,
                                        id: "",
                                        vendor_payment_type: "",
                                        amount: "",
                                    });
                                }}
                                color="primary">
                                Cancel
                            </Button>
                            {this.state.id === "" ?
                                <Button disabled={true}>
                                    Add
                                </Button>
                                :
                                <Button
                                    onClick={() => {
                                        assign_vendor(login.token, login.building_id, this.state.id, vendors.limit, vendors.starting_after);
                                        this.setState({
                                            redirect: false,
                                            id: "",
                                            vendor_payment_type: "",
                                            amount: "",
                                        })
                                    }}
                                    color="primary">
                                    Add
                            </Button>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.view_redirect}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={true}
                    // maxWidth={"md"}
                    >
                        <DialogTitle id="alert-dialog-title">Vendor Details</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogContent>
                            <Grid container justify="center">
                                <Grid item xs={12} md={6}>
                                    <Image
                                        src={vendors.vendor_details.vendor_image}
                                        style={{ width: "98%" }}
                                        width="100%"
                                    />
                                </Grid>
                            </Grid>
                            {"Name:- "}{vendors.vendor_details.vendor_name}<br />
                            {"Service:- "}{vendors.vendor_details.vendor_service_name}<br />
                            {"Contact Number:- "}{vendors.vendor_details.vendor_contact_info}<br /><br />
                            <b>Bank Details</b><br />
                            {"Account Number:- "}{vendors.vendor_details.vendor_account_no}<br />
                            {"Bank:- "}{vendors.vendor_details.vendor_bank_name}<br />
                            {"IFSC code:- "}{vendors.vendor_details.vendor_bank_ifsc}<br />
                            {"Branch:- "}{vendors.vendor_details.vendor_bank_branch}<br /><br />
                            {vendors.vendor_details.vendor_type && vendors.vendor_details.vendor_type === "I" &&
                                <div>
                                    <b>ID DETAILS</b><br />
                                    {"ID type:- "}{vendors.vendor_details.vendor_id_type}<br />
                                    {"ID number:- "}{vendors.vendor_details.vendor_id_number}<br />
                                    <Image
                                        src={vendors.vendor_details.vendor_id_image}
                                        style={{ width: "50%" }}
                                    />
                                </div>
                            }
                            {vendors.vendor_details.vendor_type && vendors.vendor_details.vendor_type === "O" &&
                                <div>
                                    <b>Contact Details</b><br />
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Image</TableCell>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Contact number</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {vendors.vendor_details.vendor_other_contacts && vendors.vendor_details.vendor_other_contacts.length === 0 && "No Contacts added"}
                                            {vendors.vendor_details.vendor_other_contacts && vendors.vendor_details.vendor_other_contacts.map((s) => (
                                                <TableRow key={s.contact_person}>
                                                    <TableCell align="left" className="table_cells">
                                                        <Avatar src={s.contact_image} />
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {s.contact_person}
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {s.contact_phonenumber}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        view_redirect: false
                                    });
                                }}
                                color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default withStyles(styles)(ViewAdminVendors);