import {
  SET_ALL_MAINTENANCE_TYPE,
  SET_UNIT_MAINTENANCE,
  SET_UNIT_MAINTENANCE_DETAILS,
  RESET,
  SET_INVOICE_AMOUNT,
  SET_INVOICE_TYPE,
  SET_PENDING_PAYMENTS,
  SET_ALL_DATA_UNITS,
  SET_ALL_MAINTENANCE_CREDIT,
} from "../../constants/maintenance/maintenance_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import { getAllBankAccounts } from "../bankAccounts/bankAccountAction";
// import { RESET } from "../../constants/ActionTypes";
export function getAllMaintenaceType(token) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/view_maintenance_types", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      }
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setAllMaintenanceType(responseJson.result));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function setAllMaintenanceType(allMaintenaceType) {
  return {
    type: SET_ALL_MAINTENANCE_TYPE,
    payload: allMaintenaceType
  };
}

export function import_file_func(files, token, building_id, parent_id) {
  return dispatch => {
    var formData = new FormData();
    formData.append("file", files);

    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/import_maintenance_sheet", {
      method: "POST",
      headers: {
        "user-token": token,
        "building-id": building_id,
        "unit-parent-id": parent_id,
      },
      body:
        formData
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson.status) {
          dispatch(getUnitMaintenance(token, parent_id))
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader())
      })
      .catch(error => {
      });
  };
}

export function getUnitMaintenance(token, unit_parent_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_unit_type_maintenance", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_parent_id: unit_parent_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setUnitMaintenance(responseJson.result));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function setUnitMaintenance(data) {
  return {
    type: SET_UNIT_MAINTENANCE,
    payload: data
  };
}
export function getMaintenanceDetails(token, unit_id, limit, starting_after, startDate, endDate, building_id) {
  var starting_after = parseInt(starting_after)
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_unit_maintenance", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
        limit: limit,
        starting_after: starting_after,
        start_date: startDate,
        end_date: endDate,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.result.length >= limit) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(setUnitMaintenanceDetails(responseJson, limit, starting_after))
          }
          else {
            dispatch(setUnitMaintenanceDetailsPagination(responseJson, limit, starting_after))
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          if (responseJson.message === "No Records Found") {
            dispatch(setUnitMaintenanceDetails({ result: [], total: 0 }, 0, 0))
          }
        }
        dispatch(getPendingPayments(token, unit_id));
        dispatch(getAllMaintenaceType(token));
        dispatch(getAllBankAccounts(token, building_id));
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function setUnitMaintenanceDetails(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0, starting_after: starting_after };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.totaldata, starting_after: starting_after };
  }
  return {
    type: SET_UNIT_MAINTENANCE_DETAILS,
    payload: obj,
  }
}
export function setUnitMaintenanceDetailsPagination(responseJson, limit, starting_after) {
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.totaldata), total: responseJson.totaldata, starting_after: starting_after };
  return {
    type: SET_UNIT_MAINTENANCE_DETAILS,
    payload: obj,
  }
}

export function getPendingPayments(token, unit_id) {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return (dispatch) => {
    // dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_unit_pending_charges", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
        start_date: firstDay,
        end_date: lastDay
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          for (var i = 0; i < responseJson.result.length; i++) {
            responseJson.result[i].checked = false;
          }
          dispatch(setPendingPayments(responseJson.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        else {
          if (responseJson.message === "No Records Found") {
            dispatch(setPendingPayments([]));
          }
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function setPendingPayments(result) {
  return {
    type: SET_PENDING_PAYMENTS,
    payload: result
  };
}

export function approvePayment(token, unit_id, array, maintenance, building_id, creditTo) {
  var array1 = [];
  for (var i = 0; i < array.length; i++) {
    array1.push({
      invoice_id: array[i].invoice_id,
      is_full_paid: array[i].is_full_paid
    })
  }
  var array2 = JSON.stringify(array1)

  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/validate_maintenance_payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
        maintenance_id: array[0].id,
        invoice_id: array2,
        bank_id: creditTo
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getMaintenanceDetails(token, unit_id, maintenance.limit, 0, null, null, building_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function reset() {
  return {
    type: RESET,
  }
}


export function setInvoiceAmount(amount) {
  return {
    type: SET_INVOICE_AMOUNT,
    payload: amount
  }
}

export function setInvoiceType(type, token, unit_id) {
  return (dispatch) => {
    if (type === "Amenity Charges") {
      dispatch(getAllPendingAmenityCharges(token, unit_id))
    } else if (type === "Complaints/Repairs Attended") {
      dispatch(getAllPendingComplaintCharges(token, unit_id))
    }
    dispatch(setInvoiceTypeFinal(type))
  }
}

export function setInvoiceTypeFinal(type) {
  return {
    type: SET_INVOICE_TYPE,
    payload: type
  }
}

export function getAllPendingAmenityCharges(token, unit_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_unit_amenity_charges", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setInvoiceAmount(responseJson.result));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getAllPendingComplaintCharges(token, unit_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_unit_complaint_charges", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setInvoiceAmount(responseJson.result));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function addUnitMaintenance(token, unit_id, building_id, charge, amount, dueDate, pendingPayments, fullyPaid, maintenance, raisedDate, id, bankAccount) {
  var invoice_id = [];
  var type = "";
  var raiseDates = new Date(pendingPayments[0].raised_date);
  var dueDates = new Date(pendingPayments[0].due_date);
  var check = false;

  for (var i = 0; i < pendingPayments.length; i++) {
    if (pendingPayments[i].checked === true) {

      if (check) {
        invoice_id.push({
          invoice_id: pendingPayments[i]._id,
          is_full_paid: fullyPaid,
          raiseDate: pendingPayments[i].raised_date
        })
        type += ", " + pendingPayments[i].type
      }
      else {
        invoice_id.push({
          invoice_id: pendingPayments[i]._id,
          is_full_paid: fullyPaid,
          raiseDate: pendingPayments[i].raised_date
        })
        type += pendingPayments[i].type + " "

      }
      check = true
    }
    if ((new Date(pendingPayments[i].raised_date)).getTime() < raiseDates.getTime()) {
      raiseDates = pendingPayments[i].raised_date
    }
    if ((new Date(pendingPayments[i].due_date)).getTime() > dueDates.getTime()) {
      dueDates = pendingPayments[i].due_date
    }
  }
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/pay_unit_maintenance", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
        building_id: building_id,
        type: type,
        amount: amount,
        invoice_id: JSON.stringify(invoice_id),
        due_date: dueDates,
        payment_type: charge,
        raised_date: raiseDates,
        txn_id: id,
        bank_id: bankAccount
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getMaintenanceDetails(token, unit_id, maintenance.limit, 0, null, null, building_id));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function addUnitPaymentMaintenance(token, unit_id, building_id, charge, amount, dueDate, maintenance) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/add_unit_maintenance", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
        building_id: building_id,
        type: charge,
        amount: amount,
        due_date: dueDate,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getMaintenanceDetails(token, unit_id, maintenance.limit, 0, null, null, building_id));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function getAllMaintenaceCredit(token, limit, starting_after, startDate, endDate, building_id) {
  var starting_after = parseInt(starting_after)
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_building_maintenance_credits", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
        limit: limit,
        starting_after: starting_after,
        start_date: startDate,
        end_date: endDate,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          if (responseJson.result.length >= limit) {
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
            dispatch(setmaintenanceCredit(responseJson, limit, starting_after))

          }
          else {
            dispatch(setmaintenanceCreditPagination(responseJson, limit, starting_after))
            dispatch(set_snack_bar(responseJson.status, responseJson.message));
          }
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          if (responseJson.message === "No Records Found") {
            dispatch(setmaintenanceCredit({ result: [], total: 0 }, 0, 0))
          }
        }
        // dispatch(getPendingPayments(token, unit_id));
        // dispatch(getAllMaintenaceType(token));
        dispatch(getAllBankAccounts(token, building_id));
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function setmaintenanceCredit(responseJson, limit, starting_after) {
  var obj;
  if (responseJson.result.length === 0) {
    obj = { response: responseJson.result, f: 0, s: 0, total: 0, starting_after: starting_after };
  }
  else {
    obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.totaldata, starting_after: starting_after };
  }
  return {
    type: SET_ALL_MAINTENANCE_CREDIT,
    payload: obj,
  }
}
export function setmaintenanceCreditPagination(responseJson, limit, starting_after) {
  var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.totaldata), total: responseJson.totaldata, starting_after: starting_after };
  return {
    type: SET_ALL_MAINTENANCE_CREDIT,
    payload: obj,
  }
}
export function approveMaintenanceCredit(token, unit_id, array, maintenance, building_id, creditTo) {
  var array1 = [];
  for (var i = 0; i < array.length; i++) {
    array1.push({
      invoice_id: array[i].invoice_id,
      is_full_paid: array[i].is_full_paid
    })
  }
  var array2 = JSON.stringify(array1)

  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/validate_maintenance_payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
        maintenance_id: array[0].id,
        invoice_id: array2,
        bank_id: creditTo
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getAllMaintenaceCredit(token, maintenance.limit, 0, null, null, building_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getPendingChargesPayment(token, unit_id) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/view_unit_pending_amount", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        unit_id: unit_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        if (responseJson.status) {

          dispatch(setInvoiceAmount(responseJson.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }

        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}