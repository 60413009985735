import { LIMIT } from "../../constants/ActionTypes";
import {
    RESET_ADD_DEPARTMENT,
    SET_DEPARTMENT_NAME,
    SET_DEPARTMENT_DESC,
    SET_STARTING_AFTER,
    SET_ALL_DEPARTMENT,
    SET_ONLY_DEPARTMENT
} from "../../constants/department/department_const";
const initial_state = {
    all_department: [],
    department_name: "",
    department_desc: "",
    starting_after: 0,
    start_var: "",
    end_var: "",
    limit: 25,
    total: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_DEPARTMENT:
            return state = { ...state, all_department: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total };
        case SET_DEPARTMENT_DESC:
            return state = { ...state, department_desc: action.payload, }
        case SET_DEPARTMENT_NAME:
            return state = { ...state, department_name: action.payload, }
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload, }
        case RESET_ADD_DEPARTMENT:
            return state = {
                ...state,
                department_name: "",
                department_desc: "",
            }
        case SET_ONLY_DEPARTMENT:
            return state = { ...state, all_department: action.payload }
        default:
            return state;
    }
} 