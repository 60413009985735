import React, { Component } from "react";
import { connect } from "react-redux";
import AddUnits from "../../components/units/add_units";
import {
    set_units_unit_type,
    set_unit_name,
    set_unit_description,
    set_units_square_feet,
    get_same_units,
    get_all_units_type,
    validate_add_unit,
    set_units_parent,
    import_file_func,
    get_all_parent,
    set_reset
} from "../../actions/units/units_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_Units_cont extends Component {
    render() {
        return (
            <AddUnits {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        residents: store.residents,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        units: store.units,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        validate_add_unit: (token, units, building_id) => {
            dispatch(validate_add_unit(token, units, building_id))
        },
        set_reset: () => {
            dispatch(set_reset())
        },
        import_file_func: (files, token, building_id, units) => {
            dispatch(import_file_func(files, token, building_id, units))
        },
        get_all_parent: (type_id, token, building_id, unit_types) => {
            dispatch(get_all_parent(type_id, token, building_id, unit_types))
        },
        set_units_unit_type: (payload) => {
            dispatch(set_units_unit_type(payload))
        },
        set_units_parent: (payload) => {
            dispatch(set_units_parent(payload))
        },
        set_unit_name: (payload) => {
            dispatch(set_unit_name(payload))
        },
        set_unit_description: (payload) => {
            dispatch(set_unit_description(payload))
        },
        set_units_square_feet: (payload) => {
            dispatch(set_units_square_feet(payload))
        },
        get_same_units: (token, building_id) => {
            dispatch(get_same_units(token, building_id))
        },
        get_all_units_type: (token, building_id) => {
            dispatch(get_all_units_type(token, building_id))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Units_cont);