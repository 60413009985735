export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const SET_ALL_AMENITIES = "SET_ALL_AMENITIES";
export const RESET_DEFAULT_AMENITIES = "RESET_DEFAULT_AMENITIES";
export const SET_GUEST_HALFDAY_PRICE ="SET_GUEST_HALFDAY_PRICE";
export const SET_AMINITY_BOOKING_ID ="SET_AMINITY_BOOKING_ID";
export const SET_AMINITY_NAME ="SET_AMINITY_NAME";
export const SET_AMINITY_DESC = "SET_AMINITY_DESC";
export const SET_AMINITY_IMAGES = "SET_AMINITY_IMAGES";
export const SET_AMINITY_FULLDAY_PRICE = "SET_AMINITY_FULLDAY_PRICE";
export const SET_AMINITY_HALFDAY_PRICE = "SET_AMINITY_HALFDAY_PRICE";
export const SET_GUEST_FULLDAY_PRICE = "SET_GUEST_FULLDAY_PRICE";
export const SET_NO_OF_SLOTS ="SET_NO_OF_SLOTS";
export const SET_START_TIME ="SET_START_TIME";
export const SET_END_TIME ="SET_END_TIME";
export const SET_ISFREE ="SET_ISFREE";
export const RESET_AMENITIES_PAGINATION = "RESET_AMENITIES_PAGINATION";
export const SET_IMAGE_URL = "SET_IMAGE_URL";
export const SET_BOOKING_DATE = "SET_BOOKING_DATE";
export const SET_SELECTED_AMENITY = "SET_SELECTED_AMENITY";