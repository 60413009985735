export const ALL_UNITS = "ALL_UNITS";
export const SET_ALL_UNITS = "SET_ALL_UNITS";
export const SET_RESIDENT_EMAIL = "SET_RESIDENT_EMAIL";
export const SET_RESIDENT_CONTACT_INFO = "SET_RESIDENT_CONTACT_INFO";
export const SET_RESIDENT_SECONDARY_CONTACT_INFO = "SET_RESIDENT_SECONDARY_CONTACT_INFO";
export const SET_RESIDENT_PERMANENT_ADDRESS = "SET_RESIDENT_PERMANENT_ADDRESS";
export const SET_RESIDENT_ID_PROOF = "SET_RESIDENT_ID_PROOF";
export const SET_RESIDENT_VEHICLE_DETAILS = "SET_RESIDENT_VEHICLE_DETAILS";
export const SET_RESIDENT_NAME = "SET_RESIDENT_NAME";
export const SET_RESIDENT_IMAGE = "SET_RESIDENT_IMAGE";
export const SET_ALL_UNITS_TYPES = "SET_ALL_UNITS_TYPES";
export const SET_UNIT_TYPE_NAME = "SET_UNIT_TYPE_NAME";
export const SET_UNIT_TYPE_PARENT_NAME = "SET_UNIT_TYPE_PARENT_NAME";
export const SET_SQUARE_FEET = "SET_SQUARE_FEET";
export const SET_UNIT_DESCRIPTION = "SET_UNIT_DESCRIPTION";
export const SET_UNIT_TYPE = "SET_UNIT_TYPE";
export const SET_UNIT_NAME = "SET_UNIT_NAME";
export const RESET_ALL_VALUE = "RESET_ALL_VALUE";
export const SET_RESET_TYPE = "SET_RESET_TYPE";
export const RESET_DEFAULT_UNITS = "RESET_DEFAULT_UNITS";
export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const SET_UNIT_PARENT = "SET_UNIT_PARENT";
export const SET_UNIT_DETAILS = "SET_UNIT_DETAILS";
export const SET_FINAL_UNIT_DETAILS = "SET_FINAL_UNIT_DETAILS";
export const SET_UNIT_INFORMATION = "SET_UNIT_INFORMATION";
export const RESET_UNIT_DETAILS = "RESET_UNIT_DETAILS";
export const SET_ONLY_UNITS = "SET_ONLY_UNITS";
export const SELECT_RESIDENT_VEHICLE = "SELECT_RESIDENT_VEHICLE";
export const SET_DEL_VALUES = "SET_DEL_VALUES";
export const SET_UNITS = "SET_UNITS";
export const SET_UNIT_PARENTS = "SET_UNIT_PARENTS";
export const SET_UNIT_PARKING = "SET_UNIT_PARKING";
export const SET_UNIT_PARKING_SPACE = "SET_UNIT_PARKING_SPACE";
export const SET_UNIT_PARKING_SPACE_TEMP = "SET_UNIT_PARKING_SPACE_TEMP";
export const SET_PARKING_TEMP = "SET_PARKING_TEMP";