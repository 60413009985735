import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";

class AddAmenities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    }
  }
  componentDidMount() {
    this.props.reset_amenities();
  }
  render() {
    const {
      set_guest_fullday_price,
      set_amenity_fullday_price,
      set_amenity_images,
      set_amenity_desc,
      set_amenity_name,
      login,
      amenities,
      set_no_of_slot,
      set_start_time,
      set_end_time,
      set_isfree,
      validate_add_amenities,
      snackbar,
      close_snack_bar,
    } = this.props;
    return (
      <Grid container justify="center">
        <Grid item xs={12}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD AMENITIES
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid container spacing={2} justify="space-between">
                <Grid container item xs={12} md={5} spacing={2}>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Amenity Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={amenities.amenity_name}
                      onChange={(event) => { set_amenity_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    Image :
                  <input type="file"
                      multiple
                      onChange={(event) => { set_amenity_images(event, amenities); }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Description"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={amenities.amenity_desc}
                      onChange={(event) => { set_amenity_desc(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  {/* <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Number Of Slots"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={amenities.no_of_slots}
                      onChange={(event) => { set_no_of_slot(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid> */}
                  <Grid item lg={6} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Starting Time"
                        variant="outlined"
                        value={amenities.start_time}
                        onChange={(date) => {
                          set_start_time(date);
                        }}
                        InputProps={{ classes: { input: this.props.classes.time } }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Ending Time"
                        variant="outlined"
                        value={amenities.end_time}
                        onChange={(date) => { set_end_time(date); }}
                        InputProps={{ classes: { input: this.props.classes.time } }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => { validate_add_amenities(login.token, login.building_id, amenities); }}
                    >
                      Submit
                  </Button>
                  </Grid>
                </Grid>
                <Grid container justify="space-between" alignContent={"flex-start"} item xs={12} md={6}>
                  <Grid item lg={12} xs={12} style={{ marginTop: 15 }}>
                    <input
                      id="outlined-email-input"
                      type="checkbox"
                      checked={amenities.isfree}
                      value={amenities.isfree}
                      onClick={(event) => { set_isfree(event.target.value) }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                    Is Free
                </Grid>
                  {amenities.isfree === false &&
                    <Grid item lg={5} xs={12}>
                      <TextField
                        id="outlined-email-input"
                        label="Resident Hourly Price"
                        type="number"
                        style={{ marginRight: 20 }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={amenities.amenity_fullday_price}
                        onChange={(event) => { set_amenity_fullday_price(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                  }
                  {/* {amenities.isfree == false &&
                    <Grid item lg={6} xs={6}>
                      <TextField
                        id="outlined-email-input"
                        label="Amenity Half day price"
                        type="number"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={amenities.amenity_halfday_price}
                        onChange={(event) => { set_amenity_halfday_price(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                  } */}
                  <Grid item lg={5} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Guest Hourly price"
                      type="number"
                      style={{ marginRight: 5 }}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={amenities.guest_fullday_price}
                      onChange={(event) => { set_guest_fullday_price(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  {/* <Grid item lg={6} xs={6}>
                    <TextField
                      id="outlined-email-input"
                      label="Guest Half day price"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={amenities.guest_halfday_price}
                      onChange={(event) => { set_guest_halfday_price(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    )
  }
}
export default withStyles(styles)(AddAmenities);