import React, { Component } from "react";
import { connect } from "react-redux";
import UnitDetails from "../../components/units/unit_details";
import {
    get_unit_details,
    reset_unit_details,
    add_helper_to_unit,
    remove_helper_from_unit,
    add_vehicle_to_unit,
    select_resident_vehicle,
    remove_vehicle_from_resident,
    add_parking_no_temp,
    set_add_parking_no,
    add_parking_to_unit,
    delete_arr_element_temp,
    remove_resident,
    delete_arr_element,
    set_temp_parking,
    update_resident,
    updateSos,
    deleteSos,
    addSos
} from "../../actions/units/units_action";
import { close_snack_bar } from "../../actions/snackbar/snackbar_action";
import {
    get_all_helpers,
    toggleCheckbox,
    select_helper
} from "../../actions/helper/helpers_action";
import {
    get_all_vehicles,
    toggleVehicleCheckbox,
    select_vehicle,
    set_vehicle_number
} from "../../actions/vehicles/vehicles_action";
export class Unit_Details_cont extends Component {
    render() {
        return (
            <UnitDetails {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        residents: store.residents,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        units: store.units,
        helper: store.helper,
        vehicles: store.vehicles
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_unit_details: (token, unit_id) => {
            dispatch(get_unit_details(token, unit_id));
        },
        delete_arr_element_temp: (s, arr) => {
            dispatch(delete_arr_element_temp(s, arr))
        },
        delete_arr_element: (s, arr, token, building_id, unit_id) => {
            dispatch(delete_arr_element(s, arr, token, building_id, unit_id))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        reset_unit_details: () => {
            dispatch(reset_unit_details());
        },
        get_all_helpers: (token, building_id, limit, starting_after) => {
            dispatch(get_all_helpers(token, building_id, limit, starting_after))
        },
        toggleCheckbox: (obj, array) => {
            dispatch(toggleCheckbox(obj, array));
        },
        toggleVehicleCheckbox: (obj, array) => {
            dispatch(toggleVehicleCheckbox(obj, array));
        },
        get_all_vehicles: (token, limit, starting_after) => {
            dispatch(get_all_vehicles(token, limit, starting_after))
        },
        select_vehicle: (vehicle_id, vehicle_name, vehicle_type) => {
            dispatch(select_vehicle(vehicle_id, vehicle_name, vehicle_type))
        },
        select_helper: (helper_id) => {
            dispatch(select_helper(helper_id))
        },
        add_helper_to_unit: (helper_id, unit_id, token, building_id) => {
            dispatch(add_helper_to_unit(helper_id, unit_id, token, building_id))
        },
        remove_helper_from_unit: (helper_id, unit_id, token, building_id) => {
            dispatch(remove_helper_from_unit(helper_id, unit_id, token, building_id))
        },
        add_vehicle_to_unit: (resident_id, vehicle_name, vehicle_type, vehicle_regd_num, token, unit_id) => {
            dispatch(add_vehicle_to_unit(resident_id, vehicle_name, vehicle_type, vehicle_regd_num, token, unit_id))
        },
        select_resident_vehicle: (resident_id) => {
            dispatch(select_resident_vehicle(resident_id))
        },
        set_vehicle_number: (vehicle_number) => {
            dispatch(set_vehicle_number(vehicle_number))
        },
        add_parking_no_temp: (parking_arr, parking_unit) => {
            dispatch(add_parking_no_temp(parking_arr, parking_unit))
        },
        set_add_parking_no: (payload) => {
            dispatch(set_add_parking_no(payload))
        },
        add_parking_to_unit: (token, building_id, unit_id, parking_spaces) => {
            dispatch(add_parking_to_unit(token, building_id, unit_id, parking_spaces))
        },
        remove_vehicle_from_resident: (resident_id, vehicle_details, token, unit_id) => {
            dispatch(remove_vehicle_from_resident(resident_id, vehicle_details, token, unit_id))
        },
        remove_resident: (token, resident_id, building_id, unit_id) => {
            dispatch(remove_resident(token, resident_id, building_id, unit_id));
        },
        set_temp_parking: (payload) => {
            dispatch(set_temp_parking(payload))
        },
        update_resident: (token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, resident_img, unit_id, is_residing, is_owner, is_sub_resident, resident_image, id_proof_array, id_proof_name, id_proof_number, resident_id_proof) => {
            dispatch(update_resident(token, building_id, resident_id, resident_name, resident_contact_info, resident_sec_contact_info, resident_permanent_address, resident_img, unit_id, is_residing, is_owner, is_sub_resident, resident_image, id_proof_array, id_proof_name, id_proof_number, resident_id_proof))
        },
        updateSos: (token, building_id, unit_id, sos_id, sos_name, sos_phone_number, sos_img) => {
            dispatch(updateSos(token, building_id, unit_id, sos_id, sos_name, sos_phone_number, sos_img))
        },
        deleteSos: (token, building_id, unit_id, sos_id) => {
            dispatch(deleteSos(token, building_id, unit_id, sos_id))
        },
        addSos: (token, unit_id, sos_name, sos_phone_number, sos_img) => {
            dispatch(addSos(token, unit_id, sos_name, sos_phone_number, sos_img))
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Unit_Details_cont);