import React, { Component } from "react";
import { connect } from "react-redux";
import ViewDetails from "../../components/employee/view_details";
import {
    toggleapprove,
    view_helper_unit,
    del_helper_unit
} from "../../actions/helper/helpers_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class View_details_cont extends Component {
    render() {
        return (
            <ViewDetails {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        employee: store.employee,
        helper: store.helper
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        toggleapprove: (token, helper_id, building_id) => {
            dispatch(toggleapprove(token, helper_id, building_id))
        },
        view_helper_unit: (token, helper_id, building_id) => {
            dispatch(view_helper_unit(token, helper_id, building_id))
        },
        del_helper_unit: (token, building_id, helper_id, unit_id) => {
            dispatch(del_helper_unit(token, building_id, helper_id, unit_id))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_details_cont);