import {
    SET_ALL_SALARIES,
    RESET_SALARIES,
    SET_ALL_PENDING_WORKERS,
    DIALOG_ACTION_RESPONSE,
} from "../../constants/salary/salaryConst";
import { LIMIT } from "../../constants/ActionTypes";

const initial_state = {
    all_salaries: [],
    all_assets_types: [],
    startingAfter: 0,
    start_var: "",
    end_var: "",
    limit: LIMIT,
    total: "",
    month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    year: ["2020", "2021"],
    all_pending_payment: [],
    mode: ["Cash", "Bank"],
    response: false,
    disabledButton: true,
};
// case SET_ALL_E_NOTICE:
// return state = { ...state, all_e_notices: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };

export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_SALARIES:
            return state = { ...state, all_salaries: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, startingAfter: action.payload.starting_after, all_pending_payment: [], disabledButton: true };
        case SET_ALL_PENDING_WORKERS:
            return state = { ...state, all_pending_payment: action.payload.data, disabledButton: action.payload.num };
        case DIALOG_ACTION_RESPONSE:
            return state = { ...state, response: action.payload };
        case RESET_SALARIES:
            return state = {
                ...state,
                all_salaries: [],
                startingAfter: 0,
                start_var: "",
                end_var: "",
                total: "",
            };
        default:
            return state;
    }
}
