import React, { Component } from "react";
import { connect } from "react-redux";
import AddComplaints from "../../components/complaints/add_complaints";
import {
    set_is_urgent,
    set_raised_date,
    set_complaints_desc,
    set_complaints_title,
    set_phone_number,
    find_resident,
    validate_add_complaints,
} from "../../actions/complaints/complaints_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class Add_Complaints_cont extends Component {
    render() {
        return (
            <AddComplaints {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        complaints: store.complaints,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        set_complaints_title: (payload) => {
            dispatch(set_complaints_title(payload))
        },
        set_complaints_desc: (payload) => {
            dispatch(set_complaints_desc(payload))
        },
        set_is_urgent: (payload) => {
            dispatch(set_is_urgent(payload))
        },
        set_raised_date: (payload) => {
            dispatch(set_raised_date(payload))
        },
        set_phone_number: (payload) => {
            dispatch(set_phone_number(payload))
        },
        find_resident: (payload, token, building_id) => {
            dispatch(find_resident(payload, token, building_id))
        },
        validate_add_complaints: (login, complaints) => {
            dispatch(validate_add_complaints(login, complaints))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Complaints_cont);