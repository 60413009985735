import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
const buttonnn = {
  borderRadius: "5px",
  height: "36px",
  color: "white",
  paddingLeft: "30px",
  paddingRight: "30px",
  backgroundColor: "green",
  alignSelf: "left",
};
class AddUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    this.props.set_reset();
    this.props.get_same_units(this.props.login.token, this.props.login.building_id)
    this.props.get_all_units_type(this.props.login.token, this.props.login.building_id)
  }
  render() {
    const {
      units,
      snackbar,
      close_snack_bar,
      login,
      set_units_unit_type,
      set_unit_name,
      set_unit_description,
      set_units_square_feet,
      validate_add_unit,
      set_units_parent,
      get_all_parent
    } = this.props;
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                {/* <Icon>content_copy</Icon> */}
                <h3>
                  ADD UNITS
              </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container spacing={8} style={{ padding: 40 }}>
                <Grid container item lg={6} xs={12}>
                  <Grid container lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Unit Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={units.unit_name}
                      onChange={(event) => { set_unit_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Unit Description"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={units.unit_description}
                      onChange={(event) => { set_unit_description(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid><Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Square Feet"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={units.square_feet}
                      onChange={(event) => { set_units_square_feet(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Unit Type"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={units.selected_unit}
                      onChange={(event) => {
                        set_units_unit_type(event.target.value);
                        get_all_parent(event.target.value, login.token, login.building_id, units.units_types);
                      }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {units.units_types.map((single) => (
                        <MenuItem key={single.type_name} value={single._id}>
                          {single.type_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {units.selected_unit !== "" &&
                    <Grid item lg={12} xs={12}>
                      <TextField
                        id="outlined-email-input"
                        label="Unit Parent"
                        type="dropdown"
                        fullWidth
                        select
                        margin="normal"
                        variant="outlined"
                        value={units.units_parent}
                        onChange={(event) => { set_units_parent(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      >
                        <MenuItem key={null} value="null">
                          NONE
                      </MenuItem>
                        {units.units_parents.map((single) => (
                          <MenuItem key={single.unit_name} value={single._id}>
                            {single.unit_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>}
                  <Grid item lg={12} xs={12}>
                    <Button
                      style={buttonnn}
                      variant="outlined"
                      size="small"
                      onClick={() => { validate_add_unit(login.token, units, login.building_id); }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddUnits);