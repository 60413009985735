import {
    RESET_ADD_DEPARTMENT,
    SET_DEPARTMENT_NAME,
    SET_DEPARTMENT_DESC,
    SET_STARTING_AFTER,
    SET_ALL_DEPARTMENT,
    SET_ONLY_DEPARTMENT
}
    from "../../constants/department/department_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function validate_add_department(token, building_id, department) {
    return (dispatch) => {
        dispatch(set_loader());
        if (department.department_name === "") {
            dispatch(set_snack_bar(true, "Please Enter Department Name"))
            dispatch(unset_loader())
        }
        else {
            dispatch(add_department(token, building_id, department))
        }
    }
}
export function add_department(token, building_id, department) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/add_department", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                department_desc: department.department_desc,
                department_name: department.department_name
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(reset_department());
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message))
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function reset_department() {
    return {
        type: RESET_ADD_DEPARTMENT
    }
}
export function set_department_name(payload) {
    return {
        type: SET_DEPARTMENT_NAME,
        payload: payload
    }
}
export function set_department_desc(payload) {
    return {
        type: SET_DEPARTMENT_DESC,
        payload: payload
    }
}
export function get_all_departments(token, building_id, limit, starting_after) {
    starting_after = 0;
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_departments", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_departments(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_departments_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message))
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_same_departments(token, building_id, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_departments", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_departments(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_departments_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    if (responseJson.message === "No Departments Found") {
                        dispatch(set_all_departments({ result: [], total: 0 }, 0, 0))
                    }
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function prev_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_departments", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_departments(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_departments_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_departments", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_departments(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_departments_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_starting_after(payload) {
    return {
        type: SET_STARTING_AFTER,
        payload: payload
    }
}
export function toggleDepartmentCheckbox(obj, array) {
    var index = array.indexOf(obj);
    array[index].checked = !array[index].checked;
    return {
        type: SET_ONLY_DEPARTMENT,
        payload: array,
    }
}
export function get_active_departments(token, building_id) {
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_all_active_departments", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_active_departments(responseJson.result))
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_active_departments(array) {
    for (var i = 0; i < array.length; i++) {
        array[i].checked = false;
    }
    return {
        type: SET_ONLY_DEPARTMENT,
        payload: array,
    }
}
export function toggleCheckbox(obj, array) {
    var index = array.indexOf(obj);
    array[index].checked = !array[index].checked;
    return {
        type: SET_ONLY_DEPARTMENT,
        payload: array,
    }
}
export function set_all_departments(responseJson, limit, starting_after) {
    var obj;
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        for (var i = 0; i < responseJson.result.length; i++) {
            responseJson.result[i].checked = false;
        }
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.totaldata };
    }
    return {
        type: SET_ALL_DEPARTMENT,
        payload: obj,
    }
}
export function set_all_departments_pagination(responseJson, limit, starting_after) {
    for (var i = 0; i < responseJson.result.length; i++) {
        responseJson.result[i].checked = false;
    }
    var obj
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.totaldata), total: responseJson.totaldata };
    }
    return {
        type: SET_ALL_DEPARTMENT,
        payload: obj,
    }
}
export function validate_update_department(token, id, building_id, name, desc, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        if (name === "") {
            dispatch(set_snack_bar(true, "Please Enter Department Name"))
            dispatch(unset_loader())
        }
        else {
            dispatch(update_single_department(token, id, building_id, name, desc, limit, starting_after))
        }
    }
}
export function update_single_department(token, id, building_id, name, desc, limit, starting_after) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/update_department", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                department_id: id,
                building_id: building_id,
                department_name: name,
                department_desc: desc
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Department Updated Succcessfully"));
                    dispatch(get_same_departments(token, building_id, limit, starting_after))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Department Could Not Be Updated"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function delete_single_department(token, department, building_id, limit, starting_after) {
    var department_id = [];
    for (var i = 0; i < department.length; i++) {
        if (department[i].checked === true) {
            department_id.push(department[i]._id)
        }
    }
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/delete_department", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                department_id: JSON.stringify(department_id),
                building_id: building_id,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Department Deleted Succcessfully"));
                    dispatch(get_same_departments(token, building_id, limit, starting_after))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Department Could Not Be Deleted"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}