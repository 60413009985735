import React, { Component } from "react";
import { connect } from "react-redux";
import ViewDepartment from "../../components/department/view_department";
import {
    get_all_departments,
    validate_update_department,
    delete_single_department,
    prev_btn,
    next_btn,
    toggleCheckbox
} from "../../actions/department/department_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class View_department_cont extends Component {
    render() {
        return (
            <ViewDepartment {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        department: store.department,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_departments: (token, building_id, limit, starting_after) => {
            dispatch(get_all_departments(token, building_id, limit, starting_after))
        },
        validate_update_department: (token, id, building_id, name, desc, limit, starting_after) => {
            dispatch(validate_update_department(token, id, building_id, name, desc, limit, starting_after))
        },
        delete_single_department: (token, department_id, building_id, limit, starting_after) => {
            dispatch(delete_single_department(token, department_id, building_id, limit, starting_after))
        },
        prev_btn: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn(token, building_id, limit, starting_after))
        },
        next_btn: (token, building_id, limit, starting_after) => {
            dispatch(next_btn(token, building_id, limit, starting_after))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        toggleCheckbox: (obj, array) => {
            dispatch(toggleCheckbox(obj, array));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_department_cont);