import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CardContent from "@material-ui/core/CardContent";
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class CreateSharewall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
    }
    render() {
        const {
            login,
            sharewall,
            set_sharewall_images,
            set_posted_on,
            set_comments,
            validate_create_sharewall,
            snackbar,
            close_snack_bar,
        } = this.props;
        return (
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                {/* <Icon>content_copy</Icon> */}
                                <h3>
                                    CREATE SHAREWALL
                                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent >
                            <Grid container item xs={12} lg={4}>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Comment"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={sharewall.comments}
                                        onChange={(event) => {
                                            if (event.target.value.length < 125) {
                                                set_comments(event.target.value);
                                            }
                                        }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    Image :
                                    <input type="file" multiple
                                        onChange={(event) => { set_sharewall_images(event, sharewall); }}
                                    />
                                </Grid>
                                <Grid item lg={6} xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Posted On"
                                            format="MM/dd/yyyy"
                                            value={sharewall.posted_on}
                                            onChange={(event) => { set_posted_on(event); }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <br />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => { validate_create_sharewall(login.token, login.building_id, login.user_id, sharewall); }}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(CreateSharewall);