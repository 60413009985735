import {
    SET_ALL_E_NOTICE,
    SET_STARTING_AFTER,
    SET_E_NOTICE_TITLE,
    SET_E_NOTICE_DESC,
    SET_E_NOTICE_TARGET,
    SET_E_NOTICE_VALID_TILL,
    RESET_E_NOTICE,
    SET_ONLY_ALL_E_NOTICE,
    SET_ADMIN_CHECK,
    SET_EMPLOYEE_CHECK,
    SET_RESIDENT_CHECK,
}
    from "../../constants/enotice/e-notice_const";
import { set_loader, unset_loader }
    from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
export function set_admin_target(payload) {
    return {
        type: SET_ADMIN_CHECK,
        payload: payload,
    }
}
export function set_employee_target(payload) {
    return {
        type: SET_EMPLOYEE_CHECK,
        payload: payload,
    }
}
export function set_resident_target(payload) {
    return {
        type: SET_RESIDENT_CHECK,
        payload: payload,
    }
}
export function toggleCheckbox(obj, array) {
    var index = array.indexOf(obj);
    array[index].checked = !array[index].checked;
    var arr = [];
    for (var i = 0; i < array.length; i++) {
        if (array[i].checked) {
            arr.push(array[i])
        }
    }
    var obj1 = { array: array, arr: arr }
    return {
        type: SET_ONLY_ALL_E_NOTICE,
        payload: obj1,
    }
}
export function get_all_e_notice(token, building_id, limit, starting_after) {
    starting_after = 0;
    return (dispatch) => {
        ("inside set loader")
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_enotice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_e_notice(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_e_notice_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    dispatch(set_all_e_notice({ result: [], total: 0 }, limit, starting_after))
                    dispatch(set_snack_bar(true, responseJson.message))
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_same_e_notice(token, building_id, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_enotice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_e_notice(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_e_notice_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    if (responseJson.message === "No Enotice Found") {
                        dispatch(set_all_e_notice({ result: [], total: 0 }, 0, 0))
                    }
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function prev_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_enotice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_e_notice(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_e_notice_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/view_enotice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_e_notice(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_e_notice_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_starting_after(payload) {
    return {
        type: SET_STARTING_AFTER,
        payload: payload
    }
}
export function set_all_e_notice(responseJson, limit, starting_after) {
    var obj;
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
    }
    return {
        type: SET_ALL_E_NOTICE,
        payload: obj,
    }
}
export function set_all_e_notice_pagination(responseJson, limit, starting_after) {
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
    return {
        type: SET_ALL_E_NOTICE,
        payload: obj,
    }
}
export function delete_single_e_notice(token, array, building_id, limit, starting_after) {
    var arr = []
    for (var i = 0; i < array.length; i++) {
        arr.push(array[i]._id);
    }
    var arr_final = JSON.stringify(arr)
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/delete_enotice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                enotice_id: arr_final,
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(get_all_e_notice(token, building_id, limit, starting_after))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function validate_single_e_notice(token, building_id, e_notice_id, desc, title, target, generated_date, valid_till, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        if (desc === "") {
            dispatch(set_snack_bar(true, "Please enter Description"));
            dispatch(unset_loader());
        } else if (title === "") {
            dispatch(set_snack_bar(true, "Please enter Title"));
            dispatch(unset_loader());
        }
        else if (target === "") {
            dispatch(set_snack_bar(true, "Please enter Title"));
            dispatch(unset_loader());
        }
        else {
            dispatch(update_e_notice(token, building_id, e_notice_id, desc, title, target, generated_date, valid_till, limit, starting_after));
        };
    }
}
export function update_e_notice(token, building_id, e_notice_id, desc, title, target, generated_date, valid_till, limit, starting_after) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/update_enotice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                enotice_id: e_notice_id,
                notice_title: title,
                notice_valid_till: valid_till,
                notice_generated_date: generated_date,
                notice_target: target,
                notice_desc: desc
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(get_same_e_notice(token, building_id, limit, starting_after))
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_e_notice_title(payload) {
    return {
        type: SET_E_NOTICE_TITLE,
        payload: payload
    }
}
export function set_e_notice_desc(payload) {
    return {
        type: SET_E_NOTICE_DESC,
        payload: payload
    }
}
export function set_e_notice_target(payload) {
    return {
        type: SET_E_NOTICE_TARGET,
        payload: payload
    }
}
export function set_e_notice_valid_till(payload) {
    return {
        type: SET_E_NOTICE_VALID_TILL,
        payload: payload
    }
}
export function validate_add_e_notice(token, building_id, e_notice, generated_date) {
    return (dispatch) => {
        dispatch(set_loader());
        if (e_notice.desc === "") {
            dispatch(set_snack_bar(true, "Please enter Description"));
            dispatch(unset_loader());
        } else if (e_notice.title === "") {
            dispatch(set_snack_bar(true, "Please enter Title"));
            dispatch(unset_loader());
        }
        else if (e_notice.admin === false && e_notice.resident === false && e_notice.employee === false) {
            dispatch(set_snack_bar(true, "Please Select the target audience"));
            dispatch(unset_loader());
        }
        else if (e_notice.valid_till === "") {
            dispatch(set_snack_bar(true, "Please select a till valid date"));
            dispatch(unset_loader());
        }
        else {
            dispatch(add_e_notice(token, building_id, e_notice, generated_date));
        };
    }
}
export function add_e_notice(token, building_id, e_notice, generated_date) {
    var arr = []
    if (e_notice.admin === true) {
        arr.push("A")
    }
    if (e_notice.employee === true) {
        arr.push("E")
    }
    if (e_notice.resident === true) {
        arr.push("R")
    }
    arr = JSON.stringify(arr)
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/add_enotice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                notice_title: e_notice.title,
                notice_valid_till: e_notice.valid_till,
                notice_generated_date: generated_date,
                notice_target: arr,
                notice_desc: e_notice.desc
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Notice Sent"));
                    dispatch(reset_e_notice())
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function reset_e_notice() {
    return {
        type: RESET_E_NOTICE,
    }
}