import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from "@material-ui/core";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AddEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
    }
  }
  componentDidMount() {
    this.props.reset_all()
    this.props.get_building_vendors(this.props.login.token, this.props.login.building_id);
    this.props.get_all_id_proofs(this.props.login.token);
    this.props.get_all_departments(this.props.login.token, this.props.login.building_id);
  }
  handleFunc = (event) => {
    this.setState({
      check: !this.state.check
    })
  }
  render() {
    const {
      id_proofs,
      snackbar,
      close_snack_bar,
      login,
      vendors,
      employee,
      set_employee_name,
      set_employee_designation,
      set_employee_image,
      set_employee_contact_info,
      set_employee_id_proof_id,
      set_employee_id_proof_type,
      set_employee_id_proof_img,
      set_employee_code,
      set_employee_email,
      set_emp_acc_no,
      set_emp_bank_name,
      set_emp_bank_ifsc,
      set_emp_branch,
      set_is_provided,
      set_selected_vendor,
      validate_add_employee,
      set_salary_amount,
      department,
      toggleDepartmentCheckbox
    } = this.props;
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  ADD EMPLOYEE
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container style={{ padding: 40 }}>
                <Grid container item lg={6} xs={12}>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input1"
                      label="Employee Name"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.employee_name}
                      onChange={(event) => { set_employee_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Employee Designation"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.employee_designation}
                      onChange={(event) => { set_employee_designation(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    Image :
                    <input type="file"
                      onChange={(event) => { set_employee_image(event); }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Contact Info"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.employee_contact_info}
                      onChange={(event) => { set_employee_contact_info(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input2"
                      label="Employee Salary Amount"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.employee_salary_amount}
                      onChange={(event) => { set_salary_amount(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input3"
                      label="ID Proof Type"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={employee.employee_id_proof}
                      onChange={(event) => { set_employee_id_proof_type(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {id_proofs.all_id_proofs.map((single) => (
                        <MenuItem key={single.proof_name} value={single.proof_name}>
                          {single.proof_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {employee.employee_id_proof !== "" &&
                    <Grid item lg={12} xs={12}>
                      <TextField
                        id="outlined-email-input4"
                        label="ID Proof Number"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={employee.employee_id_proof_id}
                        onChange={(event) => { set_employee_id_proof_id(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                      Image : <input type="file"
                        multiple
                        onChange={(event) => { set_employee_id_proof_img(event); }}
                      />
                    </Grid>
                  }
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input5"
                      label="Employee Code(Optional)"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.employee_code}
                      onChange={(event) => { set_employee_code(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid><Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input6"
                      label="Employee Email"
                      type="email"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.employee_email}
                      onChange={(event) => { set_employee_email(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <input
                      type="checkbox"
                      value={employee.is_provide_by_vendor}
                      onClick={(event) => { set_is_provided(event.target.value); }}
                    />Is Provided by vendor
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    {employee.is_provide_by_vendor === true ?
                      <TextField
                        id="outlined-email-input7"
                        label="Vendors"
                        type="dropdown"
                        fullWidth
                        select
                        margin="normal"
                        variant="outlined"
                        value={employee.selected_vendor}
                        onChange={(event) => { set_selected_vendor(event.target.value); }}
                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      >
                        {employee.building_vendors.map((single) => (
                          <MenuItem key={single.vendor_id} value={single.vendor_id}>
                            {single.vendor_name}
                          </MenuItem>
                        ))}
                      </TextField> : ""}
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <br />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => { validate_add_employee(login.token, employee, login.building_id, department.all_department) }}
                    >
                      Submit
                  </Button>
                  </Grid>
                </Grid>
                <Grid container item lg={6} xs={12} style={{ padding: 20 }}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Select roles:
                    </Typography>
                  </Grid>
                  {department.all_department.map((s) => (
                    <Grid xs={4}>
                      <Checkbox
                        key={s._id}
                        checked={s.checked}
                        size="small"
                        onChange={() => {
                          toggleDepartmentCheckbox(s, department.all_department)
                        }}
                      />
                      {s.department_name}
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Bank Details:
                    </Typography>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input45"
                      label="Employee Bank Account No"
                      type="number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.account_no}
                      onChange={(event) => { set_emp_acc_no(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input76"
                      label="Bank Name(Optional)"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.bank_name}
                      onChange={(event) => { set_emp_bank_name(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input98"
                      label="Bank IFSC(Optional)"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.bank_ifsc}
                      onChange={(event) => { set_emp_bank_ifsc(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input99"
                      label="Branch"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={employee.branch}
                      onChange={(event) => { set_emp_branch(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddEmployee);