import React, { Component } from "react";
import { connect } from "react-redux";
import ViewVisitors from "../../components/visitors/view_all_visitors";
import {
    get_all_visitors,
    allow_visitor_entry,
    set_start_date,
    set_end_date,
    prev_btn,
    next_btn,
} from "../../actions/visitors/visitors_action";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
export class View_visitors_cont extends Component {
    render() {
        return (
            <ViewVisitors {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        visitors: store.visitors,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_visitors: (token, building_id, limit, starting_after, start_date, end_date) => {
            dispatch(get_all_visitors(token, building_id, limit, starting_after, start_date, end_date))
        },
        allow_visitor_entry: (token, building_id, visitor_id, limit, starting_after, start_date, end_date) => {
            dispatch(allow_visitor_entry(token, building_id, visitor_id, limit, starting_after, start_date, end_date))
        },
        set_end_date: (payload) => {
            dispatch(set_end_date(payload))
        },
        set_start_date: (payload) => {
            dispatch(set_start_date(payload))
        },
        prev_btn: (token, building_id, limit, starting_after) => {
            dispatch(prev_btn(token, building_id, limit, starting_after))
        },
        next_btn: (token, building_id, limit, starting_after) => {
            dispatch(next_btn(token, building_id, limit, starting_after))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_visitors_cont);