import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem"
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import CardContent from "@material-ui/core/CardContent";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class AddAmenitiesbooking extends Component {
  componentDidMount() {
    this.props.get_all_amenities(this.props.login.token, this.props.login.building_id)
    this.props.reset_amenity_booking();
  }
  render() {
    const {
      snackbar,
      close_snack_bar,
      login,
      amenities_booking,
      amenities,
      set_start_time,
      set_end_time,
      set_booking_date,
      set_selected_amenity,
      find_resident,
      complaints,
      set_phone_number,
      add_amenity_booking,
      setCharges
    } = this.props;
    var submit =
      <Button
        variant="contained"
        disabled={true}
      >
        Submit
      </Button>
    if (complaints.resident_name !== "" && amenities_booking.selected_amenity !== "") {
      submit =
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            add_amenity_booking(
              this.props.login.token, 
              this.props.login.building_id, 
              amenities_booking.selected_amenity, 
              amenities_booking.start_time, 
              amenities_booking.end_time, 
              amenities_booking.booking_date, 
              complaints.resident_id,
              complaints.unit_id,
              amenities_booking.charges
              )
          }}
        >
          Submit
                </Button>
    }
    return (
      <Grid container item xs={12} lg={12} spacing={8}  >
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  AMENITY BOOKING
                                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid container spacing={4}>
                <Grid container item lg={5}>
                  <Grid item lg={12}>
                    <TextField
                      id="outlined-email-input"
                      label="Select Amenity"
                      type="dropdown"
                      fullWidth
                      select
                      margin="normal"
                      variant="outlined"
                      value={amenities_booking.selected_amenity}
                      onChange={(event) => { set_selected_amenity(event.target.value); }}
                      InputProps={{ classes: { input: this.props.classes.dropdown } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    >
                      {amenities.all_amenities.map((single) => (
                        <MenuItem key={single.amenity_name} value={single._id}>
                          {single.amenity_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item lg={6} xs={12}>
                      <KeyboardTimePicker
                        margin="normal"
                        label="Start Time"
                        fullWidth
                        value={amenities_booking.start_time}
                        onChange={(event) => { set_start_time(event); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <KeyboardTimePicker
                        margin="normal"
                        label="End Time"
                        fullWidth
                        value={amenities_booking.end_time}
                        onChange={(event) => { set_end_time(event); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Booking Date"
                        fullWidth
                        format="dd/MM/yyyy"
                        value={amenities_booking.booking_date}
                        onChange={(event) => { set_booking_date(event); }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item lg={12} xs={12} >
                      <TextField
                        id="outlined-email-input"
                        label="Search Resident by Phone number"
                        type="number"
                        autoComplete={"off"}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={complaints.phone_no}
                        onChange={(event) => {
                          set_phone_number(event.target.value);
                        }}
                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => { find_resident(complaints.phone_no, login.token, login.building_id) }}
                      >
                        search
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <h2>
                      Selected Resident :
                      {complaints.resident_name}
                    </h2>
                  </Grid>
                  <Grid item lg={12} xs={12} >
                    <TextField
                      id="outlined-email-input"
                      label="Charges"
                      type="number"
                      autoComplete={"off"}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={complaints.charges}
                      onChange={(event) => {
                        setCharges(event.target.value);
                      }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              {submit}
            </CardContent>
          </Card>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AddAmenitiesbooking);