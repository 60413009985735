import React, { Component } from "react";
import { connect } from "react-redux";
import MaintenancePaymentUnit from "../../components/maintenance/maintenancePayment";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  get_same_units,
} from "../../actions/units/units_action";
import {
  getUnitMaintenance,
  reset,
  import_file_func
} from "../../actions/maintenance/maintenance_action";

export class MaintenancePaymentUnitCont extends Component {
  render() {
    return (
      <MaintenancePaymentUnit {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    maintenance: store.maintenance,
    units: store.units
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    get_same_units: (token, building_id) => {
      dispatch(get_same_units(token, building_id))
    },
    getUnitMaintenance: (token, unit_parent_id) => {
      dispatch(getUnitMaintenance(token, unit_parent_id))
    },
    reset: () => {
      dispatch(reset())
    },
    import_file_func: (file, token, building_id, unit_parent_id) => {
      dispatch(import_file_func(file, token, building_id, unit_parent_id))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePaymentUnitCont);