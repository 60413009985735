import { LIMIT } from "../../constants/ActionTypes";
import {
    SET_ALL_COMPLAINTS,
    RESET_DEFAULT_COMPLAINTS,
    SET_STARTING_AFTER,
    SET_UNRESOLVED_COMPLAINTS,
    SET_STARTING_AFTER_UNRESOLVED,
    SET_INCOMPLETE_COMPLAINTS,
    SET_STARTING_AFTER_INCOMPLETE,
    SET_IS_URGENT,
    SET_RAISED_DATE,
    SET_COMPLAINT_DESC,
    SET_COMPLAINT_TITLE,
    PHONE_NUMBER,
    SET_RESIDENT_INFO,
    RESET_ADD_COMPLAINTS,
} from "../../constants/complaints/complaints_const";
const initial_state = {
    all_complaints: [],
    starting_after: 0,
    start_var: 0,
    end_var: 0,
    check: false,
    limit: LIMIT,
    total: 0,
    starting_after_unresolved: 0,
    start_var_unresolved: 0,
    end_var_unresolved: 0,
    check_unresolved: false,
    limit_unresolved: LIMIT,
    total_unresolved: 0,
    unresolved_complaints: [],
    starting_after_incomplete: 0,
    start_var_incomplete: 0,
    end_var_incomplete: 0,
    check_incomplete: false,
    limit_incomplete: LIMIT,
    total_incomplete: 0,
    incomplete_complaints: [],
    desc: "",
    title: "",
    raised_date: new Date(),
    is_urgent: false,
    phone_no: "",
    unit_id: "",
    resident_id: "",
    resident_name: "",
    unit_number: "",
    building_name: "",
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_RESIDENT_INFO:
            return state = { ...state, unit_number: action.payload.unit_no, building_name: action.payload.building_name, unit_id: action.payload.unit_id, resident_id: action.payload.resident_id, resident_name: action.payload.resident_name }
        case PHONE_NUMBER:
            return state = { ...state, phone_no: action.payload, }
        case SET_IS_URGENT:
            return state = { ...state, is_urgent: action.payload, }
        case SET_RAISED_DATE:
            return state = { ...state, raised_date: action.payload, }
        case SET_COMPLAINT_DESC:
            return state = { ...state, desc: action.payload, }
        case SET_COMPLAINT_TITLE:
            return state = { ...state, title: action.payload, }
        case SET_ALL_COMPLAINTS:
            return state = { ...state, all_complaints: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };
        case SET_INCOMPLETE_COMPLAINTS:
            return state = { ...state, incomplete_complaints: action.payload.response, start_var_incomplete: action.payload.f, end_var_incomplete: action.payload.s, total_incomplete: action.payload.total, check_incomplete: action.payload.check };
        case RESET_DEFAULT_COMPLAINTS:
            return state = { ...state, start_var: 0, end_var: 0, starting_after: 0, total: 0 }
        case RESET_ADD_COMPLAINTS:
            return state = {
                ...state,
                is_urgent: false,
                title: "",
                desc: "",
                raised_date: new Date(),
                phone_no: "",
                resident_name: "",
                unit_number: "",
                building_name: "",
            }
        case SET_STARTING_AFTER:
            return state = { ...state, starting_after: action.payload, }
        case SET_STARTING_AFTER_UNRESOLVED:
            return state = { ...state, starting_after_unresolved: action.payload, }
        case SET_STARTING_AFTER_INCOMPLETE:
            return state = { ...state, starting_after_incomplete: action.payload, }
        case SET_UNRESOLVED_COMPLAINTS:
            return state = { ...state, unresolved_complaints: action.payload.response, start_var_unresolved: action.payload.f, end_var_unresolved: action.payload.s, total_unresolved: action.payload.total, check_unresolved: action.payload.check };
        default:
            return state;
    }
}
