import React, { Component } from "react";
import { connect } from "react-redux";
import ViewVendorCategory from "../../components/vendor_category/view_vendor_category";
import {
}
    from "../../components/id_proofs/id_proofs";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    get_all_vendor_category,
    validate_add_vendor_category,
    delete_vendor_category,
    validate_update_vendor_category,
    set_category_name,
} from "../../actions/vendor_category/vendor_category_action";
export class View_vendor_category_cont extends Component {
    render() {
        return (
            <ViewVendorCategory {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        vendor_category: store.vendor_category,
        snackbar: store.snackbar,
        loader: store.loader,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        get_all_vendor_category: (token) => {
            dispatch(get_all_vendor_category(token))
        },
        validate_update_vendor_category: (token, vendor_category_id, vendor_category_name) => {
            dispatch(validate_update_vendor_category(token, vendor_category_id, vendor_category_name))
        },
        delete_vendor_category: (token, vendor_category_id) => {
            dispatch(delete_vendor_category(token, vendor_category_id))
        },
        validate_add_vendor_category: (token, vendor_category) => {
            dispatch(validate_add_vendor_category(token, vendor_category))
        },
        set_category_name: (payload) => {
            dispatch(set_category_name(payload))
        },
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(View_vendor_category_cont);