import React, { Component } from "react";
import { connect } from "react-redux";
import MaintenancePaymentUnitDetails from "../../components/maintenance/maintenanceDetails";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  getMaintenanceDetails,
  approvePayment,
  reset,
  getAllMaintenaceType,
  addUnitMaintenance,
  getAllPendingAmenityCharges,
  getAllPendingComplaintCharges,
  setInvoiceType,
  setInvoiceAmount,
  getPendingPayments,
  setPendingPayments,
  getPendingChargesPayment,
  addUnitPaymentMaintenance,
} from "../../actions/maintenance/maintenance_action";

export class MaintenancePaymentUnitDetailsCont extends Component {
  render() {
    return (
      <MaintenancePaymentUnitDetails {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    maintenance: store.maintenance,
    bank: store.bank
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    getPendingChargesPayment: (token, unit_id) => {
      dispatch(getPendingChargesPayment(token, unit_id));
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    getMaintenanceDetails: (token, unit_id, limit, startingAfter, startDate, endDate, building_id) => {
      dispatch(getMaintenanceDetails(token, unit_id, limit, startingAfter, startDate, endDate, building_id));
    },
    approvePayment: (token, unit_id, array, maintenance, building_id, creditTo) => {
      dispatch(approvePayment(token, unit_id, array, maintenance, building_id, creditTo));
    },
    getAllMaintenaceType: (token) => {
      dispatch(getAllMaintenaceType(token));
    },
    addUnitMaintenance: (token, unit_id, building_id, charge, amount, dueDate, pendingPayment, fullyPaid, maintenance, raisedDate, id, bankAccount) => {
      dispatch(addUnitMaintenance(token, unit_id, building_id, charge, amount, dueDate, pendingPayment, fullyPaid, maintenance, raisedDate, id, bankAccount));
    },
    reset: () => {
      dispatch(reset());
    },
    getAllPendingAmenityCharges: (token, charge, unit_id) => {
      dispatch(getAllPendingAmenityCharges(token, charge, unit_id));
    },
    getAllPendingComplaintCharges: (token, charge, unit_id) => {
      dispatch(getAllPendingComplaintCharges(token, charge, unit_id));
    },
    setInvoiceAmount: (amount) => {
      dispatch(setInvoiceAmount(amount));
    },
    setInvoiceType: (type, token, unit_id) => {
      dispatch(setInvoiceType(type, token, unit_id));
    },
    getPendingPayments: (token, unit_id) => {
      dispatch(getPendingPayments(token, unit_id));
    },
    setPendingPayments: (data) => {
      dispatch(setPendingPayments(data));
    },
    addUnitPaymentMaintenance: (token, unit_id, building_id, charge, amount, dueDate, maintenance) => {
      dispatch(addUnitPaymentMaintenance(token, unit_id, building_id, charge, amount, dueDate, maintenance));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePaymentUnitDetailsCont);