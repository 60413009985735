import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from '@material-ui/core/Checkbox';
import Icon from "@material-ui/core/Icon";
import { styles } from "../../styles/style";
import { Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import ImageViewer from "../imageViewer/imageViewer";

const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class ViewHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      editredirect: false,
      helper_id_proof_id: "",
      helper_id_proof_type: "",
      helper_id_proof_image: [],
      helper_id: "",
      helper_name: "",
      helper_img: "",
      helper_img_old: "",
      helper_email: "",
      helper_contact_info: "",
      helper_sec_contact_info: "",
      helper_permanent_address: "",
      helper_service: "",
      is_private: false,
      delete_redirect: false
    }
  }
  componentDidMount() {
    this.props.get_all_helpers(this.props.login.token, this.props.login.building_id, this.props.helper.limit, this.props.helper.starting_after);
    this.props.get_all_id_proofs(this.props.login.token);
  }
  checkRedirect = () => {
    this.setState({
      redirect: true
    })
  }
  set_del_check() {
    for (var i = 0; i < this.props.helper.all_helper.length; i++) {
      if (this.props.helper.all_helper[i].checked === true) {
        this.setState({
          del_check: true
        });
        break;
      }
      else {
        this.setState({
          del_check: false
        })
      }
    }
  }
  ed = (s) => {
    this.setState({
      helper_id_proof_id: s.helper_id_proofs[0].idproof_id,
      helper_id_proof_type: s.helper_id_proofs[0].idproof_type,
      helper_id_proof_image: s.helper_id_proofs[0].idproof_image,
      helper_id: s._id,
      helper_name: s.helper_name,
      helper_img_old: s.helper_img,
      helper_email: s.helper_email,
      helper_contact_info: s.helper_contact_info,
      helper_sec_contact_info: s.helper_sec_contact_info,
      helper_permanent_address: s.helper_permanent_address,
      helper_service: s.helper_service,
      is_private: s.is_private,
    })
  }
  render() {
    const {
      helper,
      snackbar,
      close_snack_bar,
      login,
      set_id_proof_image,
      id_proofs,
      delete_helper_info,
      validate_helper_edit_func,
      prev_btn,
      next_btn,
      toggleCheckbox
    } = this.props;
    var btn;
    if (helper.total > helper.limit) {
      if (helper.end_var === helper.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, helper.limit, helper.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {helper.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{helper.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{helper.total}
        </span>
      }
      else if (helper.start_var > helper.limit && helper.end_var < helper.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, helper.limit, helper.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(login.token, login.building_id, helper.limit, helper.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {helper.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{helper.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{helper.total}
        </span>
      }
      else {
        btn =
          <span>
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(login.token, login.building_id, helper.limit, helper.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {helper.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{helper.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{helper.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {helper.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{helper.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{helper.total}
      </span>
    }
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW HELPERS
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent >
              <Grid item xs={12}>
                <Link to="add_helper" style={{ textDecoration: "none" }}>
                  <IconButton>
                    <Icon>add</Icon>
                  </IconButton>
                </Link>
                {this.state.del_check === true ? <IconButton
                  onClick={() => {
                    this.setState({
                      delete_redirect: true
                    })
                  }}
                >
                  <Icon >delete</Icon>
                </IconButton> : ""}
                <div style={{ float: "right" }}>
                  {btn}
                </div>
                <br /><br />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell> Image</TableCell>
                      <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                      <TableCell align="left">Contact info</TableCell>
                      <TableCell align="left">Permanent Address</TableCell>
                      <TableCell align="left">Service</TableCell>
                      <TableCell align="left">KYC Status</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {helper.all_helper.map(row => (
                      <TableRow key={row._id} >
                        <TableCell align="center" className="table_cells">
                          <Checkbox
                            checked={row.checked}
                            size="small"
                            onChange={() => {
                              toggleCheckbox(row, helper.all_helper);
                              this.set_del_check();
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Avatar
                            alt={row.helper_name}
                            src={row.helper_img}
                            style={{ width: 35, height: 35 }}
                            onClick={()=>{
                              this.setState({
                                image: row.helper_img
                              })
                            }}
                          >
                          </Avatar>
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          &nbsp;&nbsp;{row.helper_name}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.helper_contact_info}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.helper_permanent_address}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.helper_service}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.is_kyc_approved === true ?
                            <Button variant="outlined" color="primary" size="small">
                              APPROVED
                            </Button>
                            :
                            <Button variant="outlined" color="secondary" size="small">
                              PENDING
                            </Button>
                          }
                        </TableCell>
                        <TableCell align="right" className="table_cells">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={
                              {
                                pathname: "/view_details",
                                state: {
                                  row: row,
                                  name: "HELPER"
                                }
                              }}>
                            <IconButton
                            >
                              <Icon >list</Icon>
                            </IconButton>
                          </Link>
                          <IconButton
                            onClick={() => {
                              this.ed(row)
                              this.setState({
                                editredirect: true,
                              });
                            }}
                          >
                            <Icon >edit</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Name"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.helper_name}
                    onChange={(event) => { this.setState({ helper_name: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  Image : <input type="file"
                    onChange={(event) => { this.setState({ helper_img: event.target.files[0] }) }}
                  />
                </Grid>
                {/* <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Email"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.helper_email}
                    onChange={(event) => { this.setState({ helper_email: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid> */}
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Contact_Info"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.helper_contact_info}
                    onChange={(event) => { this.setState({ helper_contact_info: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Secondary_contact_info(Optional)"
                    type="number"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.helper_sec_contact_info}
                    onChange={(event) => { this.setState({ helper_sec_contact_info: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Permanent Address"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.helper_permanent_address}
                    onChange={(event) => { this.setState({ helper_permanent_address: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="ID Proof Type"
                    type="dropdown"
                    fullWidth
                    select
                    margin="normal"
                    variant="outlined"
                    value={this.state.helper_id_proof_type}
                    onChange={(event) => { this.setState({ helper_id_proof_type: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  >
                    {id_proofs.all_id_proofs.map((single) => (
                      <MenuItem key={single.proof_name} value={single.proof_name}>
                        {single.proof_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {this.state.helper_id_proof_type !== "" ?
                  <Grid item lg={12} xs={12}>
                    <TextField
                      id="outlined-email-input"
                      label="ID Proof Number"
                      type="text"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={this.state.helper_id_proof_id}
                      onChange={(event) => { this.setState({ helper_id_proof_id: (event.target.value) }) }}
                      InputProps={{ classes: { input: this.props.classes.textfield } }}
                      InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                    />
                    Image :
                    <input type="file" multiple
                      onChange={(event) => { set_id_proof_image(event); }}
                    />
                  </Grid> : ""}
                <Grid item lg={12} xs={12}>
                  <TextField
                    id="outlined-email-input"
                    label="Service"
                    type="text"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.helper_service}
                    onChange={(event) => { this.setState({ helper_service: (event.target.value) }) }}
                    InputProps={{ classes: { input: this.props.classes.textfield } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Checkbox
                    checked={this.state.is_private}
                    value={this.state.is_private}
                    size="small"
                    onChange={(event) => {
                      this.setState({
                        is_private: !(this.state.is_private)
                      })
                    }}
                  />
                  Is Private
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                    helper_id_proof_id: "",
                    helper_id_proof_type: "",
                    helper_id_proof_image: [],
                    helper_id: "",
                    building_id: "",
                    helper_name: "",
                    helper_img: "",
                    helper_img_old: "",
                    helper_email: "",
                    helper_contact_info: "",
                    helper_sec_contact_info: "",
                    helper_permanent_address: "",
                    helper_service: "",
                    is_private: false
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_helper_edit_func(login.token, this.state.helper_id_proof_id, this.state.helper_id_proof_type, this.state.helper_id, this.state.helper_name, this.state.helper_img, this.state.helper_img_old, this.state.helper_email, this.state.helper_contact_info, this.state.helper_sec_contact_info, this.state.helper_permanent_address, this.state.helper_service, this.state.is_private, login.building_id, helper.limit, helper.starting_after, this.state.helper_id_proof_image, helper);
                  this.setState({
                    editredirect: false,
                    helper_id_proof_id: "",
                    helper_id_proof_type: "",
                    helper_id_proof_image: [],
                    helper_id: "",
                    building_id: "",
                    helper_name: "",
                    helper_img: "",
                    helper_img_old: "",
                    helper_email: "",
                    helper_contact_info: "",
                    helper_sec_contact_info: "",
                    helper_permanent_address: "",
                    helper_service: "",
                    is_private: false
                  })
                }}
                color="primary" autoFocus>
                UPDATE
                </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.delete_redirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are You Sure Want To Delete The Helper</DialogTitle>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    delete_redirect: false,
                    resident_id: ""
                  });
                }}
                color="primary">
                NO
                </Button>
              <Button
                onClick={() => {
                  delete_helper_info(this.props.login.token, helper.all_helper, login.building_id, helper.limit, helper.starting_after)
                  this.setState({
                    delete_redirect: false,
                    helper_id: ""
                  })
                }}
                color="primary" autoFocus>
                Yes
                            </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
        <ImageViewer
          image={this.state.image}
          close={() => {
            this.setState({
              image: null
            })
          }}
        />
      </Grid>
    );
  }
}
export default withStyles(styles)(ViewHelper);