import {
    SET_ALL_ASSETS,
    RESET,
    SET_ALL_ASSETS_TYPES,
} from "../../constants/assets/assetsConst";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
// import { RESET } from "../../constants/ActionTypes";
export function getAllAssets(token, building_id, limit, starting_after, startDate, endDate) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/view_asset_entries", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
                start_date: startDate,
                end_date: endDate,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.result.length >= limit) {
                        dispatch(set_snack_bar(responseJson.status, responseJson.message));
                        dispatch(setAllAssets(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(setAllAssetsPagination(responseJson, limit, starting_after))
                        dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    }
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    if (responseJson.message === "No Entries Found") {
                        dispatch(setAllAssets({ result: [], total: 0 }, 0, 0))
                    }
                }
                dispatch(getAllAssetsTypes(token))
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function setAllAssets(responseJson, limit, starting_after) {
    var obj;
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0, starting_after: starting_after };
    }
    else {
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.totaldata, starting_after: starting_after };
    }
    return {
        type: SET_ALL_ASSETS,
        payload: obj,
    }
}
export function setAllAssetsPagination(responseJson, limit, starting_after) {
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.totaldata), total: responseJson.totaldata, starting_after: starting_after };
    return {
        type: SET_ALL_ASSETS,
        payload: obj,
    }
}

export function reset() {
    return {
        type: RESET,
    };
}
export function getAllAssetsTypes(token) {
    return (dispatch) => {
        return fetch(UNIVERSAL.BASEURL + "v1/view_asset_type", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    // dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(setAllAssetsTypes(responseJson.result));
                }
                else {
                    // dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function setAllAssetsTypes(data) {
    return {
        type: SET_ALL_ASSETS_TYPES,
        payload: data
    };
}
export function addAsset(token, building_id, state, assets) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/add_asset_entry", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            }, body: JSON.stringify({
                building_id: building_id,
                asset_type: state.assetType,
                asset_name: state.assetName,
                amount: state.amount,
                payment_mode: state.paymentMode,
                bank_account: state.bankAccount,
                gst_percentage: state.gstPercentage,
                gst_amount: state.gstAmount,
                comments: state.comment,
                transaction_id: state.transactionId
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                    dispatch(getAllAssets(token, building_id, assets.limit, 0));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
// export function set_all_e_notice(responseJson, limit, starting_after) {
//     var obj;
//     if (responseJson.result.length === 0) {
//         obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
//     }
//     else {
//         obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
//     }
//     return {
//         type: SET_ALL_E_NOTICE,
//         payload: obj,
//     }
// }
// export function set_all_e_notice_pagination(responseJson, limit, starting_after) {
//     var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
//     return {
//         type: SET_ALL_E_NOTICE,
//         payload: obj,
//     }
// }