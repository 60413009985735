import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from "@material-ui/core/MenuItem";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import UNIVERSAL from "../../config/config";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
const card = {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    marginTop: 50
};
class ViewLoans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addDialog: false,
            title: "",
            startDate: null,
            endDate: null,
            loanAmount: "",
            outstandingAmount: "",
            bank: "",
            viewArray: [],
            viewDialog: false,
            selectedId: "",
            closeDialog: false,
            paidMonth: "",
            paidYear: "",
            paymentMode: "",
            emiAmount: "",
            transactionId: "",
            bankId: "",
            addEmiDialog: false,
            loanId: ""

        }
    }
    componentDidMount() {
        this.props.getAllLoans(this.props.login.token, this.props.login.building_id);
        this.props.getAllBankAccounts(this.props.login.token, this.props.login.building_id);
    }
    render() {
        const {
            login,
            loans,
            snackbar,
            bank,
            close_snack_bar,
        } = this.props;
        return (
            <Grid container justify="center" spacing={1}>
                <Grid item xs={12}>
                    <Card style={card}>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="rose">
                                <h3>
                                    VIEW LOANS
                </h3>
                            </CardIcon>
                        </CardHeader>
                        <CardContent >
                            <Grid item lg={12}>
                                <IconButton
                                    onClick={() => { this.setState({ addDialog: true }) }}
                                >
                                    <Icon>add</Icon>
                                </IconButton>
                                <Tooltip title="Click to Refresh">
                                    <IconButton
                                        onClick={() => {
                                            this.props.getAllLoans(this.props.login.token, this.props.login.building_id);
                                        }}
                                    >
                                        <Icon>
                                            autorenew
                                    </Icon>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Entry Date</TableCell>
                                        <TableCell align="left">Title</TableCell>
                                        <TableCell align="left">Tenure</TableCell>
                                        <TableCell align="left">Loan Amount</TableCell>
                                        <TableCell align="left">Outstanding Amount</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loans.all_loans.map(row => (
                                        <TableRow key={row._id} >
                                            <TableCell align="left" className="table_cells">
                                                {moment(row.created_on).format(" DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell align="left" className="table_cells">
                                                {row.loan_title}
                                            </TableCell>
                                            <TableCell align="left" className="table_cells">
                                                {moment(row.starts_from).format(" MMMM Do YYYY")}
                                                {" - "}
                                                {moment(row.ends_on).format(" MMMM Do YYYY")}
                                            </TableCell>
                                            <TableCell align="left" className="table_cells">
                                                {row.loan_amount}
                                            </TableCell>
                                            <TableCell align="left" className="table_cells">
                                                {row.current_outstanding_amount}
                                            </TableCell>
                                            <TableCell align="right" className="table_cells">
                                                {row.active && <Tooltip title="Click to add a new EMI Entry">
                                                    <IconButton
                                                        onClick={() => { this.setState({ addEmiDialog: true, selectedId: row._id }) }}

                                                    >
                                                        <Icon className="font" >add</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                                }
                                                {row.emi_details.length > 0 &&
                                                    <Tooltip title="View All EMI Entries">
                                                        <IconButton
                                                            onClick={() => { this.setState({ viewDialog: true, viewArray: row.emi_details, loanId: row._id }) }}
                                                        >
                                                            <Icon className="font" >visibility</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {row.active &&
                                                    <Tooltip title="Close Loan">
                                                        <IconButton
                                                            onClick={() => { this.setState({ closeDialog: true, selectedId: row._id }) }}
                                                        >
                                                            <Icon className="font" >close</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    <Dialog
                        open={this.state.addDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Add Building Loan</DialogTitle>
                        <DialogContent>
                            <Grid container justify="space-between" >
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Title"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.title}
                                        onChange={(event) => { this.setState({ title: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid item md={6} xs={12} >
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Start Date"
                                            format="MM/dd/yyyy"
                                            value={this.state.startDate}
                                            onChange={(e) => {
                                                this.setState({ startDate: e }
                                                )
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="End Date"
                                            format="MM/dd/yyyy"
                                            value={this.state.endDate}
                                            onChange={(e) => {
                                                this.setState({ endDate: e }
                                                )
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="loan Amount"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.loanAmount}
                                        onChange={(event) => { this.setState({ loanAmount: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Curent Outstanding Amount"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.outstandingAmount}
                                        onChange={(event) => { this.setState({ outstandingAmount: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Bank"
                                        type="text"
                                        select
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.bank}
                                        onChange={(event) => { this.setState({ bank: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {bank.all_bank_accounts.map(su => (
                                            <MenuItem key={su.id}
                                                value={su.id}
                                            >
                                                {su.bankAccountMix}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        addDialog: false,
                                        title: "",
                                        startDate: null,
                                        endDate: null,
                                        loanAmount: "",
                                        outstandingAmount: "",
                                        bank: "",
                                    });
                                }}
                                color="primary">
                                Cancel
              </Button>
                            <Button
                                disabled={
                                    this.state.title === "" ||
                                        this.state.loanAmount === "" ||
                                        this.state.outstandingAmount === "" ||
                                        this.state.startDate === null ||
                                        this.state.endDate === null ||
                                        this.state.bank === "" ? true : false
                                }
                                onClick={() => {
                                    this.props.addBuildingLoan(login.token, login.building_id, this.state)
                                    this.setState({
                                        addDialog: false,
                                        title: "",
                                        startDate: null,
                                        endDate: null,
                                        loanAmount: "",
                                        outstandingAmount: "",
                                        bank: "",
                                    })
                                }}
                                color="primary" autoFocus>
                                Add Loan
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.viewDialog}
                        maxWidth={"md"}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">VIEW EMI ENTRY DETAILS</DialogTitle>
                        <DialogContent>
                            <Grid container >
                                <Grid item xs={12} md={12}>

                                    <a
                                        style={{ textDecoration: "none" }}
                                        href={UNIVERSAL.BASEURL + "v1/export_loan_entries?building_id=" + login.building_id + "&loan_id=" + this.state.loanId}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                        >
                                            Export
                                       </Button>

                                    </a>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Amount</TableCell>
                                                <TableCell align="left">Month</TableCell>
                                                <TableCell align="left">Year</TableCell>
                                                <TableCell align="left">Payment Mode</TableCell>
                                                <TableCell align="left">Transaction ID</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.viewArray.map(row => (
                                                <TableRow key={row.emi_id} >
                                                    <TableCell align="left" className="table_cells">
                                                        {row.emi_amount}
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {row.paid_month}
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {row.paid_year}
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {(row.payment_mode === "Bank" || row.paymentMode === "NEFT") && row.bank_details.account_no ?
                                                            row.payment_mode + "-  Account Number-   " + "****" +
                                                            row.bank_details.account_no.substring(row.bank_details.account_no.length, row.bank_details.account_no.length - 4)
                                                            :
                                                            row.payment_mode
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left" className="table_cells">
                                                        {row.transaction_id !== null ?
                                                            row.transaction_id : "N/A"
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        viewDialog: false,

                                    });
                                }}
                                color="primary">
                                Close
              </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.addEmiDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">ADD EMI ENTRY</DialogTitle>
                        <DialogContent>
                            <Grid container justify="space-between" >
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Paid Month"
                                        type="text"
                                        select
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.paidMonth}
                                        onChange={(event) => { this.setState({ paidMonth: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {loans.month.map(s => (
                                            <MenuItem key={s}
                                                value={s}
                                            >
                                                {s}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Paid Year"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.paidYear}
                                        onChange={(event) => { this.setState({ paidYear: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="EMI Amount"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.emiAmount}
                                        onChange={(event) => { this.setState({ emiAmount: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Payment Mode"
                                        type="text"
                                        select
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.paymentMode}
                                        onChange={(event) => {
                                            if (event.target.value === "Cash") {
                                                this.setState({
                                                    paymentMode: event.target.value,
                                                    bankId: ""
                                                })
                                            }
                                            else {
                                                this.setState({ paymentMode: event.target.value })
                                            }
                                        }}
                                        InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    >
                                        {loans.mode.map(s => (
                                            <MenuItem key={s}
                                                value={s}
                                            >
                                                {s}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {(this.state.paymentMode === "Bank" || this.state.paymentMode === "Cheque") &&
                                    <Grid item lg={12} xs={12} >
                                        <TextField
                                            id="outlined-email-input"
                                            label="Bank"
                                            type="text"
                                            select
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.bankId}
                                            onChange={(event) => { this.setState({ bankId: event.target.value }) }}
                                            InputProps={{ classes: { input: this.props.classes.dropdown } }}
                                            InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                        >
                                            {bank.all_bank_accounts.map(su => (
                                                <MenuItem key={su.id}
                                                    value={su.id}
                                                >
                                                    {su.bankAccountMix}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                                <Grid item lg={12} xs={12} >
                                    <TextField
                                        id="outlined-email-input"
                                        label="Transaction ID"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.transactionId}
                                        onChange={(event) => { this.setState({ transactionId: event.target.value }) }}
                                        InputProps={{ classes: { input: this.props.classes.textfield } }}
                                        InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                                    />
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        addEmiDialog: false,
                                        paidMonth: "",
                                        paidYear: "",
                                        paymentMode: "",
                                        emiAmount: "",
                                        transactionId: "",
                                        bankId: "",
                                    })
                                }
                                }
                                color="primary">
                                Close
                            </Button>
                            <Button
                                disabled={
                                    this.state.paidMonth === "" ||
                                        this.state.paidYear === "" ||
                                        this.state.paymentMode === "" ||
                                        this.state.emiAmount === "" ?
                                        true : false}
                                onClick={() => {
                                    this.props.addEmiEntry(login.token, login.building_id, this.state)
                                    this.setState({
                                        addEmiDialog: false,
                                        paidMonth: "",
                                        paidYear: "",
                                        paymentMode: "",
                                        emiAmount: "",
                                        transactionId: "",
                                        bankId: "",
                                    })
                                }}
                                color="primary" autoFocus>
                                ADD ENTRY
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Are you sure you want to Close this Loan?</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        closeDialog: false,
                                        selectedId: "",
                                    });
                                }}
                                color="primary">
                                No
              </Button>
                            <Button
                                onClick={() => {
                                    this.props.closeLoan(login.token, login.building_id, this.state.selectedId)
                                    this.setState({
                                        closeDialog: false,
                                        selectedId: "",
                                    })
                                }}
                                color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <LoaderCon />
                    <Snackbar
                        open={snackbar.response_received}
                        close_snack_bar={close_snack_bar}
                        message={snackbar.message}
                    />
                </Grid>
            </Grid >
        )
    }
}
export default withStyles(styles)(ViewLoans);