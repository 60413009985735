import {
    SET_VEHICLE_TYPE,
    SET_VEHICLE_MANUFACTURER,
    SET_VEHICLE_NAME,
    RESET_VEHICLE,
    SET_ALL_VEHICLES,
    SET_STARTING_AFTER_FOR_VEHICLES,
    RESET_DEFAULT_VEHICLE,
    SET_ONLY_VEHICLES,
    SELECT_VEHICLE,
    SET_VEHICLE_NUMBER
} from "../../constants/vehicles/vehicles_const";
import { LIMIT } from "../../constants/ActionTypes";
const initial_state = {
    vehicle_types: ["TWO WHEELER", "FOUR WHEELER"],
    vehicle_type: "",
    vehicle_manufacturer: "",
    vehicle_name: "",
    all_vehicles: [],
    limit: LIMIT,
    starting_after: 0,
    start_var: "",
    end_var: "",
    check: false,
    total: "",
    selected_vehicle_id: "",
    selected_vehicle_name: "",
    selected_vehicle_type: "",
    vehicle_number: ""
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_VEHICLE_TYPE:
            return state = { ...state, vehicle_type: action.payload };
        case SET_VEHICLE_MANUFACTURER:
            return state = { ...state, vehicle_manufacturer: action.payload };
        case SET_VEHICLE_NAME:
            return state = { ...state, vehicle_name: action.payload };
        case RESET_VEHICLE:
            return state = { ...state, vehicle_type: "", vehicle_manufacturer: "", vehicle_name: "" };
        case SET_ALL_VEHICLES:
            return state = { ...state, all_vehicles: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total };
        case SET_STARTING_AFTER_FOR_VEHICLES:
            return state = { ...state, starting_after: action.payload, }
        case RESET_DEFAULT_VEHICLE:
            return state = { ...state, start_var: 0, end_var: 0, starting_after: 0 }
        case SET_ONLY_VEHICLES:
            return state = { ...state, all_vehicles: action.payload }
        case SELECT_VEHICLE:
            return state = { ...state, selected_vehicle_id: action.payload.id, selected_vehicle_name: action.payload.name, selected_vehicle_type: action.payload.type }
        case SET_VEHICLE_NUMBER:
            return state = { ...state, vehicle_number: action.payload }
        default:
            return state;
    }
} 