import React, { Component } from "react";
import { connect } from "react-redux";
import AddEmployee from "../../components/employee/add_employee";
import {
  get_all_vendors,
} from "../../actions/vendors/vendors_action";
import {
  get_all_id_proofs
} from "../../actions/id_proofs/id_proofs_action";
import {
  close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
  set_employee_name,
  set_employee_designation,
  set_employee_image,
  set_employee_contact_info,
  set_employee_id_proof_id,
  set_employee_id_proof_type,
  set_employee_code,
  set_employee_email,
  set_emp_acc_no,
  set_emp_bank_name,
  set_emp_bank_ifsc,
  set_emp_branch,
  set_is_provided,
  set_selected_vendor,
  validate_add_employee,
  set_salary_amount,
  reset_all,
  set_employee_id_proof_img,
  get_building_vendors
} from "../../actions/employee/employee_action";
import {
  get_all_departments,
  toggleDepartmentCheckbox
} from "../../actions/department/department_action";
export class Add_Employee_cont extends Component {
  render() {
    return (
      <AddEmployee {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    employee: store.employee,
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    vendors: store.vendors,
    id_proofs: store.id_proofs,
    department: store.department
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    reset_all: () => {
      dispatch(reset_all())
    },
    get_building_vendors: (token, building_id) => {
      dispatch(get_building_vendors(token, building_id))
    },
    set_salary_amount: (payload) => {
      dispatch(set_salary_amount(payload))
    },
    validate_add_employee: (token, employee, building_id, departments) => {
      dispatch(validate_add_employee(token, employee, building_id, departments))
    },
    get_all_vendors: (token, limit, starting_after, building_id) => {
      dispatch(get_all_vendors(token, limit, starting_after, building_id))
    },
    set_employee_name: (payload) => {
      dispatch(set_employee_name(payload))
    },
    set_employee_designation: (payload) => {
      dispatch(set_employee_designation(payload))
    },
    set_employee_image: (payload) => {
      dispatch(set_employee_image(payload))
    },
    set_employee_contact_info: (payload) => {
      dispatch(set_employee_contact_info(payload))
    },
    set_employee_id_proof_type: (payload) => {
      dispatch(set_employee_id_proof_type(payload))
    },
    set_employee_code: (payload) => {
      dispatch(set_employee_code(payload))
    },
    set_employee_email: (payload) => {
      dispatch(set_employee_email(payload))
    },
    set_emp_acc_no: (payload) => {
      dispatch(set_emp_acc_no(payload))
    },
    set_emp_bank_name: (payload) => {
      dispatch(set_emp_bank_name(payload))
    },
    set_emp_bank_ifsc: (payload) => {
      dispatch(set_emp_bank_ifsc(payload))
    },
    set_emp_branch: (payload) => {
      dispatch(set_emp_branch(payload))
    },
    set_is_provided: (payload) => {
      dispatch(set_is_provided(payload))
    },
    set_selected_vendor: (payload) => {
      dispatch(set_selected_vendor(payload))
    },
    get_all_id_proofs: (payload) => {
      dispatch(get_all_id_proofs(payload))
    },
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    get_all_departments: (token, building_id) => {
      dispatch(get_all_departments(token, building_id));
    },
    toggleDepartmentCheckbox: (obj, array) => {
      dispatch(toggleDepartmentCheckbox(obj, array));
    },
    set_employee_id_proof_id: (proof_number) => {
      dispatch(set_employee_id_proof_id(proof_number));
    },
    set_employee_id_proof_img: (file) => {
      dispatch(set_employee_id_proof_img(file));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Employee_cont);