export const SET_ALL_COMPLAINTS = "SET_ALL_COMPLAINTS";
export const RESET_DEFAULT_COMPLAINTS = "RESET_DEFAULT_COMPLAINTS";
export const SET_STARTING_AFTER = "SET_STARTING_AFTER";
export const SET_STARTING_AFTER_UNRESOLVED = "SET_STARTING_AFTER_UNRESOLVED";
export const SET_UNRESOLVED_COMPLAINTS = "SET_UNRESOLVED_COMPLAINTS";
export const SET_INCOMPLETE_COMPLAINTS = "SET_INCOMPLETE_COMPLAINTS";
export const SET_STARTING_AFTER_INCOMPLETE = "SET_STARTING_AFTER_INCOMPLETE";
export const SET_IS_URGENT = "SET_IS_URGENT";
export const SET_RAISED_DATE = "SET_RAISED_DATE";
export const SET_COMPLAINT_DESC = "SET_COMPLAINT_DESC";
export const SET_COMPLAINT_TITLE = "SET_COMPLAINT_TITLE";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const SET_RESIDENT_INFO = "SET_RESIDENT_INFO";
export const RESET_ADD_COMPLAINTS = "RESET_ADD_COMPLAINTS";