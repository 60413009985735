import {
    SET_ALL_SHAREWALL,
    SET_STARTING_AFTER,
    SET_IMAGE_URL,
    SET_SHAREWALL_IMAGES,
    RESET_DEFAULT_SHAREWALL,
    SET_POSTED_ON,
    SET_COMMENTS,
    SET_ONLY_SHAREWALL
} from "../../constants/sharewall/sharewall_const";
import { set_loader, unset_loader }
    from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
export function get_all_sharewall(token, building_id, limit, starting_after) {
    starting_after = 0;
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/get_sharewall_posts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_sharewall(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_sharewall_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function get_same_sharewall(token, building_id, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader())
        return fetch(UNIVERSAL.BASEURL + "v1/get_sharewall_posts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_sharewall(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_sharewall_pagination(responseJson, limit, starting_after))
                    }
                }
                else {
                    dispatch(set_all_sharewall({ result: [], total: 0 }, 0, 0))
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function prev_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) - parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/get_sharewall_posts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_sharewall(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_sharewall_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function next_btn(token, building_id, limit, starting_after) {
    starting_after = parseInt(starting_after) + parseInt(limit);
    return (dispatch) => {
        dispatch(set_starting_after(starting_after));
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/get_sharewall_posts", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                building_id: building_id,
                limit: limit,
                starting_after: starting_after
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    if (responseJson.totaldata >= limit) {
                        dispatch(set_all_sharewall(responseJson, limit, starting_after))
                    }
                    else {
                        dispatch(set_all_sharewall_pagination(responseJson, limit, starting_after))
                    }
                }
                dispatch(unset_loader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_starting_after(payload) {
    return {
        type: SET_STARTING_AFTER,
        payload: payload
    }
}
export function toggleCheckbox(obj, array) {
    var index = array.indexOf(obj);
    array[index].checked = !array[index].checked;
    return {
        type: SET_ONLY_SHAREWALL,
        payload: array,
    }
}
export function set_all_sharewall(responseJson, limit, starting_after) {
    var obj;
    if (responseJson.result.length === 0) {
        obj = { response: responseJson.result, f: 0, s: 0, total: 0 };
    }
    else {
        for (var i = 0; i < responseJson.result.length; i++) {
            responseJson.result[i].checked = false;
        }
        obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(starting_after) + parseInt(limit), total: responseJson.total };
    }
    return {
        type: SET_ALL_SHAREWALL,
        payload: obj,
    }
}
export function set_all_sharewall_pagination(responseJson, limit, starting_after) {
    for (var i = 0; i < responseJson.result.length; i++) {
        responseJson.result[i].checked = false;
    }
    var obj = { response: responseJson.result, f: parseInt(starting_after) + 1, s: parseInt(responseJson.total), total: responseJson.total };
    return {
        type: SET_ALL_SHAREWALL,
        payload: obj,
    }
}
export function reset_sharewall() {
    return {
        type: RESET_DEFAULT_SHAREWALL
    }
}
export function delete_sharewall_post(token, post, limit, starting_after, building_id) {
    var post_id = []
    for (var i = 0; i < post.length; i++) {
        if (post[i].checked === true) {
            post_id.push(post[i]._id)
        }
    }
    return (dispatch) => {
        dispatch(set_loader());
        return fetch(UNIVERSAL.BASEURL + "v1/delete_sharewall_post", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                post_id: JSON.stringify(post_id),
                building_id: building_id
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Sharewall Deleted Succcessfully"));
                    dispatch(get_same_sharewall(token, building_id, limit, starting_after));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Sharewall Could Not Be Deleted"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function validate_create_sharewall(token, building_id, user_id, sharewall) {
    return (dispatch) => {
        dispatch(set_loader());
        if (sharewall.comments === "") {
            dispatch(set_snack_bar(true, "Please Comment"));
            dispatch(unset_loader())
        }
        else if (sharewall.posted_on === "") {
            dispatch(set_snack_bar(true, "Please Enter posted on date"));
            dispatch(unset_loader())
        }
        else {
            dispatch(add_sharewall(token, sharewall, building_id, user_id));
        }

    };
}
export function set_images_url(arr, sharewall) {
    return {
        type: SET_IMAGE_URL,
        payload: arr
    }
}
export function add_sharewall(token, sharewall, building_id, user_id) {
    return (dispatch) => {
        dispatch(set_loader());
        var img = JSON.stringify(sharewall.image_url);
        return fetch(UNIVERSAL.BASEURL + "v1/create_sharewall_post", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                user_id: user_id,
                comments: sharewall.comments,
                img: img,
                posted_on: sharewall.posted_on,
                building_id: building_id,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Sharewall Created Succcessfully"));
                    dispatch(reset_sharewall())
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Failed To Create Sharewall"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_images(payload) {
    return {
        type: SET_SHAREWALL_IMAGES,
        payload: payload
    }
}
export function add_sharewall_images(payload, sharewall) {
    var i = 0;
    return (dispatch) => {
        dispatch(set_loader())
        if (payload.target.files[0] !== "") {
            while (i < payload.target.files.length) {
                var storageRef = firebase.storage().ref();
                var uploadTask = storageRef.child("images/" + (sharewall.comments + i) + ".png").put(payload.target.files[i]);
                i = i + 1;
                uploadTask.on("state_changed", function (snapshot) {
                }, function (error) {
                    dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
                }, function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        dispatch(set_add_images(downloadURL, sharewall))
                    });
                });
            };
        }
        dispatch(unset_loader())
    }
}
export function set_add_images(payload, sharewall) {
    var arr = sharewall.img;
    arr.push(payload);
    return {
        type: SET_SHAREWALL_IMAGES,
        payload: arr
    }
}
export function set_del_images(payload, sharewall) {
    var index = sharewall.img.indexOf(payload);
    var arr = sharewall.img;
    arr.splice(index, 1);
    return {
        type: SET_SHAREWALL_IMAGES,
        payload: arr,
    }
}
export function validate_edit_sharewall(token, building_id, user_id, post_id, comments, img, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        if (comments === "") {
            dispatch(set_snack_bar(true, "Please Comment"));
        }
        else {
            dispatch(edit_sharewall(token, building_id, user_id, post_id, comments, img, limit, starting_after));
        }
        dispatch(unset_loader())
    };
}
export function edit_sharewall(token, building_id, user_id, post_id, comments, img, limit, starting_after) {
    return (dispatch) => {
        dispatch(set_loader());
        img = JSON.stringify(img);
        return fetch(UNIVERSAL.BASEURL + "v1/edit_sharewall_post", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "user-token": token
            },
            body: JSON.stringify({
                user_id: user_id,
                post_id: post_id,
                comments: comments,
                img: img,
                building_id: building_id,
            }),
        }).then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    dispatch(set_snack_bar(responseJson.status, "Sharewall Updated Succcessfully"));
                    dispatch(get_same_sharewall(token, building_id, limit, starting_after));
                }
                else {
                    dispatch(set_snack_bar(responseJson.status, "Sharewall Could Not Be Updated"));
                }
                dispatch(unset_loader())
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function set_comments(payload) {
    return {
        type: SET_COMMENTS,
        payload: payload
    }
}
export function set_posted_on(payload) {
    return {
        type: SET_POSTED_ON,
        payload: payload
    }
}
export function set_sharewall_images(payload, sharewall) {
    var length = payload.target.files.length
    var arr = []
    return (dispatch) => {
        dispatch(set_loader())
        Array.from(payload.target.files).forEach(file => {
            var storageRef = firebase.storage().ref();
            var uploadTask = storageRef.child("images/" + file.name + ".png").put(file);
            uploadTask.on("state_changed", function (snapshot) {
            }, function (error) {
                dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
            }, function () {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    arr.push(downloadURL)
                    if (arr.length === length) {
                        dispatch(unset_loader())
                        dispatch(set_images_url(arr, sharewall))
                    }


                });
            });
        });
    }
}