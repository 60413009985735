import React, { Component } from "react";
import { connect } from "react-redux";
import AdminDashboard from "../../components/dashboard/dashboard";
import SuperAdminDashboard from "../../components/dashboard/super_dashboard";
import {
  getAdmindashboardFunc,
  get_super_dashboard_details
} from "../../actions/dashboard/dashboard_action";
export class Admin_dashboard_cont extends Component {
  render() {
    if (this.props.login.type === "A") {
      return (
        <AdminDashboard {...this.props} />
      );
    } else if (this.props.login.type === "SA") {
      return (
        <SuperAdminDashboard {...this.props} />
      );
    } else {
      return (<div></div>)
    }
  }
};
export const mapStateToProps = store => {
  return {
    dashboard: store.dashboard,
    login: store.login,
    snackbar: store.snackbar
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    getAdmindashboardFunc: (token, building_id) => {
      dispatch(getAdmindashboardFunc(token, building_id));
    },
    get_super_dashboard_details: (token) => {
      dispatch(get_super_dashboard_details(token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin_dashboard_cont);
