import {
  SET_BUILDING_NAME,
  SET_BUILDING_POC_NUMBER,
  SET_BUILDING_POC_NAME,
  SET_BUILDING_ACCOUNT_NO,
  SET_BUILDING_IFSC_CODE,
  SET_BUILDING_BANK_NAME,
  SET_BUILDING_ADDRESS,
  RESET_ADD_BUILDING,
  SET_ALL_BUILDINGS,
  SET_BUILDING_LATITUDE,
  SET_BUILDING_LONGITUDE,
  SET_BUILDING_ADMINS,
  SET_ALL_DEPARTMENT_IDS,
  SET_REDIRECT_OPTION,
  SET_REDIRECT,
} from "../../constants/buildings/buildings_const";
import { set_loader, unset_loader } from "../loader/loader_action";
import { set_snack_bar } from "../snackbar/snackbar_action";
import UNIVERSAL from "../../config/config";
import firebase from "firebase";
export function reset_all() {
  return {
    type: RESET_ADD_BUILDING
  }
}
export function get_all_departments_id(token, id) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/view_all_departments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_departments_ids(responseJson.result));
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function set_all_departments_ids(payload) {
  var arr = []
  for (var i = 0; i < payload.length; i++) {
    arr.push(payload[i]._id)
  }
  return {
    type: SET_ALL_DEPARTMENT_IDS,
    payload: arr
  }
}
export function subcription_extend(token, amount, duration, building, id) {
  var value_of_duration = 0
  if (duration === "15 Days") {
    value_of_duration = 15
  }
  else {
    value_of_duration = parseInt(duration.slice(0, 2)) * 30
  }
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/add_building_subscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: id,
        booking_date: building.current_date,
        duration: value_of_duration,
        amount: amount
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(reset());
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function resend_invitiation(token, email) {
  return (dispatch) => {
    dispatch(set_loader())
    return fetch(UNIVERSAL.BASEURL + "v1/resend_invitation_admin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        email_id: email
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader())
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
          dispatch(unset_loader())
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function validate_add_building(token, buildings) {
  return (dispatch) => {
    dispatch(set_loader());
    if (buildings.building_address === "") {
      dispatch(set_snack_bar(true, "Please Enter Building Address"))
      dispatch(unset_loader())
    }
    else if (buildings.building_poc_name === "") {
      dispatch(set_snack_bar(true, "Please Enter Building Point of Contact Name"))
      dispatch(unset_loader())
    }
    else if (buildings.building_name === "") {
      dispatch(set_snack_bar(true, "Please Enter Building Name"))
      dispatch(unset_loader())
    }
    else if (buildings.building_poc_number === "") {
      dispatch(set_snack_bar(true, "Please Enter Building Point of Contact Number"))
      dispatch(unset_loader())
    }
    else {
      dispatch(add_building(token, buildings))
    }
  }
}
export function add_building(token, buildings) {
  var building_accounts = JSON.stringify([{ "bank_name": buildings.building_bank_name, "ifsc_code": buildings.building_ifsc_code, "account_number": buildings.building_account_no }])
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/add_building_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_address: buildings.building_address,
        building_poc_name: buildings.building_poc_name,
        building_poc_phonenumber: buildings.building_poc_number,
        building_accounts: building_accounts,
        building_name: buildings.building_name,
        location: [buildings.latitude, buildings.longitude]
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(reset());
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function reset() {
  return {
    type: RESET_ADD_BUILDING
  }
}
export function set_building_address(payload) {
  return {
    type: SET_BUILDING_ADDRESS,
    payload: payload
  }
}
export function set_building_latitude(payload) {
  return {
    type: SET_BUILDING_LATITUDE,
    payload: payload
  }
}
export function set_building_longitude(payload) {
  return {
    type: SET_BUILDING_LONGITUDE,
    payload: payload
  }
}
export function set_building_name(payload) {
  return {
    type: SET_BUILDING_NAME,
    payload: payload
  }
}
export function set_building_poc_name(payload) {
  return {
    type: SET_BUILDING_POC_NAME,
    payload: payload
  }
}
export function set_building_poc_number(payload) {
  return {
    type: SET_BUILDING_POC_NUMBER,
    payload: payload
  }
}
export function set_building_account_no(payload) {
  return {
    type: SET_BUILDING_ACCOUNT_NO,
    payload: payload
  }
}
export function set_building_ifsc_code(payload) {
  return {
    type: SET_BUILDING_IFSC_CODE,
    payload: payload
  }
}
export function set_building_bank_name(payload) {
  return {
    type: SET_BUILDING_BANK_NAME,
    payload: payload
  }
}
export function set_all_building_admins(building_admins) {
  return {
    type: SET_BUILDING_ADMINS,
    payload: building_admins
  }
}
export function get_all_buildings(token) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/get_building_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_buildings(responseJson.result))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function get_building_admins(token, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/get_building_admins", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_all_building_admins(responseJson.result))
        } else {
          dispatch(set_all_building_admins([]))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function remove_building_admin(token, admin_id, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/remove_building_admins", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        admin_id: admin_id
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(get_building_admins(token, building_id))
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function set_all_buildings(payload) {
  return {
    type: SET_ALL_BUILDINGS,
    payload: payload,
  }
}

export function update_buiding_single(token, name, account_num, ifsc_code, bank_name, building_address, building_poc_name, building_poc_phonenumber, building_id, latitude, longitude) {
  var building_accounts = JSON.stringify([{ "bank_name": bank_name, "ifsc_code": ifsc_code, "account_number": account_num }])
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/update_building_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_name: name,
        building_id: building_id,
        building_address: building_address,
        building_poc_name: building_poc_name,
        building_poc_phonenumber: building_poc_phonenumber,
        building_accounts: building_accounts,
        location: [latitude, longitude]
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Building Updated Successfully"))
          dispatch(get_all_buildings(token))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function delete_single_building(token, building_id) {
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "v1/delete_building_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        building_id: building_id,
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, "Building Deleted Successfully"))
          dispatch(get_all_buildings(token))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, "Error Deleting Building"))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export function validate_create_credential(token, e_name, e_building_id, e_img, e_email, e_contact_info, id_arr) {
  return (dispatch) => {
    dispatch(set_loader());
    if (e_name === "") {
      dispatch(set_snack_bar(true, "Please enter Name"));
      dispatch(unset_loader())
    } else if (e_img === "") {
      dispatch(set_snack_bar(true, "Please upload Image"));
      dispatch(unset_loader())
    }
    else if (e_email === "") {
      dispatch(set_snack_bar(true, "Please provide email"));
      dispatch(unset_loader())
    }
    else if (e_contact_info === "") {
      dispatch(set_snack_bar(true, "Please enter contact info"));
      dispatch(unset_loader())
    }
    else {
      var storageRef = firebase.storage().ref();
      var uploadTask = storageRef.child("images/" + e_name + ".png").put(e_img);
      uploadTask.on("state_changed", function (snapshot) {
      }, function (error) {
        dispatch(set_snack_bar(true, "Image Could Not Be Uploaded"));
      }, function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch(create_login_credential(token, e_name, e_building_id, downloadURL, e_email, e_contact_info, id_arr));
        });
      });
    }
  };
}
export function create_login_credential(token, e_name, e_building_id, downloadURL, e_email, e_contact_info, id_arr) {
  return (dispatch) => {
    return fetch(UNIVERSAL.BASEURL + "v1/add_admin_employee", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "user-token": token
      },
      body: JSON.stringify({
        employee_name: e_name,
        building_id: e_building_id,
        employee_img: downloadURL,
        employee_email: e_email,
        employee_contact_info: e_contact_info,
        departments: JSON.stringify(id_arr)
      }),
    }).then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(get_building_admins(token, e_building_id));
          // dispatch(get_all_buildings(token))
          // dispatch(setRedirect(true))
        }
        else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message))
        }
        dispatch(unset_loader())
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
export function setRedirect(value) {
  return {
    type: SET_REDIRECT,
    payload: value
  }
}
export function set_redirect_variable() {
  return {
    type: SET_REDIRECT_OPTION
  }
}
