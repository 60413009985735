import React, { Component } from "react";
import { connect } from "react-redux";
import AddClassified from "../../components/classified/add_classified";
import {
    close_snack_bar
} from "../../actions/snackbar/snackbar_action";
import {
    validate_add_classified,
    set_ad_name,
    set_ad_desc,
    set_ad_contact_name,
    set_ad_contact_number,
    set_ad_images,
    reset_classified
} from "../../actions/classified/classified_action"
export class Add_Classified_Cont extends Component {
    render() {
        return (
            <AddClassified {...this.props} />
        );
    };
};
export const mapStateToProps = store => {
    return {
        classified: store.classified,
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        validate_add_classified: (token, building_id, classified, limit, starting_after) => {
            dispatch(validate_add_classified(token, building_id, classified, limit, starting_after));
        },
        set_ad_images: (event) => {
            dispatch(set_ad_images(event));
        },
        set_ad_name: (ad_name) => {
            dispatch(set_ad_name(ad_name));
        },
        set_ad_desc: (ad_desc) => {
            dispatch(set_ad_desc(ad_desc));
        },
        set_ad_contact_name: (contact_name) => {
            dispatch(set_ad_contact_name(contact_name));
        },
        set_ad_contact_number: (contact_number) => {
            dispatch(set_ad_contact_number(contact_number));
        },
        reset_classified: (contact_number) => {
            dispatch(reset_classified(contact_number));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Add_Classified_Cont); 