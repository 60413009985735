import React, { Component } from "react/index.js";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../cards/CardHeader.js";
import CardIcon from "../cards/CardIcon.js";
import Card from "../cards/Card.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "../snackbar/snackbar";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { styles } from "../../styles/style";
import LoaderCon from "../../containers/loader/loader_cont";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import "../../styles/style.scss";
const card = {
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  marginTop: 50
};
class ViewDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editredirect: false,
      name: "",
      desc: "",
      id: "",
      confirm_delete: false,
    }
  }
  componentDidMount() {
    this.props.get_all_departments(this.props.login.token, this.props.login.building_id, this.props.department.limit, this.props.department.starting_after)
  }
  handleClose = () => {
    this.setState({
      editredirect: true,
    })
  }
  edit_single_unit = (s) => {
    this.setState({
      name: s.department_name,
      desc: s.department_desc,
      id: s._id,
      editredirect: true,
    })
  }
  del_single_row = (row) => {
    this.setState({
      confirm_delete: true,
      id: row._id,
    })
  }
  render() {
    const {
      department,
      snackbar,
      login,
      close_snack_bar,
      prev_btn,
      next_btn,
      validate_update_department,
      delete_single_department,
    } = this.props;
    var btn;
    if (department.total > department.limit) {
      if (department.end_var === department.total) {
        btn = <span >
          <IconButton
            onClick={() => { prev_btn(login.token, login.building_id, department.limit, department.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton disabled>
            <Icon>navigate_next</Icon>
          </IconButton>
          {department.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{department.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{department.total}
        </span>
      }
      else if (department.start_var > department.limit && department.end_var < department.total) {
        btn = <span>
          <IconButton
            onClick={() => { prev_btn(this.props.login.token, login.building_id, department.limit, department.starting_after); }}
          >
            <Icon>navigate_before</Icon>
          </IconButton>
          <IconButton
            onClick={() => { next_btn(this.props.login.token, login.building_id, department.limit, department.starting_after); }}
          >
            <Icon>navigate_next</Icon>
          </IconButton>
          {department.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{department.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{department.total}
        </span>
      }
      else {
        btn =
          <span >
            <IconButton disabled>
              <Icon>navigate_before</Icon>
            </IconButton>
            <IconButton
              onClick={() => { next_btn(login.token, login.building_id, department.limit, department.starting_after); }}
            >
              <Icon>navigate_next</Icon>
            </IconButton>
            {department.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{department.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{department.total}
          </span>
      }
    }
    else {
      btn = <span >
        <IconButton disabled>
          <Icon>navigate_before</Icon>
        </IconButton>
        <IconButton disabled>
          <Icon>navigate_next</Icon>
        </IconButton>
        {department.start_var}&nbsp;&nbsp;-&nbsp;&nbsp;{department.end_var}&nbsp;&nbsp;out of&nbsp;&nbsp;{department.total}
      </span>
    }
    return (
      <Grid container spacing={8} justify="center">
        <Grid item sm={12} spacing={8}>
          <Card style={card}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="rose">
                <h3>
                  VIEW DEPARTMENT
                </h3>
              </CardIcon>
            </CardHeader>
            <CardContent>
              <Grid item lg={12} xs={12} justify="center">
                <br /><br />
                <div style={{ float: "right" }}>
                  {btn}
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">&nbsp;&nbsp;Name</TableCell>
                      <TableCell align="left">Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {department.all_department.map(row => (
                      <TableRow key={row._id}>
                        <TableCell align="left" className="table_cells">
                          {row.department_name}
                        </TableCell>
                        <TableCell align="left" className="table_cells">
                          {row.department_desc}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </CardContent>
          </Card>
          <Dialog
            open={this.state.editredirect}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Edit Your information Here And then Update it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
              <Grid container>
                <Grid container>
                  <TextField
                    type="text"
                    label="Name"
                    style={{ backgroundColor: "white" }}
                    placeholder={this.state.name}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.name}
                    onChange={(event) => { this.setState({ name: event.target.value }) }}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    type="dropdown"
                    label="Description"
                    placeholder={this.state.desc}
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={this.state.desc}
                    onChange={(event) => { this.setState({ desc: event.target.value }) }}
                    InputProps={{ classes: { input: this.props.classes.dropdown } }}
                    InputLabelProps={{ classes: { root: this.props.classes.textfieldLabel } }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    editredirect: false,
                  });
                }}
                color="primary">
                Close
              </Button>
              <Button
                onClick={() => {
                  validate_update_department(this.props.login.token, this.state.id, login.building_id, this.state.name, this.state.desc, department.limit, department.starting_after);
                  this.setState({
                    editredirect: false
                  })
                }}
                color="primary" autoFocus>
                UPDATE
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirm_delete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete it</DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogContent>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    confirm_delete: false,
                    id: ""
                  });
                }}
                color="primary">
                No
            </Button>
              <Button
                onClick={() => {
                  delete_single_department(login.token, department.all_department, login.building_id, department.limit, department.starting_after);
                  this.setState({
                    confirm_delete: false,
                  })
                }}
                color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <LoaderCon />
          <Snackbar
            open={snackbar.response_received}
            close_snack_bar={close_snack_bar}
            message={snackbar.message}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(ViewDepartment);