import {
    SET_ALL_ASSETS,
    RESET,
    SET_ALL_ASSETS_TYPES,
} from "../../constants/assets/assetsConst";
import { LIMIT } from "../../constants/ActionTypes";

const initial_state = {
    all_assets: [],
    all_assets_types: [],
    limit: LIMIT,
    startingAfter: 0,
    start_var: "",
    end_var: "",
    total: "",
    mode: ["Cash", "UPI", "Cheque", "Bank"]
};
// case SET_ALL_E_NOTICE:
// return state = { ...state, all_e_notices: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, check: action.payload.check };

export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case SET_ALL_ASSETS:
            return state = { ...state, all_assets: action.payload.response, start_var: action.payload.f, end_var: action.payload.s, total: action.payload.total, startingAfter: action.payload.starting_after };
        case SET_ALL_ASSETS_TYPES:
            return state = { ...state, all_assets_types: action.payload };
        case RESET:
            return state = {
                ...state,
                all_assets: [],
            };
        default:
            return state;
    }
}
